export var CURRENCY_TYPE;
(function (CURRENCY_TYPE) {
    CURRENCY_TYPE["BNB"] = "BNB";
    CURRENCY_TYPE["BUSD"] = "BUSD";
    CURRENCY_TYPE["USDT"] = "USDT";
    CURRENCY_TYPE["USDC"] = "USDC";
    CURRENCY_TYPE["USDC_E"] = "USDC.E";
    CURRENCY_TYPE["DAI"] = "DAI";
    CURRENCY_TYPE["BLID"] = "BLID";
    CURRENCY_TYPE["BLID_USDT"] = "BLID-USDT";
    CURRENCY_TYPE["ETH"] = "ETH";
    CURRENCY_TYPE["BTC"] = "BTC";
    CURRENCY_TYPE["XRP"] = "XRP";
    CURRENCY_TYPE["XVS"] = "XVS";
    CURRENCY_TYPE["LTC"] = "LTC";
    CURRENCY_TYPE["ADA"] = "ADA";
    CURRENCY_TYPE["LINK"] = "LINK";
    CURRENCY_TYPE["DOT"] = "DOT";
    CURRENCY_TYPE["MATIC"] = "MATIC";
})(CURRENCY_TYPE || (CURRENCY_TYPE = {}));
