export var TRANSACTION_STEP_ID;
(function (TRANSACTION_STEP_ID) {
    TRANSACTION_STEP_ID["SWITCH_NETWORK"] = "SWITCH_NETWORK";
    TRANSACTION_STEP_ID["APPROVE"] = "APPROVE";
    TRANSACTION_STEP_ID["MINT"] = "MINT";
})(TRANSACTION_STEP_ID || (TRANSACTION_STEP_ID = {}));
export var PROCESS_ID_FOR_TRANSACTION_STEP;
(function (PROCESS_ID_FOR_TRANSACTION_STEP) {
    PROCESS_ID_FOR_TRANSACTION_STEP["NOT_STARTED"] = "NOT_STARTED";
    PROCESS_ID_FOR_TRANSACTION_STEP["STARTED"] = "STARTED";
    PROCESS_ID_FOR_TRANSACTION_STEP["PENDING"] = "PENDING";
    PROCESS_ID_FOR_TRANSACTION_STEP["FAILED"] = "FAILED";
    PROCESS_ID_FOR_TRANSACTION_STEP["SUCCESS"] = "SUCCESS";
    PROCESS_ID_FOR_TRANSACTION_STEP["CANCELLED"] = "CANCELLED";
    PROCESS_ID_FOR_TRANSACTION_STEP["CONDITIONS_NOT_MET"] = "CONDITIONS_NOT_MET";
})(PROCESS_ID_FOR_TRANSACTION_STEP || (PROCESS_ID_FOR_TRANSACTION_STEP = {}));
