import multiCallManager from '@/web3-server/web3/handler/multicall-manager';
import { getDistributorContractAddress } from '@/web3-server/web3/actions/nft/get-distributor-contract-address';
import NFTDistributor from '@/web3-server/abis/nft/distributor.json';
import converter from '@/web3-server/utils/converter';
import linker from '@/web3-server/utils/linker';
import { devError } from '@/utils/devError';
import multiCallUtils from '@/web3-server/web3/handler/multicall-utils';
import getNftImg from '@/web3-server/web3/actions/nft/get-nft-img';
import { getCurrencyCallsForMultiCall, getCurrencyContractsForMultiCall, } from '@/web3-server/web3-currencies/actions/get-simple-basic-info';
import { CALL_REFERENCE_FOR_CURRENCY } from '@/web3-server/web3-currencies/types/multicall-currencies/constants';
var CALL_REFERENCE;
(function (CALL_REFERENCE) {
    CALL_REFERENCE["GET_NUM_OF_CREATED_NFT"] = "numOfCreatedNFT_";
    CALL_REFERENCE["GET_IMG_URL"] = "imgUrl_";
    CALL_REFERENCE["GET_CONFIG"] = "config_";
    CALL_REFERENCE["MINT_IS_AVAILABLE"] = "mintIsAvailable_";
})(CALL_REFERENCE || (CALL_REFERENCE = {}));
var NAME_OF_METHODS;
(function (NAME_OF_METHODS) {
    NAME_OF_METHODS["GET_NUM_OF_CREATED_NFT"] = "totalSupply";
    NAME_OF_METHODS["GET_IMG_URL"] = "uri";
    NAME_OF_METHODS["GET_CONFIG"] = "config";
    NAME_OF_METHODS["MINT_IS_AVAILABLE"] = "isMintAvailable";
})(NAME_OF_METHODS || (NAME_OF_METHODS = {}));
var GROUP_ID;
(function (GROUP_ID) {
    GROUP_ID["COLLECTION"] = "COLLECTION";
    GROUP_ID["DISTRIBUTOR"] = "DISTRIBUTOR";
    GROUP_ID["CURRENCY"] = "CURRENCY";
})(GROUP_ID || (GROUP_ID = {}));
export async function getNftCollection(request, userWalletAddress, currency, contractNFTCollection) {
    const contracts = await getContracts(request, currency, contractNFTCollection);
    const calls = getCalls(contractNFTCollection.id, contractNFTCollection.networkId, currency, userWalletAddress, contracts.distributor?.contractAddress);
    const nftCollectionResult = {
        priceForEveryone: linker.decimal.getSimple('0'),
        notAvailableQuantity: linker.decimal.getSimple('0'),
        totalQuantity: linker.decimal.getSimple('0'),
        isMintAvailable: false,
        imgPromise: null,
    };
    const currencyResult = {
        availableUserBalance: linker.decimal.getSimple('0'),
        approvedAmountByUser: linker.decimal.getSimple('0'),
    };
    try {
        const multiCallByNFTCollection = multiCallManager(request, contracts.list);
        const multiCallResponse = await multiCallByNFTCollection.call({
            multiCall: calls.list.filter((item) => !!item.value.length),
        });
        if (multiCallResponse && multiCallResponse.length) {
            const getGroupIdForSorting = (groupId) => groupId && groupId === GROUP_ID.COLLECTION ? 1 : 0;
            multiCallResponse
                .sort((a, b) => getGroupIdForSorting(b.groupId) - getGroupIdForSorting(a.groupId))
                .forEach((callsResults) => {
                if (callsResults.value?.length) {
                    for (const item of callsResults.value) {
                        if (callsResults.groupId === GROUP_ID.COLLECTION) {
                            if (item.reference === CALL_REFERENCE.GET_NUM_OF_CREATED_NFT + callsResults.id) {
                                nftCollectionResult.notAvailableQuantity = linker.decimal.getSimple(multiCallUtils.getVal('hex', item, 0));
                            }
                            else if (item.reference === CALL_REFERENCE.GET_IMG_URL + callsResults.id) {
                                const pathToJSONImgFile = multiCallUtils.getVal('string', item, 0);
                                if (pathToJSONImgFile) {
                                    nftCollectionResult.imgPromise = getNftImg(pathToJSONImgFile);
                                }
                            }
                        }
                        else if (callsResults.groupId === GROUP_ID.DISTRIBUTOR) {
                            if (item.reference === CALL_REFERENCE.GET_CONFIG + callsResults.id) {
                                const totalQuantity = linker.decimal.getSimple(multiCallUtils.getVal('hex', item, 2));
                                nftCollectionResult.priceForEveryone = converter.withType.fromWei(multiCallUtils.getVal('hex', item, 3), {
                                    decimals: currency.decimals,
                                });
                                nftCollectionResult.totalQuantity = totalQuantity;
                            }
                            else if (item.reference === CALL_REFERENCE.MINT_IS_AVAILABLE + callsResults.id) {
                                nftCollectionResult.isMintAvailable = multiCallUtils.getVal('boolean', item, 0);
                            }
                        }
                        else if (callsResults.groupId === GROUP_ID.CURRENCY) {
                            if (item.reference ===
                                CALL_REFERENCE_FOR_CURRENCY.GET_TOKEN_BALANCE + callsResults.id) {
                                currencyResult.availableUserBalance = converter.withType.fromWei(multiCallUtils.getVal('hex', item, 0), { decimals: currency.decimals });
                            }
                            else if (item.reference ===
                                CALL_REFERENCE_FOR_CURRENCY.GET_TOKEN_APPROVAL + callsResults.id) {
                                currencyResult.approvedAmountByUser = converter.withType.fromWei(multiCallUtils.getVal('hex', item, 0), { decimals: currency.decimals });
                            }
                        }
                    }
                }
            });
        }
    }
    catch (e) {
        devError(e);
    }
    return { collection: nftCollectionResult, currency: currencyResult };
}
function getDistributorContract(request, contractNFTCollection) {
    return getDistributorContractAddress({ web3: request[0].value }, {
        address: contractNFTCollection.contractAddress,
        abi: contractNFTCollection.abi,
        id: contractNFTCollection.id,
    })
        .then((address) => {
        if (address) {
            return {
                ...contractNFTCollection,
                contractAddress: address,
                abi: NFTDistributor,
                groupId: GROUP_ID.DISTRIBUTOR,
            };
        }
        else {
            return null;
        }
    })
        .catch(() => null);
}
async function getContracts(request, currency, contractNFTCollection) {
    const contractNFTCollectionWithGroupID = {
        ...contractNFTCollection,
        groupId: GROUP_ID.COLLECTION,
    };
    const currentDistributorContract = await getDistributorContract(request, contractNFTCollection);
    const listOfCurrencyContracts = getCurrencyContractsForMultiCall([currency], [
        { currencyId: currency.id, address: currentDistributorContract?.contractAddress || '' },
    ].filter((item) => !!item.address));
    const currencyContractWithGroupId = {
        ...listOfCurrencyContracts[0],
        groupId: GROUP_ID.CURRENCY,
    };
    const list = [contractNFTCollectionWithGroupID, currencyContractWithGroupId];
    if (currentDistributorContract) {
        list.push(currentDistributorContract);
    }
    return {
        collection: contractNFTCollectionWithGroupID,
        distributor: currentDistributorContract,
        currency: currencyContractWithGroupId,
        list,
    };
}
function getCalls(nftId, networkId, currency, userWalletAddress, distributorContractAddress) {
    const callsForContractNFTCollection = {
        id: nftId,
        groupId: GROUP_ID.COLLECTION,
        networkId,
        value: [
            {
                reference: CALL_REFERENCE.GET_NUM_OF_CREATED_NFT + nftId,
                methodName: NAME_OF_METHODS.GET_NUM_OF_CREATED_NFT,
                methodParameters: [nftId],
            },
            {
                reference: CALL_REFERENCE.GET_IMG_URL + nftId,
                methodName: NAME_OF_METHODS.GET_IMG_URL,
                methodParameters: [nftId],
            },
        ],
    };
    const callsForDistributorContract = {
        id: nftId,
        groupId: GROUP_ID.DISTRIBUTOR,
        networkId,
        value: [
            {
                reference: CALL_REFERENCE.GET_CONFIG + nftId,
                methodName: NAME_OF_METHODS.GET_CONFIG,
                methodParameters: [],
            },
            {
                reference: CALL_REFERENCE.MINT_IS_AVAILABLE + nftId,
                methodName: NAME_OF_METHODS.MINT_IS_AVAILABLE,
                methodParameters: [],
            },
        ],
    };
    const callsForCurrencyContract = userWalletAddress
        ? getCurrencyCallsForMultiCall([currency], distributorContractAddress
            ? [{ currencyId: currency.id, address: distributorContractAddress }]
            : [], userWalletAddress, true).map((call) => {
            return {
                ...call,
                groupId: GROUP_ID.CURRENCY,
            };
        })
        : [];
    const calls = [...callsForCurrencyContract, callsForContractNFTCollection];
    if (distributorContractAddress) {
        calls.push(callsForDistributorContract);
    }
    return {
        collection: callsForContractNFTCollection,
        distributor: callsForDistributorContract,
        currency: callsForCurrencyContract,
        list: calls,
    };
}
