import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import converter from '@/web3-server/utils/converter';
export async function getPriceByQuantity(request, nftDistributionContractConfig, amount, currencyDecimals) {
    try {
        const nftDistributionContract = new request.web3.eth.Contract(nftDistributionContractConfig.abi, nftDistributionContractConfig.address);
        const price = await nftDistributionContract.methods.getPrice(amount).call();
        return converter.withType.fromWei(price, { decimals: currencyDecimals });
    }
    catch (e) {
        devError(DEV_ERROR_CODE.WEB_CONTRACT, e);
        return converter.withType.fromWei('0');
    }
}
