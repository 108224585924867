import { defineStore } from 'pinia';
import providerActions from '@/web3-server/wallet/actions/provider';
import { TYPES_OF_WALLET_PROVIDERS } from '@/web3-server/wallet/types/provider/constants';
import connectActions from '@/web3-server/wallet/actions/connect';
import networkActions from '@/web3-server/wallet/actions/network';
import listOfSwapSites from '@/web3-server/wallet/data/list-of-swap-sites';
import BLIDPriceActions from '@/web3-server/wallet/actions/blid-price';
import { walletSessionActions } from '@/web3-server/wallet/actions/session/wallet-session';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import { LiFiWalletManagement } from '@lifi/wallet-management';
import liFiWidgetActions from '@/web3-server/wallet/actions/li-fi';
export const useWalletStore = defineStore('wallet', {
    state: () => ({
        userAddress: '',
        userName: '',
        isLoadingUserName: false,
        network: null,
        provider: null,
        wc: {
            provider: null,
            localeStorageKey: {
                session: 'wc@2:client:0.3//session',
                chainId: 'wc@2:ethereum_provider:/chainId',
            },
        },
        blocto: {
            currentNetwork: {
                key: 'bloctoNetwork',
                value: NETWORK_ID.BNB,
            },
            sdk: null,
        },
        disableAutoLogin: {
            key: 'disableAutoLogin',
            value: connectActions.isAutoAuth('disableAutoLogin'),
        },
        walletSession: {
            key: 'walletSession',
            value: walletSessionActions.getWalletSession('walletSession'),
        },
        listOfSwapSites,
        liFiWidget: {
            wallet: {
                account: {},
            },
            walletManagement: new LiFiWalletManagement(),
            currentPurchaseNetwork: null,
            currentTokenDefaultAmountFrom: 10,
        },
    }),
    actions: {
        ...liFiWidgetActions,
        ...providerActions,
        ...connectActions,
        ...networkActions,
        ...BLIDPriceActions,
        ...walletSessionActions,
    },
    getters: {
        isWalletConnectFromState: (state) => {
            return !!(state.provider &&
                state.provider.type &&
                state.provider.type === TYPES_OF_WALLET_PROVIDERS.WALLET_CONNECT &&
                state.provider.session);
        },
        isAuth: (state) => !!state.userAddress,
    },
});
