import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "flex flex-wrap space-x-0.5" };
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SpinnerMini = _resolveComponent("SpinnerMini");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
            return (_openBlock(), _createElementBlock("div", {
                key: item.id,
                class: _normalizeClass(["relative py-[7px] px-4 my-px tracking-tight leading-10/10 bg-dark rounded-[45px] cursor-pointer title-italic", [
                        _ctx.customClass,
                        {
                            'opacity-30 cursor-not-allowed': item.disabled,
                            'hover:scale-95 active:scale-90': !item.disabled && !_ctx.isBlocking && _ctx.isLoading !== item.id,
                        },
                    ]]),
                onClick: ($event) => (_ctx.setSelect(item.id))
            }, [
                (_ctx.isLoading && item.id === _ctx.isLoading)
                    ? (_openBlock(), _createBlock(_component_SpinnerMini, {
                        key: 0,
                        class: "absolute inset-0 m-auto w-[15px] h-[15px]"
                    }))
                    : _createCommentVNode("", true),
                _createElementVNode("span", {
                    class: _normalizeClass({ 'opacity-0': _ctx.isLoading && item.id === _ctx.isLoading })
                }, _toDisplayString(item.symbol), 3)
            ], 10, _hoisted_2));
        }), 128))
    ]));
}
