import multiCallManager from '@/web3-server/web3/handler/multicall-manager';
import Logic from '@/web3-server/abis/strategies/Logic.json';
import multiCallUtils from '@/web3-server/web3/handler/multicall-utils';
import checker from '@/web3-server/utils/checker';
import { devError } from '@/utils/devError';
export async function getAddressesOfLogicContracts(requestByNetworks, multiCall, listOfStrategyId) {
    const addressesOfLogicContractsByStrategyId = [];
    try {
        const multiCallResponse = await multiCall.call({
            singleCall: [
                {
                    reference: 'logicContractCall',
                    methodName: 'logicContract',
                    methodParameters: [],
                },
            ],
        }, listOfStrategyId);
        const multiCallForLogicContract = multiCallManager(requestByNetworks, multiCallResponse.reduce((prev, item) => {
            const value = item.value.length ? item.value[0] : null;
            if (value && value.returnValues && value.returnValues[0]) {
                prev.push({
                    id: item.id,
                    networkId: item.networkId,
                    contractAddress: value.returnValues[0],
                    abi: Logic.abi,
                });
            }
            return prev;
        }, []));
        const numOfMultiStrategies = await multiCallForLogicContract.call({
            singleCall: [
                {
                    reference: 'multiStrategyLenCall',
                    methodName: 'multiStrategyLength',
                    methodParameters: [],
                },
            ],
        });
        const namesOfMultiStrategies = await multiCallForLogicContract.call({
            multiCall: numOfMultiStrategies.map((item) => {
                const count = multiCallUtils.getVal('hex', item.value && item.value[0], 0);
                const calls = [];
                if (checker.numericStr.isNotEmpty(count)) {
                    for (let i = 0; i < Number(count); i++) {
                        calls.push({
                            reference: `multiStrategyName${i}`,
                            methodName: 'multiStrategyName',
                            methodParameters: [i],
                        });
                    }
                }
                return {
                    id: item.id,
                    networkId: item.networkId,
                    value: calls,
                };
            }),
        });
        const allLogicContractAddresses = await multiCallForLogicContract.call({
            multiCall: namesOfMultiStrategies.map((item) => {
                return {
                    id: item.id,
                    networkId: item.networkId,
                    value: item.value.reduce((prev, val) => {
                        const name = multiCallUtils.getVal('string', val, 0);
                        if (name) {
                            prev.push({
                                reference: `strategyInfo${name}`,
                                methodName: 'strategyInfo',
                                methodParameters: [name],
                            });
                        }
                        return prev;
                    }, []),
                };
            }),
        });
        allLogicContractAddresses.forEach((callResult) => {
            if (callResult.value?.length) {
                const currentLogicAddress = {
                    strategyId: callResult.id,
                    list: [],
                };
                for (const item of callResult.value) {
                    if (item.methodParameters?.length && item.returnValues?.length) {
                        currentLogicAddress.list.push({
                            name: item.methodParameters[0].replace(/_/g, '-'),
                            address: item.returnValues[0],
                        });
                    }
                }
                addressesOfLogicContractsByStrategyId.push(currentLogicAddress);
            }
        });
    }
    catch (e) {
        devError(e);
    }
    return addressesOfLogicContractsByStrategyId.filter((item) => item.list.length);
}
