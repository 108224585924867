import getNativeTokenBalance from '@/web3-server/web3-currencies/actions/native-token/composables/get-native-token-balance';
import { requestAPI } from '@/web3-server/web3/handler/inspectors';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import getMaxApproval from '@/web3-server/web3-currencies/actions/native-token/composables/get-max-approval';
import Decimal from 'decimal.js';
const nativeTokenActions = {
    async getNativeBalance(currencyId) {
        const currency = this.getById(currencyId);
        const defaultValue = {
            wei: new Decimal(0),
            simple: new Decimal(0),
            simpleInStringFormat: '0',
        };
        if (currency && currency.relations?.vault) {
            const request = requestAPI('user', { web3: { vault: currency.relations.vault } });
            if (request.isUser && currency.decimals) {
                return getNativeTokenBalance(request.getWithUser, currency.decimals);
            }
            else {
                devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR, {
                    isUser: request.isUser,
                    decimals: currency.decimals,
                });
                return defaultValue;
            }
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_ITEM_SEARCH);
            return defaultValue;
        }
    },
    getMaxApproval() {
        return getMaxApproval();
    },
};
export default nativeTokenActions;
