import Decimal from 'decimal.js';
import web3 from 'web3';
import { getDefaultCalcByDecimal, getShortDecimal } from '@/web3-server/web3/handler/calc';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import multiCallUtils from '@/web3-server/web3/handler/multicall-utils';
import { walletNetworkById } from '@/web3-server/wallet/data/network';
const BLOCK_PER_YEAR = {
    [NETWORK_ID.BNB]: new Decimal(web3.utils.toWei(walletNetworkById.BNB.blockPerYear)),
    [NETWORK_ID.POLYGON]: new Decimal(web3.utils.toWei(walletNetworkById.POLYGON.blockPerYear)),
    [NETWORK_ID.ETH]: new Decimal(web3.utils.toWei(walletNetworkById.ETH.blockPerYear)),
    [NETWORK_ID.ARBITRUM]: new Decimal(web3.utils.toWei(walletNetworkById.ARBITRUM.blockPerYear)),
    [NETWORK_ID.OPTIMISM]: new Decimal(web3.utils.toWei(walletNetworkById.OPTIMISM.blockPerYear)),
    [NETWORK_ID.GOERLI]: new Decimal(web3.utils.toWei(walletNetworkById.GOERLI.blockPerYear)),
};
export async function getBoostingMaxAPYForAllStrategies(multiCall, listOfMinStrategyConfigById, BLIDPriceWei, listOfStrategyResults) {
    const listOfBoostingMaxAPY = [];
    try {
        const multiCallResponse = await multiCall.call({
            multiCall: listOfMinStrategyConfigById.map((item) => {
                return {
                    id: item.strategyId,
                    networkId: item.networkId,
                    value: [
                        {
                            reference: 'blidPerBlockCall',
                            methodName: 'blidPerBlock',
                            methodParameters: [],
                        },
                        {
                            reference: 'maxBlidPerUSDCall',
                            methodName: 'maxBlidPerUSD',
                            methodParameters: [],
                        },
                    ],
                };
            }),
        });
        if (multiCallResponse && multiCallResponse.length) {
            multiCallResponse.forEach((callsResults) => {
                const defaultResult = {
                    apr: {
                        original: 0,
                        roundedDown: 0,
                    },
                    apy: {
                        original: 0,
                        roundedDown: 0,
                    },
                    pureAPR: {
                        original: 0,
                        roundedDown: 0,
                    },
                };
                if (callsResults.value && callsResults.value.length) {
                    const BLIDPerBlock = callsResults.value.find((item) => item.reference === 'blidPerBlockCall');
                    const maxBLIDPerUSD = callsResults.value.find((item) => item.reference === 'maxBlidPerUSDCall');
                    let BLIDPerBlockByString = '0';
                    let maxBLIDPerUSDByString = '0';
                    if (BLIDPerBlock && BLIDPerBlock.returnValues && BLIDPerBlock.returnValues.length) {
                        BLIDPerBlockByString = multiCallUtils.getVal('hex', BLIDPerBlock, 0);
                    }
                    if (maxBLIDPerUSD && maxBLIDPerUSD.returnValues && maxBLIDPerUSD.returnValues.length) {
                        maxBLIDPerUSDByString = multiCallUtils.getVal('hex', maxBLIDPerUSD, 0);
                    }
                    if (BLIDPerBlockByString &&
                        maxBLIDPerUSDByString &&
                        BLIDPerBlockByString !== '0' &&
                        maxBLIDPerUSDByString !== '0') {
                        const boosting = getMaxAPYPromise(BLIDPerBlockByString, maxBLIDPerUSDByString, BLIDPriceWei, callsResults.networkId);
                        const strategyResults = listOfStrategyResults
                            ? listOfStrategyResults.find((item) => item.strategyId === callsResults.id)
                            : undefined;
                        listOfBoostingMaxAPY.push({
                            strategyId: callsResults.id,
                            networkId: callsResults.networkId,
                            value: {
                                apr: getBoostingRounded(boosting.apr, strategyResults && strategyResults.value.apr),
                                apy: getBoostingRounded(boosting.apy, strategyResults && strategyResults.value.apy),
                                pureAPR: getBoostingRounded(boosting.apr),
                            },
                        });
                    }
                    else {
                        listOfBoostingMaxAPY.push({
                            strategyId: callsResults.id,
                            networkId: callsResults.networkId,
                            value: defaultResult,
                        });
                    }
                }
                else {
                    listOfBoostingMaxAPY.push({
                        strategyId: callsResults.id,
                        networkId: callsResults.networkId,
                        value: defaultResult,
                    });
                }
            });
        }
    }
    catch (e) {
        devError(e);
    }
    return listOfBoostingMaxAPY;
}
function getMaxAPYPromise(BLIDPerBlockByString, maxBLIDPerUSDByString, BLIDPriceWei, networkId) {
    try {
        const BLIDPerBlock = new Decimal(BLIDPerBlockByString);
        const maxBLIDPerUSDWei = new Decimal(maxBLIDPerUSDByString).div(2);
        const hundred = new Decimal(web3.utils.toWei('100'));
        const max = BLIDPerBlock.mul(BLOCK_PER_YEAR[networkId] || BLOCK_PER_YEAR.BNB)
            .mul(BLIDPriceWei)
            .mul(maxBLIDPerUSDWei)
            .mul(hundred);
        const apr = getDefaultCalcByDecimal(max, { type: 'divide', times: 5 });
        const pureBoosting = new Decimal(apr.formatted.full).div(100).div(365);
        const boostingAPY = new Decimal(Decimal.pow(new Decimal(1).plus(pureBoosting), 365))
            .minus(1)
            .mul(100);
        return {
            apr,
            apy: getDefaultCalcByDecimal(boostingAPY),
        };
    }
    catch (e) {
        devError(DEV_ERROR_CODE.WEB_CONTRACT_METHOD);
        throw e;
    }
}
function getBoostingRounded(boosting, apy) {
    const result = {
        original: 0,
        roundedDown: 0,
    };
    if (boosting && boosting.formatted && boosting.formatted.full) {
        const calc = new Decimal(boosting.formatted.full).plus(apy || 0);
        result.original = calc.toNumber();
        result.roundedDown = getShortDecimal(calc, 2, 'down').toNumber();
    }
    return result;
}
