import Decimal from 'decimal.js';
import { getSimpleDecimal } from '@/web3-server/web3/handler/calc';
import { devError } from '@/utils/devError';
import checker from '@/web3-server/utils/checker';
import { DECIMAL_TYPE } from '@/web3-server/types/decimal/constants';
import clearNumLine from '@/web3-server/utils/composables/clear-num-line';
import web3 from 'web3';
const converter = {
    withType: {
        fromWeiToSimple: fromWei,
        fromWei,
        fromSimpleToWei: toWei,
        toWei,
    },
    withoutType: {
        fromWeiToSimple: fromWeiWithoutType,
        fromWei: fromWeiWithoutType,
        fromSimpleToWei: toWeiWithoutType,
        toWei: toWeiWithoutType,
    },
    toHex: toHex,
    numericStr: {
        toDecimal: getDecimalFromNumberString,
    },
};
function toWeiWithoutType(val, extra = { decimals: 18 }) {
    return toWei(val, extra).value;
}
function toWei(val, extra = { decimals: 18 }) {
    if (val) {
        const wei = converterWei(val, 'multiply', extra.decimals);
        if (wei.value.isInteger() && wei.type === DECIMAL_TYPE.WEI) {
            return wei;
        }
        else if (!wei.value.isInteger() && extra.round && wei.type === DECIMAL_TYPE.WEI) {
            wei.value = wei.value.toDecimalPlaces(0, Decimal.ROUND_DOWN);
            return wei;
        }
        else {
            devError('Number is not integer');
            throw new Error('Number is not integer');
        }
    }
    else {
        return {
            type: DECIMAL_TYPE.WEI,
            value: new Decimal(0),
        };
    }
}
function fromWeiWithoutType(val, extra = { decimals: 18 }) {
    return fromWei(val, extra).value;
}
function fromWei(val, extra = { decimals: 18 }) {
    const simple = converterWei(val, 'divide', extra.decimals);
    if (simple.type === DECIMAL_TYPE.SIMPLE) {
        return simple;
    }
    else {
        devError('Decimal is not simple');
        throw new Error('Decimal is not simple');
    }
}
function converterWei(val, type, decimals = 18, repeatDueToError = false) {
    const decimal = Decimal.isDecimal(val) ? val : new Decimal(clearNumLine(val));
    const decimalType = type === 'divide' ? DECIMAL_TYPE.SIMPLE : DECIMAL_TYPE.WEI;
    if (checker.decimal.isPositive(decimal)) {
        if (decimals === 18 || decimals === 6) {
            const unit = decimals === 6 ? 'lovelace' : 'ether';
            try {
                return {
                    type: decimalType,
                    value: new Decimal(type === 'divide'
                        ? web3.utils.fromWei(decimal.toFixed(), unit)
                        : web3.utils.toWei(decimal.toFixed(), unit)),
                };
            }
            catch (e) {
                const error = e;
                devError(error);
                if (!repeatDueToError && error.message.includes('too many decimal places')) {
                    return converterWei(decimal.toDecimalPlaces(decimals), type, decimals, true);
                }
                else {
                    return {
                        type: decimalType,
                        value: getSimpleDecimal(decimal, { type, times: 1 }, decimals || 18),
                    };
                }
            }
        }
        else {
            return {
                type: decimalType,
                value: getSimpleDecimal(decimal, { type, times: 1 }, decimals || 18),
            };
        }
    }
    else {
        return {
            type: decimalType,
            value: new Decimal(0),
        };
    }
}
function toHex(val) {
    const decimal = Decimal.isDecimal(val) ? val : new Decimal(val || 0);
    if (checker.decimal.isNotEmpty(decimal)) {
        return decimal.toHex();
    }
    else {
        return new Decimal(0).toHex();
    }
}
function getDecimalFromNumberString(val) {
    return new Decimal(clearNumLine(val));
}
export default converter;
