import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
export function graphStorageTVLAdapter(response, contractAddress) {
    return response
        .reduce((prev, item) => {
        if (item.strategiesTvlData && item.strategiesTvlData.length) {
            const currentTVL = item.strategiesTvlData.find((item) => item.contractAddress && item.contractAddress === contractAddress);
            if (currentTVL &&
                currentTVL.totalStrategyTvl !== null &&
                currentTVL.totalStrategyTvl !== undefined) {
                const graph = {
                    date: item.date,
                    value: currentTVL.totalStrategyTvl,
                };
                prev.push(graph);
            }
        }
        return prev;
    }, [])
        .sort((a, b) => {
        return new Date(a.date).valueOf() - new Date(b.date).valueOf();
    });
}
export function graphTVLAdapter(response, contractType) {
    return get(response, getTVLKey(contractType));
}
export function graphAPYAdapter(response, contractType, contractAddress) {
    if (contractType === CONTRACT_TYPE.STORAGE) {
        return graphStorageAPYAdapter(response, contractAddress);
    }
    else {
        return get(response, contractType === CONTRACT_TYPE.STAKING ? 'stakingApy' : 'farmingApy');
    }
}
function graphStorageAPYAdapter(response, contractAddress) {
    if (response) {
        return response
            .reduce((prev, item) => {
            if (item.strategiesApyData && item.strategiesApyData.length && contractAddress) {
                const currentAPY = item.strategiesApyData.find((item) => item.contractAddress && item.contractAddress === contractAddress);
                if (currentAPY) {
                    const graph = {
                        date: item.date,
                        value: currentAPY.baseApy ? currentAPY.baseApy : currentAPY.strategyApy,
                    };
                    prev.push(graph);
                }
            }
            return prev;
        }, [])
            .sort((a, b) => {
            return new Date(a.date).valueOf() - new Date(b.date).valueOf();
        });
    }
    else {
        return [];
    }
}
function getTVLKey(contractType) {
    switch (contractType) {
        case CONTRACT_TYPE.STORAGE:
            return 'totalTvl';
        case CONTRACT_TYPE.STAKING:
            return 'stakingTvl';
        case CONTRACT_TYPE.FARMING:
            return 'farmingTvl';
        default:
            return undefined;
    }
}
function get(response, key) {
    if (response && key) {
        return response
            .reduce((prev, item) => {
            const num = item[key] ? Number(item[key]) : 0;
            if (num && !isNaN(num) && isFinite(num)) {
                const graph = {
                    date: item.date,
                    value: num,
                };
                prev.push(graph);
            }
            return prev;
        }, [])
            .sort((a, b) => {
            return new Date(a.date).valueOf() - new Date(b.date).valueOf();
        });
    }
    else {
        return [];
    }
}
