import BigNumber from 'bignumber.js';
const ethUnits = {
    gwei: 1000000000,
};
export const multiply = (numberOne, numberTwo) => new BigNumber(numberOne).times(numberTwo);
export const divide = (numberOne, numberTwo) => {
    if (!(numberOne || numberTwo))
        return new BigNumber(0);
    return new BigNumber(numberOne).dividedBy(numberTwo);
};
export const gweiToWei = (gweiAmount) => {
    const weiAmount = multiply(gweiAmount, ethUnits.gwei).toFixed(0);
    return weiAmount;
};
export const weiToGwei = (weiAmount) => {
    const gweiAmount = divide(weiAmount, ethUnits.gwei).toFixed();
    return gweiAmount;
};
export const weiToGweiNumber = (weiAmount) => {
    const gweiAmount = divide(weiAmount, ethUnits.gwei).toNumber();
    return gweiAmount;
};
