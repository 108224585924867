export var SINGLE_CONNECTION_TYPE;
(function (SINGLE_CONNECTION_TYPE) {
    SINGLE_CONNECTION_TYPE["EXODUS"] = "EXODUS";
    SINGLE_CONNECTION_TYPE["ANOTHER_ETHEREUM_EXTENSION"] = "ANOTHER_ETHEREUM_EXTENSION";
    SINGLE_CONNECTION_TYPE["ANY_ETHEREUM_EXTENSION"] = "ANY_ETHEREUM_EXTENSION";
    SINGLE_CONNECTION_TYPE["WALLET_CONNECT"] = "WALLET_CONNECT";
    SINGLE_CONNECTION_TYPE["BLOCTO"] = "BLOCTO";
})(SINGLE_CONNECTION_TYPE || (SINGLE_CONNECTION_TYPE = {}));
export var WALLET_SESSION_TYPE;
(function (WALLET_SESSION_TYPE) {
    WALLET_SESSION_TYPE["EXODUS"] = "EXODUS";
    WALLET_SESSION_TYPE["ETHEREUM_EXTENSION"] = "ETHEREUM_EXTENSION";
    WALLET_SESSION_TYPE["WALLET_CONNECT"] = "WALLET_CONNECT";
    WALLET_SESSION_TYPE["BLOCTO"] = "BLOCTO";
})(WALLET_SESSION_TYPE || (WALLET_SESSION_TYPE = {}));
