import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import { numWei } from '@/composables/useFormatCurrency';
import Vbep from '@/infra/abis/Vbep.json';
import Venus from '@/infra/abis/Venus.json';
import VenusOracle from '@/infra/abis/VenusOracle.json';
export default async function getTVL({ web3 }, BLIDPrice, storageContract, logicAddress) {
    const result = {
        deposited: 0,
        borrowed: 0,
        total: 0,
    };
    const contractConfig = {
        venus: {
            abi: Venus.abi,
            address: '0xfD36E2c2a6789Db23113685031d7F16329158384',
        },
        venusOracle: {
            abi: VenusOracle.abi,
            address: '0xd8B6dA2bfEC71D684D3E2a2FC9492dDad5C3787F',
        },
    };
    try {
        const currentLockedUSD = await storageContract.methods.getTotalDeposit().call();
        const totalSupplyBLIDPromise = storageContract.methods
            .totalSupplyBLID()
            .call();
        if (logicAddress) {
            let totalAmountOfTokens = 0;
            const bolideUserDeposited = numWei(currentLockedUSD);
            const { getListOfTokens, getTokenBalance } = getMethods(web3, logicAddress);
            const listOfTokens = await getListOfTokens(contractConfig.venus);
            if (listOfTokens && listOfTokens.length) {
                for (const token of listOfTokens) {
                    const borrowed = await getTokenBalance(token);
                    const venusOracleContract = new web3.eth.Contract(contractConfig.venusOracle.abi, contractConfig.venusOracle.address);
                    const underlyingPrice = await venusOracleContract.methods
                        .getUnderlyingPrice(token)
                        .call();
                    if (borrowed && underlyingPrice) {
                        totalAmountOfTokens += numWei(borrowed) * numWei(underlyingPrice);
                    }
                }
            }
            result.deposited = bolideUserDeposited;
            result.borrowed = totalAmountOfTokens;
            result.total = totalAmountOfTokens + bolideUserDeposited;
            return totalSupplyBLIDPromise
                .then((value) => {
                const totalSupplyBLID = numWei(value);
                result.total += BLIDPrice ? totalSupplyBLID * BLIDPrice : totalSupplyBLID;
                return result;
            })
                .catch(() => {
                return result;
            });
        }
        else {
            result.total = numWei(currentLockedUSD);
            return totalSupplyBLIDPromise
                .then((value) => {
                const totalSupplyBLID = numWei(value);
                result.total += BLIDPrice ? totalSupplyBLID * BLIDPrice : totalSupplyBLID;
                return result;
            })
                .catch(() => {
                return result;
            });
        }
    }
    catch (e) {
        devError(DEV_ERROR_CODE.GET_TVL);
        return result;
    }
}
function getMethods(web3, logicAddress) {
    return {
        getTokenBalance: async (tokenAddress) => {
            const tokenContract = new web3.eth.Contract(Vbep.abi, tokenAddress);
            const snapshot = await tokenContract.methods
                .getAccountSnapshot(logicAddress)
                .call();
            return snapshot && snapshot['2'] ? snapshot['2'] : '';
        },
        getListOfTokens: async (contractConfig) => {
            const venusContract = new web3.eth.Contract(contractConfig.abi, contractConfig.address);
            return await venusContract.methods.getAssetsIn(logicAddress).call();
        },
    };
}
