import { checkVars, getStrategyByIdFromWeb3, readyChecks, requestAPI, } from '@/web3-server/web3/handler/inspectors';
import { getListOfLevelsToBoosting } from '@/web3-server/web3/actions/strategies/boost/get-list-of-levels-to-boosting';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import deposit from '@/web3-server/web3/actions/strategies/boost/deposit-boosting';
import withdraw from '@/web3-server/web3/actions/strategies/boost/withdraw-boosting';
import getCustomCalc from '@/web3-server/web3/actions/strategies/boost/get-custom-calc';
import Decimal from 'decimal.js';
import checker from '@/web3-server/utils/checker';
const boostingActions = {
    async getBoosting(apy, currency, strategyContract, networkId) {
        const request = requestAPI('userWithPrice', { web3: { networkId } });
        if (request.isUserWithPrice &&
            apy >= 0 &&
            strategyContract &&
            strategyContract.abi &&
            currency &&
            checkVars([strategyContract.address])) {
            return getListOfLevelsToBoosting(request.getUserWithPrice, apy, currency, strategyContract, networkId);
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR);
            return {
                levels: [],
                deposited: {
                    wei: new Decimal(0),
                    short: new Decimal(0),
                    toShow: '',
                },
                priceIsTooLow: false,
            };
        }
    },
    async getUserBoosting(strategyContract, amount, defaultAPY, currencyBalance, depositedWei, networkId) {
        const request = requestAPI('userWithPrice', { web3: { networkId } });
        if (request.isUserWithPrice &&
            strategyContract &&
            strategyContract.abi &&
            defaultAPY >= 0 &&
            checkVars([strategyContract.address, amount])) {
            return getCustomCalc(request.getUserWithPrice, strategyContract, amount, defaultAPY, networkId, currencyBalance, depositedWei);
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR);
            return {
                apy: '',
            };
        }
    },
    depositBoosting(strategyId, amount, currencyDecimals, depositedWei, events) {
        const request = requestAPI('user', { events });
        const strategy = getStrategyByIdFromWeb3(strategyId);
        if (request.isUser &&
            strategy &&
            currencyDecimals &&
            readyChecks.strategy.isContract(strategy) &&
            checker.decimal.isNotEmpty(amount)) {
            deposit(request.getWithUser, strategy.contract, amount, currencyDecimals, depositedWei, strategy.supportEIP1559);
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
    withdrawBoosting(strategyId, amountWei, events) {
        const request = requestAPI('user', { events });
        const strategy = getStrategyByIdFromWeb3(strategyId);
        if (request.isUser &&
            strategy &&
            readyChecks.strategy.isContract(strategy) &&
            checker.decimal.isNotEmpty(amountWei)) {
            withdraw(request.getWithUser, strategy.contract, amountWei, strategy.supportEIP1559);
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
};
export default boostingActions;
