import { getConfigForBoosting } from '@/web3-server/web3/actions/strategies/boost/get-config-for-boosting';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import Decimal from 'decimal.js';
import web3 from 'web3';
import { decimalFormatting } from '@/composables/useFormatCurrency';
import getCalcCurrentAPY from '@/web3-server/web3/actions/strategies/boost/get-calc-current-apy';
import { simpleDecimalCheck } from '@/web3-server/web3/handler/inspectors';
export default async function getCustomCalc(requestWithPrice, strategyContract, amount, defaultAPY, networkId, currencyBalance, depositedWei) {
    const configForBoosting = getConfigForBoosting(requestWithPrice, strategyContract, networkId);
    if (!configForBoosting) {
        devError(DEV_ERROR_CODE.WEB_CONTRACT);
        return {
            apy: '',
        };
    }
    else {
        try {
            const { maxAPYPromise, BLIDNeedPromise } = configForBoosting;
            const amountDecimal = new Decimal(amount);
            const isAmountZero = amountDecimal.isZero();
            const amountWei = new Decimal(web3.utils.toWei(isAmountZero ? '0' : amount));
            const maxAPYData = await maxAPYPromise;
            const BLIDNeed = await BLIDNeedPromise;
            const getPurchase = (price, available) => {
                const purchase = price.minus(new Decimal(available || 0));
                if (purchase.isPositive() && !purchase.isZero()) {
                    return decimalFormatting(purchase, 2, { showWithManyZeros: true });
                }
                else {
                    return undefined;
                }
            };
            const currentAPYData = getCalcCurrentAPY(BLIDNeed.decimal, depositedWei && simpleDecimalCheck(depositedWei) ? depositedWei.plus(amountWei) : amountWei);
            const currentAPYShort = new Decimal(currentAPYData.apy.formatted.full);
            let allowedAPY = currentAPYData.apy.formatted.full;
            if (isAmountZero) {
                allowedAPY = '0';
            }
            else if (currentAPYShort.greaterThan(new Decimal(maxAPYData.apy.formatted.full))) {
                allowedAPY = maxAPYData.apy.formatted.full;
            }
            return {
                amount: isAmountZero ? '' : amount,
                apy: decimalFormatting(new Decimal(defaultAPY).plus(allowedAPY), 2, { isRounding: 'down' }),
                purchase: getPurchase(amountDecimal, currencyBalance),
            };
        }
        catch (e) {
            return {
                amount: '',
                apy: '',
            };
        }
    }
}
