import { useDialogStore } from '@/store/dialog';
import { pageManagement } from '@/store/page-management';
import { currencyAdapter, currencyListAdapter, earnedIncomeAdapter, } from '@/components/popup/manager/manager.adapter';
import { STRATEGY_TABS } from '@/infra/types/strategies/constants';
import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
import { useStrategiesStore } from '@/store/perfect-strategies';
import { NETWORK_ID, STARGATE_NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import { useCurrenciesStore } from '@/store/currencies';
import { useWalletStore } from '@/web3-server/wallet';
import checker from '@/web3-server/utils/checker';
import { STRATEGY_ID, } from '@/web3-server/web3-strategies/types/config/constants';
import { networkForCrossChain } from '@/web3-server/wallet/data/network';
import { STEPPER_MOD } from '@/infra/types/stepper/constants';
export const myDialog = {
    openConnectWallet(props, closeCb) {
        const dialogStore = useDialogStore();
        dialogStore.open({
            id: dialogStore.config.ids.CONNECTION_TO_WALLET,
            props: props || {},
            close(isAuth) {
                if (closeCb) {
                    closeCb(isAuth);
                }
                else {
                    dialogStore.close();
                }
            },
        });
    },
    openConnectWalletWithOptionToClose() {
        myDialog.openConnectWallet({ allowedToClose: true });
    },
    openStrategy(type, isAuth, strategyConfig, extra) {
        if (!isAuth) {
            if (extra && extra.routeHash && extra.routeHash === '#info' && strategyConfig) {
                pageManagement.openInfoPage(strategyConfig.route);
            }
            else if (strategyConfig) {
                myDialog.openDeposit(strategyConfig, { currencyId: extra && extra.currencyId });
            }
        }
        else if (strategyConfig) {
            const currency = strategyConfig.currencies && strategyConfig.currencies.length
                ? strategyConfig.currencies[0]
                : null;
            if (type !== 'deposit' && !strategyConfig.isDeposit) {
                pageManagement.openDepositPage(strategyConfig.route, {
                    currencyId: extra && extra.currencyId,
                });
            }
            else if (type === 'withdraw') {
                const currenciesStore = useCurrenciesStore();
                const depositCurrency = extra
                    ? currenciesStore.depositedCurrenciesUnderContracts.find((item) => item.currencyType === extra.currencyType &&
                        item.contractAddress === strategyConfig.contract.address)
                    : null;
                const currencyState = depositCurrency
                    ? strategyConfig.currencies.find((item) => item.symbol === depositCurrency.currencyType &&
                        item.address.toLowerCase() === depositCurrency.currencyAddress.toLowerCase())
                    : null;
                if (depositCurrency && currencyState) {
                    myDialog.openWithdraw(strategyConfig.route, strategyConfig.id, currencyAdapter({ ...currencyState, deposited: depositCurrency.depositedAmount }, false), strategyConfig.vault, strategyConfig.supportsEIP1559);
                }
                else {
                    pageManagement.openManagementPage(strategyConfig.route, {
                        currencyId: extra && extra.currencyId,
                    });
                }
            }
            else if (type === 'manager') {
                myDialog.openManager(strategyConfig, { currencyId: extra && extra.currencyId });
            }
            else if (type === 'deposit' && strategyConfig.isDeposit) {
                pageManagement.openManagementPage(strategyConfig.route, {
                    currencyId: extra && extra.currencyId,
                });
            }
            else if (type === 'deposit' &&
                strategyConfig.id === STRATEGY_ID.BNB_BLID_USDT &&
                currency &&
                (currency.balance === '0' || !currency.balance)) {
                myDialog.openFarmingCheck(strategyConfig);
            }
            else {
                myDialog.openDeposit(strategyConfig, { currencyId: extra && extra.currencyId });
            }
        }
    },
    openManager(strategyConfig, extra) {
        if (pageManagement.isManagementPage()) {
            createMainDialog({
                isManager: true,
                strategyConfig,
                isLoading: extra && extra.isLoading,
                presetValues: {
                    selectedCurrency: {
                        deposit: extra && extra.currencyId,
                    },
                },
            });
        }
        else {
            pageManagement.openManagementPage(strategyConfig.route, {
                currencyId: extra && extra.currencyId,
            });
        }
    },
    openDeposit(strategyConfig, extra) {
        if (pageManagement.isDepositPage()) {
            createMainDialog({
                isManager: false,
                strategyConfig,
                isLoading: extra && extra.isLoading,
                presetValues: {
                    selectedCurrency: {
                        deposit: extra && extra.currencyId,
                    },
                },
            });
        }
        else {
            pageManagement.openDepositPage(strategyConfig.route, {
                currencyId: extra && extra.currencyId,
            });
        }
    },
    openInfo(strategyConfig) {
        if (pageManagement.isInfoPage()) {
            const dialogStore = useDialogStore();
            dialogStore.open({
                id: dialogStore.config.ids.INFO,
                commonProps: getCommonProps(strategyConfig),
                props: {
                    isLoading: false,
                },
                close: async () => {
                    dialogStore.close();
                    await pageManagement.goHome();
                },
            });
        }
        else {
            pageManagement.openInfoPage(strategyConfig.route);
        }
    },
    openWithdraw(route, strategyId, selectedCurrency, vault, supportsEIP1559) {
        if (pageManagement.isWithdrawPage()) {
            const dialogStore = useDialogStore();
            const openDialog = () => {
                dialogStore.open({
                    id: dialogStore.config.ids.WITHDRAW,
                    props: {
                        selectedCurrency,
                        vault,
                    },
                    strategyId,
                    close: () => {
                        pageManagement.openManagementPage(route);
                    },
                    reopen: () => openDialog(),
                    supportsEIP1559,
                });
            };
            openDialog();
        }
        else {
            pageManagement.openWithdrawPage({ route, currencyType: selectedCurrency.symbol });
        }
    },
    openCurrencies(props, updateSelectedCurrency) {
        const dialogStore = useDialogStore();
        dialogStore.open({
            id: dialogStore.config.ids.CURRENCIES,
            props: {
                title: props.title || 'Deposit',
                listOfCurrencies: props.listOfCurrencies,
                modelValue: props.modelValue || props.listOfCurrencies[0],
            },
            updateSelectedCurrency(selectedCurrency) {
                if (updateSelectedCurrency) {
                    updateSelectedCurrency(selectedCurrency);
                }
                else {
                    dialogStore.goBack(1, { isWithClosing: true });
                }
            },
        });
    },
    openNotifyAboutTransaction(props, extra) {
        const dialogStore = useDialogStore();
        dialogStore.open({
            id: dialogStore.config.ids.NOTIFY_ABOUT_TRANSACTION,
            props,
            close() {
                if (extra && 'closingCb' in extra && extra.closingCb) {
                    extra.closingCb();
                }
                else {
                    if (extra && 'stepBack' in extra && extra.stepBack === 0) {
                        dialogStore.close();
                    }
                    else {
                        dialogStore.goBack(extra && 'stepBack' in extra && extra.stepBack ? extra.stepBack : 2);
                    }
                }
            },
        });
    },
    openConfirmTransaction() {
        const dialogStore = useDialogStore();
        dialogStore.open({
            id: dialogStore.config.ids.CONFIRM_TRANSACTION,
        });
    },
    openFarmingCheck(strategyConfig) {
        const dialogStore = useDialogStore();
        dialogStore.open({
            id: dialogStore.config.ids.FARMING_CHECK,
            close(userHasCurrency) {
                if (userHasCurrency && strategyConfig) {
                    myDialog.openDeposit(strategyConfig);
                }
                else {
                    myDialog.goHome();
                }
            },
        });
    },
    openCrossChainTransaction(step, networkName) {
        const dialogStore = useDialogStore();
        const props = {
            step,
        };
        if (networkName) {
            if (networkName.from) {
                props.fromNetwork = networkName.from;
            }
            if (networkName.to) {
                props.toNetwork = networkName.to;
            }
        }
        dialogStore.open({
            id: dialogStore.config.ids.CROSS_CHAIN_TRANSACTION,
            props,
            close() {
                myDialog.goHome();
            },
        });
    },
    openReLoginWC(requiredNetwork, closingCb) {
        const dialogStore = useDialogStore();
        dialogStore.open({
            id: dialogStore.config.ids.RE_LOGIN_WALLETCONNECT,
            props: {
                requiredNetwork,
            },
            close() {
                if (closingCb) {
                    closingCb();
                }
                else {
                    dialogStore.goBack(1);
                }
            },
        });
    },
    openBuyBLID(isBack) {
        const dialogStore = useDialogStore();
        dialogStore.open({
            id: dialogStore.config.ids.BUY_BLID,
            close() {
                if (isBack) {
                    dialogStore.goBack(1);
                }
                else {
                    dialogStore.close();
                }
            },
        });
    },
    openSuccessOperation(strategyRouteForPopupOpening, props) {
        const dialogStore = useDialogStore();
        dialogStore.open({
            id: dialogStore.config.ids.SUCCESS_OPERATION,
            props,
            openBoosting() {
                if (strategyRouteForPopupOpening) {
                    myDialog.goHome().then(() => {
                        pageManagement.openManagementPage(strategyRouteForPopupOpening, {
                            hash: STRATEGY_TABS.BOOST,
                        });
                    });
                }
            },
            close() {
                myDialog.goHome();
            },
        });
    },
    openSuccessClaim(strategyRouteForPopupOpening, showBoostBtn) {
        const strategiesStore = useStrategiesStore();
        const strategyConfig = strategiesStore.getCurrentStrategy({
            route: strategyRouteForPopupOpening,
        });
        if (strategyConfig) {
            myDialog.openSuccessOperation(strategyConfig.route, {
                showAddToken: true,
                showBoostBtn: showBoostBtn && strategyConfig.isDeposit,
                text: 'BLID claiming successfully completed',
            });
        }
    },
    openSuccessDeposit(strategyRouteForPopupOpening, showBoostBtn) {
        const strategiesStore = useStrategiesStore();
        const strategyConfig = strategiesStore.getCurrentStrategy({
            route: strategyRouteForPopupOpening,
        });
        if (strategyConfig) {
            myDialog.openSuccessOperation(strategyConfig.route, {
                showAddToken: false,
                showBoostBtn: showBoostBtn && strategyConfig.isDeposit,
                text: 'Deposit successfully completed',
            });
        }
    },
    openSwitchNetwork() {
        const dialogStore = useDialogStore();
        dialogStore.open({
            id: dialogStore.config.ids.SWITCH_NETWORK,
        });
    },
    openDepositStepByStep() {
        const dialogStore = useDialogStore();
        dialogStore.open({
            id: dialogStore.config.ids.DEPOSIT_STEP_BY_STEP,
        });
    },
    openSuccessfulCreationOfNft() {
        const dialogStore = useDialogStore();
        dialogStore.open({
            id: dialogStore.config.ids.SUCCESSFUL_CREATION_OF_NFT,
        });
    },
    openStepper(props) {
        const dialogStore = useDialogStore();
        dialogStore.open({
            id: dialogStore.config.ids.STEPPER,
            props,
            close() {
                const stepBack = 1;
                if (props && props.paramsByMod) {
                    const params = props.paramsByMod;
                    if (params.mod === STEPPER_MOD.DEPOSIT &&
                        params.strategyId &&
                        params.selectedData &&
                        params.selectedData.selectedCurrency) {
                        myDialog.reopen.openMain(params.strategyId, {
                            selectedCurrencyId: params.selectedData.selectedCurrency.id,
                            selectedNetwork: params.selectedData.selectedNetwork,
                        }, stepBack);
                    }
                    else {
                        dialogStore.goBack(stepBack);
                    }
                }
                else {
                    dialogStore.goBack(stepBack);
                }
            },
        });
    },
    goHome() {
        const dialogStore = useDialogStore();
        dialogStore.close();
        return pageManagement.goHome();
    },
    reopen: {
        openMain(strategyId, props, stepBack = 1) {
            const dialogStore = useDialogStore();
            const strategiesStore = useStrategiesStore();
            const strategyConfig = strategiesStore.getCurrentStrategy({ id: strategyId });
            if (strategyConfig) {
                let selectedCurrency = undefined;
                let selectedNetwork = undefined;
                if (props) {
                    if ('selectedCurrency' in props) {
                        selectedCurrency = props.selectedCurrencyId;
                    }
                    if ('selectedNetwork' in props) {
                        selectedNetwork = props.selectedNetwork;
                    }
                }
                dialogStore.close();
                createMainDialog({
                    isManager: strategyConfig.isDeposit,
                    strategyConfig,
                    presetValues: {
                        selectedCurrency: {
                            deposit: selectedCurrency,
                        },
                        selectedNetwork,
                    },
                });
            }
            else {
                dialogStore.goBack(stepBack);
            }
        },
    },
};
function createMainDialog(props) {
    const { isManager, strategyConfig, presetValues, isLoading } = props;
    const walletStore = useWalletStore();
    const dialogStore = useDialogStore();
    const currenciesStore = useCurrenciesStore();
    const dialogId = isManager ? dialogStore.config.ids.MANAGEMENT : dialogStore.config.ids.DEPOSIT;
    const earnedBalance = strategyConfig.earnedIncome.balance;
    const currentNetwork = (presetValues && presetValues.selectedNetwork) || walletStore.network?.crossChain;
    const currentNetworkConfig = {
        bridgeType: currentNetwork?.bridgeType,
        id: currentNetwork?.id || STARGATE_NETWORK_ID.BNB,
        networkId: currentNetwork?.networkId || NETWORK_ID.BNB,
    };
    const getPresetCurrency = () => {
        if (presetValues && presetValues.selectedCurrency) {
            if (presetValues.selectedCurrency.deposit &&
                typeof presetValues.selectedCurrency.deposit === 'string') {
                return {
                    deposit: currencyAPI.getById(presetValues.selectedCurrency.deposit, true),
                    withdrawal: presetValues.selectedCurrency.withdrawal,
                };
            }
            else if (!presetValues.selectedCurrency.doNotUpdateCurrencyData &&
                presetValues.selectedCurrency.deposit) {
                return {
                    deposit: currencyAPI.getById(presetValues.selectedCurrency.deposit.id, true),
                    withdrawal: presetValues.selectedCurrency.withdrawal,
                };
            }
            else {
                return {
                    deposit: presetValues.selectedCurrency.deposit,
                    withdrawal: presetValues.selectedCurrency.withdrawal,
                };
            }
        }
        else {
            return undefined;
        }
    };
    const isCrossChain = !!strategyConfig.crossChain;
    const updateDialogProp = (propName, propValue) => {
        dialogStore.updateSpecificProp(dialogId, propName, propValue);
    };
    const getApprovalForSorting = (val) => (val && val !== '0' ? 1 : 0);
    const currencyAPI = {
        get: (currenciesConfig, networkConfig) => {
            const currency = {
                toCreateNewDeposit: currenciesConfig
                    .filter((item) => !item.forbiddenToDeposit &&
                    (isCrossChain
                        ? item.networkId === networkConfig.networkId &&
                            ((networkConfig.bridgeType && item.bridgeType === networkConfig.bridgeType) ||
                                !networkConfig.bridgeType)
                        : true))
                    .sort((a, b) => {
                    const approved = getApprovalForSorting(b.approved) - getApprovalForSorting(a.approved);
                    return (Number(b.balance.replace(/\s/, '')) -
                        Number(a.balance.replace(/\s/, '')) +
                        (approved < 0 ? 0 : approved));
                }),
                toWithdrawDeposit: currenciesStore.depositedCurrenciesUnderContracts
                    .filter((item) => item.contractAddress === strategyConfig.contract.address)
                    .sort((a, b) => Number(b.depositedAmount.replace(/\s/, '')) -
                    Number(a.depositedAmount.replace(/\s/, ''))),
            };
            return {
                toCreateNewDeposit: currencyListAdapter(currency.toCreateNewDeposit, true),
                toWithdrawDeposit: currencyListAdapter(currency.toWithdrawDeposit.reduce((prev, item) => {
                    const currency = currenciesConfig.find((currency) => currency.address.toLowerCase() === item.currencyAddress.toLowerCase());
                    if (currency) {
                        prev.push({ ...currency, deposited: item.depositedAmount });
                    }
                    return prev;
                }, []), false),
                toWithdrawDepositOriginal: currency.toWithdrawDeposit,
            };
        },
        depositUpdate: (props) => {
            const listOfCurrencies = currencyAPI.get(strategyConfig.currencies, 'network' in props && props.network ? props.network : currentNetworkConfig);
            let selectedCurrency = listOfCurrencies.toCreateNewDeposit[0];
            if ('selectedDepositCurrency' in props && props.selectedDepositCurrency) {
                selectedCurrency = props.selectedDepositCurrency;
            }
            else if (props.network) {
                selectedCurrency = listOfCurrencies.toCreateNewDeposit[0];
            }
            else if (presetSelectedCurrency && presetSelectedCurrency.deposit) {
                selectedCurrency = presetSelectedCurrency.deposit;
            }
            const createDeposit = {
                listOfCurrencies: listOfCurrencies.toCreateNewDeposit,
                selectedCurrency,
            };
            updateDialogProp('createDeposit', createDeposit);
        },
        getByType: (type, network, isOpenDeposit) => {
            const currency = strategyConfig.currencies
                .filter((item) => network
                ? item.networkId === network.networkId &&
                    ((network.bridgeType && network.bridgeType === item.bridgeType) ||
                        !network.bridgeType)
                : true)
                .find((item) => item.symbol === type);
            if (currency) {
                return currencyAdapter(currency, isOpenDeposit);
            }
            else {
                return currency;
            }
        },
        getById: (id, isOpenDeposit) => {
            const currency = strategyConfig.currencies.find((item) => item.id === id);
            if (currency) {
                return currencyAdapter(currency, isOpenDeposit);
            }
            else {
                return currency;
            }
        },
    };
    const currencies = currencyAPI.get(strategyConfig.currencies, currentNetworkConfig);
    const presetSelectedCurrency = getPresetCurrency();
    const getProps = (isManager, strategyConfig, listOfCurrencies, customCurrency, isLoading) => {
        const props = {
            createDeposit: {
                listOfCurrencies: listOfCurrencies.toCreateNewDeposit,
                selectedCurrency: customCurrency && customCurrency.deposit
                    ? customCurrency.deposit
                    : listOfCurrencies.toCreateNewDeposit[0],
            },
            depositLoading: false,
            vault: strategyConfig.vault,
            isLoading,
            currencyLoading: false,
            isEnableBuyCurrencyBtn: strategyConfig.id === STRATEGY_ID.BNB_BLID,
            bridgeType: strategyConfig.crossChain,
            selectedNetwork: currentNetwork,
            showBoostBtn: showBoostBtn(strategyConfig),
        };
        if (isManager) {
            props.depositWithdrawal = {
                listOfCurrencies: listOfCurrencies.toWithdrawDeposit,
                selectedCurrency: customCurrency && customCurrency.withdrawal
                    ? customCurrency.withdrawal
                    : listOfCurrencies.toWithdrawDeposit[0],
            };
        }
        if (earnedBalance && Number(earnedBalance.replace(/\s/, ''))) {
            props.earned = earnedIncomeAdapter(strategyConfig.earnedIncome);
        }
        if (strategyConfig.horizon.deposit.depositedBoostInBLID &&
            strategyConfig.horizon.deposit.depositedBoostInBLID.short &&
            checker.decimal.isNotEmpty(strategyConfig.horizon.deposit.depositedBoostInBLID.short)) {
            props.depositedBoost = strategyConfig.horizon.deposit.depositedBoostInBLID;
        }
        return props;
    };
    const defaultDialogParams = {
        id: dialogId,
        commonProps: getCommonProps(strategyConfig, isManager),
        supportsEIP1559: strategyConfig.supportsEIP1559,
        contract: strategyConfig.contract,
        GAPrefix: strategyConfig.GAPrefix,
        routeToOpenPopup: strategyConfig.route,
        strategyId: strategyConfig.id,
        openCurrencies: (props) => {
            myDialog.openCurrencies(props, (currency) => {
                const isDeposit = props.title.includes('Deposit');
                const selected = presetValues && presetValues.selectedCurrency ? presetValues.selectedCurrency : {};
                if (isDeposit) {
                    selected.deposit = currency;
                }
                else {
                    selected.withdrawal = currency;
                }
                dialogStore.close();
                createMainDialog({
                    isManager,
                    strategyConfig,
                    presetValues: {
                        selectedCurrency: selected,
                    },
                    isLoading,
                });
            });
        },
        openConnect: (props) => {
            myDialog.openConnectWallet({
                allowedToClose: true,
                networkId: props.selectedNetwork && props.selectedNetwork.networkId,
            }, (isAuth) => {
                const { selectedCurrency, selectedNetwork } = props;
                if (isAuth && strategyConfig.isDeposit) {
                    pageManagement.openManagementPage(strategyConfig.route);
                }
                else {
                    dialogStore.close();
                    createMainDialog({
                        isManager,
                        strategyConfig,
                        presetValues: {
                            selectedCurrency: {
                                deposit: selectedCurrency,
                            },
                            selectedNetwork,
                        },
                        isLoading,
                    });
                }
            });
        },
        close: async () => {
            dialogStore.close();
            await pageManagement.goHome();
        },
        reopen: (props) => {
            let selectedCurrency = undefined;
            let selectedNetwork = undefined;
            if (props) {
                if ('selectedCurrency' in props) {
                    selectedCurrency = props.selectedCurrency;
                }
                else if ('selectedCurrencyType' in props) {
                    selectedCurrency = currencyAPI.getByType(props.selectedCurrencyType, props.selectedNetwork && {
                        bridgeType: props.selectedNetwork.bridgeType,
                        id: props.selectedNetwork.id,
                        networkId: props.selectedNetwork.networkId,
                    }, true);
                }
                else if ('selectedCurrencyId' in props) {
                    selectedCurrency = currencyAPI.getById(props.selectedCurrencyId, true);
                }
                if ('selectedStargateNetworkId' in props && props.selectedStargateNetworkId) {
                    selectedNetwork = networkForCrossChain['STARGATE'][props.selectedStargateNetworkId];
                }
                else if ('selectedNetwork' in props) {
                    selectedNetwork = props.selectedNetwork;
                }
            }
            dialogStore.close();
            createMainDialog({
                isManager,
                strategyConfig,
                presetValues: {
                    selectedCurrency: {
                        deposit: selectedCurrency,
                    },
                    selectedNetwork,
                },
                isLoading,
            });
        },
        changeCurrency(props) {
            currencyAPI.depositUpdate({
                selectedDepositCurrency: props.selectedCurrency,
                network: props.selectedNetwork && {
                    bridgeType: props.selectedNetwork.bridgeType,
                    id: props.selectedNetwork.id,
                    networkId: props.selectedNetwork.networkId,
                },
            });
        },
        changeNetwork(value) {
            const currenciesStore = useCurrenciesStore();
            updateDialogProp('currencyLoading', true);
            currenciesStore
                .update({
                currencies: strategyConfig.currencies,
                contract: strategyConfig.contract,
                vault: strategyConfig.vault,
            }, isCrossChain, isCrossChain
                ? {
                    network: {
                        bridgeType: value.bridgeType,
                        id: value.id,
                        networkId: value.networkId,
                        contractAddress: value.contractAddress,
                    },
                    rpcUrl: value.rpcUrl,
                }
                : undefined)
                .finally(() => {
                currencyAPI.depositUpdate({
                    network: { bridgeType: value.bridgeType, id: value.id, networkId: value.networkId },
                });
                updateDialogProp('selectedNetwork', value);
                updateDialogProp('currencyLoading', false);
            });
        },
    };
    if (isManager) {
        const dialogManagerParams = {
            ...defaultDialogParams,
            id: dialogStore.config.ids.MANAGEMENT,
            props: getProps(true, strategyConfig, currencies, presetSelectedCurrency, isLoading),
        };
        dialogStore.open(dialogManagerParams);
    }
    else {
        const dialogDepositParams = {
            ...defaultDialogParams,
            id: dialogStore.config.ids.DEPOSIT,
            props: getProps(false, strategyConfig, currencies, presetSelectedCurrency, isLoading),
        };
        dialogStore.open(dialogDepositParams);
    }
}
function getCommonProps(strategyConfig, isManager) {
    const getTabList = () => {
        const tabs = [];
        if (isManager !== undefined) {
            if (isManager) {
                tabs.push(STRATEGY_TABS.MANAGE);
                if (showBoostBtn(strategyConfig)) {
                    tabs.push(STRATEGY_TABS.BOOST);
                }
            }
            else {
                tabs.push(STRATEGY_TABS.DEPOSIT);
            }
        }
        return tabs;
    };
    const props = {
        title: strategyConfig.title,
        selectedTab: isManager !== undefined ? (isManager ? 'manage' : 'deposit') : '',
        tabList: getTabList(),
        basicInfo: {
            dataLoaded: strategyConfig.horizon.dataLoaded,
            formatted: strategyConfig.horizon.formatted,
            defaultNums: strategyConfig.horizon.defaultNums,
        },
        strategyText: strategyConfig.text,
        contract: {
            type: strategyConfig.contract.type,
            isStorageContract: strategyConfig.contract.type === CONTRACT_TYPE.STORAGE,
            address: strategyConfig.contract.address,
            abi: strategyConfig.contract.abi,
        },
        networkId: strategyConfig.network,
        addressesOfLogicContracts: strategyConfig.addressesOfLogicContracts,
    };
    if (strategyConfig.contract.type === CONTRACT_TYPE.STORAGE &&
        strategyConfig.contract.logicAddress) {
        props.contract.logicAddress = strategyConfig.contract.logicAddress;
    }
    if (strategyConfig.subtitle) {
        props.subtitle = strategyConfig.subtitle;
    }
    return props;
}
function showBoostBtn(strategyConfig) {
    return !!(strategyConfig.contract.type === CONTRACT_TYPE.STORAGE &&
        strategyConfig.horizon.isBoostingV2 &&
        strategyConfig.horizon.defaultNums.maxBoosting &&
        strategyConfig.horizon.defaultNums.maxBoosting.apr &&
        strategyConfig.horizon.deposit.depositAmountInUSD &&
        strategyConfig.horizon.deposit.depositAmountInUSD !== '0' &&
        !strategyConfig.horizon.leftAfterDeposit.boost);
}
