import { numWei } from '@/composables/useFormatCurrency';
export default async function getTVL(lpContract, contractAddress, BLIDTokenAddress, BLIDPrice) {
    const lpBalanceOfMaster = await lpContract.contract.methods
        .balanceOf(contractAddress)
        .call();
    let currentLockedUSD = 0;
    if (lpContract.token0 === BLIDTokenAddress) {
        currentLockedUSD =
            (numWei(lpBalanceOfMaster) / lpContract.totalSupply) * lpContract.reserve0 * BLIDPrice * 2;
    }
    else if (lpContract.token1 === BLIDTokenAddress) {
        currentLockedUSD =
            (numWei(lpBalanceOfMaster) / lpContract.totalSupply) * lpContract.reserve1 * BLIDPrice * 2;
    }
    return currentLockedUSD;
}
