import Decimal from 'decimal.js';
export function getDefaultCalcByBN(val, actions) {
    return {
        ...getDefaultCalc(val, actions),
        bn: val,
    };
}
export function getDefaultCalcByDecimal(val, actions) {
    return {
        ...getDefaultCalc(val, actions),
        decimal: val,
    };
}
export function getFormattedDecimal(val, actions, decimals = 18) {
    return {
        original: val,
        formatted: getSimpleDecimal(val, actions, decimals),
    };
}
export function myFromWei(val, extra = { decimals: 18 }) {
    const decimal = new Decimal(val);
    if (decimal.isFinite() && decimal.isPositive() && !decimal.isNaN()) {
        return getSimpleDecimal(decimal, { type: 'divide', times: 1 }, extra.decimals || 18);
    }
    else {
        return new Decimal(0);
    }
}
export function getSimpleDecimal(val, actions, decimals = 18) {
    if (actions && actions.times) {
        const wei = Decimal.pow(10, decimals);
        const tenToThePower = Decimal.pow(wei, actions.times);
        if (actions.type === 'divide') {
            return val.div(tenToThePower);
        }
        else if (actions.type === 'multiply') {
            return val.mul(tenToThePower);
        }
        else {
            return val;
        }
    }
    else {
        return val;
    }
}
export function getShortDecimal(val, decimalPlaces = 0, isRounding) {
    const short = val.toDecimalPlaces(decimalPlaces);
    if (decimalPlaces === 20) {
        return short;
    }
    else if (short.toNumber() === 0) {
        return getShortDecimal(val, ++decimalPlaces, isRounding);
    }
    else {
        if (isRounding) {
            if (isRounding === 'up') {
                return val.toDecimalPlaces(decimalPlaces, Decimal.ROUND_UP);
            }
            else {
                const roundedNum = val.toDecimalPlaces(decimalPlaces, Decimal.ROUND_DOWN);
                if (roundedNum.toNumber() === 0) {
                    return getShortDecimal(val, ++decimalPlaces, isRounding);
                }
                else {
                    return roundedNum;
                }
            }
        }
        else {
            return short;
        }
    }
}
export function getDecimalInObj(val) {
    const str = val.toFixed();
    const arr = str.split('.');
    return {
        value: str,
        separately: {
            firstNum: arr[0] || '',
            secondNum: arr.length > 1 ? arr[1] : '',
        },
    };
}
function getDefaultCalc(val, actions) {
    const isDecimal = Decimal.isDecimal(val);
    const str = isDecimal ? val.toFixed() : val.toString();
    const formatted = getSimpleDecimal(isDecimal ? val : new Decimal(str), actions);
    return {
        original: str,
        formatted: {
            full: formatted.toFixed(),
            short: getShortDecimal(formatted).toFixed(),
        },
    };
}
