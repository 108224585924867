export async function getDateOfLastDistribution(storageContract) {
    let ms = 0;
    try {
        const countEarns = await storageContract.methods.getCountEarns().call();
        if (countEarns && countEarns !== '0') {
            const id = Number(countEarns) - 1;
            const currentEarn = await storageContract.methods
                .getEarnsByID(id)
                .call();
            if (currentEarn && currentEarn['1'] && currentEarn['1'] !== '0') {
                ms = Number.parseInt(currentEarn['1'], 10) * 1000;
            }
        }
        return ms;
    }
    catch (e) {
        return ms;
    }
}
