import axios from 'axios';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export default async function getBLIDPriceFromCoinGecko() {
    try {
        const requestUrl = 'https://api.coingecko.com/api/v3/simple/price';
        const params = {
            ids: 'bolide',
            vs_currencies: 'usd',
            include_market_cap: false,
            include_24hr_vol: false,
            include_24hr_change: false,
            include_last_updated_at: false,
            precision: 6,
        };
        return axios.get(requestUrl, {
            params,
        });
    }
    catch (e) {
        devError(DEV_ERROR_CODE.AXIOS);
        return null;
    }
}
