import { Multicall, } from 'ethereum-multicall';
import { devError } from '@/utils/devError';
export default function multiCallManager(requestByNetwork, listOfItemForMultiCall) {
    const contractsWithEmptyCalls = getContractsCallContext(listOfItemForMultiCall);
    const contractsReference = contractsWithEmptyCalls.map((item) => {
        return {
            ...item,
            value: item.value.reference,
        };
    });
    const multiCallByNetwork = requestByNetwork.map((item) => {
        return {
            networkId: item.networkId,
            value: new Multicall({
                web3Instance: item.value,
                tryAggregate: true,
            }),
        };
    });
    return {
        call: (params, listOfAllowedIds) => {
            const contractsWithCallsByNetwork = requestByNetwork.map((request) => {
                return {
                    networkId: request.networkId,
                    value: contractsWithEmptyCalls
                        .filter((item) => item.networkId === request.networkId &&
                        (listOfAllowedIds?.length ? listOfAllowedIds.includes(item.id) : true))
                        .map((item) => {
                        const contractCallContext = item.value;
                        let calls = [];
                        if ('singleCall' in params && params.singleCall) {
                            calls = params.singleCall;
                        }
                        else if ('multiCall' in params && params.multiCall) {
                            if (item.groupId) {
                                calls = params.multiCall
                                    .filter((call) => call.id === item.id && call.groupId === item.groupId)
                                    .reduce((prev, call) => {
                                    if (call.value) {
                                        prev.push(...call.value);
                                    }
                                    return prev;
                                }, []);
                            }
                            else {
                                calls = params.multiCall
                                    .filter((call) => call.id === item.id && !call.groupId)
                                    .reduce((prev, call) => {
                                    if (call.value) {
                                        prev.push(...call.value);
                                    }
                                    return prev;
                                }, []);
                            }
                        }
                        return {
                            ...contractCallContext,
                            calls,
                        };
                    }),
                };
            });
            const callsMade = contractsWithCallsByNetwork.map((contracts) => {
                const multiCall = multiCallByNetwork.find((item) => item.networkId === contracts.networkId)?.value;
                const contractsCallContext = contracts.value;
                if (multiCall) {
                    return multiCall.call(contractsCallContext);
                }
            });
            return Promise.all(callsMade)
                .then((response) => {
                const resultList = response.filter((item) => item !== undefined);
                return contractsReference
                    .filter((item) => listOfAllowedIds?.length ? listOfAllowedIds.includes(item.id) : true)
                    .map((contractReference) => {
                    const reference = contractReference.value;
                    const currentResult = resultList.find((item) => item.results[reference])?.results;
                    const result = {
                        id: contractReference.id,
                        networkId: contractReference.networkId,
                        value: currentResult &&
                            currentResult[reference] &&
                            currentResult[reference].callsReturnContext
                            ? currentResult[reference].callsReturnContext
                            : [],
                    };
                    if (contractReference.groupId) {
                        result.groupId = contractReference.groupId;
                    }
                    if (contractReference.extra) {
                        result.extra = contractReference.extra;
                    }
                    return result;
                });
            })
                .catch((e) => {
                devError(e, callsMade);
                return [];
            });
        },
    };
}
function getContractsCallContext(listOfItemForMultiCall) {
    const contractsCallContext = [];
    try {
        listOfItemForMultiCall.forEach((item) => {
            const contractCallContext = {
                id: item.id,
                networkId: item.networkId,
                value: {
                    reference: `contract_${item.groupId ? item.groupId + '_' : ''}${item.id}`,
                    contractAddress: item.contractAddress,
                    abi: item.abi,
                    calls: [],
                },
            };
            if (item.groupId) {
                contractCallContext.groupId = item.groupId;
            }
            if (item.extra) {
                contractCallContext.extra = item.extra;
            }
            contractsCallContext.push(contractCallContext);
        });
    }
    catch (e) {
        devError(e);
    }
    return contractsCallContext;
}
