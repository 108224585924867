import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import bnb from '@/assets/images/network/bnb.svg';
import eth from '@/assets/images/network/eth.svg';
import polygon from '@/assets/images/network/polygon.svg';
import arb from '@/assets/images/network/arb.svg';
import op from '@/assets/images/network/op.svg';
export const defaultNetwork = {
    [NETWORK_ID.BNB]: {
        name: 'BNB Chain',
        rpcUrl: process.env.VUE_APP_RPC_ADDRESS_FOR_BNB || 'https://rpc.bolide.fi/bsc',
        blockExplorerUrl: 'https://bscscan.com/',
        icon: bnb,
        noCommission: true,
    },
    [NETWORK_ID.ETH]: {
        name: 'Ethereum',
        rpcUrl: 'https://rpc.ankr.com/eth',
        blockExplorerUrl: 'https://etherscan.io/',
        icon: eth,
    },
    [NETWORK_ID.POLYGON]: {
        name: 'Polygon',
        rpcUrl: process.env.VUE_APP_RPC_ADDRESS_FOR_POLYGON || 'https://rpc.bolide.fi/polygon',
        blockExplorerUrl: 'https://polygonscan.com/',
        icon: polygon,
    },
    [NETWORK_ID.ARBITRUM]: {
        name: 'Arbitrum',
        rpcUrl: 'https://arb1.arbitrum.io/rpc',
        blockExplorerUrl: 'https://arbiscan.io/',
        icon: arb,
    },
    [NETWORK_ID.OPTIMISM]: {
        name: 'Optimism',
        rpcUrl: 'https://rpc.ankr.com/optimism',
        blockExplorerUrl: 'https://optimistic.etherscan.io/',
        icon: op,
    },
    [NETWORK_ID.GOERLI]: {
        name: 'Goerli',
        rpcUrl: 'https://rpc.ankr.com/eth_goerli',
        blockExplorerUrl: 'https://goerli.etherscan.io/',
        icon: eth,
    },
};
export default defaultNetwork;
