import converter from '@/web3-server/utils/converter';
import { devError } from '@/utils/devError';
import Decimal from 'decimal.js';
export default async function getNativeTokenBalance({ web3, userWalletAddress }, decimals) {
    const defaultValue = {
        wei: new Decimal(0),
        simple: new Decimal(0),
        simpleInStringFormat: '0',
    };
    try {
        return web3.eth
            .getBalance(userWalletAddress)
            .then((availableBalance) => {
            const simple = converter.withoutType.fromWei(availableBalance, { decimals });
            return {
                wei: new Decimal(availableBalance),
                simple,
                simpleInStringFormat: simple.toFixed(),
            };
        })
            .catch((e) => {
            devError(e);
            return defaultValue;
        });
    }
    catch (e) {
        devError(e);
        return defaultValue;
    }
}
