import { walletNetwork } from '@/web3-server/wallet/data/network';
import { TYPES_OF_WALLET_PROVIDERS } from '@/web3-server/wallet/types/provider/constants';
import { useWalletStore } from '@/web3-server/wallet';
import converter from '@/web3-server/utils/converter';
import { useWeb3Store } from '@/web3-server';
import { NETWORK_RECEIVE_STATUSES } from '@/web3-server/wallet/types/network/constants';
import { devError } from '@/utils/devError';
export default async function getCurrentAllowedNetwork(provider) {
    let chainId = '';
    if (provider) {
        if (provider.type === TYPES_OF_WALLET_PROVIDERS.WALLET_CONNECT && provider.chainId) {
            chainId = converter.toHex(provider.chainId);
        }
        else if ((provider.type === TYPES_OF_WALLET_PROVIDERS.DEFAULT_BROWSERS_EXTENSIONS ||
            provider.type === TYPES_OF_WALLET_PROVIDERS.BLOCTO) &&
            provider.ethereum) {
            chainId = await getChainIdFromEthProvider(provider.ethereum);
        }
        else {
            chainId = await getChainIdFromWeb3();
        }
    }
    else {
        const walletStore = useWalletStore();
        const walletConnect = walletStore.getWCSession();
        const exodusProvider = walletStore.getExodusProvider();
        const ethProvider = walletStore.getDefaultBrowserExtension();
        if (walletConnect && walletConnect.chainId) {
            chainId = converter.toHex(walletConnect.chainId);
        }
        else if (exodusProvider) {
            chainId = await getChainIdFromEthProvider(exodusProvider);
        }
        else if (ethProvider) {
            chainId = await getChainIdFromEthProvider(ethProvider);
        }
        else {
            chainId = await getChainIdFromWeb3();
        }
    }
    if (chainId) {
        const network = walletNetwork.find((item) => item.chainId.hex === chainId || item.chainId.int.toString() === chainId.toString());
        if (network) {
            return {
                status: NETWORK_RECEIVE_STATUSES.SUCCESS,
                response: network,
            };
        }
        else {
            return {
                status: NETWORK_RECEIVE_STATUSES.ALLOWED_NETWORK_NOT_FOUND,
            };
        }
    }
    else {
        return {
            status: NETWORK_RECEIVE_STATUSES.CHAIN_ID_MISSING,
        };
    }
}
async function getChainIdFromEthProvider(ethereum) {
    try {
        const id = await ethereum.request({ method: 'eth_chainId' });
        return converter.toHex(id);
    }
    catch (e) {
        if ('chainId' in ethereum && ethereum.chainId) {
            return converter.toHex(ethereum.chainId);
        }
        else {
            return await getChainIdFromWeb3();
        }
    }
}
async function getChainIdFromWeb3() {
    let chainId = '';
    try {
        const web3Store = useWeb3Store();
        if (web3Store.web3) {
            const id = await web3Store.web3.eth.net.getId();
            if (id) {
                chainId = converter.toHex(id);
            }
        }
    }
    catch (e) {
        devError(e);
        chainId = '';
    }
    return chainId;
}
