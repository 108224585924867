import Decimal from 'decimal.js';
import { DECIMAL_TYPE } from '@/web3-server/types/decimal/constants';
import clearNumLine from '@/web3-server/utils/composables/clear-num-line';
const checker = {
    decimal: {
        isPositive: isDecimalPositive,
        isNotEmpty: isDecimalNotEmpty,
        isEmpty: isDecimalEmpty,
        type: {
            isSimple: isDecimalSimple,
            isWei: isDecimalWei,
        },
        isWei,
    },
    numericStr: {
        isNotEmpty: isNumericStringNotEmpty,
        isEmpty: isNumericStringEmpty,
    },
};
export default checker;
function isDecimal(val) {
    return !!val && Decimal.isDecimal(val) && val.isFinite() && !val.isNaN();
}
function isDecimalPositive(val) {
    return !!val && isDecimal(val) && val.isPositive();
}
function isDecimalNotEmpty(val) {
    return !!val && isDecimalPositive(val) && !val.isZero();
}
function isDecimalEmpty(val) {
    return val === undefined || !isDecimalPositive(val) || val.isZero();
}
function isDecimalType(type, val) {
    return !!val && 'type' in val && val.type === type;
}
function isDecimalSimple(val) {
    return isDecimalType(DECIMAL_TYPE.SIMPLE, val);
}
function isDecimalWei(val) {
    return isDecimalType(DECIMAL_TYPE.WEI, val) && !!val ? isWei(val.value) : false;
}
function isWei(val) {
    return !!val && isDecimalPositive(val) && val.isInteger();
}
function isNumericStringNotEmpty(val) {
    const decimal = new Decimal(clearNumLine(val));
    return isDecimalNotEmpty(decimal);
}
function isNumericStringEmpty(val) {
    const decimal = new Decimal(clearNumLine(val));
    return !isDecimalNotEmpty(decimal);
}
