export function devError(msg, e) {
    if (process.env.NODE_ENV === 'development' || process.env.VUE_APP_ENABLE_CONSOLE === 'true') {
        // eslint-disable-next-line no-console
        if (e) {
            console.error({
                msg,
                error: e,
            });
        }
        else {
            console.error(msg);
        }
    }
}
