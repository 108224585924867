import { PID } from '@/infra/types/contracts/constants';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import { numWei } from '@/composables/useFormatCurrency';
import getFarmPrice from '@/web3-server/web3/actions/strategies/master-contract/farming/get-farm-price';
import getLpContract from '@/web3-server/web3/actions/strategies/master-contract/farming/get-contract';
export default async function getDepositFromFarming({ web3, userWalletAddress }, masterContract, BLIDPrice, BLIDTokenAddress, lpTokenAddress) {
    let isDeposit = false, USDDeposit = 0;
    try {
        const depositInfo = await masterContract.methods
            .userInfo(PID.FAMING_BLID_USDT, userWalletAddress)
            .call();
        const lpContract = await getLpContract({ web3 }, lpTokenAddress);
        if (lpContract) {
            const tokenPrice = getFarmPrice(lpContract, BLIDPrice, BLIDTokenAddress);
            if (depositInfo && depositInfo.amount && tokenPrice) {
                const tokenDeposit = depositInfo.amount;
                USDDeposit = numWei(tokenDeposit) * tokenPrice;
                if (USDDeposit) {
                    isDeposit = true;
                }
            }
        }
        else {
            devError(DEV_ERROR_CODE.GET_TOKEN_DEPOSIT_FARMING);
        }
    }
    catch (e) {
        devError(DEV_ERROR_CODE.GET_TOKEN_DEPOSIT_FARMING);
    }
    return { isDeposit, USDDeposit };
}
