import multiCallManager from '@/web3-server/web3/handler/multicall-manager';
import linker from '@/web3-server/utils/linker';
import { CURRENCY_ID } from '@/web3-server/web3-currencies/types/config/constants';
import ERC20 from '@/infra/abis/ERC20.json';
import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
import { PID } from '@/infra/types/contracts/constants';
import converter from '@/web3-server/utils/converter';
import Decimal from 'decimal.js';
import multiCallUtils from '@/web3-server/web3/handler/multicall-utils';
var GROUP_ID;
(function (GROUP_ID) {
    GROUP_ID["STRATEGY"] = "STRATEGY";
    GROUP_ID["CURRENCY"] = "CURRENCY";
})(GROUP_ID || (GROUP_ID = {}));
var CALL_REFERENCE;
(function (CALL_REFERENCE) {
    CALL_REFERENCE["STRATEGY_GET_TOKEN_DEPOSIT"] = "tokenDeposit_";
    CALL_REFERENCE["CURRENCY_GET_TOKEN_BALANCE"] = "balance_";
    CALL_REFERENCE["CURRENCY_GET_TOKEN_APPROVAL"] = "approved_";
})(CALL_REFERENCE || (CALL_REFERENCE = {}));
var NAME_OF_METHODS;
(function (NAME_OF_METHODS) {
    NAME_OF_METHODS["GET_TOKEN_DEPOSIT_FOR_STORAGE"] = "getTokenDeposit";
    NAME_OF_METHODS["GET_TOKEN_DEPOSIT_FOR_OTHER"] = "userInfo";
    NAME_OF_METHODS["GET_TOKEN_BALANCE"] = "balanceOf";
    NAME_OF_METHODS["GET_TOKEN_APPROVAL"] = "allowance";
})(NAME_OF_METHODS || (NAME_OF_METHODS = {}));
const nameOfMethodsByCallReference = {
    [CALL_REFERENCE.STRATEGY_GET_TOKEN_DEPOSIT]: {
        [CONTRACT_TYPE.STORAGE]: NAME_OF_METHODS.GET_TOKEN_DEPOSIT_FOR_STORAGE,
        [CONTRACT_TYPE.STAKING]: NAME_OF_METHODS.GET_TOKEN_DEPOSIT_FOR_OTHER,
        [CONTRACT_TYPE.FARMING]: NAME_OF_METHODS.GET_TOKEN_DEPOSIT_FOR_OTHER,
    },
    [CALL_REFERENCE.CURRENCY_GET_TOKEN_APPROVAL]: NAME_OF_METHODS.GET_TOKEN_APPROVAL,
    [CALL_REFERENCE.CURRENCY_GET_TOKEN_BALANCE]: NAME_OF_METHODS.GET_TOKEN_BALANCE,
};
export async function getBasicInfo(request, userWalletAddress, currencies, strategies, balanceIsNeed) {
    const deposited = [];
    const approved = [];
    const balances = [];
    const multiCallByStrategies = multiCallManager(request, getContractsForMultiCall(currencies, strategies));
    const calls = getCalls(currencies, strategies, userWalletAddress, balanceIsNeed);
    const multiCallResponse = await multiCallByStrategies.call({
        multiCall: calls.filter((item) => !!item.value.length),
    });
    if (multiCallResponse && multiCallResponse.length) {
        multiCallResponse.forEach((callsResults) => {
            if (callsResults.groupId === GROUP_ID.STRATEGY) {
                const listOfDeposited = getDepositedCurrenciesUnderContract(callsResults);
                if (listOfDeposited.length) {
                    deposited.push(...listOfDeposited);
                }
            }
            else if (callsResults.groupId === GROUP_ID.CURRENCY) {
                const currentCurrency = getCurrencyApprovalAndBalance(callsResults);
                if (currentCurrency) {
                    if (currentCurrency.approved.length) {
                        approved.push(...currentCurrency.approved);
                    }
                    if (currentCurrency.balance) {
                        balances.push(currentCurrency.balance);
                    }
                }
            }
        });
    }
    return { deposited, approved, balances };
}
function getContractsForMultiCall(currencies, strategies) {
    const extra = getExtraForContracts(currencies, strategies);
    const listOfStrategies = strategies
        .map((strategy) => {
        return {
            id: strategy.id,
            groupId: GROUP_ID.STRATEGY,
            networkId: linker.getNetworkId(strategy.vault),
            contractAddress: strategy.contract.address,
            abi: strategy.contract.abi,
            extra: extra.strategies.filter((extra) => extra.strategyId === strategy.id),
        };
    })
        .filter((strategy) => strategy.extra && strategy.extra.length);
    const listOfCurrencies = getSimpleCurrencies(currencies).map((currency) => {
        return {
            id: currency.id,
            groupId: GROUP_ID.CURRENCY,
            networkId: currency.relations.networkId,
            contractAddress: currency.address,
            abi: ERC20.abi,
            extra: extra.currencies.filter((item) => item.currencyId === currency.id),
        };
    });
    return [...listOfStrategies, ...listOfCurrencies];
}
function getExtraForContracts(currencies, strategies) {
    return {
        strategies: getExtraForStrategyContracts(currencies, strategies),
        currencies: getExtraForCurrencyContracts(currencies, strategies),
    };
}
function getExtraForStrategyContracts(currencies, strategies) {
    const extra = [];
    strategies.forEach((strategy) => {
        extra.push(...getExtraForStrategy(currencies, strategy));
    });
    return extra;
}
function getExtraForCurrencyContracts(currencies, strategies) {
    const extra = [];
    strategies.forEach((strategy) => {
        strategy.listOfCurrencyId.forEach((currencyId) => {
            const currency = currencies.find((item) => item.id === currencyId);
            if (currency) {
                extra.push({
                    reference: `${CALL_REFERENCE.CURRENCY_GET_TOKEN_APPROVAL}${currencyId}_${strategy.id}`,
                    decimals: currency.decimals,
                    currencyId,
                    strategyId: strategy.id,
                    currencyAddress: currency.address,
                    contractAddress: strategy.contract.address,
                });
            }
        });
        if (strategy.crossChain && strategy.crossChain.availableCurrencies?.length) {
            strategy.crossChain.availableCurrencies.forEach((currencyId) => {
                const currency = currencies.find((item) => item.id === currencyId);
                if (currency && strategy.crossChain) {
                    const contractAddressForCrossChain = strategy.crossChain.contractAddress.find((item) => item.networkId === currency.relations.networkId);
                    if (contractAddressForCrossChain) {
                        extra.push({
                            reference: `${CALL_REFERENCE.CURRENCY_GET_TOKEN_APPROVAL}${currencyId}_${strategy.id}`,
                            decimals: currency.decimals,
                            currencyId,
                            strategyId: strategy.id,
                            currencyAddress: currency.address,
                            contractAddress: contractAddressForCrossChain.address,
                        });
                    }
                }
            });
        }
    });
    return extra;
}
function getExtraForStrategy(currencies, strategy) {
    return strategy.listOfCurrencyId.reduce((prev, currencyId) => {
        const currency = currencies.find((item) => item.id === currencyId);
        if (currency) {
            prev.push({
                reference: CALL_REFERENCE.STRATEGY_GET_TOKEN_DEPOSIT + currency.id,
                decimals: 18,
                currencyId,
                strategyId: strategy.id,
                currencyAddress: currency.address,
                contractAddress: strategy.contract.address,
            });
        }
        return prev;
    }, []);
}
function getCalls(currencies, strategies, userWalletAddress, balanceIsNeed) {
    const calls = [];
    const callReferenceForTokenBalance = CALL_REFERENCE.CURRENCY_GET_TOKEN_BALANCE;
    strategies.forEach((strategy) => {
        strategy.listOfCurrencyId.forEach((currencyId) => {
            const currency = currencies.find((item) => item.id === currencyId);
            if (currency) {
                const callToReceiveDepositOfTokenByStrategy = getDepositedTokenByStrategy(currency, strategy, userWalletAddress);
                if (callToReceiveDepositOfTokenByStrategy) {
                    calls.push(callToReceiveDepositOfTokenByStrategy);
                }
                if (!currency.burnAddress) {
                    calls.push(getTokenApprovalByStrategy(currency, { id: strategy.id, contractAddress: strategy.contract.address }, userWalletAddress));
                }
            }
        });
        if (strategy.crossChain && strategy.crossChain.availableCurrencies?.length) {
            strategy.crossChain.availableCurrencies.forEach((currencyId) => {
                const currency = currencies.find((item) => item.id === currencyId);
                if (currency && !currency.burnAddress && strategy.crossChain) {
                    const contractAddressForCrossChain = strategy.crossChain.contractAddress.find((item) => item.networkId === currency.relations.networkId);
                    if (contractAddressForCrossChain) {
                        calls.push(getTokenApprovalByStrategy(currency, { id: strategy.id, contractAddress: contractAddressForCrossChain.address }, userWalletAddress));
                    }
                }
            });
        }
    });
    if (balanceIsNeed) {
        getSimpleCurrencies(currencies).forEach((currency) => {
            calls.push({
                id: currency.id,
                groupId: GROUP_ID.CURRENCY,
                networkId: currency.relations.networkId,
                value: [
                    {
                        reference: `${callReferenceForTokenBalance}${currency.id}`,
                        methodName: nameOfMethodsByCallReference[callReferenceForTokenBalance],
                        methodParameters: [userWalletAddress],
                    },
                ],
            });
        });
    }
    return calls;
}
function getDepositedTokenByStrategy(currency, strategy, userWalletAddress) {
    const call = {
        id: strategy.id,
        groupId: GROUP_ID.STRATEGY,
        networkId: linker.getNetworkId(strategy.vault),
        value: [],
    };
    const callReference = CALL_REFERENCE.STRATEGY_GET_TOKEN_DEPOSIT;
    const methodName = nameOfMethodsByCallReference[callReference];
    if (strategy.contract.type === CONTRACT_TYPE.STORAGE) {
        call.value.push({
            reference: callReference + currency.id,
            methodName: methodName.STORAGE,
            methodParameters: [
                userWalletAddress,
                currency.burnAddress ? currency.burnAddress : currency.address,
            ],
        });
    }
    else if (strategy.contract.type === CONTRACT_TYPE.STAKING) {
        call.value.push({
            reference: callReference + currency.id,
            methodName: methodName.STAKING,
            methodParameters: [PID.STAKING_BLID, userWalletAddress],
        });
    }
    else if (strategy.contract.type === CONTRACT_TYPE.FARMING) {
        call.value.push({
            reference: callReference + currency.id,
            methodName: methodName.FARMING,
            methodParameters: [PID.FAMING_BLID_USDT, userWalletAddress],
        });
    }
    return call.value.length ? call : null;
}
function getTokenApprovalByStrategy(currency, strategy, userWalletAddress) {
    const callReference = CALL_REFERENCE.CURRENCY_GET_TOKEN_APPROVAL;
    return {
        id: currency.id,
        groupId: GROUP_ID.CURRENCY,
        networkId: currency.relations.networkId,
        value: [
            {
                reference: `${callReference}${currency.id}_${strategy.id}`,
                methodName: nameOfMethodsByCallReference[callReference],
                methodParameters: [userWalletAddress, strategy.contractAddress],
            },
        ],
    };
}
function getDepositedCurrenciesUnderContract(callsResults) {
    const deposited = [];
    const currenciesFromStrategy = callsResults.extra;
    for (const item of callsResults.value) {
        const currentReference = currenciesFromStrategy?.length
            ? currenciesFromStrategy.find((currency) => currency.reference === item.reference && currency.strategyId === callsResults.id)
            : undefined;
        if (currentReference) {
            const wei = multiCallUtils.getVal('hex', item, 0);
            const simple = converter.withoutType.fromWei(wei, { decimals: currentReference.decimals });
            deposited.push({
                currencyId: currentReference.currencyId,
                strategyId: currentReference.strategyId,
                currencyAddress: currentReference.currencyAddress,
                contractAddress: currentReference.contractAddress,
                depositedAmount: {
                    wei: new Decimal(wei),
                    simple,
                    simpleInStringFormat: simple.toFixed(),
                },
            });
        }
    }
    return deposited;
}
function getCurrencyApprovalAndBalance(callsResults) {
    let balance = null;
    const approved = [];
    const listOfCurrencyId = Object.values(CURRENCY_ID);
    const currenciesForApproved = callsResults.extra;
    const currencyId = callsResults.id;
    const decimals = currenciesForApproved?.length
        ? currenciesForApproved.find((item) => item.currencyId === callsResults.id)?.decimals
        : 18;
    for (const item of callsResults.value) {
        const currentReference = currenciesForApproved?.length
            ? currenciesForApproved.find((currency) => currency.reference === item.reference && currency.currencyId === callsResults.id)
            : undefined;
        if (currentReference) {
            const wei = multiCallUtils.getVal('hex', item, 0);
            const simple = converter.withoutType.fromWei(wei, { decimals: currentReference.decimals });
            approved.push({
                currencyId: currentReference.currencyId,
                strategyId: currentReference.strategyId,
                currencyAddress: currentReference.currencyAddress,
                contractAddress: currentReference.contractAddress,
                approvedAmount: {
                    wei: new Decimal(wei),
                    simple,
                    simpleInStringFormat: simple.toFixed(),
                },
            });
        }
        else if (listOfCurrencyId.includes(currencyId) &&
            item.reference === CALL_REFERENCE.CURRENCY_GET_TOKEN_BALANCE + callsResults.id) {
            const wei = multiCallUtils.getVal('hex', item, 0);
            const simple = converter.withoutType.fromWei(wei, { decimals });
            balance = {
                currencyId,
                balance: {
                    wei: new Decimal(wei),
                    simple,
                    simpleInStringFormat: simple.toFixed(),
                },
            };
        }
    }
    return {
        approved,
        balance,
    };
}
function getSimpleCurrencies(currencies) {
    return currencies.filter((currency) => isNotNative(currency));
}
function isNotNative(currency) {
    return !currency.burnAddress;
}
