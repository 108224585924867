import { getSendOptions } from '@/web3-server/web3/handler/get-send-options';
import { sendEvent } from '@/web3-server/web3/handler/sendEvent';
export async function mintNft(request, nftContractConfig, quantity = '1') {
    const nftContract = new request.web3.eth.Contract(nftContractConfig.abi, nftContractConfig.address);
    const sendOptions = await getSendOptions(request, {
        supportEIP1559: true,
    });
    sendEvent(request, nftContract.methods
        .mint(request.userWalletAddress, nftContractConfig.id, quantity && quantity !== '0' ? quantity : '1', '0x')
        .send(sendOptions));
}
