const popupModule = {
    namespaced: true,
    state: () => ({
        simpleNotice: null,
        preloader: false,
    }),
    actions: {},
    mutations: {
        setSimpleNotice(state, params) {
            state.simpleNotice = params;
        },
        setPreloader(state, val) {
            state.preloader = val;
        },
    },
};
export default popupModule;
