import { defineStore } from 'pinia';
import { web3CurrenciesActions } from '@/web3-server/web3-currencies/actions';
import { getAllCurrenciesForStore } from '@/web3-server/web3-currencies/actions/composables/get-all-currencies';
import nativeTokenActions from '@/web3-server/web3-currencies/actions/native-token';
import depositedActions from '@/web3-server/web3-currencies/actions/deposited';
export const useWeb3Currencies = defineStore('web3Currencies', {
    state: () => ({
        list: getAllCurrenciesForStore(),
        depositedCurrenciesUnderContracts: [],
        approvedCurrenciesUnderContracts: [],
    }),
    actions: {
        ...depositedActions,
        ...web3CurrenciesActions,
        ...nativeTokenActions,
        getById(id) {
            return this.list.find((currency) => currency.id === id);
        },
    },
});
