export var TYPES_OF_WALLET_PROVIDERS;
(function (TYPES_OF_WALLET_PROVIDERS) {
    TYPES_OF_WALLET_PROVIDERS["DEFAULT_BROWSERS_EXTENSIONS"] = "DEFAULT_BROWSERS_EXTENSIONS";
    TYPES_OF_WALLET_PROVIDERS["WALLET_CONNECT"] = "WALLET_CONNECT";
    TYPES_OF_WALLET_PROVIDERS["BLOCTO"] = "BLOCTO";
})(TYPES_OF_WALLET_PROVIDERS || (TYPES_OF_WALLET_PROVIDERS = {}));
export var NETWORK_SWITCH_STATUSED;
(function (NETWORK_SWITCH_STATUSED) {
    NETWORK_SWITCH_STATUSED["SUCCESS"] = "SUCCESS";
    NETWORK_SWITCH_STATUSED["ERROR"] = "ERROR";
    NETWORK_SWITCH_STATUSED["RE_LOGIN_VIA_WC"] = "RE LOGIN WC";
})(NETWORK_SWITCH_STATUSED || (NETWORK_SWITCH_STATUSED = {}));
