import { NETWORK_ID, STARGATE_NETWORK_ID, WALLET_NETWORK_ID, } from '@/web3-server/wallet/types/network/constants';
import defaultNetwork from '@/web3-server/wallet/data/cross-chain/default-network';
import { BRIDGE_TYPE } from '@/web3-server/web3/types/cross-chain/constants';
import { CURRENCY_TYPE } from '@/infra/types/currency/constants';
export const networkForStargate = {
    [STARGATE_NETWORK_ID.POLYGON]: {
        ...defaultNetwork.POLYGON,
        bridgeType: BRIDGE_TYPE.STARGATE,
        id: STARGATE_NETWORK_ID.POLYGON,
        networkId: NETWORK_ID.POLYGON,
        walletId: WALLET_NETWORK_ID.POLYGON_STARGATE,
        chainId: '109',
        contractAddress: process.env.VUE_APP_CROSSCHAIN_DEPOSITOR_POLYGON || '',
        feeLibrary: '0xd46ccf40d6dd371ade5c9d0e8f83354cf62285ed',
        token: [
            { symbol: CURRENCY_TYPE.USDT, poolId: 2 },
            { symbol: CURRENCY_TYPE.USDC, poolId: 1 },
        ],
        dstGasForCall: 400000,
        supportsEIP1559: true,
    },
    [STARGATE_NETWORK_ID.BNB]: {
        ...defaultNetwork.BNB,
        bridgeType: BRIDGE_TYPE.STARGATE,
        id: STARGATE_NETWORK_ID.BNB,
        networkId: NETWORK_ID.BNB,
        walletId: WALLET_NETWORK_ID.BNB_STARGATE,
        chainId: '102',
        contractAddress: process.env.VUE_APP_ACCUMULATED_DEPOSITOR_BSC || '',
        feeLibrary: '0xcbff39d34789f5d3a8d305e7b804f272e5fd7361',
        token: [
            { symbol: CURRENCY_TYPE.USDT, poolId: 2 },
            { symbol: CURRENCY_TYPE.BUSD, poolId: 5 },
        ],
        supportsEIP1559: false,
    },
    [STARGATE_NETWORK_ID.ETH]: {
        ...defaultNetwork.ETH,
        bridgeType: BRIDGE_TYPE.STARGATE,
        id: STARGATE_NETWORK_ID.ETH,
        networkId: NETWORK_ID.ETH,
        walletId: WALLET_NETWORK_ID.ETH_STARGATE,
        chainId: '101',
        contractAddress: process.env.VUE_APP_CROSSCHAIN_DEPOSITOR_ETH || '',
        feeLibrary: '0xDBF50791d09603915Bd066354A5B45775CfaD924',
        token: [
            { symbol: CURRENCY_TYPE.USDT, poolId: 2 },
            { symbol: CURRENCY_TYPE.USDC, poolId: 1 },
        ],
        supportsEIP1559: true,
        dstGasForCall: 400000,
    },
};
