import { decimalFormatting } from '@/composables/useFormatCurrency';
export function updateEarnedIncome(earnedBLID, earnedUSD, strategy, totalDeposit) {
    const strategyDeposit = strategy.horizon.deposit;
    if (earnedBLID && !earnedBLID.isZero()) {
        const formattedEarnedBLID = decimalFormatting(earnedBLID, 2, { showWithManyZeros: true });
        const formattedEarnedUSD = decimalFormatting(earnedUSD, 2, {
            showWithManyZeros: true,
            minNumToShow: 0.01,
        });
        strategyDeposit.earnedBLID = formattedEarnedBLID;
        strategyDeposit.earnedUSD = formattedEarnedUSD;
        strategy.earnedIncome.balance = formattedEarnedBLID;
        if (totalDeposit) {
            totalDeposit.earnedBLID = earnedBLID.toNumber();
            totalDeposit.earnedUSD = earnedUSD.toNumber();
        }
    }
    else if (strategyDeposit.earnedBLID && strategyDeposit.earnedBLID !== '0') {
        strategyDeposit.earnedBLID = '0';
        strategyDeposit.earnedUSD = '0';
        strategy.earnedIncome.balance = '0';
        if (totalDeposit && totalDeposit.earnedBLID) {
            totalDeposit.earnedBLID = 0;
            totalDeposit.earnedUSD = 0;
        }
    }
}
