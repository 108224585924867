export default function getFarmPrice(lpContract, BLIDPrice, BLIDTokenAddress) {
    const userLpPart = 1 / lpContract.totalSupply;
    const userToken0Amount = lpContract.reserve0 * userLpPart;
    const userToken1Amount = lpContract.reserve1 * userLpPart;
    if (lpContract.token0 === BLIDTokenAddress) {
        return userToken0Amount * BLIDPrice * 2;
    }
    else if (lpContract.token1 === BLIDTokenAddress) {
        return userToken1Amount * BLIDPrice * 2;
    }
    else {
        return 0;
    }
}
