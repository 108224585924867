export var GA_PREFIX;
(function (GA_PREFIX) {
    GA_PREFIX["BNB_STABLECOINS"] = "low_risk_strategy";
    GA_PREFIX["BNB_ETH"] = "low_risk_strategyETH";
    GA_PREFIX["BNB_BTC"] = "low_risk_strategyBTC";
    GA_PREFIX["BNB_BLID"] = "staking_blid";
    GA_PREFIX["BNB_BLID_USDT"] = "farming_blid_usdt";
    GA_PREFIX["BNB_ALTCOINS"] = "altcoins_bnb";
    GA_PREFIX["POLYGON_USDT"] = "usdt_polygon";
    GA_PREFIX["POLYGON_USDC"] = "usdc_polygon";
    GA_PREFIX["POLYGON_MATIC"] = "matic_polygon";
    GA_PREFIX["POLYGON_DAI"] = "dai_polygon";
    GA_PREFIX["POLYGON_ETH"] = "eth_polygon";
    GA_PREFIX["BNB_BNB"] = "bnb_strategy_bnb";
    GA_PREFIX["BNB_USDT_V2"] = "usdt_v2_strategy_bnb";
    GA_PREFIX["NFT"] = "nft";
    GA_PREFIX["ARB_USDC_E"] = "usdc_e_arbitrum";
    GA_PREFIX["DEV_POLYGON_STABLECOINS"] = "stablecoins_polygon";
    GA_PREFIX["DEV_BNB_USDT_V2_DFORCE"] = "usdt_dforce_v2_strategy_bnb";
    GA_PREFIX["OP_USDC_E"] = "usdc_e_optimism";
})(GA_PREFIX || (GA_PREFIX = {}));
export var GA_EVENT_NAME;
(function (GA_EVENT_NAME) {
    GA_EVENT_NAME["APPROVE_DEPOSIT"] = "deposit_approve";
    GA_EVENT_NAME["APPROVE_NFT"] = "nft_approve";
    GA_EVENT_NAME["APPROVE_BOOST"] = "boost_approve";
    GA_EVENT_NAME["APPROVE_CROSS_CHAIN"] = "cross_chain_approve";
    GA_EVENT_NAME["DEPOSIT"] = "deposit";
    GA_EVENT_NAME["CROSS_CHAIN_DEPOSIT"] = "crosschain";
    GA_EVENT_NAME["WITHDRAW"] = "withdraw";
    GA_EVENT_NAME["CLAIM"] = "claim";
    GA_EVENT_NAME["BOOST_DEPOSIT"] = "deposit_boost";
    GA_EVENT_NAME["BOOST_WITHDRAW"] = "withdraw_boost";
    GA_EVENT_NAME["MINT_NFT"] = "mint_nft";
})(GA_EVENT_NAME || (GA_EVENT_NAME = {}));
export var GA_POSTFIX;
(function (GA_POSTFIX) {
    GA_POSTFIX["START"] = "start";
    GA_POSTFIX["SIGNED"] = "signed";
    GA_POSTFIX["ERROR_SIGNED"] = "errorsign";
    GA_POSTFIX["SUCCESS"] = "success";
    GA_POSTFIX["ERROR"] = "error";
})(GA_POSTFIX || (GA_POSTFIX = {}));
