export var SWAP_SITE_TYPE;
(function (SWAP_SITE_TYPE) {
    SWAP_SITE_TYPE["UNISWAP"] = "Uniswap";
    SWAP_SITE_TYPE["PANCAKESWAP"] = "Pancakeswap";
    SWAP_SITE_TYPE["LBANK"] = "LBank";
    SWAP_SITE_TYPE["XY_FINANCE"] = "XY Finance";
    SWAP_SITE_TYPE["QUICK_SWAP"] = "QuickSwap";
})(SWAP_SITE_TYPE || (SWAP_SITE_TYPE = {}));
export var SWAP_SITE_ID;
(function (SWAP_SITE_ID) {
    SWAP_SITE_ID["BNB_PANCAKESWAP"] = "BNB_PANCAKESWAP";
    SWAP_SITE_ID["ETH_LBANK"] = "ETH_LBANK";
    SWAP_SITE_ID["POLYGON_QUICK_SWAP"] = "POLYGON_QUICK_SWAP";
    SWAP_SITE_ID["ETH_UNISWAP"] = "ETH_UNISWAP";
    SWAP_SITE_ID["ALL_XY_FINANCE"] = "ALL_XY_FINANCE";
})(SWAP_SITE_ID || (SWAP_SITE_ID = {}));
