import financeSummaryTotalAdapter from '@/components/finance-summary-total/finance-summary-total.adapter';
import { CONNECTION_TYPE, EXTENSION_TYPE } from '@/infra/types/wallets/constants';
import { getCurrentExtension } from '@/infra/wallet/get-current-extension';
import financeSummaryCardAdapter from '@/components/finance-summary-card/finance-summary-card.adapter';
const walletModule = {
    namespaced: true,
    state: () => ({
        chainId: 0,
        user: '',
        web3: null,
        allFinanceSummary: [],
        transactions: [],
        isConnected: false,
        listOfWallets: [
            {
                title: 'Blocto',
                type: CONNECTION_TYPE.BLOCTO,
                available: true,
            },
            {
                title: 'Exodus',
                type: CONNECTION_TYPE.EXODUS,
                available: true,
            },
            {
                title: 'Metamask',
                type: CONNECTION_TYPE.METAMASK,
                available: true,
            },
            {
                title: 'WalletConnect',
                type: CONNECTION_TYPE.WALLET_CONNECT,
                available: true,
            },
            {
                title: 'Trust Wallet',
                type: CONNECTION_TYPE.TRUST_WALLET,
                available: true,
            },
        ],
        connectionType: localStorage.getItem('connectionType'),
    }),
    mutations: {
        setWeb3(state, web3) {
            state.web3 = web3;
        },
        setUser(state, user) {
            state.user = user;
        },
        addFinanceSummary(state, params) {
            const strategyId = params.value.strategyId;
            const index = state.allFinanceSummary.findIndex((item) => item.strategyId === strategyId);
            if (index > -1) {
                state.allFinanceSummary.splice(index, 1, {
                    ...state.allFinanceSummary[index],
                    ...params.value,
                });
            }
            else {
                const id = state.allFinanceSummary.length;
                state.allFinanceSummary.push({ id, ...params.value });
            }
        },
        updateFinanceSummary(state, params) {
            if (params.index !== undefined) {
                state.allFinanceSummary.splice(params.index, 1, {
                    ...state.allFinanceSummary[params.index],
                    ...params.value,
                });
            }
            else if (params.strategyId) {
                const index = state.allFinanceSummary.findIndex((item) => item.strategyId === params.strategyId);
                if (index > -1) {
                    state.allFinanceSummary.splice(index, 1, {
                        ...state.allFinanceSummary[index],
                        ...params.value,
                    });
                }
                else {
                    state.allFinanceSummary.push(params.value);
                }
            }
            else {
                state.allFinanceSummary.push(params.value);
            }
        },
        removeFinanceSummary(state, index) {
            state.allFinanceSummary.splice(index, 1);
        },
        addTransaction(state, transaction) {
            state.transactions.push(transaction);
        },
        setTransaction(state, transactions) {
            state.transactions = transactions;
        },
        setConnected(state, val) {
            state.isConnected = val;
        },
        setChainId(state, val) {
            state.chainId = val;
        },
        updateWalletToConnect(state, params) {
            const indexWallet = state.listOfWallets.findIndex((item) => item.type === params.type);
            if (indexWallet > -1) {
                state.listOfWallets.splice(indexWallet, 1, {
                    ...state.listOfWallets[indexWallet],
                    ...params.wallet,
                });
            }
        },
        setConnectionType(state, val) {
            state.connectionType = val;
            if (val) {
                localStorage.setItem('connectionType', val);
            }
            else {
                localStorage.removeItem('connectionType');
            }
        },
    },
    getters: {
        btnTextWhenAddingToken(state) {
            const extensionType = getCurrentExtension(state.connectionType);
            if (extensionType && extensionType === EXTENSION_TYPE.METAMASK) {
                return 'Add BLID token to Metamask';
            }
            else if (state.connectionType === CONNECTION_TYPE.TRUST_WALLET &&
                extensionType !== EXTENSION_TYPE.COINBASE) {
                return 'Add BLID token to Trust Wallet';
            }
            else {
                return 'Add BLID token to your wallet';
            }
        },
        getFinancialSummary(state) {
            if (state.allFinanceSummary.length > 1) {
                const totalAmountFinance = state.allFinanceSummary.reduce((prev, financeSummary) => {
                    if (financeSummary.deposit) {
                        prev.deposit += financeSummary.deposit;
                    }
                    if (prev.earnedBlid !== undefined && financeSummary.earnedBlid) {
                        prev.earnedBlid += financeSummary.earnedBlid;
                    }
                    return prev;
                }, {
                    deposit: 0,
                    earnedBlid: 0,
                });
                return financeSummaryTotalAdapter(totalAmountFinance);
            }
            else {
                return undefined;
            }
        },
        getFinancialSummaryCard: (state) => (strategy) => {
            const finance = state.allFinanceSummary.find((item) => item.strategyId === strategy.id);
            return finance ? financeSummaryCardAdapter(finance) : finance;
        },
    },
};
export default walletModule;
