export var DIALOG_IDS;
(function (DIALOG_IDS) {
    DIALOG_IDS[DIALOG_IDS["CONNECTION_TO_WALLET"] = 1] = "CONNECTION_TO_WALLET";
    DIALOG_IDS[DIALOG_IDS["DEPOSIT"] = 2] = "DEPOSIT";
    DIALOG_IDS[DIALOG_IDS["MANAGEMENT"] = 3] = "MANAGEMENT";
    DIALOG_IDS[DIALOG_IDS["CURRENCIES"] = 4] = "CURRENCIES";
    DIALOG_IDS[DIALOG_IDS["NOTIFY_ABOUT_TRANSACTION"] = 5] = "NOTIFY_ABOUT_TRANSACTION";
    DIALOG_IDS[DIALOG_IDS["WITHDRAW"] = 6] = "WITHDRAW";
    DIALOG_IDS[DIALOG_IDS["INFO"] = 7] = "INFO";
    DIALOG_IDS[DIALOG_IDS["FARMING_CHECK"] = 8] = "FARMING_CHECK";
    DIALOG_IDS[DIALOG_IDS["CONFIRM_TRANSACTION"] = 9] = "CONFIRM_TRANSACTION";
    DIALOG_IDS[DIALOG_IDS["BUY_BLID"] = 10] = "BUY_BLID";
    DIALOG_IDS[DIALOG_IDS["SUCCESS_OPERATION"] = 11] = "SUCCESS_OPERATION";
    DIALOG_IDS[DIALOG_IDS["CROSS_CHAIN_TRANSACTION"] = 12] = "CROSS_CHAIN_TRANSACTION";
    DIALOG_IDS[DIALOG_IDS["RE_LOGIN_WALLETCONNECT"] = 13] = "RE_LOGIN_WALLETCONNECT";
    DIALOG_IDS[DIALOG_IDS["SWITCH_NETWORK"] = 14] = "SWITCH_NETWORK";
    DIALOG_IDS[DIALOG_IDS["DEPOSIT_STEP_BY_STEP"] = 15] = "DEPOSIT_STEP_BY_STEP";
    DIALOG_IDS[DIALOG_IDS["SUCCESSFUL_CREATION_OF_NFT"] = 16] = "SUCCESSFUL_CREATION_OF_NFT";
    DIALOG_IDS[DIALOG_IDS["STEPPER"] = 17] = "STEPPER";
})(DIALOG_IDS || (DIALOG_IDS = {}));
