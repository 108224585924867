import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export function validErrorsWhileWaitingForTransaction(e) {
    return !!(e.message && e.message.includes('50 blocks'));
}
export function waitForTxReceiptAndTransaction(web3, hash) {
    return new Promise((resolve, reject) => {
        const result = {
            isSuccess: false,
            receipt: null,
        };
        waitTxReceipt(web3, hash).then((txReceipt) => {
            if (txReceipt) {
                result.receipt = txReceipt;
                waitTransaction(txReceipt, web3)
                    .then(() => {
                    result.isSuccess = true;
                    resolve(result);
                })
                    .catch(() => {
                    reject(result);
                });
            }
            else {
                reject(result);
            }
        });
    });
}
export async function waitTxReceipt(web3, hash) {
    try {
        let expectedBlockTime = 0;
        let txReceipt = null;
        while (txReceipt === null) {
            txReceipt = await web3.eth.getTransactionReceipt(hash);
            if (expectedBlockTime >= 3000) {
                expectedBlockTime = 1000;
            }
            else {
                expectedBlockTime += 1000;
            }
            if (!txReceipt) {
                await new Promise((resolve) => setTimeout(resolve, expectedBlockTime));
            }
        }
        return txReceipt;
    }
    catch (e) {
        devError(e);
        return null;
    }
}
export async function waitTransaction(txReceipt, web3, extra) {
    if (txReceipt) {
        return confirmTransaction(web3, txReceipt, extra)
            .then(() => {
            return txReceipt;
        })
            .catch((e) => {
            devError(e);
            throw e;
        });
    }
    else {
        devError(DEV_ERROR_CODE.MISSING_VAR);
        throw DEV_ERROR_CODE.MISSING_VAR;
    }
}
function confirmTransaction(web3, txReceipt, defaultSettings = {
    blocksCount: 2,
    timeLimitMs: 300000,
    timeInterval: 2500,
}) {
    const settings = defaultSettings
        ? defaultSettings
        : {
            blocksCount: 2,
            timeLimitMs: 300000,
            timeInterval: 2500,
        };
    return new Promise((resolve, reject) => {
        if (!txReceipt.status) {
            reject(DEV_ERROR_CODE.CONFIRM_TRANSACTION_STATUS);
            return;
        }
        const startAt = new Date();
        const check = async () => {
            try {
                const isConfirmed = await checkTransactionConfirmation(txReceipt, settings.blocksCount, web3);
                if (isConfirmed) {
                    clearInterval(intervalId);
                    resolve();
                }
                const now = new Date();
                if (now.getTime() - startAt.getTime() > settings.timeLimitMs) {
                    clearInterval(intervalId);
                    reject(DEV_ERROR_CODE.CONFIRM_TRANSACTION_TIME);
                }
            }
            catch (error) {
                clearInterval(intervalId);
                reject(error);
            }
        };
        const intervalId = setInterval(check, settings.timeInterval);
        check();
    });
}
async function checkTransactionConfirmation(txReceipt, blocksCount, web3) {
    const blockNumber = await web3.eth.getBlockNumber();
    return blockNumber - txReceipt.blockNumber >= blocksCount;
}
