import { useFormatCurrencyLite } from '@/composables/useFormatCurrency';
export default function financeSummaryCardAdapter(data) {
    const result = {
        id: data.id,
        strategyId: data.strategyId,
        deposit: '$' + useFormatCurrencyLite(data.deposit),
    };
    if (data.earnedBlid) {
        result.earnedBlid = useFormatCurrencyLite(data.earnedBlid);
    }
    if (data.earnedBlidProfit) {
        const profit = useFormatCurrencyLite(data.earnedBlidProfit);
        if (profit && !profit.includes('0.00')) {
            result.earnedBlidProfit =
                '+' + useFormatCurrencyLite(data.earnedBlidProfit, { maximumFractionDigits: 2 }) + '%';
        }
    }
    return result;
}
