import { waitTransaction } from '@/web3-server/web3/handler/wait';
import depositStarGate from '@/web3-server/web3/actions/cross-chain/deposit/deposit-star-gate';
import checkTransactionStatus from '@/web3-server/web3/actions/cross-chain/check-transaction-status';
import { devError } from '@/utils/devError';
import Decimal from 'decimal.js';
import converter from '@/web3-server/utils/converter';
export default function depositCrossChain(request, toNetwork, fromNetwork, tokenAddress, depositAmount, depositFee, depositAmountWithCommission, slippage, events) {
    let transactionHash = '';
    let transactionReceipt = null;
    const amountOutMin = converter.withType.toWei(depositAmountWithCommission.value.mul(new Decimal(1).minus(slippage.value)), { decimals: 6, round: true });
    const depositStarGatePromise = depositStarGate(request, toNetwork.chainId, fromNetwork.contractAddress, tokenAddress.from, tokenAddress.to, fromNetwork.dstGasForCall || 0, depositAmount, amountOutMin, depositFee);
    const customEvents = {
        user(allowed, hash, error) {
            if (events && events.allowedByUser) {
                events.allowedByUser(allowed, hash, error);
            }
        },
        sentToBridge(completed, response) {
            if (events && events.sentToBridge) {
                events.sentToBridge(completed, response);
            }
        },
        transaction(completed, response) {
            if (events && events.transactionCompleted) {
                events.transactionCompleted(completed, response);
            }
        },
    };
    if (depositStarGatePromise) {
        const error = (error) => {
            if (!transactionHash) {
                customEvents.user(false, undefined, error);
            }
            else {
                customEvents.sentToBridge(false, { receipt: transactionReceipt, hash: transactionHash });
            }
        };
        depositStarGatePromise
            .then((response) => {
            if (response) {
                try {
                    response.method
                        .send(response.params)
                        .on('transactionHash', (hash) => {
                        transactionHash = hash;
                        customEvents.user(true, hash);
                    })
                        .then((receipt) => {
                        transactionReceipt = receipt;
                        waitTransaction(receipt, request.web3)
                            .then(() => {
                            customEvents.sentToBridge(true, { receipt, hash: transactionHash });
                            try {
                                checkTransactionStatus(transactionHash)
                                    .then(() => {
                                    customEvents.transaction(true, { receipt, hash: transactionHash });
                                })
                                    .catch((e) => {
                                    devError(e);
                                    customEvents.transaction(false, { receipt, hash: transactionHash });
                                });
                            }
                            catch (e) {
                                customEvents.transaction(false, { receipt, hash: transactionHash });
                            }
                        })
                            .catch(() => {
                            customEvents.sentToBridge(false, { receipt, hash: transactionHash });
                        });
                    })
                        .catch((e) => {
                        error(e);
                    });
                }
                catch (e) {
                    devError(e);
                    error(e);
                }
            }
            else {
                error();
            }
        })
            .catch((e) => error(e));
    }
}
