import { NETWORK_SWITCH_STATUSED } from '@/web3-server/wallet/types/provider/constants';
export default async function switchNetworkViaWc(WCProvider, network) {
    try {
        await WCProvider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: network.chainId }],
        });
        return NETWORK_SWITCH_STATUSED.SUCCESS;
    }
    catch (e) {
        const err = e;
        if (err && err.message && err.message.includes('not approved')) {
            return NETWORK_SWITCH_STATUSED.RE_LOGIN_VIA_WC;
        }
        else {
            return NETWORK_SWITCH_STATUSED.ERROR;
        }
    }
}
