import getEarnedIncome from '@/web3-server/web3/actions/user-info/get-earned-income';
import { requestAPI } from '@/web3-server/web3/handler/inspectors';
import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
import getDepositFromStorage from '@/web3-server/web3/actions/user-info/get-deposit-from-storage';
import getDepositFromStaking from '@/web3-server/web3/actions/user-info/get-deposit-from-staking';
import getDepositFromFarming from '@/web3-server/web3/actions/user-info/get-deposit-from-farming';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import { devError } from '@/utils/devError';
import { getUserBoosting } from '@/web3-server/web3/actions/strategies/boost/get-config-for-boosting';
import getPercentageYield from '@/web3-server/web3/actions/user-info/get-percentage-yield';
import { numWei } from '@/composables/useFormatCurrency';
import Decimal from 'decimal.js';
import { watch } from 'vue';
import getIsBoostingV2 from '@/web3-server/web3/actions/strategies/boost/get-is-boosting-v2';
import getDepositFromBoost from '@/web3-server/web3/actions/user-info/get-deposit-from-boost';
import checker from '@/web3-server/utils/checker';
const userInfoActions = {
    async getUserInfo(strategyContract, forStorage, forFarming, networkId) {
        const request = requestAPI('userWithPrice', { web3: { networkId } });
        if (request.isUserWithPrice) {
            const { web3, userWalletAddress, BLIDPriceWei } = request.getUserWithPrice;
            const { type: contractType } = strategyContract;
            const BLIDPrice = numWei(BLIDPriceWei.toFixed());
            let isDeposit = false, USDDeposit = 0, userBoostingPromise = undefined, averageDistributionRewardPromise = undefined, depositFromBoost = undefined;
            const leftAfterDeposit = {
                earnedBLID: false,
                boost: false,
            };
            try {
                const contract = new web3.eth.Contract(strategyContract.abi, strategyContract.address);
                const earnedIncome = await getEarnedIncome(userWalletAddress, contract, strategyContract.type, BLIDPrice);
                if (contractType === CONTRACT_TYPE.STORAGE) {
                    const deposit = await getDepositFromStorage(userWalletAddress, contract, BLIDPrice);
                    isDeposit = deposit.isDeposit;
                    USDDeposit = deposit.USDDeposit;
                    depositFromBoost = await getDepositFromBoost(userWalletAddress, contract);
                    if (depositFromBoost && checker.decimal.isNotEmpty(depositFromBoost.short)) {
                        // isDeposit = true;
                        if (deposit.USDDepositByParams.depositedUSD.isZero()) {
                            leftAfterDeposit.boost = true;
                        }
                    }
                    if (deposit.USDDepositByParams.depositedUSD.isZero()) {
                        leftAfterDeposit.earnedBLID = !earnedIncome.earnedBLID.isZero();
                    }
                    userBoostingPromise = new Promise((resolve) => {
                        const getUserBoostingPromise = async () => {
                            const isBoostingV2 = await getIsBoostingV2(contract);
                            if (isBoostingV2) {
                                return getUserBoosting(contract, userWalletAddress, BLIDPriceWei, networkId, forStorage && forStorage.value);
                            }
                            else {
                                return {
                                    apy: {
                                        original: 0,
                                        roundedDown: 0,
                                    },
                                    apr: {
                                        original: 0,
                                        roundedDown: 0,
                                    },
                                };
                            }
                        };
                        if (forStorage && forStorage.loaded && !forStorage.loaded.apy) {
                            const unwatch = watch(() => forStorage.loaded.apy, () => {
                                unwatch();
                                resolve(getUserBoostingPromise());
                            });
                        }
                        else {
                            resolve(getUserBoostingPromise());
                        }
                    });
                    averageDistributionRewardPromise = userBoostingPromise.then((userBoost) => {
                        try {
                            const percent = new Decimal(userBoost.apr.original || forStorage.value.apr).div(100);
                            return new Decimal(USDDeposit).mul(percent).div(365).div(2).div(BLIDPrice);
                        }
                        catch (e) {
                            return new Decimal(0);
                        }
                    });
                }
                else if (contractType === CONTRACT_TYPE.STAKING && BLIDPrice) {
                    const deposit = await getDepositFromStaking(userWalletAddress, contract, BLIDPrice);
                    isDeposit = deposit.isDeposit;
                    USDDeposit = deposit.USDDeposit;
                }
                else if (contractType === CONTRACT_TYPE.FARMING &&
                    BLIDPrice &&
                    forFarming &&
                    forFarming.BLIDTokenAddress &&
                    forFarming.lpTokenAddress) {
                    const deposit = await getDepositFromFarming(request.getWithUser, contract, BLIDPrice, forFarming.BLIDTokenAddress, forFarming.lpTokenAddress);
                    isDeposit = deposit.isDeposit;
                    USDDeposit = deposit.USDDeposit;
                }
                if (contractType !== CONTRACT_TYPE.STORAGE) {
                    leftAfterDeposit.earnedBLID = !USDDeposit && !earnedIncome.earnedBLID.isZero();
                }
                return {
                    earnedBLID: earnedIncome.earnedBLID,
                    earnedUSD: earnedIncome.earnedUSD,
                    isDeposit: isDeposit,
                    // isDeposit: !!earnedBLID || isDeposit,
                    USDDeposit,
                    percentageYield: getPercentageYield(earnedIncome.earnedBLID.toNumber(), USDDeposit, BLIDPrice),
                    userBoostingPromise,
                    depositFromBoost,
                    leftAfterDeposit: {
                        state: leftAfterDeposit.earnedBLID || leftAfterDeposit.boost,
                        detailed: leftAfterDeposit,
                    },
                    averageDistributionRewardPromise,
                };
            }
            catch (e) {
                devError(DEV_ERROR_CODE.WEB_CONTRACT, e);
                throw e;
            }
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR);
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
    async getEarnedIncomeFromBoosting(storageContract, vault) {
        const request = requestAPI('userWithPrice', { web3: { vault } });
        const defaultResult = {
            earnedBLID: new Decimal(0),
            earnedUSD: new Decimal(0),
            percentageYield: 0,
        };
        if (request.isUserWithPrice &&
            storageContract &&
            storageContract.abi &&
            storageContract.address &&
            storageContract.type) {
            try {
                const { userWalletAddress, web3, BLIDPriceWei } = request.getUserWithPrice;
                const contract = new web3.eth.Contract(storageContract.abi, storageContract.address);
                const BLIDPrice = numWei(BLIDPriceWei.toFixed());
                const earnedIncome = await getEarnedIncome(userWalletAddress, contract, storageContract.type, BLIDPrice);
                const { USDDeposit } = await getDepositFromStorage(userWalletAddress, contract, BLIDPrice);
                return {
                    earnedBLID: earnedIncome.earnedBLID,
                    earnedUSD: earnedIncome.earnedUSD,
                    percentageYield: getPercentageYield(earnedIncome.earnedBLID.toNumber(), USDDeposit, BLIDPrice),
                };
            }
            catch (e) {
                devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR);
                return defaultResult;
            }
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR);
            return defaultResult;
        }
    },
};
export default userInfoActions;
