import getWcProvider from '@/web3-server/wallet/actions/provider/composables/get-wc-provider';
import { WALLET_NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import { NETWORK_SWITCH_STATUSED, TYPES_OF_WALLET_PROVIDERS, } from '@/web3-server/wallet/types/provider/constants';
import switchNetwork from '@/web3-server/wallet/actions/provider/composables/switch-network';
import { networkForAdd } from '@/web3-server/wallet/data/network';
import linker from '@/web3-server/utils/linker';
import switchNetworkViaBlocto from '@/web3-server/wallet/actions/provider/composables/switch-network-via-blocto';
import bloctoActions from '@/web3-server/wallet/actions/provider/blocto';
import { devError } from '@/utils/devError';
import switchNetworkViaWc from '@/web3-server/wallet/actions/provider/composables/switch-network-via-wc';
const providerActions = {
    ...bloctoActions,
    getETHProvider() {
        const cryptoPluginProvider = this.getDefaultBrowserExtension();
        const exodusProvider = this.getExodusProvider();
        const WCProvider = this.getWCSession();
        const bloctoProvider = this.getBloctoProviderToRead();
        const whichWalletToChoose = this.whichWalletToChoose();
        let provider = null;
        if (whichWalletToChoose.isWalletConnect && WCProvider) {
            provider = {
                type: TYPES_OF_WALLET_PROVIDERS.WALLET_CONNECT,
                session: WCProvider.session,
                chainId: WCProvider.chainId,
            };
        }
        else if (whichWalletToChoose.isExodus && exodusProvider) {
            provider = {
                type: TYPES_OF_WALLET_PROVIDERS.DEFAULT_BROWSERS_EXTENSIONS,
                ethereum: exodusProvider,
            };
        }
        else if (whichWalletToChoose.isDefaultExtension && cryptoPluginProvider) {
            provider = {
                type: TYPES_OF_WALLET_PROVIDERS.DEFAULT_BROWSERS_EXTENSIONS,
                ethereum: cryptoPluginProvider,
            };
        }
        else if (whichWalletToChoose.isBlocto && bloctoProvider) {
            provider = {
                type: TYPES_OF_WALLET_PROVIDERS.BLOCTO,
                ethereum: bloctoProvider,
            };
        }
        return provider;
    },
    getCryptoBrowserProvider() {
        return this.provider &&
            this.provider.type === TYPES_OF_WALLET_PROVIDERS.DEFAULT_BROWSERS_EXTENSIONS &&
            this.provider.ethereum
            ? this.provider.ethereum
            : null;
    },
    getDefaultBrowserExtension() {
        return window.ethereum || null;
    },
    getExodusExtension() {
        return window.exodus || null;
    },
    getExodusProvider() {
        const exodus = this.getExodusExtension();
        return exodus && exodus.ethereum;
    },
    getWCSession() {
        let WCSession = null;
        try {
            const sessions = JSON.parse(localStorage.getItem(this.wc.localeStorageKey.session) || 'null');
            const chainId = Number(localStorage.getItem(this.wc.localeStorageKey.chainId) || '0');
            if (sessions && sessions[0] && chainId) {
                WCSession = {
                    session: sessions[0],
                    chainId,
                };
            }
        }
        catch (e) {
            devError(e);
        }
        return WCSession;
    },
    isWalletConnect() {
        try {
            const session = this.getWCSession();
            return !!session || this.isWalletConnectFromState;
        }
        catch (e) {
            devError(e);
            return false;
        }
    },
    isCryptoBrowserExtension() {
        const exodusPlugin = this.getExodusExtension();
        const cryptoProvider = this.getDefaultBrowserExtension();
        return !!(((exodusPlugin && exodusPlugin.ethereum) || cryptoProvider) &&
            this.provider &&
            this.provider.type === TYPES_OF_WALLET_PROVIDERS.DEFAULT_BROWSERS_EXTENSIONS &&
            this.provider.ethereum);
    },
    getWCProvider(by, showQrModal = true) {
        if (by &&
            (('chainId' in by && by.chainId) ||
                ('networkId' in by && by.networkId) ||
                ('walletNetworkId' in by && by.walletNetworkId))) {
            return getWcProvider(by, showQrModal);
        }
        else {
            return getWcProvider({ walletNetworkId: WALLET_NETWORK_ID.BNB_STARGATE }, showQrModal);
        }
    },
    async getCurrentWCProvider(showQrModal = true) {
        const WCSession = this.getWCSession();
        if (WCSession && WCSession.chainId && this.isWalletConnect()) {
            if (this.wc.provider) {
                return this.wc.provider;
            }
            else {
                return getWcProvider({
                    chainId: WCSession.chainId,
                }, showQrModal);
            }
        }
        else {
            return null;
        }
    },
    async removeWCProvider() {
        const provider = await this.getCurrentWCProvider(false);
        if (provider) {
            await provider.disconnect();
            this.clearWallet();
            localStorage.setItem(this.wc.localeStorageKey.session, '[]');
        }
    },
    clearWallet() {
        this.provider = null;
        this.network = null;
        this.userAddress = '';
        this.userName = '';
        this.wc.provider = null;
    },
    updateProviderWithNetwork() {
        this.provider = this.getETHProvider();
        this.getAllowedNetwork(this.provider).then((response) => {
            this.network = response && response.response ? response.response : null;
        });
    },
    async switchNetwork(networkId) {
        if (this.provider && networkId) {
            const network = networkForAdd[linker.getNetworkId(networkId)];
            const isWalletConnect = this.isWalletConnect();
            if (network) {
                if (this.provider.type === TYPES_OF_WALLET_PROVIDERS.WALLET_CONNECT && isWalletConnect) {
                    const WCProvider = await this.getCurrentWCProvider(false);
                    if (WCProvider) {
                        return switchNetworkViaWc(WCProvider, network).then((status) => {
                            if (status === NETWORK_SWITCH_STATUSED.SUCCESS) {
                                this.updateProviderWithNetwork();
                            }
                            return status;
                        });
                    }
                    else {
                        return NETWORK_SWITCH_STATUSED.RE_LOGIN_VIA_WC;
                    }
                }
                else if (this.provider.type === TYPES_OF_WALLET_PROVIDERS.DEFAULT_BROWSERS_EXTENSIONS &&
                    !isWalletConnect) {
                    return switchNetwork(this.provider, network).then((status) => {
                        if (status === NETWORK_SWITCH_STATUSED.SUCCESS) {
                            this.updateProviderWithNetwork();
                        }
                        return status;
                    });
                }
                else if (this.provider.type === TYPES_OF_WALLET_PROVIDERS.BLOCTO && !isWalletConnect) {
                    return switchNetworkViaBlocto(network).then((status) => {
                        if (status === NETWORK_SWITCH_STATUSED.SUCCESS) {
                            this.setBloctoNetwork(linker.getNetworkId(networkId));
                        }
                        return status;
                    });
                }
                else {
                    return NETWORK_SWITCH_STATUSED.RE_LOGIN_VIA_WC;
                }
            }
            else {
                return NETWORK_SWITCH_STATUSED.ERROR;
            }
        }
        else {
            return NETWORK_SWITCH_STATUSED.ERROR;
        }
    },
};
export default providerActions;
