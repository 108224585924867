import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export async function getDistributorContractAddress({ web3 }, nftContractConfig) {
    try {
        const nftContract = new web3.eth.Contract(nftContractConfig.abi, nftContractConfig.address);
        return nftContract.methods.distribution(nftContractConfig.id).call();
    }
    catch (e) {
        devError(DEV_ERROR_CODE.WEB_CONTRACT, e);
        return null;
    }
}
