import getDepositGasFee from '@/web3-server/web3/actions/cross-chain/get-deposit-gas-fee';
import { currencyForNetwork, networkForCrossChain } from '@/web3-server/wallet/data/network';
import { getShortDecimal } from '@/web3-server/web3/handler/calc';
import getConversionFee from '@/web3-server/web3/actions/cross-chain/get-conversion-fee';
import converter from '@/web3-server/utils/converter';
import web3Constants from '@/web3-server/web3/data/web3-constants';
export default function getCrossDepositSummary(props, userWalletAddress) {
    const bnb = networkForCrossChain[props.bridgeType].BNB;
    const bnbToken = bnb.token.find((item) => item.symbol === props.currencyType) || bnb.token[0];
    const networkCross = networkForCrossChain[props.bridgeType][props.networkId];
    const networkCrossToken = networkCross.token.find((item) => item.symbol === props.currencyType) || networkCross.token[1];
    const gasPromise = getDepositGasFee(networkCross.rpcUrl, networkCross.contractAddress, bnb.chainId, networkCross.dstGasForCall || 0);
    const conversionFeePromise = getConversionFee(userWalletAddress || '0xf1f25A26499B023200B3f9A30a8eCEE87b031Ee1', networkCross.rpcUrl, networkCross.feeLibrary, bnb.chainId, networkCrossToken.poolId, bnbToken.poolId, props.amountSimple);
    return Promise.all([gasPromise, conversionFeePromise]).then((values) => {
        const gasDecimal = values[0];
        const gasCurrency = {
            value: gasDecimal
                ? getShortDecimal(converter.withoutType.fromWei(gasDecimal.value), 6, 'down').toFixed()
                : '',
            currency: currencyForNetwork[props.networkId] || null,
        };
        const conversionFeeWei = values[1];
        const conversionFeeSimple = converter.withoutType
            .fromWei(conversionFeeWei, { decimals: 6 })
            .toFixed();
        return {
            total: {
                value: conversionFeeSimple === '0' ? props.amountSimple.value.toFixed() : conversionFeeSimple,
                currency: props.currencyType,
            },
            gas: gasCurrency,
            conversionFee: {
                value: props.amountSimple.value.minus(conversionFeeSimple).toFixed(),
                currency: props.currencyType,
                details: [],
            },
            slippage: web3Constants.crossChainSlippage && web3Constants.crossChainSlippage.toShow,
        };
    });
}
