import { TYPES_OF_SAVED_OPERATAIONS } from '@/infra/types/saved-operations/constants';
import { myDialog } from '@/store/my-dialog';
import { walletNetworkByWalletId } from '@/web3-server/wallet/data/network';
import infra from '@/infra';
import { TRANSACTION_TYPES } from '@/infra/types/transactions/constants';
import { formatDate } from '@/utils/formatDate';
import notify from '@/store/notify/notify';
import { useCurrenciesStore } from '@/store/currencies';
import linker from '@/web3-server/utils/linker';
import GAManager from '@/utils/ga-manager/ga-manager';
import { useWeb3Currencies } from '@/web3-server/web3-currencies';
import { OPERATION_TYPE } from '@/infra/types/operations/constants';
import { useWeb3Store } from '@/web3-server';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import { useDialogStore } from '@/store/dialog';
export function getOperationEvents(params) {
    let events = null;
    if (params && params.type && params.defaultParams && params.currency) {
        const web3Currencies = useWeb3Currencies();
        const { type, defaultParams, openErrorNotify } = params;
        const currencyType = web3Currencies.getCurrencyType(params.currency);
        if (currencyType) {
            const defaultOperationParams = {
                ...defaultParams,
                currencyType,
            };
            let toastId = Date.now();
            let savedHash = undefined;
            if (type === OPERATION_TYPE.CROSS_CHAIN) {
                const crossChainOperation = getOperations(defaultOperationParams, openErrorNotify).crossChain(params.crossChainProps);
                events = {
                    default: {
                        allowedByUser(allowed, hash, error) {
                            if (allowed) {
                                const params = crossChainOperation.allowedByUser.onSuccess(hash);
                                toastId = params.toastId;
                                savedHash = hash;
                            }
                            else {
                                crossChainOperation.allowedByUser.onError(error);
                            }
                        },
                        transactionCompleted(completed) {
                            if (completed) {
                                crossChainOperation.transactionCompleted.onSuccess({
                                    toastId,
                                    hash: savedHash,
                                });
                            }
                            else {
                                crossChainOperation.transactionCompleted.onError({
                                    toastId,
                                    hash: savedHash,
                                });
                            }
                        },
                    },
                    extended: {
                        sentToBridge(completed) {
                            if (completed) {
                                toastId = crossChainOperation.sentToBridge.onSuccess({
                                    toastId,
                                    hash: savedHash,
                                });
                            }
                            else {
                                crossChainOperation.sentToBridge.onError({ toastId, hash: savedHash });
                            }
                        },
                    },
                };
            }
            else if (type === OPERATION_TYPE.APPROVE) {
                const tokenApprovalOperation = getOperations(defaultOperationParams, openErrorNotify).approve(params.paramsForTokenApproval);
                events = {
                    default: {
                        allowedByUser(allowed, hash, error) {
                            if (allowed) {
                                savedHash = hash;
                                toastId = tokenApprovalOperation.allowedByUser.onSuccess(hash);
                            }
                            else {
                                tokenApprovalOperation.allowedByUser.onError(error);
                            }
                        },
                        async transactionCompleted(completed) {
                            if (completed) {
                                const approvedAmount = await tokenApprovalOperation.transactionCompleted.onSuccess({
                                    toastId,
                                    hash: savedHash,
                                });
                                if (params.successCallback) {
                                    params.successCallback(approvedAmount);
                                }
                            }
                            else {
                                tokenApprovalOperation.transactionCompleted.onError({ toastId, hash: savedHash });
                            }
                        },
                    },
                };
            }
            else if (type === OPERATION_TYPE.DEFAULT) {
                const operations = getOperations(defaultOperationParams, openErrorNotify);
                events = {
                    default: {
                        allowedByUser(allowed, hash, error) {
                            const paramsForSuccess = params.paramsForDefaultOperation;
                            if (allowed) {
                                savedHash = hash;
                                toastId = operations.default.allowedByUser.onSuccess(paramsForSuccess.networkId, paramsForSuccess.notifyText, hash, paramsForSuccess.notGoHome);
                            }
                            else {
                                operations.default.allowedByUser.onError(error);
                            }
                        },
                        transactionCompleted(completed, response) {
                            if (completed) {
                                operations.default.transactionCompleted.onSuccess({ toastId, hash: savedHash });
                            }
                            else {
                                operations.default.transactionCompleted.onError({ toastId, hash: savedHash }, response && response.error);
                            }
                        },
                    },
                };
            }
        }
    }
    return events;
}
function getOperations(defaultProps, openErrorNotify) {
    const { finishWaitingForNotify } = getDefaultMethods();
    const { availableForUse, currencyType } = defaultProps;
    const GAEvent = availableForUse?.sendEventsToGA && 'readyEvents' in availableForUse.sendEventsToGA
        ? availableForUse.sendEventsToGA.readyEvents
        : GAManager.hooks.manager(availableForUse?.sendEventsToGA);
    const operations = {
        crossChain(crossChainProps) {
            const dialogStore = useDialogStore();
            const network = walletNetworkByWalletId[crossChainProps.walletNetworkId];
            const rpcUrl = network.rpcUrl;
            return {
                allowedByUser: {
                    onSuccess(hash) {
                        const networkName = network.id;
                        const networkNameCase = networkName[0].toUpperCase() + networkName.slice(1).toLowerCase();
                        if (GAEvent && GAEvent.signature) {
                            GAEvent.signature(true);
                        }
                        if (availableForUse && availableForUse.addToWalletHistory) {
                            infra.wallet.addTransaction({
                                type: TRANSACTION_TYPES.CROSS_CHAIN_DEPOSIT,
                                currency: currencyType,
                                amount: availableForUse.addToWalletHistory.amount,
                                date: formatDate(new Date()),
                                link: getMethodsForCrossChain().getLinkForToast(hash),
                                isFailure: false,
                            });
                        }
                        const toastId = notify.show.pendingWithSave({
                            text: `Deposit ${currencyType} on ${networkNameCase}`,
                            hash,
                            link: getDefaultMethods().getLinkForToast(hash, network),
                            type: TYPES_OF_SAVED_OPERATAIONS.CROSSCHAIN_MY_NETWORK,
                            rpcUrl,
                            detailed: {
                                default: defaultProps,
                                network: crossChainProps,
                            },
                        });
                        // myDialog.openCrossChainTransaction(1, { from: networkName });
                        return { toastId, hash };
                    },
                    onError(error) {
                        if (GAEvent && GAEvent.signature) {
                            GAEvent.signature(false);
                        }
                        if (openErrorNotify) {
                            openErrorNotify(getErrorTextForPopup(error));
                        }
                    },
                },
                sentToBridge: {
                    onSuccess(props) {
                        finishWaitingForNotify(props);
                        const newToastId = notify.show.pendingWithSave({
                            text: 'Move assets to BNB Chain',
                            hash: props && props.hash,
                            link: getMethodsForCrossChain().getLinkForToast(props && props.hash),
                            type: TYPES_OF_SAVED_OPERATAIONS.CROSSCHAIN_BNB,
                            rpcUrl,
                            detailed: {
                                default: defaultProps,
                                network: crossChainProps,
                            },
                        });
                        // if (dialogStore.isOpen(dialogStore.config.ids.CROSS_CHAIN_TRANSACTION)) {
                        //   myDialog.openCrossChainTransaction(2);
                        // }
                        return newToastId;
                    },
                    onError(props) {
                        finishWaitingForNotify(props);
                        notify.show.error(props?.toastId);
                        if (openErrorNotify) {
                            openErrorNotify();
                        }
                    },
                },
                transactionCompleted: {
                    onSuccess(props) {
                        if (GAEvent && GAEvent.after) {
                            GAEvent.after(true);
                        }
                        finishWaitingForNotify(props);
                        infra.wallet.connect().finally(() => {
                            notify.show.success(props.toastId);
                        });
                        if (dialogStore.isOpen(dialogStore.config.ids.STEPPER)) {
                            myDialog.openNotifyAboutTransaction({ isSuccess: true }, { closingCb: () => myDialog.goHome() });
                        }
                    },
                    onError(props) {
                        if (GAEvent && GAEvent.after) {
                            GAEvent.after(false);
                        }
                        finishWaitingForNotify(props);
                        notify.show.error(props?.toastId);
                        // if (dialogStore.isOpen(dialogStore.config.ids.CROSS_CHAIN_TRANSACTION)) {
                        //   myDialog.goHome();
                        // }
                    },
                },
            };
        },
        approve(props) {
            const { networkId, currencyAddress, contractAddress } = props;
            const network = linker.getWalletNetwork(networkId);
            const blockExplorerUrl = network.blockExplorerUrl;
            const rpcUrl = network.rpcUrl;
            const { getLinkForToast } = getDefaultMethods();
            const currenciesStore = useCurrenciesStore();
            const clearCurrency = () => {
                if (contractAddress) {
                    currenciesStore.clearCurrencyInProgress(currencyAddress, contractAddress);
                }
            };
            if (contractAddress) {
                currenciesStore.currencyToBeApproved.push({ currencyAddress, contractAddress });
            }
            return {
                allowedByUser: {
                    onSuccess(hash) {
                        const link = getLinkForToast(hash, network);
                        if (GAEvent && GAEvent.signature) {
                            GAEvent.signature(true);
                        }
                        if (availableForUse && availableForUse.addToWalletHistory) {
                            infra.wallet.addTransaction({
                                type: availableForUse.addToWalletHistory.transactionType,
                                currency: defaultProps.currencyType,
                                amount: availableForUse.addToWalletHistory.amount || '0',
                                date: formatDate(new Date()),
                                link,
                                isFailure: false,
                                blockExplorerUrl,
                            });
                        }
                        return notify.show.pendingWithSave({
                            text: `Approve ${currencyType} transaction`,
                            type: TYPES_OF_SAVED_OPERATAIONS.APPROVE,
                            hash,
                            link,
                            rpcUrl,
                            detailed: {
                                default: defaultProps,
                                paramsForTokenApproval: props,
                            },
                        });
                    },
                    onError(error) {
                        if (GAEvent && GAEvent.signature) {
                            GAEvent.signature(false);
                        }
                        if (openErrorNotify) {
                            openErrorNotify(getErrorTextForPopup(error));
                        }
                        clearCurrency();
                    },
                },
                transactionCompleted: {
                    async onSuccess(props) {
                        if (GAEvent && GAEvent.after) {
                            GAEvent.after(true);
                        }
                        finishWaitingForNotify(props);
                        clearCurrency();
                        notify.show.success(props.toastId);
                        if (contractAddress) {
                            return getApprovedAmountOfCurrency(networkId, contractAddress, currencyAddress).then((approvedAmount) => {
                                const currenciesStore = useCurrenciesStore();
                                const duplicateIndex = currenciesStore.approvedCurrenciesUnderContracts.findIndex((item) => item.currencyAddress === currencyAddress &&
                                    item.contractAddress === contractAddress);
                                if (duplicateIndex >= 0) {
                                    currenciesStore.approvedCurrenciesUnderContracts.splice(duplicateIndex, 1);
                                }
                                currenciesStore.approvedCurrenciesUnderContracts.push({
                                    currencyType: defaultProps.currencyType,
                                    currencyAddress,
                                    contractAddress,
                                    approvedAmount,
                                });
                                return approvedAmount;
                            });
                        }
                        else {
                            return '0';
                        }
                    },
                    onError(props) {
                        if (GAEvent && GAEvent.after) {
                            GAEvent.after(false);
                        }
                        finishWaitingForNotify(props);
                        clearCurrency();
                        notify.show.error(props?.toastId);
                    },
                },
            };
        },
        default: {
            allowedByUser: {
                onSuccess(networkId, notifyText, hash, notGoHome) {
                    const network = linker.getWalletNetwork(networkId);
                    const link = getDefaultMethods().getLinkForToast(hash, network);
                    if (availableForUse && availableForUse.addToWalletHistory) {
                        infra.wallet.addTransaction({
                            type: availableForUse.addToWalletHistory.transactionType,
                            currency: defaultProps.currencyType,
                            amount: availableForUse.addToWalletHistory.amount,
                            date: formatDate(new Date()),
                            link,
                            isFailure: false,
                            blockExplorerUrl: network.blockExplorerUrl,
                        });
                    }
                    if (GAEvent && GAEvent.signature) {
                        GAEvent.signature(true);
                    }
                    if (!notGoHome) {
                        myDialog.goHome();
                    }
                    return notify.show.pendingWithSave({
                        text: notifyText || `${defaultProps.currencyType} transaction`,
                        type: TYPES_OF_SAVED_OPERATAIONS.OTHER,
                        hash,
                        link,
                        rpcUrl: network.rpcUrl,
                        detailed: {
                            default: defaultProps,
                        },
                    });
                },
                onError(error) {
                    if (GAEvent && GAEvent.signature) {
                        GAEvent.signature(false);
                    }
                    if (openErrorNotify) {
                        openErrorNotify(getErrorTextForPopup(error));
                    }
                },
            },
            transactionCompleted: {
                onSuccess(props, turnConnect = true) {
                    if (GAEvent && GAEvent.after) {
                        GAEvent.after(true);
                    }
                    finishWaitingForNotify(props);
                    if (turnConnect) {
                        infra.wallet.connect().finally(() => {
                            notify.show.success(props.toastId);
                        });
                    }
                    else {
                        notify.show.success(props.toastId);
                    }
                },
                onError(props) {
                    if (GAEvent && GAEvent.after) {
                        GAEvent.after(false);
                    }
                    finishWaitingForNotify(props);
                    notify.show.error(props?.toastId);
                },
            },
        },
    };
    return operations;
}
export default getOperations;
function getMethodsForCrossChain() {
    return {
        getLinkForToast(hash) {
            return hash ? 'https://layerzeroscan.com/tx/' + hash : '';
        },
    };
}
function getDefaultMethods() {
    return {
        finishWaitingForNotify(props) {
            if (props) {
                const { toastId, hash } = props;
                if (toastId !== undefined && toastId !== null) {
                    notify.hide.withMemoryErasure(toastId, hash);
                }
            }
        },
        getLinkForToast(hash, network) {
            if (hash && network) {
                return network.blockExplorerUrl + 'tx/' + hash;
            }
            else if (hash) {
                return 'https://bscscan.com/tx/' + hash;
            }
            else {
                return '';
            }
        },
    };
}
async function getApprovedAmountOfCurrency(networkId, strategyContractAddress, currencyAddress) {
    if (strategyContractAddress && currencyAddress && networkId) {
        const web3Store = useWeb3Store();
        const web3Currencies = useWeb3Currencies();
        const currency = web3Currencies.getCurrency({ address: currencyAddress });
        if (currency && currency.decimals) {
            return web3Store.getApprovedCurrency(strategyContractAddress, { currencyAddress }, currency.decimals, { networkId });
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR, { currency });
            return '0';
        }
    }
    else {
        devError(DEV_ERROR_CODE.MISSING_VAR, { strategyContractAddress, currencyAddress, networkId });
        return '0';
    }
}
function getErrorTextForPopup(error) {
    const subtitle = [];
    if (error) {
        if (error.message) {
            subtitle.push(error.message);
        }
        if ('code' in error && error.code) {
            subtitle.push(`(сode: ${error.code})`);
        }
    }
    return subtitle.length ? subtitle.join(' ') : undefined;
}
