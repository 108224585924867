import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createPinia } from 'pinia';
import './assets/css/tailwind.css';
import VueGtag from 'vue-gtag';
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import Toast, { TYPE } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { versionCheck, removeRefreshBtn } from '@/utils/struggle-with-locale-cache';
versionCheck();
removeRefreshBtn();
createApp(App)
    .use(createPinia())
    .use(store)
    .use(router)
    .use(VueGtag, {
    pageTrackerScreenviewEnabled: true,
    config: {
        id: process.env.VUE_APP_GA_ID,
        params: {
            debug_mode: process.env.VUE_APP_IS_GA_DEBUG,
            send_page_view: true,
        },
    },
    router,
})
    .use(VueTippy)
    .use(Toast, {
    containerClassName: 'my-toast-container',
    hideProgressBar: true,
    closeButton: false,
    toastDefaults: {
        [TYPE.ERROR]: {
            toastClassName: 'my-toast',
            icon: {
                iconClass: 'my-toast-icon-error',
            },
        },
        [TYPE.SUCCESS]: {
            toastClassName: 'my-toast',
            icon: {
                iconClass: 'my-toast-icon-success',
            },
        },
        [TYPE.DEFAULT]: {
            timeout: false,
            icon: false,
            closeOnClick: false,
            draggable: false,
            toastClassName: 'my-toast-clear',
        },
    },
})
    .mount('#app');
