import { DECIMAL_TYPE } from '@/web3-server/types/decimal/constants';
import { useCurrenciesStore } from '@/store/currencies';
export default function updateCurrency(web3Store, web3Currencies, strategyContract, currency, vault) {
    const currenciesStore = useCurrenciesStore();
    if (currency.burnAddress) {
        return {
            availableBalancePromise: web3Currencies
                .getNativeBalance(currency.id)
                .then((availableBalance) => {
                currency.balance = availableBalance.simple.toFixed();
                return {
                    type: DECIMAL_TYPE.SIMPLE,
                    value: availableBalance.simple,
                };
            }),
            approvedPromise: new Promise((resolve) => {
                const maxApproval = web3Currencies.getMaxApproval();
                currency.approved = maxApproval;
                resolve(maxApproval);
            }),
            depositPromise: web3Currencies
                .getDepositedFromStorage(currency.id, strategyContract)
                .then((depositDecimal) => {
                const deposit = depositDecimal.value.toFixed();
                currenciesStore.addDepositToCurrency(currency.id, strategyContract.address, deposit);
                return deposit;
            }),
        };
    }
    else {
        const web3Currency = web3Store.getCurrency(strategyContract, {
            address: currency.address,
            decimals: currency.decimals,
            burnAddress: currency.burnAddress,
        }, vault);
        if (web3Currency) {
            web3Currency.availableBalancePromise.then((availableBalance) => {
                currency.balance = availableBalance.value.toFixed();
            });
            web3Currency.approvedPromise.then((approved) => {
                currency.approved = approved;
            });
            if (web3Currency.depositPromise) {
                web3Currency.depositPromise.then((deposit) => {
                    currenciesStore.addDepositToCurrency(currency.id, strategyContract.address, deposit);
                });
            }
        }
        return web3Currency;
    }
}
