import { myDialog } from '@/store/my-dialog';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import { useCurrenciesStore } from '@/store/currencies';
import { useDialogStore } from '@/store/dialog';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import getOperations from '@/store/operations/operations';
import { useWalletStore } from '@/web3-server/wallet';
import { NETWORK_SWITCH_STATUSED } from '@/web3-server/wallet/types/provider/constants';
import myWallet from '@/store/wallet/my-wallet';
import { getStrategyAndCurrencyFromWeb3 } from '@/web3-server/web3/handler/inspectors';
import { TRANSACTION_TYPES } from '@/infra/types/transactions/constants';
import linker from '@/web3-server/utils/linker';
import GAManager from '@/utils/ga-manager/ga-manager';
export function depositEventHandlerV2(props) {
    const { callback, mainParams, selectedCrossChainNetwork } = props;
    const { strategy, currency } = getStrategyAndCurrencyFromWeb3({
        strategyId: mainParams.strategyId,
        currencyId: mainParams.currencyId,
    });
    if (strategy && currency) {
        const selectedNetworkForTransaction = linker.getNetworkId(selectedCrossChainNetwork || strategy.vault);
        const GAEvent = GAManager.hooks.manager({
            anyEventName: props.mainParams.GAEventName,
            GAPrefix: strategy.GAPrefix,
            extra: { currencyType: currency.symbol, amount: mainParams.amount },
        });
        const { setConfirmTransaction, defaultEvents, error, checkNetwork, switchNetwork } = getMethods(strategy.network, currency.symbol, {
            currencyType: currency.symbol,
            availableForUse: {
                sendEventsToGA: { readyEvents: GAEvent },
                addToWalletHistory: {
                    amount: mainParams.amount,
                    transactionType: mainParams.transactionType,
                },
            },
        }, props.savePosition, getNotifyText(mainParams.transactionType, currency.symbol), GAEvent, props.errorCallback);
        if (GAEvent && GAEvent.before) {
            GAEvent.before();
        }
        try {
            setConfirmTransaction(true);
            if (strategy && currency) {
                if (checkNetwork(selectedNetworkForTransaction)) {
                    callback({ strategy, currency, defaultEvents });
                }
                else {
                    switchNetwork(selectedNetworkForTransaction).then((result) => {
                        if (result === NETWORK_SWITCH_STATUSED.SUCCESS) {
                            callback({ strategy, currency, defaultEvents });
                        }
                        else if (result === NETWORK_SWITCH_STATUSED.ERROR) {
                            devError(DEV_ERROR_CODE.SWITCH_NETWORK);
                            error();
                        }
                    });
                }
            }
            else {
                devError(DEV_ERROR_CODE.MISSING_VAR);
                error();
            }
        }
        catch (e) {
            devError(e);
            error();
        }
    }
    else {
        devError(DEV_ERROR_CODE.MISSING_VAR);
        if (props.errorCallback) {
            props.errorCallback();
        }
        myDialog.openNotifyAboutTransaction({ isSuccess: false }, { stepBack: 1 });
    }
}
function getNotifyText(transactionType, currencyType) {
    switch (transactionType) {
        case TRANSACTION_TYPES.DEPOSIT:
            return `Deposit ${currencyType} transaction`;
        case TRANSACTION_TYPES.APPROVE:
            return `Approve ${currencyType} transaction`;
        case TRANSACTION_TYPES.CLAIM:
            return `Claim ${currencyType} transaction`;
        case TRANSACTION_TYPES.WITHDRAW:
            return `Withdraw ${currencyType} transaction`;
        case TRANSACTION_TYPES.BOOSTING_DEPOSIT:
            return 'Boosting APY transaction';
        case TRANSACTION_TYPES.BOOSTING_WITHDRAW:
            return `Withdraw ${currencyType} transaction`;
    }
}
export function simpleDepositEventHandler(props) {
    const { callback, GAEvent } = props;
    const { setConfirmTransaction, defaultEvents, error } = getMethods(props.networkId, props.currencyType, props.propsDefaultOperation, undefined, props.notifyText, GAEvent);
    if (GAEvent && GAEvent.before) {
        GAEvent.before();
    }
    try {
        setConfirmTransaction(true);
        callback(defaultEvents);
    }
    catch (e) {
        devError(e);
        error();
    }
}
export function depositEventHandler(props) {
    const { callback, GAEvent, selectedNetworkForTransaction, propsDefaultOperation } = props;
    const { $store, setConfirmTransaction, defaultEvents, error, checkNetwork, switchNetwork } = getMethods(props.networkId, props.currencyType, propsDefaultOperation, props.savePosition, props.notifyText, GAEvent, props.errorCallback);
    const currency = $store.getCurrency(props.currencyType, selectedNetworkForTransaction);
    if (GAEvent && GAEvent.before) {
        GAEvent.before();
    }
    try {
        setConfirmTransaction(true);
        if (currency) {
            if (checkNetwork(selectedNetworkForTransaction && selectedNetworkForTransaction.networkId)) {
                callback(currency, defaultEvents);
            }
            else {
                switchNetwork(selectedNetworkForTransaction && selectedNetworkForTransaction.networkId).then((result) => {
                    if (result === NETWORK_SWITCH_STATUSED.SUCCESS) {
                        callback(currency, defaultEvents);
                    }
                    else if (result === NETWORK_SWITCH_STATUSED.ERROR) {
                        devError(DEV_ERROR_CODE.SWITCH_NETWORK);
                        error();
                    }
                });
            }
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR);
            error();
        }
    }
    catch (e) {
        devError(e);
        error();
    }
}
function getMethods(networkId, currencyType, propsDefaultOperation, savePosition, notifyText, GAEvent, errorCallback) {
    const dialogStore = useDialogStore();
    const currenciesStore = useCurrenciesStore();
    const walletStore = useWalletStore();
    const $store = {
        getCurrency: (type, network) => currenciesStore.getCurrency(type, network),
    };
    const setConfirmTransaction = (val) => {
        if (val) {
            myDialog.openConfirmTransaction();
        }
        else {
            dialogStore.close();
        }
    };
    const reopenFunc = dialogStore.current && 'reopen' in dialogStore.current ? dialogStore.current.reopen : undefined;
    const openErrorNotify = (subtitle) => {
        myDialog.openNotifyAboutTransaction({ isSuccess: false, subtitle }, {
            closingCb: () => {
                if (reopenFunc) {
                    let props = { selectedNetwork: undefined };
                    if (savePosition) {
                        if (savePosition.selectedStargateNetworkId) {
                            props = { selectedStargateNetworkId: savePosition.selectedStargateNetworkId };
                        }
                        else if (savePosition.selectedNetwork) {
                            props = { selectedNetwork: savePosition.selectedNetwork };
                        }
                    }
                    reopenFunc(savePosition && savePosition.selectedCurrencyInMultipleList
                        ? {
                            ...props,
                            selectedCurrency: savePosition.selectedCurrencyInMultipleList,
                        }
                        : {
                            ...props,
                            selectedCurrencyType: currencyType,
                        });
                }
                else {
                    dialogStore.goBack(2);
                }
            },
        });
    };
    return {
        $store,
        defaultEvents: getDefaultEvents(networkId, setConfirmTransaction, propsDefaultOperation, openErrorNotify, notifyText),
        setConfirmTransaction,
        error: () => {
            if (errorCallback) {
                errorCallback();
            }
            setConfirmTransaction(false);
            openErrorNotify();
            if (GAEvent && GAEvent.after) {
                GAEvent.after(false);
            }
        },
        checkNetwork: (selectedNetworkId) => {
            let networksMatch = true;
            const currentNetwork = walletStore.network;
            if (!currentNetwork) {
                networksMatch = false;
            }
            else if (selectedNetworkId) {
                if (currentNetwork && currentNetwork.id !== selectedNetworkId) {
                    networksMatch = false;
                }
            }
            else if (currentNetwork.id !== NETWORK_ID.BNB) {
                networksMatch = false;
            }
            return networksMatch;
        },
        switchNetwork: async (selectedNetworkId) => {
            return myWallet.switchNetwork(selectedNetworkId || NETWORK_ID.BNB, 2);
        },
    };
}
function getDefaultEvents(networkId, setConfirmTransaction, propsDefaultOperation, openErrorNotify, notifyText) {
    let toastId = Date.now();
    let savedHash = undefined;
    const operations = getOperations(propsDefaultOperation, openErrorNotify);
    const allowedByUser = (allowed, hash, error) => {
        setConfirmTransaction(false);
        if (allowed) {
            savedHash = hash;
            toastId = operations.default.allowedByUser.onSuccess(networkId, notifyText, hash);
        }
        else {
            operations.default.allowedByUser.onError(error);
        }
    };
    const transactionCompleted = (completed, response) => {
        if (completed) {
            operations.default.transactionCompleted.onSuccess({ toastId, hash: savedHash });
        }
        else {
            operations.default.transactionCompleted.onError({ toastId, hash: savedHash }, response && response.error);
        }
    };
    return { allowedByUser, transactionCompleted };
}
