import LPToken from '@/infra/abis/LPToken.json';
import { numWei } from '@/composables/useFormatCurrency';
export default async function getLpContract({ web3 }, lpTokenAddress) {
    try {
        const contract = new web3.eth.Contract(LPToken, lpTokenAddress);
        const reserves = await contract.methods.getReserves().call();
        const totalSupply = await contract.methods.totalSupply().call();
        const token0 = await contract.methods.token0().call();
        const token1 = await contract.methods.token1().call();
        return {
            contract,
            // eslint-disable-next-line no-underscore-dangle
            reserve0: numWei(reserves._reserve0),
            // eslint-disable-next-line no-underscore-dangle
            reserve1: numWei(reserves._reserve1),
            totalSupply: numWei(totalSupply),
            token0: token0.toLowerCase(),
            token1: token1.toLowerCase(),
        };
    }
    catch (e) {
        return undefined;
    }
}
