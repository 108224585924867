import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
import { graphAPYAdapter } from '@/web3-server/web3/actions/strategies/graph/graph.adapter';
import getAPYFromServer from '@/web3-server/web3/actions/strategies/graph/apy/get-apy-from-server';
export default async function getGraphDataByAPY(contractType, contractAddress, url) {
    if (contractType === CONTRACT_TYPE.STORAGE) {
        return getAPYFromServer(contractAddress, url).then((response) => graphAPYAdapter(response, contractType, contractAddress));
    }
    else if (contractType === CONTRACT_TYPE.STAKING) {
        return getAPYFromServer(undefined, url).then((response) => getFilteredAPY(response, contractType, 'stakingApy'));
    }
    else if (contractType === CONTRACT_TYPE.FARMING) {
        return getAPYFromServer(undefined, url).then((response) => getFilteredAPY(response, contractType, 'farmingApy'));
    }
    else {
        return [];
    }
}
function getFilteredAPY(response, contractType, key) {
    let graphData = [];
    if (response) {
        const list = response.filter((item) => item[key] && item[key] !== '0');
        if (list.length) {
            graphData = graphAPYAdapter(list, contractType);
        }
    }
    return graphData;
}
