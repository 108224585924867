import { defineStore } from 'pinia';
import { DIALOG_IDS } from '@/infra/types/dialog/constants';
import { nextTick } from 'vue';
export const useDialogStore = defineStore('dialog', {
    state: () => ({
        config: {
            ids: DIALOG_IDS,
            maxStorageHistory: 10,
            minStorageHistory: 5,
        },
        current: null,
        history: [],
        available: [],
        availableById: [],
        idTimer: null,
    }),
    actions: {
        open(dialog) {
            if (this.current) {
                this.close();
            }
            this.current = dialog;
        },
        openFuture(dialog) {
            const duplicateIndex = this.available.findIndex((item) => item && dialog && item.id === dialog.id);
            const duplicateIndexById = this.availableById.findIndex((item) => dialog && item === dialog.id);
            if (duplicateIndex > -1) {
                this.available.splice(duplicateIndex, 1, dialog);
                this.availableById.splice(duplicateIndexById, 1);
                nextTick(() => {
                    if (dialog) {
                        this.availableById.push(dialog.id);
                    }
                });
            }
            else if (dialog) {
                this.available.push(dialog);
                this.availableById.push(dialog.id);
            }
            if (this.current) {
                this.close();
            }
            this.current = dialog;
        },
        openWithTimer(dialog, timeout = 5000) {
            this.open(dialog);
            this.idTimer = setTimeout(() => {
                if (dialog && this.current && this.current.id === dialog.id) {
                    if ('close' in dialog) {
                        dialog.close();
                    }
                    else {
                        this.close();
                    }
                }
            }, timeout);
        },
        close() {
            if (this.current) {
                // const dialog = this.available.find(
                //   (item) => item && this.current && item.id === this.current.id,
                // );
                //
                // if (dialog) {
                //   dialog.isHidden = true;
                // }
                if (this.history.length === this.config.maxStorageHistory) {
                    this.history.splice(0, this.config.minStorageHistory);
                }
                this.history.push(this.current);
                this.current = null;
                if (this.idTimer) {
                    clearTimeout(this.idTimer);
                    this.idTimer = null;
                }
            }
        },
        show(id) {
            const dialog = this.available.find((item) => item && item.id === id);
            if (dialog) {
                dialog.isHidden = false;
                // if (this.current) {
                //   this.hide();
                // }
                //
                this.current = dialog;
            }
        },
        hide() {
            if (this.current) {
                const duplicateIndex = this.available.findIndex((item) => item && this.current && item.id === this.current.id);
                if (duplicateIndex > -1) {
                    this.available.splice(duplicateIndex, 1, this.current);
                }
                else {
                    this.available.push(this.current);
                    this.availableById.push(this.current.id);
                }
                this.current = null;
                if (this.idTimer) {
                    clearTimeout(this.idTimer);
                    this.idTimer = null;
                }
            }
        },
        goBack(step = 1, extra) {
            const { isWithClosing, skipConfirmTransaction } = extra || {};
            if (this.history.length && step) {
                const last = this.history[this.history.length - step];
                if (last) {
                    if (skipConfirmTransaction && last.id === this.config.ids.CONFIRM_TRANSACTION) {
                        this.goBack(++step, extra);
                    }
                    else {
                        this.open({ ...last });
                    }
                }
            }
            else if (isWithClosing) {
                this.close();
            }
        },
        update(dialogId, newProps) {
            if (this.current && this.current.id === dialogId) {
                this.current = { ...this.current, ...newProps };
            }
        },
        updateSpecificProp(dialogId, name, value) {
            if (this.current && this.current.id === dialogId && 'props' in this.current) {
                const props = this.current.props;
                if (name in props) {
                    props[name] = value;
                }
            }
        },
        updateArrayProp(dialogId, name, value, isDelete) {
            if (this.current && this.current.id === dialogId && 'props' in this.current) {
                const props = this.current.props;
                if (name in props) {
                    const array = props[name];
                    if (array && Array.isArray(array) && isDelete) {
                        if (isDelete) {
                            const index = array.findIndex((item) => item === value);
                            if (index > -1) {
                                array.splice(index, 1);
                            }
                        }
                        else {
                            array.push(value);
                        }
                    }
                }
            }
        },
    },
    getters: {
        depositManagementDialog: (state) => {
            const current = state.current;
            return current && current.id === state.config.ids.MANAGEMENT ? current : undefined;
        },
        isOpen() {
            return (dialogId) => !!(this.current && this.current.id === dialogId);
        },
    },
});
