export var TRANSACTION_TYPES;
(function (TRANSACTION_TYPES) {
    TRANSACTION_TYPES["DEPOSIT"] = "DEPOSIT";
    TRANSACTION_TYPES["WITHDRAW"] = "WITHDRAW";
    TRANSACTION_TYPES["APPROVE"] = "APPROVE";
    TRANSACTION_TYPES["CLAIM"] = "CLAIM";
    TRANSACTION_TYPES["BOOSTING_DEPOSIT"] = "DEPOSIT";
    TRANSACTION_TYPES["BOOSTING_WITHDRAW"] = "WITHDRAW";
    TRANSACTION_TYPES["CROSS_CHAIN_DEPOSIT"] = "CROSS-CHAIN DEPOSIT";
    TRANSACTION_TYPES["MINT_NFT"] = "MINT NFT";
})(TRANSACTION_TYPES || (TRANSACTION_TYPES = {}));
