import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "mx-auto w-full md:w-[660px]" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_StrategyMyVaults = _resolveComponent("StrategyMyVaults");
    const _component_StrategyVaults = _resolveComponent("StrategyVaults");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_StrategyMyVaults, {
            "list-of-strategies": _ctx.strategiesWithDeposit,
            "total-deposit": _ctx.totalDeposit,
            onOpen: _cache[0] || (_cache[0] = ($event) => (_ctx.openPageWithCurrencyUpdate(true, $event))),
            onBoost: _ctx.openBoostPopup
        }, null, 8, ["list-of-strategies", "total-deposit", "onBoost"]),
        _createVNode(_component_StrategyVaults, {
            "list-of-strategies-by-vaults": _ctx.noDepositStrategiesGroupedByVault,
            onOpen: _ctx.event.openStrategyPage,
            onOpenInfo: _ctx.openInfoPage,
            onOpenByCurrency: _ctx.event.openStrategyPageByCurrency,
            onSwitchNetwork: _ctx.switchNetwork
        }, null, 8, ["list-of-strategies-by-vaults", "onOpen", "onOpenInfo", "onOpenByCurrency", "onSwitchNetwork"])
    ]));
}
