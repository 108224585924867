export default async function getDstGasForCallFromContract(contract) {
    try {
        const dstGasForCall = await contract.methods.getStargateDstGasForCall().call();
        if (dstGasForCall && dstGasForCall !== '0') {
            return Number(dstGasForCall);
        }
        else {
            return 0;
        }
    }
    catch (e) {
        return 0;
    }
}
