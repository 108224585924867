import { useFormatCurrencyLite } from '@/composables/useFormatCurrency';
export default function financeSummaryTotalAdapter(data) {
    const result = {
        deposit: '$' + useFormatCurrencyLite(data.deposit),
    };
    if (data.earnedBlid) {
        result.earnedBlid = useFormatCurrencyLite(data.earnedBlid);
    }
    if (data.earned) {
        result.earned = '$' + useFormatCurrencyLite(data.earned);
    }
    return result;
}
