import { BOOSTING_LEVEL_STATUS } from '@/web3-server/web3/types/strategies/boost/constants';
import Decimal from 'decimal.js';
import { decimalFormatting } from '@/composables/useFormatCurrency';
export default function getUserInfo(currency, price, deposited, decimalPlaces = 2) {
    const available = new Decimal(currency.balance || 0);
    if (deposited && deposited.equals(price)) {
        return {
            type: BOOSTING_LEVEL_STATUS.BOOST,
        };
    }
    else if (available.isZero()) {
        const purchase = price.minus(deposited);
        const userInfo = {
            type: BOOSTING_LEVEL_STATUS.BUY,
        };
        if (purchase.isPositive() && !purchase.isZero()) {
            userInfo.purchase = decimalFormatting(purchase, decimalPlaces, {
                showWithManyZeros: true,
                isRounding: 'default',
            });
        }
        else {
            userInfo.type = BOOSTING_LEVEL_STATUS.DEPOSIT;
        }
        return userInfo;
    }
    else {
        const purchase = price.minus(available).minus(deposited);
        const userInfo = {
            type: BOOSTING_LEVEL_STATUS.BUY,
        };
        if (purchase.isPositive() && !purchase.isZero()) {
            userInfo.purchase = decimalFormatting(purchase, decimalPlaces, {
                showWithManyZeros: true,
                isRounding: 'default',
            });
        }
        else {
            userInfo.type = BOOSTING_LEVEL_STATUS.DEPOSIT;
        }
        return userInfo;
    }
}
