import { STRATEGY_ID, STRATEGY_ROUTE, STRATEGY_TYPE, STRATEGY_VAULT, } from '@/web3-server/web3-strategies/types/config/constants';
import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
import Storage from '@/web3-server/abis/strategies/Storage.json';
import StorageV2 from '@/web3-server/abis/strategies/StorageV2.json';
import Master from '@/web3-server/abis/strategies/MasterCsrt.json';
import { getStrategyTemplate } from '@/web3-server/web3-strategies/data/template';
import { CURRENCY_ID } from '@/web3-server/web3-currencies/types/config/constants';
import { BRIDGE_TYPE } from '@/web3-server/web3/types/cross-chain/constants';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import { networkForCrossChain } from '@/web3-server/wallet/data/network';
import { GA_PREFIX } from '@/infra/types/ga-manager/constants';
const strategyHeadlines = {
    [STRATEGY_TYPE.STABLECOINS]: {
        title: 'stablecoins',
        subtitle: 'USDT, USDC, DAI',
        text: 'Deposit stablecoins (USDT, USDC, DAI) to have high income with lowest risk possible',
    },
    [STRATEGY_TYPE.BTC]: {
        title: 'btc',
        text: 'Deposit BTC to have high income with lowest risk possible',
    },
    [STRATEGY_TYPE.ETH]: {
        title: 'eth',
        text: 'Deposit ETH to have high income with lowest risk possible',
    },
    [STRATEGY_TYPE.ALTCOINS]: {
        title: 'altcoins',
        subtitle: 'XRP, XVS, LTC, ADA, LINK, DOT, MATIC',
        text: 'Deposit altcoins (XRP, XVS, LTC, ADA, LINK, DOT, MATIC) to have high income with lowest risk possible',
    },
    [STRATEGY_TYPE.BLID]: {
        title: 'BLID',
        text: 'Deposit BLID tokens on staking to have high income',
    },
    [STRATEGY_TYPE.BLID_USDT]: {
        title: 'BLID-USDT',
        subtitle: '@Pancakeswap',
        popupSubtitle: '@Pancakeswap',
        text: 'Become a liquidity provider on Pancakeswap and earn high yield on farming',
    },
};
const defaultStorageContract = {
    type: CONTRACT_TYPE.STORAGE,
    abi: Storage.abi,
};
const defaultStorageContractV2 = {
    type: CONTRACT_TYPE.STORAGE,
    abi: StorageV2.abi,
};
const bnbStrategies = [
    {
        id: STRATEGY_ID.BNB_STABLECOINS,
        vault: STRATEGY_VAULT.BNB,
        network: NETWORK_ID.BNB,
        route: STRATEGY_ROUTE.STABLECOINS_BNB,
        contract: {
            ...defaultStorageContract,
            address: process.env.VUE_APP_STORAGE_ADDRESS || '',
            graphAddress: process.env.VUE_APP_SUBGRAPH_API || '',
            logicAddress: process.env.VUE_APP_LOGIC_ADDRESS || '',
        },
        listOfCurrencyId: [
            CURRENCY_ID.BNB_BUSD,
            CURRENCY_ID.BNB_USDT,
            CURRENCY_ID.BNB_USDC,
            CURRENCY_ID.BNB_DAI,
        ],
        GAPrefix: GA_PREFIX.BNB_STABLECOINS,
        supportEIP1559: true,
        crossChain: {
            bridge: BRIDGE_TYPE.STARGATE,
            availableNetworks: [NETWORK_ID.POLYGON, NETWORK_ID.ETH],
            availableCurrencies: [CURRENCY_ID.POLYGON_USDT, CURRENCY_ID.ETH_USDT],
            contractAddress: [
                {
                    networkId: NETWORK_ID.POLYGON,
                    address: networkForCrossChain[BRIDGE_TYPE.STARGATE].POLYGON.contractAddress,
                },
                {
                    networkId: NETWORK_ID.ETH,
                    address: networkForCrossChain[BRIDGE_TYPE.STARGATE].ETH.contractAddress,
                },
            ],
        },
    },
    {
        id: STRATEGY_ID.BNB_BTC,
        vault: STRATEGY_VAULT.BNB,
        network: NETWORK_ID.BNB,
        route: STRATEGY_ROUTE.BTC_BNB,
        contract: {
            ...defaultStorageContract,
            address: process.env.VUE_APP_LOW_RISK_BTC_STORAGE_ADDRESS || '',
            graphAddress: process.env.VUE_APP_LOW_RISK_BTC_GRAPH_API || '',
            logicAddress: process.env.VUE_APP_LOW_RISK_BTC_LOGIC_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.BNB_BTC],
        GAPrefix: GA_PREFIX.BNB_BTC,
        supportEIP1559: true,
    },
    {
        id: STRATEGY_ID.BNB_ETH,
        vault: STRATEGY_VAULT.BNB,
        network: NETWORK_ID.BNB,
        route: STRATEGY_ROUTE.ETH_BNB,
        contract: {
            ...defaultStorageContract,
            address: process.env.VUE_APP_LOW_RISK_ETH_STORAGE_ADDRESS || '',
            graphAddress: process.env.VUE_APP_LOW_RISK_ETH_GRAPH_API || '',
            logicAddress: process.env.VUE_APP_LOW_RISK_ETH_LOGIC_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.BNB_ETH],
        GAPrefix: GA_PREFIX.BNB_ETH,
        supportEIP1559: true,
    },
    {
        id: STRATEGY_ID.BNB_ALTCOINS,
        vault: STRATEGY_VAULT.BNB,
        network: NETWORK_ID.BNB,
        route: STRATEGY_ROUTE.ALTCOINS_BNB,
        contract: {
            ...defaultStorageContract,
            address: process.env.VUE_APP_ALTCOINS_STORAGE_ADDRESS || '',
            graphAddress: process.env.VUE_APP_ALTCOINS_GRAPH_API || '',
            logicAddress: process.env.VUE_APP_ALTCOINS_LOGIC_ADDRESS || '',
        },
        listOfCurrencyId: [
            CURRENCY_ID.BNB_XRP,
            CURRENCY_ID.BNB_XVS,
            CURRENCY_ID.BNB_LTC,
            CURRENCY_ID.BNB_ADA,
            CURRENCY_ID.BNB_LINK,
            CURRENCY_ID.BNB_DOT,
            CURRENCY_ID.BNB_MATIC,
        ],
        GAPrefix: GA_PREFIX.BNB_ALTCOINS,
        supportEIP1559: true,
    },
    {
        id: STRATEGY_ID.BNB_BLID,
        vault: STRATEGY_VAULT.BNB,
        network: NETWORK_ID.BNB,
        route: STRATEGY_ROUTE.BLID_BNB,
        contract: {
            type: CONTRACT_TYPE.STAKING,
            abi: Master.abi,
            address: process.env.VUE_APP_MASTER_BLID_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.BNB_BLID],
        GAPrefix: GA_PREFIX.BNB_BLID,
        supportEIP1559: true,
    },
    {
        id: STRATEGY_ID.BNB_BLID_USDT,
        vault: STRATEGY_VAULT.BNB,
        network: NETWORK_ID.BNB,
        route: STRATEGY_ROUTE.BLID_USDT_BNB,
        contract: {
            type: CONTRACT_TYPE.FARMING,
            abi: Master.abi,
            address: process.env.VUE_APP_MASTER_BLID_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.BNB_BLID_USDT],
        GAPrefix: GA_PREFIX.BNB_BLID_USDT,
        supportEIP1559: true,
    },
    {
        id: STRATEGY_ID.BNB_BNB,
        vault: STRATEGY_VAULT.BNB_V2,
        network: NETWORK_ID.BNB,
        route: STRATEGY_ROUTE.BNB_BNB,
        contract: {
            ...defaultStorageContractV2,
            address: process.env.VUE_APP_BNB_NETWORK_BNB_STORAGE_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.BNB_BNB],
        GAPrefix: GA_PREFIX.BNB_BNB,
        supportEIP1559: true,
    },
    {
        id: STRATEGY_ID.BNB_USDT_V2,
        vault: STRATEGY_VAULT.BNB_V2,
        network: NETWORK_ID.BNB,
        route: STRATEGY_ROUTE.USDT_V2_BNB,
        contract: {
            ...defaultStorageContractV2,
            address: process.env.VUE_APP_BNB_NETWORK_USDT_STORAGE_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.BNB_USDT],
        GAPrefix: GA_PREFIX.BNB_USDT_V2,
        supportEIP1559: true,
    },
    {
        id: STRATEGY_ID.DEV_BNB_USDT_DFORCE,
        vault: STRATEGY_VAULT.BNB_V2,
        network: NETWORK_ID.BNB,
        route: STRATEGY_ROUTE.DEV_BNB_USDT_DFORCE,
        contract: {
            ...defaultStorageContractV2,
            address: process.env.VUE_APP_DEV_BNB_NETWORK_USDT_DFORCE_STORAGE_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.BNB_USDT],
        GAPrefix: GA_PREFIX.DEV_BNB_USDT_V2_DFORCE,
        supportEIP1559: true,
    },
];
const polygonStrategies = [
    {
        id: STRATEGY_ID.DEV_POLYGON_STABLECOINS,
        vault: STRATEGY_VAULT.POLYGON,
        network: NETWORK_ID.POLYGON,
        route: STRATEGY_ROUTE.DEV_POLYGON_STABLECOINS,
        contract: {
            ...defaultStorageContractV2,
            address: process.env.VUE_APP_DEV_POLYGON_STABLECOINS_STORAGE_ADDRESS || '',
        },
        listOfCurrencyId: [
            CURRENCY_ID.POLYGON_USDC,
            CURRENCY_ID.POLYGON_MATIC,
            CURRENCY_ID.POLYGON_ETH,
            CURRENCY_ID.POLYGON_DAI,
        ],
        GAPrefix: GA_PREFIX.DEV_POLYGON_STABLECOINS,
        supportEIP1559: true,
    },
    {
        id: STRATEGY_ID.POLYGON_USDC,
        vault: STRATEGY_VAULT.POLYGON,
        network: NETWORK_ID.POLYGON,
        route: STRATEGY_ROUTE.POLYGON_USDC,
        contract: {
            ...defaultStorageContractV2,
            address: process.env.VUE_APP_POLYGON_NETWORK_USDC_STORAGE_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.POLYGON_USDC],
        GAPrefix: GA_PREFIX.POLYGON_USDC,
        supportEIP1559: true,
    },
    {
        id: STRATEGY_ID.POLYGON_MATIC,
        vault: STRATEGY_VAULT.POLYGON,
        network: NETWORK_ID.POLYGON,
        route: STRATEGY_ROUTE.POLYGON_MATIC,
        contract: {
            ...defaultStorageContractV2,
            address: process.env.VUE_APP_POLYGON_NETWORK_MATIC_STORAGE_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.POLYGON_MATIC],
        GAPrefix: GA_PREFIX.POLYGON_MATIC,
        supportEIP1559: true,
    },
    {
        id: STRATEGY_ID.POLYGON_DAI,
        vault: STRATEGY_VAULT.POLYGON,
        network: NETWORK_ID.POLYGON,
        route: STRATEGY_ROUTE.POLYGON_DAI,
        contract: {
            ...defaultStorageContractV2,
            address: process.env.VUE_APP_POLYGON_NETWORK_DAI_STORAGE_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.POLYGON_DAI],
        GAPrefix: GA_PREFIX.POLYGON_DAI,
        supportEIP1559: true,
    },
    {
        id: STRATEGY_ID.POLYGON_ETH,
        vault: STRATEGY_VAULT.POLYGON,
        network: NETWORK_ID.POLYGON,
        route: STRATEGY_ROUTE.POLYGON_ETH,
        contract: {
            ...defaultStorageContractV2,
            address: process.env.VUE_APP_POLYGON_NETWORK_ETH_STORAGE_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.POLYGON_ETH],
        GAPrefix: GA_PREFIX.POLYGON_ETH,
        supportEIP1559: true,
    },
    {
        id: STRATEGY_ID.POLYGON_USDT,
        vault: STRATEGY_VAULT.POLYGON,
        network: NETWORK_ID.POLYGON,
        route: STRATEGY_ROUTE.USDT_POLYGON,
        contract: {
            ...defaultStorageContractV2,
            address: process.env.VUE_APP_USDT_POLYGON_STORAGE_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.POLYGON_USDT],
        GAPrefix: GA_PREFIX.POLYGON_USDT,
        supportEIP1559: true,
    },
];
const arbitrumStrategies = [
    {
        id: STRATEGY_ID.ARBITRUM_USDC_E,
        vault: STRATEGY_VAULT.ARBITRUM,
        network: NETWORK_ID.ARBITRUM,
        route: STRATEGY_ROUTE.ARBITRUM_USDC_E,
        contract: {
            ...defaultStorageContractV2,
            address: process.env.VUE_APP_ARBITRUM_NETWORK_USDC_E_STORAGE_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.ARB_USDC_E],
        GAPrefix: GA_PREFIX.ARB_USDC_E,
        supportEIP1559: true,
    },
];
const optimismStrategies = [
    {
        id: STRATEGY_ID.OPTIMISM_USDC_E,
        vault: STRATEGY_VAULT.OPTIMISM,
        network: NETWORK_ID.OPTIMISM,
        route: STRATEGY_ROUTE.OPTIMISM_USDC_E,
        contract: {
            ...defaultStorageContractV2,
            address: process.env.VUE_APP_OPTIMISM_NETWORK_USDC_E_STORAGE_ADDRESS || '',
        },
        listOfCurrencyId: [CURRENCY_ID.OP_USDC_E],
        GAPrefix: GA_PREFIX.OP_USDC_E,
        supportEIP1559: true,
    },
];
const ethStrategies = [];
export const listOfWeb3Strategies = bnbStrategies
    .concat(polygonStrategies, ethStrategies, arbitrumStrategies, optimismStrategies)
    .map((strategy) => {
    return {
        ...strategy,
        ...getStrategyTemplate(),
    };
});
export const web3StrategiesById = bnbStrategies
    .concat(polygonStrategies, ethStrategies, arbitrumStrategies, optimismStrategies)
    .map((strategy) => {
    const { listOfCurrencyId, deactivate, ...other } = strategy;
    return other;
});
