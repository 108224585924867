import { devError } from '@/utils/devError';
import multiCallUtils from '@/web3-server/web3/handler/multicall-utils';
export default async function getCountEarns(multiCall) {
    const countEarnsByStrategyId = [];
    try {
        const multiCallResponse = await multiCall.call({
            singleCall: [
                {
                    reference: 'getCountEarnsCall',
                    methodName: 'getCountEarns',
                    methodParameters: [],
                },
            ],
        });
        if (multiCallResponse && multiCallResponse.length) {
            multiCallResponse.forEach((callsResults) => {
                if (callsResults.value && callsResults.value.length) {
                    countEarnsByStrategyId.push({
                        strategyId: callsResults.id,
                        networkId: callsResults.networkId,
                        value: multiCallUtils.getVal('hex', callsResults.value[0], 0),
                    });
                }
            });
        }
    }
    catch (e) {
        devError(e);
    }
    return countEarnsByStrategyId;
}
