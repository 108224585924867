export var CONNECTION_TYPE;
(function (CONNECTION_TYPE) {
    CONNECTION_TYPE["METAMASK"] = "METAMASK";
    CONNECTION_TYPE["WALLET_CONNECT"] = "WALLET_CONNECT";
    CONNECTION_TYPE["TRUST_WALLET"] = "TRUST_WALLET";
    CONNECTION_TYPE["EXODUS"] = "EXODUS";
    CONNECTION_TYPE["BLOCTO"] = "BLOCTO";
})(CONNECTION_TYPE || (CONNECTION_TYPE = {}));
export var EXTENSION_TYPE;
(function (EXTENSION_TYPE) {
    EXTENSION_TYPE["METAMASK"] = "Metamask";
    EXTENSION_TYPE["COINBASE"] = "Coinbase";
    EXTENSION_TYPE["WALLET_CONNECT"] = "WalletConnect";
    EXTENSION_TYPE["EXODUS"] = "Exodus";
    EXTENSION_TYPE["BLOCTO"] = "Blocto";
})(EXTENSION_TYPE || (EXTENSION_TYPE = {}));
