import CrossChainDepositor from '@/infra/abis/CrossChainDepositor.json';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import { suggestMaxFees } from '@/web3-server/utils/suggest-fees';
import getDstGasForCallFromContract from '@/web3-server/web3/actions/cross-chain/get-dst-gas-for-call-from-contract';
export default async function depositStarGate({ web3, userWalletAddress }, chainId, contractAddress, fromTokenAddress, toTokenAddress, dstGasForCall, depositAmount, amountOutMin, depositFee) {
    try {
        return suggestMaxFees(web3, 'urgent')
            .then(async ({ maxFeePerGas, maxPriorityFeePerGas }) => {
            const contract = new web3.eth.Contract(CrossChainDepositor, contractAddress);
            const dstGasForCallFromContract = (await getDstGasForCallFromContract(contract)) || dstGasForCall;
            return {
                method: contract.methods.depositStarGate(chainId, fromTokenAddress, toTokenAddress, depositAmount.value.toFixed(), amountOutMin.value.toFixed(), dstGasForCallFromContract),
                params: {
                    from: userWalletAddress,
                    value: depositFee.value.toFixed(),
                    maxFeePerGas,
                    maxPriorityFeePerGas,
                },
            };
        })
            .catch(() => {
            devError(DEV_ERROR_CODE.WEB_CONTRACT);
            return null;
        });
    }
    catch (e) {
        devError(DEV_ERROR_CODE.WEB_CONTRACT_METHOD);
        return null;
    }
}
