import { getDecimalInObj } from '@/web3-server/web3/handler/calc';
import Decimal from 'decimal.js';
import getUserInfo from '@/web3-server/web3/actions/strategies/boost/get-user-info';
import { decimalFormatting } from '@/composables/useFormatCurrency';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import { getConfigForBoosting } from '@/web3-server/web3/actions/strategies/boost/get-config-for-boosting';
import web3 from 'web3';
import { BOOSTING_LEVEL_STATUS } from '@/web3-server/web3/types/strategies/boost/constants';
import getCalcCurrentAPY from '@/web3-server/web3/actions/strategies/boost/get-calc-current-apy';
import web3Constants from '@/web3-server/web3/data/web3-constants';
export async function getListOfLevelsToBoosting(requestWithPrice, apy, currency, strategyContract, networkId) {
    const boostingList = {
        levels: [],
        deposited: {
            wei: new Decimal(0),
            short: new Decimal(0),
            toShow: '',
        },
        priceIsTooLow: false,
    };
    try {
        const configForBoosting = getConfigForBoosting(requestWithPrice, strategyContract, networkId);
        if (!configForBoosting) {
            devError(DEV_ERROR_CODE.WEB_CONTRACT);
            return boostingList;
        }
        else {
            const { maxAPYPromise, getBoostingBLIDAmountPromise, BLIDNeedPromise } = configForBoosting;
            return Promise.all([maxAPYPromise, BLIDNeedPromise, getBoostingBLIDAmountPromise])
                .then((values) => {
                const maxAPYData = values[0];
                const BLIDNeed = values[1];
                const depositedBLID = {
                    wei: new Decimal(values[2]),
                    simple: new Decimal(web3.utils.fromWei(values[2])),
                };
                const apyDecimal = new Decimal(apy);
                const maxAPYSimple = new Decimal(maxAPYData.apy.formatted.full);
                const numOfLevels = maxAPYSimple.toDecimalPlaces(0, Decimal.ROUND_DOWN).toNumber();
                const maxNumOfCards = Number(web3Constants.maxNumOfCardsInBoosting || 20);
                const numOfCards = numOfLevels < maxNumOfCards ? numOfLevels : maxNumOfCards;
                const list = [];
                const getDecimalPlaces = (decimalPlaces = 3) => {
                    const priceList = [];
                    for (let i = 1; i <= numOfCards; i++) {
                        const decimal = new Decimal(BLIDNeed.formatted.full).mul(i);
                        priceList.push(decimalFormatting(decimal, decimalPlaces, {
                            isRounding: 'up',
                            showWithManyZeros: true,
                        }));
                    }
                    const isDuplicate = !!priceList.filter((item, index, arr) => arr.indexOf(item) !== index).length;
                    if (isDuplicate && decimalPlaces < 20) {
                        return getDecimalPlaces(++decimalPlaces);
                    }
                    else {
                        return decimalPlaces;
                    }
                };
                const decimalPlacesForCards = getDecimalPlaces(new Decimal(BLIDNeed.formatted.full).greaterThan(1000) ? 2 : 3);
                for (let i = 1; i <= numOfCards; i++) {
                    const realPrice = new Decimal(BLIDNeed.formatted.full).mul(i).toDecimalPlaces(10);
                    list.push({
                        percent: String(i),
                        apy: decimalFormatting(apyDecimal.plus(i), 2, { isRounding: 'down' }),
                        price: decimalFormatting(realPrice, decimalPlacesForCards, {
                            isRounding: 'up',
                            showWithManyZeros: true,
                        }),
                        realPrice,
                        userInfo: getUserInfo(currency, realPrice, depositedBLID.simple, decimalPlacesForCards),
                    });
                }
                workingWithDepositCard(list, depositedBLID, BLIDNeed.decimal, maxAPYSimple, apyDecimal);
                return {
                    levels: list,
                    deposited: {
                        wei: depositedBLID.wei,
                        short: depositedBLID.simple,
                        toShow: decimalFormatting(depositedBLID.simple.toNumber(), 2, {
                            showWithManyZeros: true,
                        }),
                    },
                    priceIsTooLow: new Decimal(BLIDNeed.formatted.full).toDecimalPlaces(10).isZero(),
                };
            })
                .catch(() => {
                devError(DEV_ERROR_CODE.WEB_CONTRACT_METHOD);
                return boostingList;
            });
        }
    }
    catch (e) {
        devError(DEV_ERROR_CODE.WEB_CONTRACT);
        return boostingList;
    }
}
function workingWithDepositCard(listOfLevels, depositedBLID, BLIDNeedWei, maxAPYSimple, baseAPY) {
    const depositedSimple = depositedBLID.simple;
    if (!depositedSimple.isZero()) {
        const currentAPYSimple = getCalcCurrentAPY(BLIDNeedWei, depositedBLID.wei).apy.formatted.full;
        const allowedAPY = maxAPYSimple.greaterThan(currentAPYSimple)
            ? new Decimal(currentAPYSimple)
            : maxAPYSimple;
        const duplicate = searchForDuplicates(listOfLevels, allowedAPY);
        if (duplicate) {
            changeCurrentCard(listOfLevels, depositedSimple, duplicate.integerAPY, allowedAPY, baseAPY);
        }
        else {
            const priceList = listOfLevels.map((item) => item.realPrice.toFixed());
            const allowedToAddDepositCard = !priceList.includes(depositedSimple.toFixed());
            if (allowedToAddDepositCard) {
                createAndAddDepositCard(listOfLevels, allowedAPY, depositedSimple, baseAPY);
            }
        }
    }
}
function searchForDuplicates(listOfLevels, allowedAPYSimple) {
    const integerAPY = allowedAPYSimple.round();
    const diff = integerAPY.minus(allowedAPYSimple).abs();
    let result = null;
    if (diff.greaterThanOrEqualTo(0) && diff.lessThanOrEqualTo(0.09)) {
        const indexBoostCard = listOfLevels.findIndex((item) => item.percent === integerAPY.toFixed());
        if (indexBoostCard > -1) {
            result = { integerAPY };
        }
    }
    return result;
}
function changeCurrentCard(listOfLevels, depositedSimple, duplicatePercent, realAPY, baseAPY) {
    const indexBoostCard = listOfLevels.findIndex((item) => item.percent === duplicatePercent.toFixed());
    if (indexBoostCard > -1) {
        listOfLevels.splice(indexBoostCard, 1, getDepositCard(realAPY, depositedSimple, baseAPY));
    }
}
function createAndAddDepositCard(listOfLevels, allowedAPY, depositedSimple, baseAPY) {
    addDepositCard(listOfLevels, getDepositCard(allowedAPY, depositedSimple, baseAPY), allowedAPY);
}
function getDepositCard(allowedAPY, depositedSimple, baseAPY) {
    return {
        percent: getDecimalInObj(allowedAPY.toDecimalPlaces(2, Decimal.ROUND_DOWN)),
        apy: decimalFormatting(baseAPY.plus(allowedAPY), 2, { isRounding: 'down' }),
        price: decimalFormatting(depositedSimple, 2, { showWithManyZeros: true }),
        realPrice: depositedSimple,
        userInfo: {
            type: BOOSTING_LEVEL_STATUS.BOOST,
        },
    };
}
function addDepositCard(listOfLevels, depositCard, allowedAPY) {
    const percent = allowedAPY.toDecimalPlaces(0, Decimal.ROUND_DOWN).toFixed();
    const currentLevelIndex = listOfLevels.findIndex((item) => item.percent === percent);
    if (currentLevelIndex > -1) {
        listOfLevels.splice(currentLevelIndex + 1, 0, depositCard);
    }
    else if (percent === '0') {
        listOfLevels.unshift(depositCard);
    }
    else {
        listOfLevels.push(depositCard);
    }
}
