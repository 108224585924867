import { devError } from '@/utils/devError';
import converter from '@/web3-server/utils/converter';
import Decimal from 'decimal.js';
import { decimalFormatting } from '@/composables/useFormatCurrency';
export default async function getDepositFromBoost(userWalletAddress, storageContract) {
    const defaultValue = {
        wei: new Decimal(0),
        short: new Decimal(0),
        toShow: '',
    };
    try {
        const getBoostingBLIDAmountPromise = storageContract.methods
            .getBoostingBLIDAmount(userWalletAddress)
            .call();
        return getBoostingBLIDAmountPromise
            .then((wei) => {
            const decimalWei = converter.numericStr.toDecimal(wei);
            const decimalSimple = converter.withoutType.fromWei(decimalWei);
            return {
                wei: decimalWei,
                short: decimalSimple,
                toShow: decimalFormatting(decimalSimple.toNumber(), 2, {
                    showWithManyZeros: true,
                }),
            };
        })
            .catch((e) => {
            devError(e);
            return defaultValue;
        });
    }
    catch (e) {
        devError(e);
        return defaultValue;
    }
}
