import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import converter from '@/web3-server/utils/converter';
import Decimal from 'decimal.js';
export default async function getDepositFromStorage(userWalletAddress, storageContract, BLIDPriceSimple) {
    const result = {
        isDeposit: false,
        USDDeposit: 0,
        USDDepositByParams: {
            depositedUSD: new Decimal(0),
            depositedBLID: new Decimal(0),
        },
    };
    try {
        const USDDepositDecimalSimple = converter.withoutType.fromWei(await storageContract.methods.balanceOf(userWalletAddress).call());
        const depositedBLIDSimple = converter.withoutType.fromWei(await storageContract.methods.getBoostingBLIDAmount(userWalletAddress).call());
        const depositedBLID = depositedBLIDSimple.mul(BLIDPriceSimple);
        result.USDDeposit = USDDepositDecimalSimple.plus(depositedBLID).toNumber();
        result.isDeposit = !USDDepositDecimalSimple.isZero();
        result.USDDepositByParams.depositedUSD = USDDepositDecimalSimple;
        result.USDDepositByParams.depositedBLID = depositedBLIDSimple;
    }
    catch (e) {
        devError(DEV_ERROR_CODE.GSC_BALANCE_OF, e);
    }
    return result;
}
