import { WALLET_NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import { walletNetwork, walletNetworkById, walletNetworkByWalletId, } from '@/web3-server/wallet/data/network';
import EthereumProvider from '@walletconnect/ethereum-provider';
import { devError } from '@/utils/devError';
export default async function getWcProvider(by, showQrModal = true) {
    const projectId = process.env.VUE_APP_WALLET_CONNECT_ID || '';
    try {
        const wallet = getCurrentWallet(by) || walletNetworkByWalletId[WALLET_NETWORK_ID.BNB_STARGATE];
        return EthereumProvider.init({
            projectId,
            chains: [wallet.chainId.int],
            optionalChains: getAllChains(wallet),
            rpcMap: getAllRPC(wallet),
            showQrModal,
        });
    }
    catch (e) {
        devError(e);
        return null;
    }
}
function getCurrentWallet(by) {
    if ('chainId' in by && typeof by.chainId === 'string') {
        return walletNetwork.find((item) => item.chainId.hex === by.chainId);
    }
    else if ('chainId' in by && typeof by.chainId === 'number') {
        return walletNetwork.find((item) => item.chainId.int === by.chainId);
    }
    else if ('networkId' in by) {
        return walletNetworkById[by.networkId];
    }
    else if ('walletNetworkId' in by) {
        return walletNetworkByWalletId[by.walletNetworkId];
    }
    else {
        return undefined;
    }
}
function getAllChains(currentWallet) {
    return walletNetwork
        .filter((item) => item.walletId !== currentWallet.walletId)
        .map((wallet) => {
        return wallet.chainId.int;
    });
}
function getAllRPC(currentWallet) {
    return walletNetwork
        .filter((item) => item.walletId !== currentWallet.walletId)
        .reduce((prev, item) => {
        prev[item.chainId.int] = item.rpcUrl;
        return prev;
    }, { [currentWallet.chainId.int]: currentWallet.rpcUrl });
}
