import { DateTime } from 'luxon';
import { devError } from '@/utils/devError';
import web3 from 'web3';
import getCountEarns from '@/web3-server/web3/actions/strategies/multi/multi-storage-contract/composables/get-count-earns';
import multiCallUtils from '@/web3-server/web3/handler/multicall-utils';
export default async function getAPYAndAPRForAllStrategies(BLIDPrice, multiCall) {
    let listOfAPYAndAPRByStrategyId = [];
    const { earns, dateLastDistributionInMS } = await getEarns(multiCall);
    const periodInDays = getPeriodInDays(earns);
    listOfAPYAndAPRByStrategyId = earns.map((currentEarn) => {
        const currentResult = {
            apy: 0,
            apr: 0,
        };
        const currentPeriodInDays = periodInDays.find((item) => item.strategyId === currentEarn.strategyId)?.value || 0;
        if (currentPeriodInDays) {
            const DAYS_IN_YEAR = 365;
            const avgPercentPerDay = getAvgPercentPerDay(BLIDPrice, currentEarn.value, currentPeriodInDays);
            currentResult.apy = (Math.pow(1 + avgPercentPerDay, 365) - 1) * 100;
            currentResult.apr = avgPercentPerDay * 100 * DAYS_IN_YEAR;
        }
        return {
            strategyId: currentEarn.strategyId,
            networkId: currentEarn.networkId,
            value: currentResult,
        };
    });
    return { listOfAPYAndAPRByStrategyId, dateLastDistributionInMS };
}
async function getEarns(multiCall) {
    const earns = [];
    const dateLastDistributionInMS = [];
    try {
        const listOfCountEarns = await getCountEarns(multiCall);
        const multiCallResponse = await multiCall.call({
            multiCall: listOfCountEarns.map((item) => {
                const countEarns = Number(item.value || 0);
                const calls = [];
                if (countEarns) {
                    const limit = Math.min(countEarns, 6);
                    for (let i = limit; i > 0; i--) {
                        calls.push({
                            reference: `earn${countEarns - i}`,
                            methodName: 'getEarnsByID',
                            methodParameters: [countEarns - i],
                        });
                    }
                }
                return {
                    id: item.strategyId,
                    networkId: item.networkId,
                    value: calls,
                };
            }),
        });
        if (multiCallResponse && multiCallResponse.length) {
            multiCallResponse.forEach((callsResults) => {
                const currentEarns = {
                    strategyId: callsResults.id,
                    networkId: callsResults.networkId,
                    value: [],
                };
                if (callsResults.value && callsResults.value.length) {
                    for (const item of callsResults.value) {
                        currentEarns.value.push({
                            amount: multiCallUtils.getVal('hex', item, 0),
                            timestamp: multiCallUtils.getVal('hex', item, 1),
                            usd: multiCallUtils.getVal('hex', item, 2),
                        });
                    }
                    const lastResult = callsResults.value[callsResults.value.length - 1];
                    if (lastResult && lastResult.returnValues && lastResult.returnValues.length) {
                        dateLastDistributionInMS.push({
                            strategyId: callsResults.id,
                            networkId: callsResults.networkId,
                            value: Number.parseInt(web3.utils.hexToNumberString(callsResults.value[callsResults.value.length - 1].returnValues[1]?.hex || 0), 10) * 1000,
                        });
                    }
                }
                earns.push(currentEarns);
            });
        }
    }
    catch (e) {
        devError(e);
    }
    return { earns, dateLastDistributionInMS };
}
function getPeriodInDays(earns) {
    return earns.map((item) => {
        try {
            const firstDateTime = DateTime.fromJSDate(new Date(Number.parseInt(item.value[0].timestamp, 10) * 1000));
            const lastDateTime = DateTime.fromJSDate(new Date(Number.parseInt(item.value[item.value.length - 1].timestamp, 10) * 1000));
            const diff = lastDateTime.diff(firstDateTime, ['days']);
            return {
                strategyId: item.strategyId,
                networkId: item.networkId,
                value: diff.toObject().days,
            };
        }
        catch (e) {
            return {
                strategyId: item.strategyId,
                networkId: item.networkId,
                value: undefined,
            };
        }
    });
}
function getAvgPercentPerDay(BLIDPrice, earns, periodInDays) {
    let totalInBLID = 0;
    let totalDepositedInUSD = 0;
    for (const earn of earns) {
        totalInBLID += Number(earn.amount);
        totalDepositedInUSD += Number(earn.usd);
    }
    const avgDeposited = totalDepositedInUSD / earns.length;
    const avgPercent = (totalInBLID * BLIDPrice) / avgDeposited;
    return avgPercent / periodInDays;
}
