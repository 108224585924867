import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["href"];
const _hoisted_2 = { class: "ml-2.5 text-sm leading-tight" };
const _hoisted_3 = {
    key: 1,
    class: "flex items-center py-[0.6875rem] px-5 min-w-[180px] min-h-[40px] font-common bg-light-dark rounded-small"
};
const _hoisted_4 = { class: "ml-2.5 text-sm leading-tight" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SpinnerMini = _resolveComponent("SpinnerMini");
    return (_ctx.hash || _ctx.link)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.link || 'https://bscscan.com/tx/' + _ctx.hash,
            target: "_blank",
            class: "flex items-center py-[0.6875rem] px-5 min-w-[180px] min-h-[40px] font-common bg-light-dark rounded-small"
        }, [
            _createVNode(_component_SpinnerMini),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text || 'Pending transaction'), 1)
        ], 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_SpinnerMini),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.text || 'Pending transaction'), 1)
        ]));
}
