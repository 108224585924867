import { useWeb3Store } from '@/web3-server';
import { TRANSACTION_TYPES } from '@/infra/types/transactions/constants';
import { depositEventHandlerV2 } from '@/store/strategies/actions/deposit/composables/deposit-event-handler';
import { useCurrenciesStore } from '@/store/currencies';
import { decimalFormatting } from '@/composables/useFormatCurrency';
import { GA_EVENT_NAME } from '@/infra/types/ga-manager/constants';
const strategiesBoostingActions = {
    depositBoosting(networkId, strategyId, amount, depositedWei) {
        const currenciesStore = useCurrenciesStore();
        const BLIDCurrency = currenciesStore.getCurrency(currenciesStore.config.currencyTypes.BLID, {
            networkId,
        });
        const depositFunction = ({ currency, defaultEvents }) => {
            const web3Store = useWeb3Store();
            web3Store.depositBoosting(strategyId, amount, currency.decimals, depositedWei, {
                allowedByUser: (allowed, hash) => defaultEvents.allowedByUser(allowed, hash),
                transactionCompleted: (completed) => defaultEvents.transactionCompleted(completed),
            });
        };
        depositEventHandlerV2({
            callback: depositFunction,
            mainParams: {
                strategyId,
                currencyId: BLIDCurrency && BLIDCurrency.id,
                amount: decimalFormatting(amount, 2, { showWithManyZeros: true }),
                transactionType: TRANSACTION_TYPES.BOOSTING_DEPOSIT,
                GAEventName: GA_EVENT_NAME.BOOST_DEPOSIT,
            },
        });
    },
    withdrawBoosting(networkId, strategyId, deposited) {
        const currenciesStore = useCurrenciesStore();
        const BLIDCurrency = currenciesStore.getCurrency(currenciesStore.config.currencyTypes.BLID, {
            networkId,
        });
        const withdrawFunction = ({ defaultEvents }) => {
            const web3Store = useWeb3Store();
            web3Store.withdrawBoosting(strategyId, deposited.wei, {
                allowedByUser: (allowed, hash) => defaultEvents.allowedByUser(allowed, hash),
                transactionCompleted: (completed) => defaultEvents.transactionCompleted(completed),
            });
        };
        depositEventHandlerV2({
            callback: withdrawFunction,
            mainParams: {
                strategyId,
                currencyId: BLIDCurrency && BLIDCurrency.id,
                amount: deposited.toShow,
                transactionType: TRANSACTION_TYPES.BOOSTING_WITHDRAW,
                GAEventName: GA_EVENT_NAME.BOOST_WITHDRAW,
            },
        });
    },
};
export default strategiesBoostingActions;
