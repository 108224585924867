import axios from 'axios';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export default async function getTVLFromServer(contractAddress, url) {
    try {
        const requestUrl = url ? url : process.env.VUE_APP_DEV_PROXY_API_URL || '';
        const { data } = await axios.get(requestUrl + '/api/tvl/history', {
            params: { period: 'days', contractAddress },
        });
        return data;
    }
    catch (e) {
        devError(DEV_ERROR_CODE.AXIOS);
        return null;
    }
}
