import { createRouter, createWebHashHistory } from 'vue-router';
import { ROUTE_NAME } from '@/infra/types/page/constants';
import Strategies from '@/views/strategies.vue';
const routes = [
    {
        path: '/',
        name: 'Layer',
        components: {
            default: () => import(/* webpackChunkName: "default" */ '@/layouts/default.vue'),
        },
        children: [
            {
                path: '',
                name: ROUTE_NAME.HOME,
                component: Strategies,
            },
            {
                path: '/nft',
                name: ROUTE_NAME.NFT,
                component: () => import('@/views/nft.vue'),
            },
            {
                path: 'strategy',
                name: 'strategy',
                redirect: '/',
            },
            {
                path: 'strategy/:title',
                name: ROUTE_NAME.MANAGEMENT,
                components: {
                    default: Strategies,
                    popup: () => import(/* webpackChunkName: "strategy" */ '@/views/strategy/strategy.vue'),
                },
                props: true,
            },
            {
                path: 'strategy/:title/deposit',
                name: ROUTE_NAME.DEPOSIT,
                components: {
                    default: Strategies,
                    popup: () => import(/* webpackChunkName: "strategy" */ '@/views/strategy/strategy-deposit.vue'),
                },
                props: true,
            },
            {
                path: 'strategy/:title/withdraw',
                name: ROUTE_NAME.WITHDRAW,
                components: {
                    default: Strategies,
                    popup: () => import(/* webpackChunkName: "strategy" */ '@/views/strategy/strategy-withdraw.vue'),
                },
                props: true,
            },
            {
                path: 'strategy/:title/info',
                name: ROUTE_NAME.INFO,
                components: {
                    default: Strategies,
                    popup: () => import(/* webpackChunkName: "strategy" */ '@/views/strategy/strategy-info.vue'),
                },
                props: true,
            },
        ],
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/404.vue') },
];
const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});
export default router;
