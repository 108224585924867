import { EXTENSION_TYPE } from '@/infra/types/wallets/constants';
import { WALLET_SESSION_TYPE } from '@/web3-server/wallet/types/connect/constants';
import { useWalletStore } from '@/web3-server/wallet';
export function getCurrentExtension(connectionType) {
    const walletStore = useWalletStore();
    const walletSession = walletStore.walletSession.value;
    let extensionType = null;
    if (walletStore.isWalletConnect()) {
        extensionType = EXTENSION_TYPE.WALLET_CONNECT;
    }
    else if (connectionType) {
        const win = window;
        const cryptoPluginProvider = walletStore.getDefaultBrowserExtension();
        const currentEthProviderIsExodus = !!(cryptoPluginProvider &&
            (cryptoPluginProvider?.isExodus || 'isExodus' in cryptoPluginProvider));
        if (walletStore.isBloctoConnected()) {
            extensionType = EXTENSION_TYPE.BLOCTO;
        }
        else if (win.exodus &&
            win.exodus.ethereum &&
            win.exodus.ethereum.isExodus &&
            (!walletSession ||
                walletSession.type === WALLET_SESSION_TYPE.EXODUS ||
                currentEthProviderIsExodus)) {
            extensionType = EXTENSION_TYPE.EXODUS;
        }
        else if (typeof win.ethereum !== 'undefined') {
            if ('overrideIsMetaMask' in win.ethereum && win.ethereum.selectedProvider) {
                if ('isCoinbaseWallet' in win.ethereum.selectedProvider &&
                    win.ethereum.selectedProvider.isCoinbaseWallet) {
                    extensionType = EXTENSION_TYPE.COINBASE;
                }
                else if ('isMetaMask' in win.ethereum.selectedProvider &&
                    win.ethereum.selectedProvider.isMetaMask) {
                    extensionType = EXTENSION_TYPE.METAMASK;
                }
            }
            else if (win.ethereum.isMetaMask) {
                extensionType = EXTENSION_TYPE.METAMASK;
            }
            else if ('isCoinbaseWallet' in win.ethereum) {
                extensionType = EXTENSION_TYPE.COINBASE;
            }
        }
    }
    return extensionType;
}
