import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import { numWei } from '@/composables/useFormatCurrency';
import Venus from '@/infra/abis/Venus.json';
import VenusOracle from '@/infra/abis/VenusOracle.json';
import Vbep from '@/infra/abis/Vbep.json';
import { Multicall } from 'ethereum-multicall';
import converter from '@/web3-server/utils/converter';
import checker from '@/web3-server/utils/checker';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import multiCallUtils from '@/web3-server/web3/handler/multicall-utils';
export default async function getTVLForAllStrategies(requestByNetworks, multiCall, BLIDPrice, listOfLogicAddress) {
    const listOfTVLByStrategyId = [];
    const listOfLockedUSD = [];
    try {
        const multiCallResponse = await multiCall.call({
            singleCall: [
                {
                    reference: 'getTotalDepositCall',
                    methodName: 'getTotalDeposit',
                    methodParameters: [],
                },
                {
                    reference: 'totalSupplyBLIDCall',
                    methodName: 'totalSupplyBLID',
                    methodParameters: [],
                },
            ],
        });
        if (multiCallResponse && multiCallResponse.length) {
            multiCallResponse.forEach((callsResults) => {
                const result = {
                    deposited: 0,
                    borrowed: 0,
                    total: 0,
                };
                if (callsResults.value && callsResults.value.length) {
                    const currentLockedUSD = callsResults.value.find((item) => item.reference === 'getTotalDepositCall');
                    const totalSupplyBLID = callsResults.value.find((item) => item.reference === 'totalSupplyBLIDCall');
                    let currentLockedUSDByNumber = 0;
                    if (currentLockedUSD &&
                        currentLockedUSD.returnValues &&
                        currentLockedUSD.returnValues.length) {
                        currentLockedUSDByNumber = numWei(multiCallUtils.getVal('hex', currentLockedUSD, 0));
                        result.total = currentLockedUSDByNumber;
                    }
                    if (totalSupplyBLID &&
                        totalSupplyBLID.returnValues &&
                        totalSupplyBLID.returnValues.length) {
                        const totalSupplyBLIDByNumber = numWei(multiCallUtils.getVal('hex', totalSupplyBLID, 0));
                        result.total += BLIDPrice
                            ? totalSupplyBLIDByNumber * BLIDPrice
                            : totalSupplyBLIDByNumber;
                    }
                    if (currentLockedUSDByNumber) {
                        listOfLockedUSD.push({
                            strategyId: callsResults.id,
                            networkId: callsResults.networkId,
                            value: currentLockedUSDByNumber,
                        });
                    }
                    listOfTVLByStrategyId.push({
                        strategyId: callsResults.id,
                        networkId: callsResults.networkId,
                        value: result,
                    });
                }
            });
        }
        const web3BSC = requestByNetworks.find((item) => item.networkId === NETWORK_ID.BNB)?.value;
        if (web3BSC) {
            const listOfLoanAmounts = await getDetailedTVL(web3BSC, listOfLogicAddress);
            listOfLoanAmounts.forEach((loanAmount) => {
                if (loanAmount.value) {
                    const currentTVL = listOfTVLByStrategyId.find((item) => item.strategyId === loanAmount.strategyId);
                    const currentLockedUSD = listOfLockedUSD.find((item) => item.strategyId === loanAmount.strategyId);
                    if (currentTVL && currentLockedUSD) {
                        currentTVL.value.borrowed = loanAmount.value;
                        currentTVL.value.deposited = currentLockedUSD.value;
                        currentTVL.value.total += loanAmount.value;
                    }
                }
            });
        }
    }
    catch (e) {
        devError(DEV_ERROR_CODE.GET_TVL, e);
    }
    return listOfTVLByStrategyId;
}
async function getDetailedTVL(web3, listOfPossibleLogicAddress) {
    const contractConfig = {
        venus: {
            abi: Venus.abi,
            address: '0xfD36E2c2a6789Db23113685031d7F16329158384',
        },
        venusOracle: {
            abi: VenusOracle.abi,
            address: '0xd8B6dA2bfEC71D684D3E2a2FC9492dDad5C3787F',
        },
    };
    const multiCall = new Multicall({
        web3Instance: web3,
        tryAggregate: true,
    });
    const listOfLogicAddress = listOfPossibleLogicAddress.filter((item) => item?.value?.logicAddress);
    const getMethods = {
        getTokenBalance: async (listOfTokens) => {
            const referenceVenusOracle = 'venusOracleContract';
            const contractsReferenceByToken = [];
            const contractsReferenceByVenus = [];
            const contractsCallContext = [
                {
                    reference: referenceVenusOracle,
                    contractAddress: contractConfig.venusOracle.address,
                    abi: contractConfig.venusOracle.abi,
                    calls: [],
                },
            ];
            const venusOracleContract = contractsCallContext[0];
            listOfTokens.forEach((token) => {
                const logicAddress = listOfLogicAddress.find((item) => item.strategyId === token.strategyId)
                    ?.value?.logicAddress;
                contractsCallContext.push(...token.value.map((tokenAddress) => {
                    return {
                        reference: `tokenContract_${token.strategyId}_${tokenAddress}`,
                        contractAddress: tokenAddress,
                        abi: Vbep.abi,
                        calls: [
                            {
                                reference: 'getAccountSnapshotCall',
                                methodName: 'getAccountSnapshot',
                                methodParameters: [logicAddress],
                            },
                        ],
                    };
                }));
                venusOracleContract.calls.push(...token.value.map((tokenAddress) => {
                    return {
                        reference: `getUnderlyingPrice_${token.strategyId}_${tokenAddress}`,
                        methodName: 'getUnderlyingPrice',
                        methodParameters: [tokenAddress],
                    };
                }));
                contractsReferenceByToken.push(...token.value.map((tokenAddress) => {
                    return {
                        strategyId: token.strategyId,
                        networkId: token.networkId,
                        value: {
                            reference: `tokenContract_${token.strategyId}_${tokenAddress}`,
                            tokenAddress,
                        },
                    };
                }));
                contractsReferenceByVenus.push(...token.value.map((tokenAddress) => {
                    return {
                        strategyId: token.strategyId,
                        networkId: token.networkId,
                        value: {
                            reference: `getUnderlyingPrice_${token.strategyId}_${tokenAddress}`,
                            tokenAddress,
                        },
                    };
                }));
            });
            const tokenResults = await multiCall.call(contractsCallContext);
            const borrowed = contractsReferenceByToken.map((contractReference) => {
                const reference = contractReference.value.reference;
                const currentResult = tokenResults?.results &&
                    tokenResults.results[reference] &&
                    tokenResults.results[reference].callsReturnContext?.length
                    ? tokenResults.results[reference].callsReturnContext[0].returnValues
                    : [];
                return {
                    strategyId: contractReference.strategyId,
                    networkId: contractReference.networkId,
                    tokenAddress: contractReference.value.tokenAddress,
                    value: web3.utils.hexToNumberString(currentResult?.length && currentResult[2] && currentResult[2].hex
                        ? currentResult[2].hex
                        : 0),
                };
            });
            const underlyingPrice = contractsReferenceByVenus.map((contractReference) => {
                const reference = contractReference.value.reference;
                const overallResult = tokenResults?.results &&
                    tokenResults.results[referenceVenusOracle] &&
                    tokenResults.results[referenceVenusOracle].callsReturnContext?.length
                    ? tokenResults.results[referenceVenusOracle].callsReturnContext
                    : [];
                const currentResult = overallResult.find((item) => item.reference === reference)?.returnValues || [];
                return {
                    strategyId: contractReference.strategyId,
                    networkId: contractReference.networkId,
                    tokenAddress: contractReference.value.tokenAddress,
                    value: web3.utils.hexToNumberString(currentResult?.length && currentResult[0] && currentResult[0].hex
                        ? currentResult[0].hex
                        : 0),
                };
            });
            const is = (arr, current) => {
                return !!arr.find((item) => item.tokenAddress === current.value.tokenAddress &&
                    item.strategyId === current.strategyId);
            };
            return contractsReferenceByToken
                .filter((item) => is(borrowed, item) && is(underlyingPrice, item))
                .reduce((prev, token) => {
                const currentBorrowed = converter.withoutType.fromWei(borrowed.find((item) => item.tokenAddress === token.value.tokenAddress &&
                    item.strategyId === token.strategyId)?.value || '0');
                const currentUnderlyingPrice = converter.withoutType.fromWei(underlyingPrice.find((item) => item.tokenAddress === token.value.tokenAddress &&
                    item.strategyId === token.strategyId)?.value || '0');
                const currentTotalAmount = prev.find((item) => item.strategyId === token.strategyId);
                const totalAmountOfTokens = checker.decimal.isNotEmpty(currentBorrowed) &&
                    checker.decimal.isNotEmpty(currentUnderlyingPrice)
                    ? currentBorrowed.mul(currentUnderlyingPrice).toNumber()
                    : 0;
                if (currentTotalAmount) {
                    currentTotalAmount.value += totalAmountOfTokens;
                }
                else {
                    prev.push({
                        strategyId: token.strategyId,
                        networkId: token.networkId,
                        value: totalAmountOfTokens,
                    });
                }
                return prev;
            }, []);
        },
        getListOfTokens: async (contractConfig) => {
            const contractsCallContext = [
                {
                    reference: 'venus',
                    contractAddress: contractConfig.address,
                    abi: contractConfig.abi,
                    calls: listOfLogicAddress.map((item) => {
                        return {
                            reference: `getAssetsIn_${item.strategyId}`,
                            methodName: 'getAssetsIn',
                            methodParameters: [item.value.logicAddress],
                        };
                    }),
                },
            ];
            const venusResults = await multiCall.call(contractsCallContext);
            const callResults = venusResults?.results?.venus?.callsReturnContext;
            return callResults?.length
                ? listOfLogicAddress.map((item) => {
                    const currentResult = callResults.find((result) => result.reference === `getAssetsIn_${item.strategyId}`)?.returnValues;
                    return {
                        strategyId: item.strategyId,
                        networkId: item.networkId,
                        value: currentResult || [],
                    };
                })
                : [];
        },
    };
    const listOfTokens = await getMethods.getListOfTokens(contractConfig.venus);
    return await getMethods.getTokenBalance(listOfTokens);
}
