import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
import { graphStorageTVLAdapter, graphTVLAdapter, } from '@/web3-server/web3/actions/strategies/graph/graph.adapter';
import getTVLFromServer from '@/web3-server/web3/actions/strategies/graph/tvl/get-tvl-from-server';
export default async function getGraphDataByTVL(contractType, contractAddress, url) {
    if (contractType === CONTRACT_TYPE.STORAGE) {
        return getTVLFromServer(contractAddress, url).then((response) => {
            let graphData = [];
            if (response) {
                if (contractAddress) {
                    graphData = graphStorageTVLAdapter(response, contractAddress);
                }
                else {
                    graphData = graphTVLAdapter(response, contractType);
                }
            }
            return graphData;
        });
    }
    else if (contractType === CONTRACT_TYPE.STAKING) {
        return getTVLFromServer(undefined, url).then((response) => getFilteredTVL(response, contractType, 'stakingTvl'));
    }
    else if (contractType === CONTRACT_TYPE.FARMING) {
        return getTVLFromServer(undefined, url).then((response) => getFilteredTVL(response, contractType, 'farmingTvl'));
    }
    else {
        return [];
    }
}
function getFilteredTVL(response, contractType, key) {
    let graphData = [];
    if (response) {
        const list = response.filter((item) => item[key] && item[key] !== '0');
        if (list.length) {
            graphData = graphTVLAdapter(list, contractType);
        }
    }
    return graphData;
}
