import { GA_EVENT_NAME, GA_POSTFIX } from '@/infra/types/ga-manager/constants';
import { GAEvent } from '@/utils/ga-manager/composables/ga-event';
const GAHooks = {
    manager(params) {
        let hook = null;
        if (params &&
            (('eventName' in params && params.eventName) ||
                ('anyEventName' in params && params.anyEventName))) {
            const eventName = 'eventName' in params && params.eventName ? params.eventName : params.anyEventName;
            if ([
                GA_EVENT_NAME.APPROVE_DEPOSIT,
                GA_EVENT_NAME.APPROVE_NFT,
                GA_EVENT_NAME.APPROVE_BOOST,
                GA_EVENT_NAME.APPROVE_CROSS_CHAIN,
            ].includes(eventName) &&
                'extra' in params &&
                params.extra &&
                params.extra.currencyType) {
                hook = GAHooks.approve({ eventName, prefix: params.GAPrefix }, { currencyType: params.extra.currencyType });
            }
            else if (eventName === GA_EVENT_NAME.CLAIM) {
                hook = GAHooks.claim(params.GAPrefix);
            }
            else if ('extra' in params &&
                params.extra &&
                params.extra.currencyType &&
                'amount' in params.extra &&
                params.extra.amount) {
                hook = GAHooks.default({ eventName, prefix: params.GAPrefix }, { currencyType: params.extra.currencyType, amount: params.extra.amount });
            }
        }
        return hook;
    },
    approve(params, extra) {
        return getDefaultGAHook(params, extra);
    },
    claim(GAPrefix) {
        return getDefaultGAHook({ prefix: GAPrefix, eventName: GA_EVENT_NAME.CLAIM });
    },
    default(params, extra) {
        return getDefaultGAHook(params, extra);
    },
};
export default GAHooks;
function getDefaultGAHook(params, extra) {
    const GAExtra = getGAExtra(extra);
    return {
        before: () => {
            GAEvent(getGAEventName(params, GA_POSTFIX.START), GAExtra);
        },
        signature: (success) => {
            GAEvent(getGAEventName(params, success ? GA_POSTFIX.SIGNED : GA_POSTFIX.ERROR_SIGNED), GAExtra);
        },
        after: (success) => {
            GAEvent(getGAEventName(params, success ? GA_POSTFIX.SUCCESS : GA_POSTFIX.ERROR), GAExtra);
        },
    };
}
function getGAEventName(params, postfix) {
    const eventName = [];
    if (params) {
        if (params.prefix) {
            eventName.push(params.prefix);
        }
        if (params.eventName) {
            eventName.push(params.eventName);
        }
    }
    if (postfix) {
        eventName.push(postfix);
    }
    return eventName.join('_');
}
function getGAExtra(extra) {
    let params = undefined;
    if (extra && (extra.currencyType || extra?.amount)) {
        params = { valueWithParams: {} };
        if (extra.currencyType) {
            params.valueWithParams = { ...params.valueWithParams, token: extra.currencyType };
        }
        if (extra.amount) {
            params.valueWithParams = { ...params.valueWithParams, amount: extra.amount };
        }
    }
    return params;
}
