import web3 from 'web3';
import Decimal from 'decimal.js';
import { getShortDecimal } from '@/web3-server/web3/handler/calc';
import checker from '@/web3-server/utils/checker';
export function useFormatCurrency(num, digits, returnCharacter) {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find((item) => num >= item.value);
    if (num > 1e18 && num.toString().includes('e+')) {
        return Number(num.toString().split('e+')[0]).toFixed();
    }
    else if (item) {
        let fixed = (num / item.value).toFixed(digits);
        if (num > 1000 && fixed === '1000') {
            fixed = '999';
        }
        const value = fixed.replace(rx, '$1');
        const getSeparately = () => {
            const arr = value.split('.');
            return { firstNum: arr[0], secondNum: arr.length > 1 ? arr[1] : '' };
        };
        return returnCharacter
            ? { value, symbol: item.symbol, separately: getSeparately() }
            : value + item.symbol;
    }
    else {
        return '0';
    }
}
function useFormatCurrencyWithoutZeros(amount, isWithoutRounding) {
    if (amount.includes('e-')) {
        const numAmount = Number(amount);
        if (!isNaN(numAmount)) {
            return numAmount.toFixed(Number(amount.split('e-')[1]));
        }
    }
    let formatAmount = amount;
    const indexOfFloatPartStart = formatAmount.indexOf('.');
    if (indexOfFloatPartStart > 0) {
        const quantitySymbolsOfFloatPath = 2;
        const getFloatPartOfAmount = (quantitySymbol, amountIsZero) => {
            let floatAmount = amount.substring(indexOfFloatPartStart, indexOfFloatPartStart + quantitySymbol);
            if (!amountIsZero && Number(floatAmount) <= 0) {
                return getFloatPartOfAmount(++quantitySymbol, amountIsZero);
            }
            else if (isWithoutRounding) {
                return floatAmount;
            }
            else {
                const nextDigit = amount[indexOfFloatPartStart + quantitySymbol];
                if (nextDigit && Number(nextDigit) >= 5) {
                    floatAmount = floatAmount.replace(/\d$/, String(Number(floatAmount[floatAmount.length - 1]) + 1));
                }
                return floatAmount;
            }
        };
        formatAmount = amount.substring(0, indexOfFloatPartStart);
        return formatAmount + getFloatPartOfAmount(quantitySymbolsOfFloatPath, Number(amount) <= 0);
    }
    else {
        return formatAmount;
    }
}
export function useFormatCurrencyLite(val, options, isWithoutRounding) {
    const numVal = typeof val === 'string' ? Number(val.replace(/\s/g, '')) : val;
    const strVal = String(typeof val === 'string' ? val.replace(/\s/g, '') : val);
    if (numVal < 1 && (strVal.includes('0.000') || strVal.includes('e-'))) {
        return useFormatCurrencyWithoutZeros(typeof val === 'string' ? val : String(val), isWithoutRounding);
    }
    else {
        if (isWithoutRounding) {
            const trauncateFractionAndFormat = (parts, digits) => {
                return parts
                    .map(({ type, value }) => {
                    if (type !== 'fraction' || !value || value.length < digits) {
                        return value;
                    }
                    let retVal = '';
                    for (let idx = 0, counter = 0; idx < value.length && counter < digits; idx++) {
                        if (value[idx] !== '0') {
                            counter++;
                        }
                        retVal += value[idx];
                    }
                    return retVal;
                })
                    .reduce((string, part) => string + part);
            };
            const formatter = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 20,
                ...options,
            });
            return trauncateFractionAndFormat(formatter.formatToParts(numVal), 3).replace(/,/g, ' ');
        }
        else {
            return new Intl.NumberFormat('en', options).format(numVal).replace(/,/g, ' ');
        }
    }
}
export function simpleCurrencyFormat(props) {
    const { val, digits, returnCharacter, maxZeroLen } = props;
    const numVal = typeof val === 'string' ? Number(val) : val;
    if (numVal < 1) {
        const num = useFormatCurrencyWithoutZeros(typeof val === 'string' ? val : String(val));
        return maxZeroLen && num.length > maxZeroLen ? '0' : num;
    }
    else {
        return useFormatCurrency(numVal, digits, returnCharacter);
    }
}
export function decimalFormatting(val, decimalPlaces = 0, extra = {
    transformLargeNums: false,
    isRounding: 'down',
    showWithManyZeros: false,
    hideFractionsFromIntegers: false,
}) {
    try {
        const decimal = Decimal.isDecimal(val) ? val : new Decimal(clearNumLine(val));
        const { transformLargeNums, isRounding, showWithManyZeros, minNumToShow, hideFractionsFromIntegers, } = extra || {};
        const transformParams = {
            minHighDigit: transformLargeNums &&
                typeof transformLargeNums !== 'boolean' &&
                'minHighDigit' in transformLargeNums
                ? transformLargeNums.minHighDigit
                : 1000,
        };
        if (decimal.isNaN() ||
            !decimal.isFinite() ||
            (minNumToShow !== undefined && minNumToShow !== null && decimal.lessThan(minNumToShow))) {
            return '0';
        }
        else if (transformLargeNums && decimal.greaterThan(transformParams.minHighDigit)) {
            return useFormatCurrency(decimal.toNumber(), decimalPlaces, false);
        }
        else {
            const isRound = isRounding && isRounding === 'default' ? undefined : isRounding || 'down';
            const shortDecimal = getShortDecimal(decimal, decimalPlaces, isRound);
            const getMinFractionDigits = () => {
                if (hideFractionsFromIntegers) {
                    if (shortDecimal.isInteger()) {
                        return 0;
                    }
                    else {
                        try {
                            const arr = shortDecimal.toFixed().split('.');
                            const fraction = arr && arr.length > 1 ? arr[1].length : 0;
                            if (fraction >= decimalPlaces) {
                                return decimalPlaces;
                            }
                            else {
                                return fraction;
                            }
                        }
                        catch (e) {
                            return decimalPlaces;
                        }
                    }
                }
                else {
                    return decimalPlaces;
                }
            };
            const formattedValue = Intl.NumberFormat('en', {
                minimumFractionDigits: getMinFractionDigits(),
            })
                .format(shortDecimal.toNumber())
                .replace(/,/g, ' ');
            if (showWithManyZeros &&
                shortDecimal.lessThan(1) &&
                checker.numericStr.isEmpty(formattedValue)) {
                return shortDecimal.toFixed();
            }
            else {
                return formattedValue;
            }
        }
    }
    catch (e) {
        return '0';
    }
}
export function cutOffLongZeros(value, maxLen = 18) {
    const decimal = new Decimal(clearNumLine(value));
    const balance = value || '';
    if (checker.decimal.isNotEmpty(decimal) &&
        decimal.lessThan(1) &&
        balance &&
        balance.length > maxLen) {
        return balance.substring(0, 4) + '...' + balance.substring(balance.length - 4);
    }
    else {
        return balance;
    }
}
function clearNumLine(val) {
    if (val) {
        if (typeof val === 'string') {
            const formatted = val.replace(/\s/gi, '');
            return !formatted ? '0' : formatted;
        }
        else {
            return val;
        }
    }
    else {
        return '0';
    }
}
export function numWei(val) {
    return Number(web3.utils.fromWei(val));
}
