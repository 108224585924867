import Web3 from 'web3';
import { useWeb3Store } from '@/web3-server';
import { useWalletStore } from '@/web3-server/wallet';
import Decimal from 'decimal.js';
import { STRATEGY_VAULT } from '@/web3-server/web3-strategies/types/config/constants';
import { useWeb3StrategiesStore } from '@/web3-server/web3-strategies';
import { useWeb3Currencies } from '@/web3-server/web3-currencies';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import linker from '@/web3-server/utils/linker';
import checker from '@/web3-server/utils/checker';
export function checkVars(vars) {
    return vars.reduce((prev, item) => {
        if (prev) {
            if (!item) {
                prev = false;
            }
            else if (typeof item === 'object') {
                prev = checkVars(Object.values(item));
            }
            else if (Array.isArray(item)) {
                if (!item.length) {
                    prev = false;
                }
                else {
                    prev = item.reduce((last, current) => {
                        if (last) {
                            last = checkVars(current);
                        }
                        return last;
                    }, true);
                }
            }
        }
        return prev;
    }, true);
}
export function requestAPI(type, extra) {
    const request = getDefaultRequest(type, extra && extra.web3);
    if (extra && request) {
        if (extra.events) {
            request.events = extra.events;
        }
    }
    return {
        is: !!(request && request.web3),
        isUser: !!(request && request.web3 && 'userWalletAddress' in request),
        isUserWithPrice: !!(request &&
            request.web3 &&
            'userWalletAddress' in request &&
            'BLIDPriceWei' in request),
        isWithPrice: !!(request && request.web3 && 'BLIDPriceWei' in request),
        get: request,
        getWithUser: request,
        getUserWithPrice: request,
        getWithPrice: request,
    };
}
export function getStrategyByIdFromWeb3(strategyId) {
    const web3Strategies = useWeb3StrategiesStore();
    return strategyId ? web3Strategies.getById(strategyId) : undefined;
}
export function getCurrencyByIdFromWeb3(currencyId) {
    const web3Currencies = useWeb3Currencies();
    return currencyId ? web3Currencies.getById(currencyId) : undefined;
}
export function getStrategyAndCurrencyFromWeb3(params) {
    return {
        strategy: getStrategyByIdFromWeb3(params && params.strategyId),
        currency: getCurrencyByIdFromWeb3(params && params.currencyId),
    };
}
export const readyChecks = {
    strategy: {
        isContract(strategy) {
            return !!(strategy &&
                strategy.contract &&
                strategy.contract.abi &&
                strategy.contract.type &&
                strategy.contract.address);
        },
    },
    currency: {
        isAddress: isCurrencyAddress,
        isAddressAndDecimals(currency) {
            return !!(currency && currency.decimals && isCurrencyAddress);
        },
    },
};
function isCurrencyAddress(currency) {
    return !!(currency && currency.address);
}
export function getAvailableWeb3ByNetworkId() {
    const web3Store = useWeb3Store();
    // const web3Strategies = useWeb3StrategiesStore();
    const listOfNetworkId = Object.values(NETWORK_ID);
    return listOfNetworkId.reduce((prev, networkId) => {
        const vault = linker.getStrategyVault(networkId);
        if (vault === STRATEGY_VAULT.BNB && networkId !== NETWORK_ID.BNB) {
            return prev;
        }
        else {
            // const listOfStrategies = web3Strategies.list.filter((strategy) => strategy.vault === vault);
            //
            // if (listOfStrategies.length) {
            //   prev.push({
            //     networkId,
            //     value: web3Store.web3ByNetwork[networkId] as Web3,
            //   });
            // }
            prev.push({
                networkId,
                value: web3Store.web3ByNetwork[networkId],
            });
        }
        return prev;
    }, []);
}
export function getDefaultRequest(type, extra) {
    const web3Store = useWeb3Store();
    const walletStore = useWalletStore();
    const getWeb3 = () => {
        if (extra && 'isBSC' in extra && extra.isBSC) {
            return web3Store.web3ByNetwork.BNB;
        }
        else if (extra && 'rpcUrl' in extra && extra.rpcUrl) {
            return new Web3(extra.rpcUrl);
        }
        else if (extra && 'vault' in extra && extra.vault && web3Store.web3ByVault[extra.vault]) {
            return web3Store.web3ByVault[extra.vault];
        }
        else if (extra &&
            'networkId' in extra &&
            extra.networkId &&
            web3Store.web3ByNetwork[extra.networkId]) {
            return web3Store.web3ByNetwork[extra.networkId];
        }
        else {
            return web3Store.web3;
        }
    };
    const getNetworkId = () => {
        if (extra && 'vault' in extra && extra.vault) {
            return linker.getNetworkId(extra.vault);
        }
        else if (extra && 'networkId' in extra && extra.networkId) {
            return extra.networkId;
        }
        else {
            return null;
        }
    };
    const getBLIDPrice = () => {
        const networkId = getNetworkId();
        const wei = networkId && web3Store.BLIDPriceByNetwork[networkId]
            ? web3Store.BLIDPriceByNetwork[networkId].wei
            : new Decimal(0);
        if (checker.decimal.isNotEmpty(wei)) {
            return wei;
        }
        else {
            return web3Store.BLIDPrice.wei;
        }
    };
    const web3 = getWeb3();
    if (web3) {
        const defaultRequest = { web3: web3 };
        if (type === 'user' && walletStore.userAddress) {
            defaultRequest.userWalletAddress = walletStore.userAddress;
            return defaultRequest;
        }
        else if (type === 'userWithPrice' &&
            walletStore.userAddress &&
            web3Store.BLIDPrice &&
            web3Store.BLIDPrice.original &&
            web3Store.BLIDPrice.wei) {
            defaultRequest.userWalletAddress = walletStore.userAddress;
            defaultRequest.BLIDPriceWei = getBLIDPrice();
            return defaultRequest;
        }
        else if (type === 'defaultWithPrice' &&
            web3Store.BLIDPrice &&
            web3Store.BLIDPrice.original &&
            web3Store.BLIDPrice.wei) {
            defaultRequest.BLIDPriceWei = getBLIDPrice();
            return defaultRequest;
        }
        else if (type !== 'default') {
            return null;
        }
        else {
            return defaultRequest;
        }
    }
    else {
        return null;
    }
}
export function simpleDecimalCheck(val) {
    return !!val && Decimal.isDecimal(val) && val.isFinite() && val.isPositive() && !val.isZero();
}
export function getServerAddressByVault() {
    return process.env.VUE_APP_DEV_PROXY_API_URL || '';
}
