import { getStrategyAndCurrencyFromWeb3, getStrategyByIdFromWeb3, readyChecks, requestAPI, } from '@/web3-server/web3/handler/inspectors';
import approveCurrency from '@/web3-server/web3/actions/deposit/approve-currency';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import openDeposit from '@/web3-server/web3/actions/deposit/open-deposit';
import withdraw from '@/web3-server/web3/actions/deposit/withdraw';
import claim from '@/web3-server/web3/actions/deposit/claim';
import approveCurrencyEip1559 from '@/web3-server/web3/actions/deposit/approve-currency-eip1559';
import converter from '@/web3-server/utils/converter';
const depositActions = {
    approveCurrencyEIP1559(currencyAddress, contractAddress, approvalAmount, events) {
        const request = requestAPI('user', { events });
        if (request.isUser && currencyAddress && contractAddress) {
            const amount = approvalAmount
                ? converter.withType.toWei(approvalAmount.simpleValue, {
                    decimals: approvalAmount.decimals,
                })
                : null;
            approveCurrencyEip1559(request.getWithUser, currencyAddress, contractAddress, amount);
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
    approveCurrency(currencyAddress, contractAddress, events) {
        const request = requestAPI('user', { events });
        if (request.isUser && currencyAddress && contractAddress) {
            approveCurrency(request.getWithUser, currencyAddress, contractAddress);
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
    openDeposit(strategyId, currencyId, amount, events) {
        const request = requestAPI('user', { events });
        const { strategy, currency } = getStrategyAndCurrencyFromWeb3({ strategyId, currencyId });
        if (request.isUser &&
            strategy &&
            currency &&
            readyChecks.strategy.isContract(strategy) &&
            readyChecks.currency.isAddressAndDecimals(currency) &&
            amount) {
            openDeposit(request.getWithUser, strategy.contract, {
                address: currency.address,
                decimals: currency.decimals,
                burnAddress: currency.burnAddress,
            }, amount, strategy.supportEIP1559);
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
    withdraw(strategyId, currencyId, amount, events) {
        const request = requestAPI('user', { events });
        const { strategy, currency } = getStrategyAndCurrencyFromWeb3({ strategyId, currencyId });
        if (request.isUser &&
            strategy &&
            currency &&
            amount &&
            readyChecks.strategy.isContract(strategy) &&
            readyChecks.currency.isAddressAndDecimals(currency)) {
            withdraw(request.getWithUser, strategy.contract, {
                address: currency.address,
                decimals: currency.decimals,
                burnAddress: currency.burnAddress,
            }, amount, strategy.supportEIP1559);
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
    claim(strategyId, events) {
        const request = requestAPI('user', { events });
        const strategy = getStrategyByIdFromWeb3(strategyId);
        if (request.isUser && strategy && readyChecks.strategy.isContract(strategy)) {
            claim(request.getWithUser, strategy.contract, strategy.supportEIP1559);
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
};
export default depositActions;
