import axios from 'axios';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export default async function getNftImg(pathToJSONFile) {
    try {
        const requestUrl = getUrl(pathToJSONFile);
        if (requestUrl) {
            const { data } = await axios.get(requestUrl);
            if (data && data.image) {
                data.image = getUrl(data.image);
                // if (imgUrl) {
                //   try {
                //     const response = await axios.get(imgUrl, { responseType: 'arraybuffer' });
                //
                //     if (response && response.data) {
                //       data.image = Buffer.from(response.data, 'binary').toString('base64');
                //     }
                //   } catch (e) {
                //     devError(DEV_ERROR_CODE.AXIOS, e);
                //
                //     data.image = '';
                //   }
                // }
            }
            return data;
        }
        else {
            devError(DEV_ERROR_CODE.AXIOS, requestUrl);
            return null;
        }
    }
    catch (e) {
        devError(DEV_ERROR_CODE.AXIOS, e);
        return null;
    }
}
function getUrl(path) {
    return path.replace('ipfs://', 'https://ipfs.io/ipfs/');
}
