import { useFormatCurrencyLite } from '@/composables/useFormatCurrency';
export function updatePercentageYield(percentageYield, strategy) {
    const strategyDeposit = strategy.horizon.deposit;
    if (percentageYield) {
        const formattedPercentage = useFormatCurrencyLite(percentageYield, {
            maximumFractionDigits: 2,
        });
        if (!formattedPercentage.includes('0.00')) {
            strategyDeposit.percentageYield = formattedPercentage;
        }
        else if (strategyDeposit.percentageYield && strategyDeposit.percentageYield !== '0') {
            strategyDeposit.percentageYield = '0';
        }
    }
    else if (strategyDeposit.percentageYield && strategyDeposit.percentageYield !== '0') {
        strategyDeposit.percentageYield = '0';
    }
}
