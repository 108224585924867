import { SINGLE_CONNECTION_TYPE, WALLET_SESSION_TYPE, } from '@/web3-server/wallet/types/connect/constants';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import infra from '@/infra';
export function connectToAccount(ws, params) {
    const { connectionType, connectOnlyTo } = params;
    const cryptoPlugin = ws.getDefaultBrowserExtension();
    let isExodus = !!(ws.getExodusProvider() && connectOnlyTo === SINGLE_CONNECTION_TYPE.EXODUS);
    const isBlocto = connectOnlyTo === SINGLE_CONNECTION_TYPE.BLOCTO;
    if (!isExodus &&
        cryptoPlugin &&
        ('isExodus' in cryptoPlugin || cryptoPlugin?.isExodus)) {
        isExodus = true;
    }
    return new Promise((resolve, reject) => {
        const walletConnect = () => {
            connectionToWalletConnect(ws, connectionType, params.networkId).then((response) => {
                if (response.isSuccess) {
                    resolve(response.accounts);
                }
                else {
                    reject(null);
                }
            });
        };
        const connectionViaEthProvider = () => {
            if (isExodus) {
                return ws.connectExodus(params.networkId);
            }
            else {
                return ws.connectEth(params.networkId);
            }
        };
        if (!isExodus && connectOnlyTo === SINGLE_CONNECTION_TYPE.EXODUS) {
            walletConnect();
        }
        else if (isBlocto) {
            ws.connectBlocto(params.networkId)
                .then((response) => {
                if (response && !('code' in response)) {
                    setWalletSession(ws, connectionType, 'blocto');
                    infra.wallet.connect().finally(() => {
                        resolve(response);
                    });
                }
            })
                .catch((e) => {
                if (e.message === 'User declined the login request') {
                    reject(null);
                }
                else {
                    walletConnect();
                }
            });
        }
        else if ((isExodus || !!cryptoPlugin) &&
            connectOnlyTo !== SINGLE_CONNECTION_TYPE.WALLET_CONNECT) {
            const timer = setTimeout(() => {
                walletConnect();
            }, 20000);
            connectionViaEthProvider()
                .then((response) => {
                if (!response || 'code' in response) {
                    walletConnect();
                }
                else {
                    setWalletSession(ws, connectionType, isExodus ? 'exodus' : null);
                    infra.wallet.connect().finally(() => {
                        resolve(response);
                    });
                }
            })
                .catch(() => {
                walletConnect();
            })
                .finally(() => {
                clearTimeout(timer);
            });
        }
        else {
            walletConnect();
        }
    });
}
function connectionToWalletConnect(ws, connectionType, networkId) {
    const networkIdForWC = { networkId: networkId || NETWORK_ID.BNB };
    const result = {
        isSuccess: false,
        accounts: [],
    };
    return ws
        .connectWC(networkIdForWC)
        .then(async (response) => {
        if (response === null) {
            ws.removeWalletSession();
        }
        else {
            setWalletSession(ws, connectionType, 'walletConnect');
            await infra.wallet.connect({ WCProvider: response.provider });
            result.isSuccess = true;
            result.accounts = response.accounts;
        }
        return result;
    })
        .catch(() => {
        ws.removeWalletSession();
        return result;
    });
}
function setWalletSession(ws, connectionType, isWallet) {
    let currentSessionType = WALLET_SESSION_TYPE.ETHEREUM_EXTENSION;
    if (isWallet) {
        if (isWallet === 'exodus') {
            currentSessionType = WALLET_SESSION_TYPE.EXODUS;
        }
        else if (isWallet === 'blocto') {
            currentSessionType = WALLET_SESSION_TYPE.BLOCTO;
        }
        else if (isWallet === 'walletConnect') {
            currentSessionType = WALLET_SESSION_TYPE.WALLET_CONNECT;
        }
    }
    ws.setWalletSession({
        type: currentSessionType,
        connectionName: connectionType,
    });
}
