import getBLIDPriceFromPancakeswap from '@/web3-server/web3/actions/blid-price/get-pancakeswap';
import Decimal from 'decimal.js';
import { getShortDecimal } from '@/web3-server/web3/handler/calc';
import getBLIDFromXYFinance from '@/web3-server/web3/actions/blid-price/get-xy-finance';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import getBLIDPriceFromLiFi from '@/web3-server/web3/actions/blid-price/get-li-fi';
import converter from '@/web3-server/utils/converter';
import { CURRENCY_ID } from '@/web3-server/web3-currencies/types/config/constants';
import { useWeb3Currencies } from '@/web3-server/web3-currencies';
import getBLIDPriceFromCoinGecko from '@/web3-server/web3/actions/blid-price/get-coingecko';
import linker from '@/web3-server/utils/linker';
import getBLIDPriceFromOurServer from '@/web3-server/web3/actions/blid-price/get-our-server';
const BLIDPriceAction = {
    getBLIDPriceFromCoinGecko(proposedNetwork) {
        const { tokensFromBNB, tokensFromPolygon, tokensFromETH } = getTokens();
        const xyFinance = getDataForAllNetworks(tokensFromBNB, tokensFromPolygon, tokensFromETH, proposedNetwork);
        const defaultValue = { price: linker.decimal.getSimple('0'), xyFinance };
        const saveAndGet = (price) => {
            const priceSimple = linker.decimal.getSimple(new Decimal(price));
            const priceWei = converter.withoutType.toWei(priceSimple.value);
            this.BLIDPrice.original = priceWei.toFixed();
            this.BLIDPrice.wei = priceWei;
            this.BLIDPrice.formatted = priceSimple.value.toNumber();
            this.BLIDPriceByNetwork.BNB.wei = priceWei;
            this.BLIDPriceByNetwork.BNB.simple = priceSimple.value;
            this.BLIDPriceByNetwork.POLYGON.wei = priceWei;
            this.BLIDPriceByNetwork.POLYGON.simple = priceSimple.value;
            this.BLIDPriceByNetwork.ETH.wei = priceWei;
            this.BLIDPriceByNetwork.ETH.simple = priceSimple.value;
            return {
                price: priceSimple,
                xyFinance,
            };
        };
        return new Promise((resolve) => {
            let time = 0;
            let timerInterval = null;
            const interval = 100;
            const maxTime = 1500;
            const coinGeckoStatus = {
                loaded: false,
                success: false,
            };
            let coinGeckoResult = defaultValue;
            const clearTimer = () => {
                if (timerInterval !== null) {
                    clearInterval(timerInterval);
                }
                timerInterval = null;
            };
            const getPriceFromOurServer = () => {
                return getBLIDPriceFromOurServer()
                    .then((response) => {
                    if (response && response.data && response.data.rateBLIDtoUSDT) {
                        return saveAndGet(response.data.rateBLIDtoUSDT);
                    }
                    else {
                        return defaultValue;
                    }
                })
                    .catch(() => {
                    return defaultValue;
                });
            };
            getBLIDPriceFromCoinGecko()
                .then((response) => {
                if (response && response.data && response.data.bolide && response.data.bolide.usd) {
                    coinGeckoStatus.success = true;
                    coinGeckoResult = saveAndGet(response.data.bolide.usd);
                }
            })
                .finally(() => {
                coinGeckoStatus.loaded = true;
                if (coinGeckoStatus.success && timerInterval) {
                    clearTimer();
                    resolve(coinGeckoResult);
                }
            });
            timerInterval = setInterval(() => {
                if (coinGeckoStatus.loaded) {
                    if (coinGeckoStatus.success) {
                        clearTimer();
                        resolve(coinGeckoResult);
                    }
                    else {
                        clearTimer();
                        resolve(getPriceFromOurServer());
                    }
                }
                else if (time > maxTime) {
                    clearTimer();
                    resolve(getPriceFromOurServer());
                }
                time += interval;
            }, interval);
        });
    },
    getBLIDPrice(proposedNetwork) {
        const { tokensFromBNB, tokensFromPolygon, tokensFromETH } = getTokens();
        return {
            allChain: {
                xyFinance: (async function () {
                    const { chainId, tokenAddress } = getDataForAllNetworks(tokensFromBNB, tokensFromPolygon, tokensFromETH, proposedNetwork);
                    const price = getBLIDFromXYFinance(tokenAddress ? { from: chainId, to: chainId } : undefined, tokenAddress).then((val) => {
                        if (val && val.data && val.data.toTokenValue) {
                            return getShortDecimal(new Decimal(val.data.toTokenValue), 3, 'down').toNumber();
                        }
                        else {
                            return 0;
                        }
                    });
                    return { price, chainId, tokenAddress };
                })(),
            },
            [NETWORK_ID.BNB]: (function (web3Store) {
                const { pancakeswap, lbank } = getBLIDPrice({
                    web3: web3Store.web3ByVault.BNB,
                });
                if (pancakeswap) {
                    pancakeswap.then((price) => {
                        if (price) {
                            web3Store.BLIDPrice.original = price.original;
                            web3Store.BLIDPrice.wei = price.wei;
                            web3Store.BLIDPrice.formatted = price.formatted;
                            web3Store.BLIDPriceByNetwork.BNB.wei = price.wei;
                            web3Store.BLIDPriceByNetwork.BNB.simple = new Decimal(price.formatted);
                        }
                        return price;
                    });
                }
                return { pancakeswap, lbank };
            })(this),
            [NETWORK_ID.POLYGON]: {
                liFi: getFromLiFi({
                    chain: 'POL',
                    token: {
                        from: tokensFromPolygon.BLID
                            ? tokensFromPolygon.BLID.address
                            : '0x4b27Cd6E6a5E83d236eAD376D256Fe2F9e9f0d2E',
                        to: tokensFromPolygon.USDT
                            ? tokensFromPolygon.USDT.address
                            : '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
                    },
                }, tokensFromPolygon.BLID && tokensFromPolygon.BLID.decimals).then((price) => {
                    this.BLIDPriceByNetwork.POLYGON.wei = price.wei;
                    this.BLIDPriceByNetwork.POLYGON.simple = price.simple;
                    return price;
                }),
            },
            [NETWORK_ID.ETH]: {
                liFi: getFromLiFi({
                    chain: 'ETH',
                    token: {
                        from: tokensFromETH.BLID
                            ? tokensFromETH.BLID.address
                            : '0x8A7aDc1B690E81c758F1BD0F72DFe27Ae6eC56A5',
                        to: tokensFromETH.USDT
                            ? tokensFromETH.USDT.address
                            : '0xdac17f958d2ee523a2206206994597c13d831ec7',
                    },
                }, tokensFromETH.BLID && tokensFromETH.BLID.decimals).then((price) => {
                    this.BLIDPriceByNetwork.ETH.wei = price.wei;
                    this.BLIDPriceByNetwork.ETH.simple = price.simple;
                    return price;
                }),
            },
        };
    },
};
export default BLIDPriceAction;
function getTokens() {
    const web3Currencies = useWeb3Currencies();
    const tokensFromBNB = {
        BLID: web3Currencies.getById(CURRENCY_ID.BNB_BLID),
        USDT: web3Currencies.getById(CURRENCY_ID.BNB_USDT),
    };
    const tokensFromPolygon = {
        BLID: web3Currencies.getById(CURRENCY_ID.POLYGON_BLID),
        USDT: web3Currencies.getById(CURRENCY_ID.POLYGON_USDT),
    };
    const tokensFromETH = {
        BLID: web3Currencies.getById(CURRENCY_ID.ETH_BLID),
        USDT: web3Currencies.getById(CURRENCY_ID.ETH_USDT),
    };
    return { tokensFromBNB, tokensFromPolygon, tokensFromETH };
}
function getDataForAllNetworks(tokensFromBNB, tokensFromPolygon, tokensFromETH, proposedNetwork) {
    const chainId = proposedNetwork ? proposedNetwork.chainId.int.toString() : '56';
    let tokenAddress = tokensFromBNB.BLID &&
        tokensFromBNB.BLID.address &&
        tokensFromBNB.USDT &&
        tokensFromBNB.USDT.address
        ? { from: tokensFromBNB.BLID.address, to: tokensFromBNB.USDT.address }
        : undefined;
    if (proposedNetwork) {
        if (proposedNetwork.id === NETWORK_ID.POLYGON &&
            tokensFromPolygon &&
            tokensFromPolygon.BLID &&
            tokensFromPolygon.USDT) {
            tokenAddress = {
                from: tokensFromPolygon.BLID.address,
                to: tokensFromPolygon.USDT.address,
            };
        }
        else if (proposedNetwork.id === NETWORK_ID.ETH &&
            tokensFromETH &&
            tokensFromETH.BLID &&
            tokensFromETH.USDT) {
            tokenAddress = {
                from: tokensFromETH.BLID.address,
                to: tokensFromETH.USDT.address,
            };
        }
    }
    return { chainId, tokenAddress };
}
async function getFromLiFi(params, decimalsFromBLID) {
    return getBLIDPriceFromLiFi(params).then((response) => {
        if (response && response.data && response.data.estimate && response.data.estimate.toAmount) {
            const defaultDecimals = response.data.action.toToken.decimals;
            const simple = converter.withoutType.fromWei(response.data.estimate.toAmount, {
                decimals: defaultDecimals,
            });
            return {
                wei: converter.withoutType.toWei(simple, {
                    decimals: decimalsFromBLID || 18,
                }),
                simple,
            };
        }
        else {
            return {
                wei: new Decimal(0),
                simple: new Decimal(0),
            };
        }
    });
}
function getBLIDPrice(request) {
    // const lbank = getBLIDPriceFromLBank().then((response) => {
    //   if (response && response.data && response.data.ticker && response.data.ticker.latest) {
    //     return response.data.ticker.latest.toFixed(3);
    //   } else {
    //     return '';
    //   }
    // });
    const lbank = new Promise((resolve) => resolve(''));
    const pancakeswap = getBLIDPriceFromPancakeswap(request).then((val) => {
        if (val) {
            return {
                original: val.original,
                wei: new Decimal(val.original),
                formatted: getShortDecimal(new Decimal(val.formatted), 3, 'down').toNumber(),
            };
        }
        else {
            return {
                original: '',
                wei: new Decimal(0),
                formatted: 0,
            };
        }
    });
    return { pancakeswap, lbank };
}
