import { readActiveWallets, supportedWallets } from '@lifi/wallet-management';
import { getChainById, MetaMaskProviderErrorCode, supportedChains, } from '@lifi/sdk';
import { useWalletStore } from '@/web3-server/wallet';
import { ethers } from 'ethers';
import { markRaw } from 'vue';
import { LI_FI_WALLET_NAMES } from '@/web3-server/wallet/types/li-fi/constants';
import linker from '@/web3-server/utils/linker';
import { NETWORK_SWITCH_STATUSED } from '@/web3-server/wallet/types/provider/constants';
import infra from '@/infra';
import { devError } from '@/utils/devError';
import events from 'events';
import { addChain, switchChainAndAddToken } from '@lifi/wallet-management/walletAutomation';
import { walletIcons } from '@lifi/wallet-management/walletIcons';
import EthereumProvider from '@walletconnect/ethereum-provider';
import { walletNetworkByWalletId } from '@/web3-server/wallet/data/network';
import { WALLET_NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import { isWalletDeactivated } from '@lifi/wallet-management/walletPersistance';
import { useWeb3Store } from '@/web3-server';
import { InjectedConnector } from '@lifi/wallet-management/connectors/injectedConnector';
const liFiWidgetActions = {
    async autoConnectToLiFi(bloctoSDK) {
        try {
            const persistedActiveWallets = readActiveWallets();
            let activeWallets = supportedWallets.filter((wallet) => persistedActiveWallets.some((perstistedWallet) => perstistedWallet.name === wallet.name) && wallet.installed());
            const whichWalletToChoose = this.whichWalletToChoose();
            if (whichWalletToChoose.isBlocto) {
                const currentWallet = supportedWallets.find((wallet) => wallet.name === LI_FI_WALLET_NAMES.DEFAULT);
                if (currentWallet && 'windowProvider' in currentWallet) {
                    currentWallet.windowProvider = bloctoSDK?.ethereum;
                    activeWallets = [currentWallet];
                }
            }
            else if (activeWallets.length) {
                let name = null;
                if (whichWalletToChoose.isWalletConnect) {
                    name = LI_FI_WALLET_NAMES.WALLET_CONNECT;
                }
                else if (whichWalletToChoose.isExodus) {
                    name = LI_FI_WALLET_NAMES.EXODUS;
                }
                if (name) {
                    activeWallets = activeWallets.filter((wallet) => wallet.name === name);
                }
                else if (whichWalletToChoose.isDefaultExtension) {
                    activeWallets = activeWallets.filter((wallet) => wallet.name !== LI_FI_WALLET_NAMES.WALLET_CONNECT);
                }
            }
            if (!activeWallets.length) {
                return undefined;
            }
            if (whichWalletToChoose.isWalletConnect) {
                activeWallets = [
                    new WalletConnectConnectorV2({
                        name: 'Wallet Connect',
                        installed: () => true,
                        icon: walletIcons.walletConnect,
                        rpc: Object.fromEntries(supportedChains.map((chain) => {
                            return [chain.id, chain.metamask.rpcUrls[0] || ''];
                        })),
                    }),
                ];
            }
            else if (whichWalletToChoose.isExodus) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignored
                const eth = window.exodus?.ethereum;
                const exodus = new InjectedConnector({
                    name: 'Exodus',
                    installed: () => !!eth,
                    icon: walletIcons.exodus,
                }, eth);
                activeWallets = [exodus];
            }
            await this.liFiWidget.walletManagement.autoConnect(activeWallets);
            activeWallets[0].on('walletAccountChanged', handleWalletUpdate);
            await handleWalletUpdate(activeWallets[0]);
            return activeWallets[0];
        }
        catch (e) {
            devError(e);
            return undefined;
        }
    },
    async connectToLiFi(bloctoSDK) {
        const installedWallets = supportedWallets.filter((wallet) => wallet.installed());
        let currentWallet = installedWallets.find((wallet) => wallet.name !== LI_FI_WALLET_NAMES.WALLET_CONNECT);
        const whichWalletToChoose = this.whichWalletToChoose();
        if (whichWalletToChoose.isBlocto) {
            currentWallet = supportedWallets.find((wallet) => wallet.name === LI_FI_WALLET_NAMES.DEFAULT);
            if (currentWallet && 'windowProvider' in currentWallet) {
                const sdk = bloctoSDK || this.blocto.sdk;
                currentWallet.windowProvider = sdk?.ethereum;
            }
        }
        else {
            let name = null;
            if (whichWalletToChoose.isWalletConnect) {
                name = LI_FI_WALLET_NAMES.WALLET_CONNECT;
            }
            else if (whichWalletToChoose.isExodus) {
                name = LI_FI_WALLET_NAMES.EXODUS;
            }
            if (name) {
                currentWallet = installedWallets.find((wallet) => wallet.name === name);
            }
        }
        if (currentWallet) {
            try {
                if (whichWalletToChoose.isWalletConnect) {
                    currentWallet = new WalletConnectConnectorV2({
                        name: 'Wallet Connect',
                        installed: () => true,
                        icon: walletIcons.walletConnect,
                        rpc: Object.fromEntries(supportedChains.map((chain) => {
                            return [chain.id, chain.metamask.rpcUrls[0] || ''];
                        })),
                    });
                }
                else if (whichWalletToChoose.isExodus) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignored
                    const eth = window.exodus?.ethereum;
                    currentWallet = new InjectedConnector({
                        name: 'Exodus',
                        installed: () => !!eth,
                        icon: walletIcons.exodus,
                    }, eth);
                }
                await this.liFiWidget.walletManagement.connect(currentWallet);
                currentWallet.on('walletAccountChanged', handleWalletUpdate);
                await handleWalletUpdate(currentWallet);
            }
            catch (e) {
                devError(e);
            }
        }
    },
    async disconnectToLiFi() {
        if (this.liFiWidget.wallet.fully) {
            try {
                await this.liFiWidget.walletManagement.disconnect(this.liFiWidget.wallet.fully);
                this.liFiWidget.wallet.fully.removeAllListeners();
                handleWalletUpdate(undefined);
            }
            catch (e) {
                devError(e);
            }
        }
    },
    async switchChainForLiFi(chainId) {
        try {
            const whichWalletToChoose = this.whichWalletToChoose();
            if (whichWalletToChoose.isBlocto || whichWalletToChoose.isWalletConnect) {
                const status = await this.switchNetwork(linker.getNetworkId(chainId));
                if (status && status === NETWORK_SWITCH_STATUSED.SUCCESS) {
                    await infra.wallet.connect();
                }
                else {
                    return false;
                }
            }
            else {
                await this.liFiWidget.wallet.fully?.switchChain(chainId);
            }
            await handleWalletUpdate(this.liFiWidget.wallet.fully);
            return true;
        }
        catch (e) {
            return false;
        }
    },
    async addChainToLiFi(chainId) {
        try {
            await this.liFiWidget.wallet.fully?.addChain(chainId);
            handleWalletUpdate(this.liFiWidget.wallet.fully);
            return true;
        }
        catch {
            return false;
        }
    },
    async addTokenToLiFi(chainId, token) {
        try {
            await this.liFiWidget.wallet.fully?.addToken(chainId, token);
            handleWalletUpdate(this.liFiWidget.wallet.fully);
        }
        catch (e) {
            devError(e);
        }
        return;
    },
};
export default liFiWidgetActions;
async function handleWalletUpdate(wallet) {
    const walletStore = useWalletStore();
    walletStore.liFiWidget.wallet.fully = wallet;
    walletStore.liFiWidget.wallet.account = await extractAccountFromSigner(wallet?.account?.signer);
}
async function extractAccountFromSigner(signer) {
    try {
        return {
            address: (await signer?.getAddress()) || undefined,
            isActive: (signer && !!(await signer.getAddress()) === null) || !!signer,
            signer: signer ? markRaw(signer) : undefined,
            chainId: (await signer?.getChainId()) || undefined,
        };
    }
    catch {
        return {};
    }
}
class WalletConnectConnectorV2 extends events.EventEmitter {
    options;
    provider;
    walletConnectProvider;
    isActivationInProgress;
    account;
    name;
    icon;
    installed;
    constructor(constructorArgs) {
        super();
        this.isActivationInProgress = false;
        this.options = constructorArgs.rpc;
        this.name = constructorArgs.name;
        this.icon = constructorArgs.icon;
        this.installed = constructorArgs.installed;
    }
    async autoConnect() {
        const web3Store = useWeb3Store();
        const walletStore = useWalletStore();
        const walletConnectProvider = walletStore.wc.provider;
        if (this.isActivationInProgress || !walletConnectProvider || !web3Store.web3) {
            return;
        }
        try {
            const accountList = await web3Store.web3.eth.getAccounts();
            const selectedAddress = accountList.length ? accountList[0] : '';
            if (!isWalletDeactivated({
                address: selectedAddress || '',
                name: this.name,
            })) {
                this.provider = walletConnectProvider;
                this.walletConnectProvider = walletConnectProvider;
                this.startListening();
                await this.calcAccountData();
            }
        }
        catch (e) {
            //
        }
    }
    async startListening() {
        try {
            const listenerCount = this.provider?.events?.listenerCount('accountsChanged');
            if (listenerCount && listenerCount > 1) {
                return;
            }
            // Subscribe to accounts change
            this.provider?.once('accountsChanged', async (accounts) => {
                if (!accounts.length) {
                    this.account = undefined;
                    this.emit('walletAccountChanged', this);
                    return;
                }
                await this.calcAccountData();
            });
            // Subscribe to chainId change
            this.provider?.once('chainChanged', async () => {
                await this.calcAccountData();
            });
            // Subscribe to session disconnection
            this.provider?.once('disconnect', async () => {
                await this.calcAccountData();
            });
        }
        catch {
            return;
        }
    }
    async connect() {
        if (this.isActivationInProgress) {
            return;
        }
        this.isActivationInProgress = true;
        // Reset provider for every connection attempt
        const walletStore = useWalletStore();
        const defaultWallet = walletNetworkByWalletId[WALLET_NETWORK_ID.BNB_STARGATE];
        let walletConnectProvider = walletStore.wc.provider
            ? walletStore.wc.provider
            : await walletStore.getWCProvider({ walletNetworkId: WALLET_NETWORK_ID.BNB_STARGATE }, true);
        if (walletConnectProvider === null) {
            walletConnectProvider = await EthereumProvider.init({
                projectId: process.env.VUE_APP_WALLET_CONNECT_ID || '',
                chains: this.options
                    ? Object.keys(this.options).map((item) => Number(item))
                    : [defaultWallet.chainId.int],
                rpcMap: this.options,
                showQrModal: true,
            });
        }
        this.provider = walletConnectProvider;
        this.walletConnectProvider = walletConnectProvider;
        try {
            await this.walletConnectProvider?.enable(); // open modal
            this.startListening();
            await this.calcAccountData();
        }
        catch (e) {
            this.isActivationInProgress = false;
            throw e;
        }
        this.isActivationInProgress = false;
    }
    async disconnect() {
        if (this.provider) {
            await this.provider?.disconnect();
            await this.walletConnectProvider?.disconnect();
            this.provider = undefined;
            this.walletConnectProvider = undefined;
            this.isActivationInProgress = false;
            this.account = undefined;
            this.emit('walletAccountChanged', this);
        }
    }
    async switchChain(chainId) {
        if (!this.provider) {
            throw new Error('provider is not defined.');
        }
        return mySwitchChain(this.walletConnectProvider, chainId);
    }
    async addChain(chainId) {
        if (!this.provider) {
            throw new Error('provider is not defined.');
        }
        return addChain(this.walletConnectProvider, chainId);
    }
    async addToken(chainId, token) {
        if (!this.provider) {
            throw new Error('provider is not defined.');
        }
        return switchChainAndAddToken(this.walletConnectProvider, chainId, token);
    }
    async calcAccountData() {
        if (!this.walletConnectProvider) {
            throw new Error('provider is not defined.');
        }
        const provider = new ethers.providers.Web3Provider(this.walletConnectProvider, 'any');
        const signer = provider.getSigner();
        this.account = {
            chainId: await signer.getChainId(),
            address: await signer.getAddress(),
            signer,
            provider,
        };
        this.emit('walletAccountChanged', this);
    }
}
async function mySwitchChain(provider, chainId) {
    const params = { chainId: getChainById(chainId).metamask?.chainId };
    try {
        const switched = await new Promise((resolve, reject) => {
            provider.once('chainChanged', (id) => {
                setTimeout(() => {
                    if (parseInt(id) === chainId) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                }, 1000);
            });
            provider
                .request({
                method: 'wallet_switchEthereumChain',
                params: [params],
            })
                .catch((error) => {
                console.error(error);
                reject(error);
            });
        });
        return switched;
    }
    catch (error) {
        // const ERROR_CODE_UNKNOWN_CHAIN = 4902
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (error && error.code !== MetaMaskProviderErrorCode.userRejectedRequest) {
            const added = await addChain(provider, chainId);
            return added;
        }
        else {
            console.error(error);
            return false;
        }
    }
}
