import axios from 'axios';
import { LAYER_ZERO_LAB_STATUS } from '@/web3-server/web3/types/cross-chain/constants';
export default async function getTransactionStatus(hash) {
    try {
        const { data } = await axios.get(`https://api-mainnet.layerzero-scan.com/tx/${hash}`);
        if (data &&
            data.messages &&
            data.messages.length &&
            data.messages[0] &&
            data.messages[0].status) {
            return data.messages[0].status;
        }
        else {
            return LAYER_ZERO_LAB_STATUS.INFLIGHT;
        }
    }
    catch (e) {
        return LAYER_ZERO_LAB_STATUS.FAILED;
    }
}
