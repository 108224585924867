import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import getTVL from '@/web3-server/web3/actions/strategies/storage-contract/get-tvl';
import getAPYAndAPR from '@/web3-server/web3/actions/strategies/storage-contract/get-apy-and-apr';
import { getBoostingMaxAPY } from '@/web3-server/web3/actions/strategies/boost/get-config-for-boosting';
import getIsBoostingV2 from '@/web3-server/web3/actions/strategies/boost/get-is-boosting-v2';
import { numWei } from '@/composables/useFormatCurrency';
import Decimal from 'decimal.js';
import { getShortDecimal } from '@/web3-server/web3/handler/calc';
import { getDateOfLastDistribution } from '@/web3-server/web3/actions/strategies/storage-contract/get-date-last-distribution';
export function getGeneralInfoFromStorageContract(request, strategyContract, networkId) {
    try {
        const BLIDPriceSimple = numWei(request.BLIDPriceWei.toFixed());
        const storageContract = new request.web3.eth.Contract(strategyContract.abi, strategyContract.address);
        const tvlPromise = getTVL({ web3: request.web3 }, BLIDPriceSimple, storageContract, strategyContract.logicAddress);
        const apyPromise = getAPYAndAPR(request.web3, BLIDPriceSimple, storageContract, strategyContract);
        const dateOfLastDistributionPromise = getDateOfLastDistribution(storageContract);
        const boostingPromise = Promise.all([apyPromise]).then(async (values) => {
            const apyData = values[0];
            const isBoostingV2 = await getIsBoostingV2(storageContract);
            if (isBoostingV2) {
                return getBoostingMaxAPY(storageContract, request.BLIDPriceWei, networkId, apyData).then((boost) => {
                    return {
                        response: boost,
                        isBoostingV2,
                    };
                });
            }
            else {
                const boostingDecimal = new Decimal(0);
                const boost = {
                    original: boostingDecimal.toNumber(),
                    roundedDown: getShortDecimal(boostingDecimal, 2, 'down').toNumber(),
                };
                return {
                    response: {
                        apr: boost,
                        apy: boost,
                        pureAPR: boost,
                    },
                    isBoostingV2,
                };
            }
        });
        return { tvlPromise, apyPromise, boostingPromise, dateOfLastDistributionPromise };
    }
    catch (e) {
        devError(DEV_ERROR_CODE.GET_GENERAL_INFO_CONTRACT);
        return undefined;
    }
}
