import Decimal from 'decimal.js';
import { getDefaultCalcByDecimal } from '@/web3-server/web3/handler/calc';
export default function getCalcCurrentAPY(BLIDNeed, amountWei) {
    const apr = getDefaultCalcByDecimal(amountWei.div(BLIDNeed), {
        type: 'divide',
        times: 4,
    });
    const pureBoosting = new Decimal(apr.formatted.full).div(100).div(365);
    const boostingAPY = new Decimal(Decimal.pow(new Decimal(1).plus(pureBoosting), 365))
        .minus(1)
        .mul(100);
    return { apr, apy: getDefaultCalcByDecimal(boostingAPY) };
}
