import { WALLET_SESSION_TYPE } from '@/web3-server/wallet/types/connect/constants';
export const walletSessionActions = {
    getWalletSession(key) {
        return JSON.parse(localStorage.getItem(key || this.walletSession.key) || 'null');
    },
    setWalletSession(session) {
        localStorage.setItem(this.walletSession.key, JSON.stringify(session));
        this.walletSession.value = session;
    },
    removeWalletSession() {
        localStorage.removeItem(this.walletSession.key);
        this.walletSession.value = null;
    },
    whichWalletToChoose() {
        const cryptoPluginProvider = this.getDefaultBrowserExtension();
        const exodusProvider = this.getExodusProvider();
        const WCSession = this.getWCSession();
        const isBloctoConnected = this.isBloctoConnected();
        const walletSession = this.walletSession.value;
        const isWalletConnect = !!(WCSession &&
            (!walletSession || walletSession.type === WALLET_SESSION_TYPE.WALLET_CONNECT));
        const currentEthProviderIsExodus = !!(cryptoPluginProvider &&
            (cryptoPluginProvider?.isExodus || 'isExodus' in cryptoPluginProvider));
        const isExodus = !!exodusProvider &&
            !isWalletConnect &&
            !isBloctoConnected &&
            (!walletSession ||
                walletSession.type === WALLET_SESSION_TYPE.EXODUS ||
                currentEthProviderIsExodus);
        const isDefaultExtension = !isWalletConnect && !isExodus && !isBloctoConnected && !!cryptoPluginProvider;
        const isBlocto = isBloctoConnected && !isWalletConnect;
        return {
            isWalletConnect,
            isExodus,
            isDefaultExtension,
            isBlocto,
        };
    },
};
