import converter from '@/web3-server/utils/converter';
import { readyChecks, requestAPI } from '@/web3-server/web3/handler/inspectors';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import getTokenDepositFromStorage from '@/web3-server/web3-currencies/actions/deposited/get-token-deposit-from-storage';
const depositedActions = {
    async getDepositedFromStorage(currencyId, strategyContract) {
        const currency = this.getById(currencyId);
        const defaultValue = converter.withType.fromWei('0');
        if (currency && currency.relations?.vault) {
            const request = requestAPI('user', { web3: { vault: currency.relations.vault } });
            if (request.isUser &&
                readyChecks.currency.isAddressAndDecimals(currency) &&
                strategyContract &&
                strategyContract.address &&
                strategyContract.abi) {
                return getTokenDepositFromStorage(request.getWithUser, { abi: strategyContract.abi, address: strategyContract.address }, {
                    address: currency.burnAddress ? currency.burnAddress : currency.address,
                    decimals: currency.decimals,
                });
            }
            else {
                devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR);
                return defaultValue;
            }
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_ITEM_SEARCH);
            return defaultValue;
        }
    },
};
export default depositedActions;
