import router from '@/router';
import { ROUTE_NAME } from '@/infra/types/page/constants';
import { pageview } from 'vue-gtag';
import { useCurrenciesStore } from '@/store/currencies';
import GAManager from '@/utils/ga-manager/ga-manager';
import { STRATEGY_ROUTE } from '@/web3-server/web3-strategies/types/config/constants';
export const pageManagement = {
    async openStrategyPage(isAuth, strategy, isUpdateCurrency = true, currencyId) {
        if (strategy.isDeposit) {
            if (isUpdateCurrency) {
                await pageManagement.openPageWithCurrencyUpdate(true, strategy, { currencyId });
            }
            else {
                await pageManagement.openManagementPage(strategy.route, { currencyId });
            }
        }
        else if (!isAuth) {
            await pageManagement.openDepositPage(strategy.route, { currencyId });
        }
        else if (isUpdateCurrency) {
            await pageManagement.openPageWithCurrencyUpdate(false, strategy, { currencyId });
        }
        else {
            await pageManagement.openDepositPage(strategy.route, { currencyId });
        }
    },
    openManagementPage(strategyRoute, extra) {
        return router.push({
            name: ROUTE_NAME.MANAGEMENT,
            params: {
                title: strategyRoute,
                currencyId: extra && extra.currencyId,
            },
            hash: extra && extra.hash ? '#' + extra.hash : '',
        });
    },
    async openPageWithCurrencyUpdate(isManagementPage, strategy, extra) {
        const currenciesStore = useCurrenciesStore();
        strategy.horizon.isBtnProgress = true;
        strategy.horizon.isCurrencyLoaded = extra && extra.currencyId;
        await Promise.all(currenciesStore.updateCurrencyStrategy(strategy));
        if (isManagementPage) {
            await pageManagement.openManagementPage(strategy.route, extra);
        }
        else {
            await pageManagement.openDepositPage(strategy.route, extra);
        }
        strategy.horizon.isBtnProgress = false;
        strategy.horizon.isCurrencyLoaded = undefined;
    },
    openDepositPage(strategyRoute, extra) {
        return router.push({
            name: ROUTE_NAME.DEPOSIT,
            params: {
                title: strategyRoute,
                currencyId: extra && extra.currencyId,
            },
        });
    },
    openInfoPage(strategyRoute) {
        return router.push({
            name: ROUTE_NAME.INFO,
            params: {
                title: strategyRoute,
            },
        });
    },
    openWithdrawPage(params) {
        if (params.route) {
            return router.push({
                name: ROUTE_NAME.WITHDRAW,
                params: {
                    title: params.route,
                    currencyType: params.currencyType,
                },
            });
        }
    },
    goHome() {
        return router.push({
            name: ROUTE_NAME.HOME,
        });
    },
    isManagementPage() {
        return router.hasRoute(ROUTE_NAME.MANAGEMENT);
    },
    isDepositPage() {
        return router.hasRoute(ROUTE_NAME.DEPOSIT);
    },
    isWithdrawPage() {
        return router.hasRoute(ROUTE_NAME.WITHDRAW);
    },
    isInfoPage() {
        return router.hasRoute(ROUTE_NAME.INFO);
    },
    isPage(route) {
        return Object.values(STRATEGY_ROUTE).includes(route);
    },
    sendPageOpeningToAnalytics(route, props) {
        const { pageTitle, pagePath, eventName, eventPrefix } = props;
        pageview({
            page_title: pageTitle,
            page_path: '/' + route + (pagePath || ''),
        });
        GAManager.sendEvent(eventName, {
            eventPrefix,
        });
    },
};
