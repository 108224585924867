import SwapRouter from '@/infra/abis/SwapRouter.json';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export default async function getBLIDPriceFromPancakeswap({ web3, }) {
    try {
        const BLIDToken = process.env.VUE_APP_STAKING_BLID_TOKEN_ADDRESS ||
            '0x766afcf83fd5eaf884b3d529b432ca27a6d84617';
        const pancakeContract = new web3.eth.Contract(SwapRouter.abi, '0x10ED43C718714eb63d5aA57B78B54704E256024E');
        const priceList = await pancakeContract.methods
            .getAmountsOut('1000000000000000000', [
            BLIDToken,
            '0x55d398326f99059ff775485246999027b3197955',
        ])
            .call();
        return {
            original: priceList[priceList.length - 1],
            formatted: web3.utils.fromWei(priceList[priceList.length - 1]),
        };
    }
    catch (e) {
        devError(DEV_ERROR_CODE.WEB_CONTRACT);
        return {
            original: '',
            formatted: '',
        };
    }
}
