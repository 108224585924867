import infra from '@/infra';
import { pageManagement } from '@/store/page-management';
import { STEPPER_MOD, STEPPER_POPUP_TITLE } from '@/infra/types/stepper/constants';
import { myDialog } from '@/store/my-dialog';
export default function useEvent($store, strategiesStore, walletStore) {
    return {
        openStrategyPage: (strategy) => pageManagement.openStrategyPage(!!walletStore.userAddress, strategy),
        openStrategyPageByCurrency: (props) => pageManagement.openStrategyPage(!!walletStore.userAddress, props.strategy, true, props.currencyId),
        disconnect: async (disableAutoLogin) => infra.wallet.disconnect(disableAutoLogin),
        openDialog: {
            withdraw: (params, route) => pageManagement.openWithdrawPage({ ...params, route }),
        },
        boost: {
            deposit(selectedData, dialog) {
                if (dialog && dialog.strategyId) {
                    myDialog.openStepper({
                        title: STEPPER_POPUP_TITLE.DEPOSIT_BOOST,
                        paramsByMod: {
                            mod: STEPPER_MOD.DEPOSIT_BOOST,
                            strategyId: dialog.strategyId,
                            selectedData,
                        },
                    });
                }
            },
        },
        deposit: {
            create: (selectedData, dialog) => {
                if (dialog && dialog.strategyId) {
                    if (selectedData.crossChainSummary && selectedData.selectedNetwork) {
                        const selectedNetwork = selectedData.selectedNetwork;
                        const crossChainSummary = selectedData.crossChainSummary;
                        myDialog.openStepper({
                            title: STEPPER_POPUP_TITLE.DEPOSIT,
                            paramsByMod: {
                                mod: STEPPER_MOD.CROSS_CHAIN_DEPOSIT,
                                strategyId: dialog.strategyId,
                                selectedData: {
                                    ...selectedData,
                                    selectedNetwork,
                                    crossChainSummary,
                                },
                            },
                        });
                    }
                    else {
                        myDialog.openStepper({
                            title: STEPPER_POPUP_TITLE.DEPOSIT,
                            paramsByMod: {
                                mod: STEPPER_MOD.DEPOSIT,
                                strategyId: dialog.strategyId,
                                selectedData,
                            },
                        });
                    }
                }
                // if (dialog && dialog.strategyId) {
                //   if (params.selectedNetwork && params.selectedNetwork.id !== NETWORK_ID.BNB) {
                //     strategiesStore.crossChainDeposit({
                //       ...params,
                //       strategyId: dialog.strategyId,
                //     });
                //   } else {
                //     strategiesStore.createDeposit(
                //       { ...params, strategyId: dialog.strategyId },
                //       dialog.routeToOpenPopup,
                //     );
                //   }
                // }
            },
            withdraw: (params, dialog) => {
                if (dialog && dialog.strategyId) {
                    strategiesStore.withdraw({ ...params, strategyId: dialog.strategyId });
                }
            },
            claim: async (params, dialog) => {
                if (dialog && dialog.strategyId) {
                    strategiesStore.claim({ ...params, strategyId: dialog.strategyId }, dialog.routeToOpenPopup, dialog.props.showBoostBtn);
                }
            },
            back: async (strategyId) => $store.dispatch('popupDeposit/back', strategyId),
            withdrawBoosting: (deposited, dialog) => {
                if (dialog && dialog.strategyId && dialog.commonProps && dialog.commonProps.networkId) {
                    strategiesStore.withdrawBoosting(dialog.commonProps.networkId, dialog.strategyId, deposited);
                }
            },
        },
    };
}
