import { checkVars, requestAPI } from '@/web3-server/web3/handler/inspectors';
import getCurrency from '@/web3-server/web3/actions/currencies/get-currency';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import { devError } from '@/utils/devError';
import getApproved from '@/web3-server/web3/actions/currencies/get-approved';
import getCrossCurrency from '@/web3-server/web3/actions/currencies/get-cross-currency';
import { STRATEGY_VAULT } from '@/web3-server/web3-strategies/types/config/constants';
import getCurrencyBalance from '@/web3-server/web3/actions/currencies/get-currency-balance';
import converter from '@/web3-server/utils/converter';
const currenciesAction = {
    getCurrency(strategyContract, currency, vault) {
        const request = requestAPI('user', {
            web3: { vault },
        });
        if (request.isUser &&
            strategyContract &&
            strategyContract.abi &&
            strategyContract.type &&
            currency &&
            currency.address &&
            currency.decimals &&
            checkVars([strategyContract.address])) {
            return getCurrency(request.getWithUser, strategyContract, currency, vault !== STRATEGY_VAULT.ETH);
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR);
            return null;
        }
    },
    getCrossCurrency(strategyContractAddress, currency, requestVia) {
        const request = requestAPI('user', { web3: requestVia });
        if (request.isUser &&
            currency &&
            currency.address &&
            currency.decimals &&
            checkVars([strategyContractAddress])) {
            return getCrossCurrency(request.getWithUser, strategyContractAddress, currency);
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR);
            return null;
        }
    },
    async getApprovedCurrency(strategyContractAddress, props, currencyDecimals, web3Network) {
        const request = requestAPI('user', { web3: web3Network });
        if (request.isUser &&
            strategyContractAddress &&
            props &&
            ('currencyContract' in props || 'currencyAddress' in props) &&
            currencyDecimals) {
            return getApproved(request.getWithUser, strategyContractAddress, props, currencyDecimals);
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR, {
                isUser: request.isUser,
                strategyContractAddress,
                props,
                currencyDecimals,
            });
            return '0';
        }
    },
    async getCurrencyBalance(contractOptions, currencyDecimals, web3Network) {
        const request = requestAPI('user', { web3: web3Network });
        if (request.isUser &&
            contractOptions &&
            ('contract' in contractOptions || 'address' in contractOptions) &&
            currencyDecimals) {
            return getCurrencyBalance(request.getWithUser, contractOptions, currencyDecimals);
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR);
            return converter.withType.fromWei('0');
        }
    },
};
export default currenciesAction;
