export var DEPOSIT_STEPPER_ID;
(function (DEPOSIT_STEPPER_ID) {
    DEPOSIT_STEPPER_ID["APPROVE"] = "STRATEGY_APPROVE";
    DEPOSIT_STEPPER_ID["DEPOSIT"] = "STRATEGY_DEPOSIT";
})(DEPOSIT_STEPPER_ID || (DEPOSIT_STEPPER_ID = {}));
export var DEPOSIT_BOOST_STEPPER_ID;
(function (DEPOSIT_BOOST_STEPPER_ID) {
    DEPOSIT_BOOST_STEPPER_ID["APPROVE"] = "BOOST_APPROVE";
    DEPOSIT_BOOST_STEPPER_ID["BOOST"] = "BOOST_DEPOSIT";
})(DEPOSIT_BOOST_STEPPER_ID || (DEPOSIT_BOOST_STEPPER_ID = {}));
export var CROSS_CHAIN_STEPPER_ID;
(function (CROSS_CHAIN_STEPPER_ID) {
    CROSS_CHAIN_STEPPER_ID["APPROVE"] = "CROSS_CHAIN_APPROVE";
    CROSS_CHAIN_STEPPER_ID["DEPOSIT_ON_SELECTED_NETWORK"] = "DEPOSIT_ON_SELECTED_NETWORK";
    CROSS_CHAIN_STEPPER_ID["MOVE_ASSETS_TO_BNB"] = "MOVE_ASSETS_TO_BNB";
})(CROSS_CHAIN_STEPPER_ID || (CROSS_CHAIN_STEPPER_ID = {}));
export var STEPPER_STATES;
(function (STEPPER_STATES) {
    STEPPER_STATES["NOT_STARTED"] = "NOT_STARTED";
    STEPPER_STATES["STARTED"] = "STARTED";
    STEPPER_STATES["PENDING"] = "PENDING";
    STEPPER_STATES["FAILED"] = "FAILED";
    STEPPER_STATES["SUCCESS"] = "SUCCESS";
    STEPPER_STATES["CANCELLED"] = "CANCELLED";
    STEPPER_STATES["CONDITIONS_NOT_MET"] = "CONDITIONS_NOT_MET";
})(STEPPER_STATES || (STEPPER_STATES = {}));
export var STEPPER_MOD;
(function (STEPPER_MOD) {
    STEPPER_MOD["DEPOSIT"] = "DEPOSIT";
    STEPPER_MOD["DEPOSIT_BOOST"] = "DEPOSIT_BOOST";
    STEPPER_MOD["CROSS_CHAIN_DEPOSIT"] = "CROSS_CHAIN_DEPOSIT";
    STEPPER_MOD["NFT"] = "NFT";
})(STEPPER_MOD || (STEPPER_MOD = {}));
export var STEPPER_POPUP_TITLE;
(function (STEPPER_POPUP_TITLE) {
    STEPPER_POPUP_TITLE["DEPOSIT"] = "DEPOSIT";
    STEPPER_POPUP_TITLE["DEPOSIT_BOOST"] = "BOOST";
    STEPPER_POPUP_TITLE["MINT"] = "MINT";
})(STEPPER_POPUP_TITLE || (STEPPER_POPUP_TITLE = {}));
