import { CURRENCY_ID, CURRENCY_TYPE } from '@/web3-server/web3-currencies/types/config/constants';
import { STRATEGY_VAULT } from '@/web3-server/web3-strategies/types/config/constants';
import { NETWORK_ID, WALLET_NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import { BRIDGE_TYPE } from '@/web3-server/web3/types/cross-chain/constants';
const polygonCurrencies = {
    currencies: [
        {
            id: CURRENCY_ID.POLYGON_BLID,
            symbol: CURRENCY_TYPE.BLID,
            address: '0x4b27cd6e6a5e83d236ead376d256fe2f9e9f0d2e',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.POLYGON_USDT,
            symbol: CURRENCY_TYPE.USDT,
            address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
            decimals: 6,
        },
        {
            id: CURRENCY_ID.POLYGON_USDC,
            symbol: CURRENCY_TYPE.USDC,
            address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
            decimals: 6,
        },
        {
            id: CURRENCY_ID.POLYGON_DAI,
            symbol: CURRENCY_TYPE.DAI,
            address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.POLYGON_ETH,
            symbol: CURRENCY_TYPE.ETH,
            address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.POLYGON_MATIC,
            symbol: CURRENCY_TYPE.MATIC,
            address: '0x0000000000000000000000000000000000001010',
            burnAddress: '0x0000000000000000000000000000000000000000',
            decimals: 18,
        },
    ],
    relations: {
        vault: STRATEGY_VAULT.POLYGON,
        networkId: NETWORK_ID.POLYGON,
        walletNetworkId: WALLET_NETWORK_ID.POLYGON_STARGATE,
        bridgeType: BRIDGE_TYPE.STARGATE,
    },
};
const ethCurrencies = {
    currencies: [
        {
            id: CURRENCY_ID.ETH_USDT,
            symbol: CURRENCY_TYPE.USDT,
            address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
            decimals: 6,
        },
        // {
        //   id: CURRENCY_ID.ETH_BUSD,
        //   symbol: CURRENCY_TYPE.BUSD,
        //   address: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
        //   decimals: 18,
        // },
        // {
        //   id: CURRENCY_ID.ETH_USDC,
        //   symbol: CURRENCY_TYPE.USDC,
        //   address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        //   decimals: 6,
        // },
        // {
        //   id: CURRENCY_ID.ETH_DAI,
        //   symbol: CURRENCY_TYPE.DAI,
        //   address: '0x6b175474e89094c44da98b954eedeac495271d0f',
        //   decimals: 18,
        // },
        // {
        //   id: CURRENCY_ID.ETH_BLID,
        //   symbol: CURRENCY_TYPE.BLID,
        //   address: '0x8A7aDc1B690E81c758F1BD0F72DFe27Ae6eC56A5',
        //   decimals: 18,
        // },
        // {
        //   id: CURRENCY_ID.ETH_BTC,
        //   symbol: CURRENCY_TYPE.BTC,
        //   address: '',
        //   decimals: 8,
        // },
    ],
    relations: {
        vault: STRATEGY_VAULT.ETH,
        networkId: NETWORK_ID.ETH,
        walletNetworkId: WALLET_NETWORK_ID.ETH_STARGATE,
        bridgeType: BRIDGE_TYPE.STARGATE,
    },
};
const bnbCurrencies = {
    currencies: [
        {
            id: CURRENCY_ID.BNB_BNB,
            symbol: CURRENCY_TYPE.BNB,
            address: '0x0000000000000000000000000000000000000000',
            burnAddress: '0x0000000000000000000000000000000000000000',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_BUSD,
            symbol: CURRENCY_TYPE.BUSD,
            address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
            decimals: 18,
            forbiddenToDeposit: true,
        },
        {
            id: CURRENCY_ID.BNB_USDT,
            symbol: CURRENCY_TYPE.USDT,
            address: '0x55d398326f99059fF775485246999027B3197955',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_USDC,
            symbol: CURRENCY_TYPE.USDC,
            address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_DAI,
            symbol: CURRENCY_TYPE.DAI,
            address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_ETH,
            symbol: CURRENCY_TYPE.ETH,
            address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_BTC,
            symbol: CURRENCY_TYPE.BTC,
            address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_BLID,
            symbol: CURRENCY_TYPE.BLID,
            address: process.env.VUE_APP_STAKING_BLID_TOKEN_ADDRESS ||
                '0x32Ee7c89D689CE87cB3419fD0F184dc6881Ab3C7',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_BLID_USDT,
            symbol: CURRENCY_TYPE.BLID_USDT,
            address: process.env.VUE_APP_FARMING_BLID_USDT_TOKEN_ADDRESS ||
                '0xf728c4eb2b6b3a4751f11af738c6b5cbf7f66751',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_XRP,
            symbol: CURRENCY_TYPE.XRP,
            address: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_XVS,
            symbol: CURRENCY_TYPE.XVS,
            address: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_LTC,
            symbol: CURRENCY_TYPE.LTC,
            address: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_ADA,
            symbol: CURRENCY_TYPE.ADA,
            address: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_LINK,
            symbol: CURRENCY_TYPE.LINK,
            address: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_DOT,
            symbol: CURRENCY_TYPE.DOT,
            address: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.BNB_MATIC,
            symbol: CURRENCY_TYPE.MATIC,
            address: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
            decimals: 18,
        },
    ],
    relations: {
        vault: STRATEGY_VAULT.BNB,
        networkId: NETWORK_ID.BNB,
        walletNetworkId: WALLET_NETWORK_ID.BNB_STARGATE,
        bridgeType: BRIDGE_TYPE.STARGATE,
    },
};
const arbCurrencies = {
    currencies: [
        {
            id: CURRENCY_ID.ARB_BLID,
            symbol: CURRENCY_TYPE.BLID,
            address: '0x81dE4945807bb31425362F8F7109C18E3dc4f8F0',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.ARB_USDC_E,
            symbol: CURRENCY_TYPE.USDC_E,
            address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
            decimals: 6,
        },
    ],
    relations: {
        vault: STRATEGY_VAULT.ARBITRUM,
        networkId: NETWORK_ID.ARBITRUM,
        walletNetworkId: WALLET_NETWORK_ID.ARBITRUM,
        bridgeType: BRIDGE_TYPE.STARGATE,
    },
};
const optimismCurrencies = {
    currencies: [
        {
            id: CURRENCY_ID.OP_BLID,
            symbol: CURRENCY_TYPE.BLID,
            address: '0x048c6bad48c51436764ed1fdb3c9d1c25d2c0ada',
            decimals: 18,
        },
        {
            id: CURRENCY_ID.OP_USDC_E,
            symbol: CURRENCY_TYPE.USDC_E,
            address: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
            decimals: 6,
        },
    ],
    relations: {
        vault: STRATEGY_VAULT.OPTIMISM,
        networkId: NETWORK_ID.OPTIMISM,
        walletNetworkId: WALLET_NETWORK_ID.OPTIMISM,
        bridgeType: BRIDGE_TYPE.STARGATE,
    },
};
export const goerliCurrencies = {
    currencies: [
        {
            id: CURRENCY_ID.GOERLI_USDT,
            symbol: CURRENCY_TYPE.USDT,
            address: '0x78cd3d9de006f2f64826fab6cfee6d00bbba9bfd',
            decimals: 6,
        },
    ],
    relations: {
        vault: STRATEGY_VAULT.ETH,
        networkId: NETWORK_ID.GOERLI,
        walletNetworkId: WALLET_NETWORK_ID.GOERLI,
        bridgeType: BRIDGE_TYPE.STARGATE,
    },
};
export const web3StoreDataNameOfCurrencies = {
    [CURRENCY_TYPE.BUSD]: { name: 'Binance USD' },
    [CURRENCY_TYPE.USDT]: { name: 'Tether USD' },
    [CURRENCY_TYPE.USDC]: { name: 'USD Coin' },
    [CURRENCY_TYPE.USDC_E]: { name: 'Bridged USDC' },
    [CURRENCY_TYPE.DAI]: { name: 'Dai Stablecoin' },
    [CURRENCY_TYPE.BLID]: { name: 'Bolide coin' },
    [CURRENCY_TYPE.BLID_USDT]: { name: 'Bolide-Tether USD coin', subtitle: '@Pancakeswap' },
    [CURRENCY_TYPE.ETH]: { name: 'Ethereum' },
    [CURRENCY_TYPE.BTC]: { name: 'Bitcoin' },
    [CURRENCY_TYPE.XRP]: { name: 'XRP' },
    [CURRENCY_TYPE.XVS]: { name: 'XVS' },
    [CURRENCY_TYPE.LTC]: { name: 'LTC' },
    [CURRENCY_TYPE.ADA]: { name: 'ADA' },
    [CURRENCY_TYPE.LINK]: { name: 'LINK' },
    [CURRENCY_TYPE.DOT]: { name: 'DOT' },
    [CURRENCY_TYPE.MATIC]: { name: 'Matic' },
    [CURRENCY_TYPE.BNB]: { name: 'Binance' },
};
export const web3StoreDataCurrencies = {
    [STRATEGY_VAULT.BNB]: bnbCurrencies,
    [STRATEGY_VAULT.BNB_V2]: bnbCurrencies,
    [STRATEGY_VAULT.POLYGON]: polygonCurrencies,
    [STRATEGY_VAULT.ETH]: ethCurrencies,
    [STRATEGY_VAULT.ARBITRUM]: arbCurrencies,
    [STRATEGY_VAULT.OPTIMISM]: optimismCurrencies,
};
export const listOfCurrenciesWithMainParams = bnbCurrencies.currencies.concat(polygonCurrencies.currencies, ethCurrencies.currencies, optimismCurrencies.currencies);
export const listOfCurrenciesWithLinks = [
    bnbCurrencies,
    polygonCurrencies,
    ethCurrencies,
    arbCurrencies,
    optimismCurrencies,
    goerliCurrencies,
];
