import { defineStore } from 'pinia';
import { listOfWeb3Strategies } from '@/web3-server/web3-strategies/data/config';
import { web3StrategiesActions } from '@/web3-server/web3-strategies/actions';
export const useWeb3StrategiesStore = defineStore('web3Strategies', {
    state: () => ({
        list: listOfWeb3Strategies,
    }),
    actions: {
        ...web3StrategiesActions,
        getById(id) {
            return this.list.find((strategy) => strategy.id === id);
        },
    },
    getters: {},
});
