export default function clearNumLine(val) {
    if (val) {
        const rest = getAllRest(val);
        let formatted = val.replace(/[^\d.]/g, '');
        if (formatted.length && val[0] === '-') {
            formatted = val[0] + formatted;
        }
        if (rest) {
            formatted += rest;
        }
        if (formatted.length && (formatted.match(/\./g) || []).length > 1) {
            const arr = formatted.split('.');
            formatted = arr[0] + '.' + arr.slice(1).join('');
        }
        return !formatted ? '0' : formatted;
    }
    else {
        return '0';
    }
}
function getAllRest(val) {
    if (val.includes('e+')) {
        return getRest(val, 'e+').replace(/\D/g, '');
    }
    else if (val.includes('e-')) {
        return getRest(val, 'e-').replace(/\D/g, '');
    }
    else {
        return '';
    }
}
function getRest(val, template) {
    let rest = '';
    if (val.includes(template)) {
        const arr = val.split(template);
        rest = template + arr[arr.length - 1];
    }
    return rest;
}
