import ERC20 from '@/infra/abis/ERC20.json';
import BN from 'bn.js';
import { sendEvent } from '@/web3-server/web3/handler/sendEvent';
import { getSendOptions } from '@/web3-server/web3/handler/get-send-options';
export default function approveCurrencyEip1559(request, currencyAddress, contractAddress, approvalAmount) {
    const maxInt = new BN('2').pow(new BN('256')).sub(new BN('1'));
    const amount = approvalAmount && approvalAmount.value ? approvalAmount.value.toFixed() : maxInt;
    const currencyContract = new request.web3.eth.Contract(ERC20.abi, currencyAddress);
    getSendOptions(request, {
        supportEIP1559: true,
    }).then((sendOptions) => {
        sendEvent(request, currencyContract.methods.approve(contractAddress, amount).send(sendOptions));
    });
}
