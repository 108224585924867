export var TYPES_OF_SAVED_OPERATAIONS;
(function (TYPES_OF_SAVED_OPERATAIONS) {
    TYPES_OF_SAVED_OPERATAIONS["CROSSCHAIN_MY_NETWORK"] = "CROSSCHAIN_MY_NETWORK";
    TYPES_OF_SAVED_OPERATAIONS["CROSSCHAIN_BNB"] = "CROSSCHAIN_BNB";
    TYPES_OF_SAVED_OPERATAIONS["APPROVE"] = "APPROVE";
    TYPES_OF_SAVED_OPERATAIONS["OTHER"] = "OTHER";
})(TYPES_OF_SAVED_OPERATAIONS || (TYPES_OF_SAVED_OPERATAIONS = {}));
export var NOTIFICATION_TYPES;
(function (NOTIFICATION_TYPES) {
    NOTIFICATION_TYPES["SUCCESS"] = "SUCCESS";
    NOTIFICATION_TYPES["ERROR"] = "ERROR";
    NOTIFICATION_TYPES["PENDING"] = "PENDING";
})(NOTIFICATION_TYPES || (NOTIFICATION_TYPES = {}));
