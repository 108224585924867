import axios from 'axios';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export default async function getBLIDFromXYFinance(chainId = { from: '56', to: '56' }, tokenAddress = {
    from: '0x766afcf83fd5eaf884b3d529b432ca27a6d84617',
    to: '0x55d398326f99059ff775485246999027b3197955',
}) {
    try {
        const requestUrl = `https://open-api.xy.finance/v1/quote?srcChainId=${chainId.from}&fromTokenAddress=${tokenAddress.from}&amount=1000000000000000000&destChainId=${chainId.to}&toTokenAddress=${tokenAddress.to}`;
        return axios.get(requestUrl);
    }
    catch (e) {
        devError(DEV_ERROR_CODE.AXIOS);
        return null;
    }
}
