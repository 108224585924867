import { defineStore } from 'pinia';
import { STRATEGY_ID, STRATEGY_ROUTE, STRATEGY_STATUS, STRATEGY_VAULT, } from '@/web3-server/web3-strategies/types/config/constants';
import Storage from '@/infra/abis/Storage.json';
import StorageV2 from '@/infra/abis/StorageV2.json';
import Master from '@/infra/abis/MasterCsrt.json';
import { BLIDCurrencyConfig, useCurrenciesStore } from '@/store/currencies';
import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
import commonActions from '@/store/strategies/actions/common';
import strategiesDepositActions from '@/store/strategies/actions/deposit/actions';
import strategiesBoostingActions from '@/store/strategies/actions/boost';
import { BRIDGE_TYPE } from '@/web3-server/web3/types/cross-chain/constants';
import { useWalletStore } from '@/web3-server/wallet';
import linker from '@/web3-server/utils/linker';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import { GA_PREFIX } from '@/infra/types/ga-manager/constants';
export const useStrategiesStore = defineStore('strategies', {
    state: () => {
        const currenciesStore = useCurrenciesStore();
        const defaultHorizon = {
            defaultNums: {
                apy: 0,
                apr: 0,
                tvl: 0,
                maxBoosting: {
                    apy: 0,
                    apr: 0,
                    pureAPR: 0,
                },
                userBoosting: {
                    apy: 0,
                    apr: 0,
                },
            },
            listOfTokens: [],
            isBtnProgress: false,
            isCurrencyLoaded: undefined,
            dataLoaded: {
                tvl: false,
                apy: false,
                maxBoosting: false,
                userBoosting: false,
            },
            deposit: {
                depositAmountInUSD: '0',
                earnedBLID: '0',
                earnedUSD: '0',
                percentageYield: '0',
                depositedBoostInBLID: undefined,
                averageDistributionReward: '0',
            },
            formatted: {
                apy: '0',
                apr: '0',
                tvl: '0',
                maxBoosting: {
                    apy: '0',
                    apr: '0',
                    pureAPR: '0',
                },
                userBoosting: {
                    apy: '0',
                    apr: '0',
                },
            },
            leftAfterDeposit: {
                earnedBLID: false,
                boost: false,
            },
            dateOfLastDistribution: '',
            strategyStatus: STRATEGY_STATUS.DEPOSIT,
        };
        const earnedIncome = {
            ...BLIDCurrencyConfig,
            balance: '',
        };
        const list = [
            {
                isActive: !!process.env.VUE_APP_BNB_NETWORK_BNB_STORAGE_ADDRESS,
                id: STRATEGY_ID.BNB_BNB,
                vault: STRATEGY_VAULT.BNB_V2,
                network: NETWORK_ID.BNB,
                title: 'bnb',
                route: STRATEGY_ROUTE.BNB_BNB,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: StorageV2.abi,
                    address: process.env.VUE_APP_BNB_NETWORK_BNB_STORAGE_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.BNB_BNB] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.BNB_BNB,
                    vault: STRATEGY_VAULT.BNB_V2,
                    title: 'bnb',
                    extraTVL: {
                        deposited: 0,
                        borrowed: 0,
                    },
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.BNB_BNB] || [],
                },
                text: 'Deposit BNB to have high income with lowest risk possible',
                GAPrefix: GA_PREFIX.BNB_BNB,
                addressesOfLogicContracts: { list: [] },
                supportsEIP1559: true,
            },
            {
                isActive: !!process.env.VUE_APP_BNB_NETWORK_USDT_STORAGE_ADDRESS,
                id: STRATEGY_ID.BNB_USDT_V2,
                vault: STRATEGY_VAULT.BNB_V2,
                network: NETWORK_ID.BNB,
                title: 'usdt',
                route: STRATEGY_ROUTE.USDT_V2_BNB,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: StorageV2.abi,
                    address: process.env.VUE_APP_BNB_NETWORK_USDT_STORAGE_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.BNB_USDT_V2] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.BNB_USDT_V2,
                    vault: STRATEGY_VAULT.BNB_V2,
                    title: 'usdt',
                    extraTVL: {
                        deposited: 0,
                        borrowed: 0,
                    },
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.BNB_USDT_V2] || [],
                },
                text: 'Deposit USDT to have high income with lowest risk possible',
                GAPrefix: GA_PREFIX.BNB_USDT_V2,
                addressesOfLogicContracts: { list: [] },
                supportsEIP1559: true,
            },
            {
                isActive: !!process.env.VUE_APP_DEV_BNB_NETWORK_USDT_DFORCE_STORAGE_ADDRESS &&
                    process.env.VUE_APP_IS_DEV_STORY === 'true',
                id: STRATEGY_ID.DEV_BNB_USDT_DFORCE,
                vault: STRATEGY_VAULT.BNB_V2,
                network: NETWORK_ID.BNB,
                title: 'USDT DForce',
                route: STRATEGY_ROUTE.DEV_BNB_USDT_DFORCE,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: StorageV2.abi,
                    address: process.env.VUE_APP_DEV_BNB_NETWORK_USDT_DFORCE_STORAGE_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.DEV_BNB_USDT_DFORCE] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.DEV_BNB_USDT_DFORCE,
                    vault: STRATEGY_VAULT.BNB_V2,
                    title: 'USDT DForce',
                    extraTVL: {
                        deposited: 0,
                        borrowed: 0,
                    },
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.DEV_BNB_USDT_DFORCE] || [],
                },
                text: 'Deposit USDT to have high income with lowest risk possible',
                GAPrefix: GA_PREFIX.DEV_BNB_USDT_V2_DFORCE,
                addressesOfLogicContracts: { list: [] },
                supportsEIP1559: true,
            },
            {
                isActive: !!(process.env.VUE_APP_STORAGE_ADDRESS && process.env.VUE_APP_LOGIC_ADDRESS),
                id: STRATEGY_ID.BNB_STABLECOINS,
                vault: STRATEGY_VAULT.BNB,
                network: NETWORK_ID.BNB,
                title: 'stablecoins',
                route: STRATEGY_ROUTE.STABLECOINS_BNB,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: Storage.abi,
                    address: process.env.VUE_APP_STORAGE_ADDRESS || '',
                    graphAddress: process.env.VUE_APP_SUBGRAPH_API || '',
                    logicAddress: process.env.VUE_APP_LOGIC_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.BNB_STABLECOINS] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.BNB_STABLECOINS,
                    vault: STRATEGY_VAULT.BNB,
                    title: 'stablecoins',
                    subtitle: 'USDT, USDC, DAI',
                    extraTVL: {
                        deposited: 0,
                        borrowed: 0,
                    },
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.BNB_STABLECOINS] || [],
                },
                text: 'Deposit stablecoins (USDT, USDC, DAI) to have high income with lowest risk possible',
                crossChain: BRIDGE_TYPE.STARGATE,
                GAPrefix: GA_PREFIX.BNB_STABLECOINS,
                supportsEIP1559: true,
            },
            {
                isActive: !!(process.env.VUE_APP_LOW_RISK_BTC_STORAGE_ADDRESS &&
                    process.env.VUE_APP_LOW_RISK_BTC_LOGIC_ADDRESS),
                id: STRATEGY_ID.BNB_BTC,
                vault: STRATEGY_VAULT.BNB,
                network: NETWORK_ID.BNB,
                title: 'btc',
                route: STRATEGY_ROUTE.BTC_BNB,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: Storage.abi,
                    address: process.env.VUE_APP_LOW_RISK_BTC_STORAGE_ADDRESS || '',
                    graphAddress: process.env.VUE_APP_LOW_RISK_BTC_GRAPH_API || '',
                    logicAddress: process.env.VUE_APP_LOW_RISK_BTC_LOGIC_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.BNB_BTC] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.BNB_BTC,
                    vault: STRATEGY_VAULT.BNB,
                    title: 'btc',
                    extraTVL: {
                        deposited: 0,
                        borrowed: 0,
                    },
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.BNB_BTC] || [],
                },
                text: 'Deposit BTC to have high income with lowest risk possible',
                GAPrefix: GA_PREFIX.BNB_BTC,
                supportsEIP1559: true,
            },
            {
                isActive: !!(process.env.VUE_APP_LOW_RISK_ETH_STORAGE_ADDRESS &&
                    process.env.VUE_APP_LOW_RISK_ETH_LOGIC_ADDRESS),
                id: STRATEGY_ID.BNB_ETH,
                vault: STRATEGY_VAULT.BNB,
                network: NETWORK_ID.BNB,
                title: 'eth',
                route: STRATEGY_ROUTE.ETH_BNB,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: Storage.abi,
                    address: process.env.VUE_APP_LOW_RISK_ETH_STORAGE_ADDRESS || '',
                    graphAddress: process.env.VUE_APP_LOW_RISK_ETH_GRAPH_API || '',
                    logicAddress: process.env.VUE_APP_LOW_RISK_ETH_LOGIC_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.BNB_ETH] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.BNB_ETH,
                    vault: STRATEGY_VAULT.BNB,
                    title: 'eth',
                    extraTVL: {
                        deposited: 0,
                        borrowed: 0,
                    },
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.BNB_ETH] || [],
                },
                text: 'Deposit ETH to have high income with lowest risk possible',
                GAPrefix: GA_PREFIX.BNB_ETH,
                supportsEIP1559: true,
            },
            {
                isActive: !!(process.env.VUE_APP_ALTCOINS_STORAGE_ADDRESS && process.env.VUE_APP_ALTCOINS_LOGIC_ADDRESS),
                id: STRATEGY_ID.BNB_ALTCOINS,
                vault: STRATEGY_VAULT.BNB,
                network: NETWORK_ID.BNB,
                title: 'altcoins',
                route: STRATEGY_ROUTE.ALTCOINS_BNB,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: Storage.abi,
                    address: process.env.VUE_APP_ALTCOINS_STORAGE_ADDRESS || '',
                    graphAddress: process.env.VUE_APP_ALTCOINS_GRAPH_API || '',
                    logicAddress: process.env.VUE_APP_ALTCOINS_LOGIC_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.BNB_ALTCOINS] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.BNB_ALTCOINS,
                    vault: STRATEGY_VAULT.BNB,
                    title: 'altcoins',
                    subtitle: 'XRP, XVS, LTC, ADA, LINK, DOT, MATIC',
                    extraTVL: {
                        deposited: 0,
                        borrowed: 0,
                    },
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.BNB_ALTCOINS] || [],
                },
                text: 'Deposit altcoins (XRP, XVS, LTC, ADA, LINK, DOT, MATIC) to have high income with lowest risk possible',
                GAPrefix: GA_PREFIX.BNB_ALTCOINS,
                supportsEIP1559: true,
            },
            {
                isActive: !!process.env.VUE_APP_MASTER_BLID_ADDRESS,
                id: STRATEGY_ID.BNB_BLID,
                vault: STRATEGY_VAULT.BNB,
                network: NETWORK_ID.BNB,
                title: 'BLID',
                route: STRATEGY_ROUTE.BLID_BNB,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STAKING,
                    abi: Master.abi,
                    address: process.env.VUE_APP_MASTER_BLID_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.BNB_BLID] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.BNB_BLID,
                    vault: STRATEGY_VAULT.BNB,
                    title: 'BLID',
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.BNB_BLID] || [],
                },
                text: 'Deposit BLID tokens on staking to have high income',
                GAPrefix: GA_PREFIX.BNB_BLID,
                supportsEIP1559: true,
            },
            {
                isActive: !!process.env.VUE_APP_MASTER_BLID_ADDRESS,
                id: STRATEGY_ID.BNB_BLID_USDT,
                vault: STRATEGY_VAULT.BNB,
                network: NETWORK_ID.BNB,
                title: 'BLID-USDT',
                subtitle: '@Pancakeswap',
                route: STRATEGY_ROUTE.BLID_USDT_BNB,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.FARMING,
                    abi: Master.abi,
                    address: process.env.VUE_APP_MASTER_BLID_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.BNB_BLID_USDT] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.BNB_BLID_USDT,
                    vault: STRATEGY_VAULT.BNB,
                    title: 'BLID-USDT',
                    subtitle: '@Pancakeswap',
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.BNB_BLID_USDT] || [],
                },
                text: 'Become a liquidity provider on Pancakeswap and earn high yield on farming',
                GAPrefix: GA_PREFIX.BNB_BLID_USDT,
                supportsEIP1559: true,
            },
            {
                isActive: !!process.env.VUE_APP_USDT_POLYGON_STORAGE_ADDRESS,
                id: STRATEGY_ID.POLYGON_USDT,
                vault: STRATEGY_VAULT.POLYGON,
                network: NETWORK_ID.POLYGON,
                title: 'usdt',
                route: STRATEGY_ROUTE.USDT_POLYGON,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: StorageV2.abi,
                    address: process.env.VUE_APP_USDT_POLYGON_STORAGE_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.POLYGON_USDT] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.POLYGON_USDT,
                    vault: STRATEGY_VAULT.POLYGON,
                    title: 'usdt',
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.POLYGON_USDT] || [],
                },
                text: 'Deposit USDT to have high income with lowest risk possible',
                crossChain: undefined,
                supportsEIP1559: true,
                GAPrefix: GA_PREFIX.POLYGON_USDT,
                addressesOfLogicContracts: { list: [] },
            },
            {
                isActive: !!process.env.VUE_APP_DEV_POLYGON_STABLECOINS_STORAGE_ADDRESS &&
                    process.env.VUE_APP_IS_DEV_STORY === 'true',
                id: STRATEGY_ID.DEV_POLYGON_STABLECOINS,
                vault: STRATEGY_VAULT.POLYGON,
                network: NETWORK_ID.POLYGON,
                title: 'stablecoins',
                route: STRATEGY_ROUTE.DEV_POLYGON_STABLECOINS,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: StorageV2.abi,
                    address: process.env.VUE_APP_DEV_POLYGON_STABLECOINS_STORAGE_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.DEV_POLYGON_STABLECOINS] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.DEV_POLYGON_STABLECOINS,
                    vault: STRATEGY_VAULT.POLYGON,
                    title: 'stablecoins',
                    subtitle: 'USDC/MATIC/DAI/ETH',
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.DEV_POLYGON_STABLECOINS] || [],
                },
                text: 'Deposit USDC/MATIC/DAI/ETH to have high income with lowest risk possible',
                crossChain: undefined,
                supportsEIP1559: true,
                GAPrefix: GA_PREFIX.DEV_POLYGON_STABLECOINS,
                addressesOfLogicContracts: { list: [] },
            },
            {
                isActive: !!process.env.VUE_APP_POLYGON_NETWORK_USDC_STORAGE_ADDRESS,
                id: STRATEGY_ID.POLYGON_USDC,
                vault: STRATEGY_VAULT.POLYGON,
                network: NETWORK_ID.POLYGON,
                title: 'usdc',
                route: STRATEGY_ROUTE.POLYGON_USDC,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: StorageV2.abi,
                    address: process.env.VUE_APP_POLYGON_NETWORK_USDC_STORAGE_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.POLYGON_USDC] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.POLYGON_USDC,
                    vault: STRATEGY_VAULT.POLYGON,
                    title: 'usdc',
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.POLYGON_USDC] || [],
                },
                text: 'Deposit USDC to have high income with lowest risk possible',
                crossChain: undefined,
                supportsEIP1559: true,
                GAPrefix: GA_PREFIX.POLYGON_USDC,
                addressesOfLogicContracts: { list: [] },
            },
            {
                isActive: !!process.env.VUE_APP_POLYGON_NETWORK_MATIC_STORAGE_ADDRESS,
                id: STRATEGY_ID.POLYGON_MATIC,
                vault: STRATEGY_VAULT.POLYGON,
                network: NETWORK_ID.POLYGON,
                title: 'matic',
                route: STRATEGY_ROUTE.POLYGON_MATIC,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: StorageV2.abi,
                    address: process.env.VUE_APP_POLYGON_NETWORK_MATIC_STORAGE_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.POLYGON_MATIC] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.POLYGON_MATIC,
                    vault: STRATEGY_VAULT.POLYGON,
                    title: 'matic',
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.POLYGON_MATIC] || [],
                },
                text: 'Deposit MATIC to have high income with lowest risk possible',
                crossChain: undefined,
                supportsEIP1559: true,
                GAPrefix: GA_PREFIX.POLYGON_MATIC,
                addressesOfLogicContracts: { list: [] },
            },
            {
                isActive: !!process.env.VUE_APP_POLYGON_NETWORK_DAI_STORAGE_ADDRESS,
                id: STRATEGY_ID.POLYGON_DAI,
                vault: STRATEGY_VAULT.POLYGON,
                network: NETWORK_ID.POLYGON,
                title: 'dai',
                route: STRATEGY_ROUTE.POLYGON_DAI,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: StorageV2.abi,
                    address: process.env.VUE_APP_POLYGON_NETWORK_DAI_STORAGE_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.POLYGON_DAI] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.POLYGON_DAI,
                    vault: STRATEGY_VAULT.POLYGON,
                    title: 'dai',
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.POLYGON_DAI] || [],
                },
                text: 'Deposit DAI to have high income with lowest risk possible',
                crossChain: undefined,
                supportsEIP1559: true,
                GAPrefix: GA_PREFIX.POLYGON_DAI,
                addressesOfLogicContracts: { list: [] },
            },
            {
                isActive: !!process.env.VUE_APP_POLYGON_NETWORK_ETH_STORAGE_ADDRESS,
                id: STRATEGY_ID.POLYGON_ETH,
                vault: STRATEGY_VAULT.POLYGON,
                network: NETWORK_ID.POLYGON,
                title: 'eth',
                route: STRATEGY_ROUTE.POLYGON_ETH,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: StorageV2.abi,
                    address: process.env.VUE_APP_POLYGON_NETWORK_ETH_STORAGE_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.POLYGON_ETH] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.POLYGON_ETH,
                    vault: STRATEGY_VAULT.POLYGON,
                    title: 'eth',
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.POLYGON_ETH] || [],
                },
                text: 'Deposit ETH to have high income with lowest risk possible',
                crossChain: undefined,
                supportsEIP1559: true,
                GAPrefix: GA_PREFIX.POLYGON_ETH,
                addressesOfLogicContracts: { list: [] },
            },
            {
                isActive: !!process.env.VUE_APP_ARBITRUM_NETWORK_USDC_E_STORAGE_ADDRESS,
                id: STRATEGY_ID.ARBITRUM_USDC_E,
                vault: STRATEGY_VAULT.ARBITRUM,
                network: NETWORK_ID.ARBITRUM,
                title: 'usdc.e',
                route: STRATEGY_ROUTE.ARBITRUM_USDC_E,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: StorageV2.abi,
                    address: process.env.VUE_APP_ARBITRUM_NETWORK_USDC_E_STORAGE_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.ARBITRUM_USDC_E] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.ARBITRUM_USDC_E,
                    vault: STRATEGY_VAULT.ARBITRUM,
                    title: 'usdc.e',
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.ARBITRUM_USDC_E] || [],
                },
                text: 'Deposit USDC.E to have high income with lowest risk possible',
                crossChain: undefined,
                supportsEIP1559: true,
                GAPrefix: GA_PREFIX.ARB_USDC_E,
                addressesOfLogicContracts: { list: [] },
            },
            {
                isActive: !!process.env.VUE_APP_OPTIMISM_NETWORK_USDC_E_STORAGE_ADDRESS,
                id: STRATEGY_ID.OPTIMISM_USDC_E,
                vault: STRATEGY_VAULT.OPTIMISM,
                network: NETWORK_ID.OPTIMISM,
                title: 'usdc.e',
                route: STRATEGY_ROUTE.OPTIMISM_USDC_E,
                isDeposit: false,
                contract: {
                    type: CONTRACT_TYPE.STORAGE,
                    abi: StorageV2.abi,
                    address: process.env.VUE_APP_OPTIMISM_NETWORK_USDC_E_STORAGE_ADDRESS || '',
                },
                currencies: currenciesStore.listForStrategies[STRATEGY_ID.OPTIMISM_USDC_E] || [],
                earnedIncome: JSON.parse(JSON.stringify(earnedIncome)),
                horizon: {
                    ...JSON.parse(JSON.stringify(defaultHorizon)),
                    id: STRATEGY_ID.OPTIMISM_USDC_E,
                    vault: STRATEGY_VAULT.OPTIMISM,
                    title: 'usdc.e',
                    showBoostBtn: false,
                    listOfTokens: currenciesStore.tagsForStrategies[STRATEGY_ID.OPTIMISM_USDC_E] || [],
                },
                text: 'Deposit USDC.E to have high income with lowest risk possible',
                crossChain: undefined,
                supportsEIP1559: true,
                GAPrefix: GA_PREFIX.OP_USDC_E,
                addressesOfLogicContracts: { list: [] },
            },
        ];
        return {
            list,
            totalDeposit: {
                [STRATEGY_ID.BNB_STABLECOINS]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.BNB_ETH]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.BNB_BTC]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.BNB_BLID]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.BNB_BLID_USDT]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.BNB_ALTCOINS]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                // [STRATEGY_ID.ETH_STABLECOINS]: {
                //   depositAmountInUSD: 0,
                //   earnedBLID: 0,
                //   earnedUSD: 0,
                // },
                [STRATEGY_ID.POLYGON_USDT]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.POLYGON_USDC]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.POLYGON_MATIC]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.POLYGON_DAI]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.POLYGON_ETH]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.BNB_USDT_V2]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.BNB_BNB]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.ARBITRUM_USDC_E]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.DEV_BNB_USDT_DFORCE]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.DEV_POLYGON_STABLECOINS]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
                [STRATEGY_ID.OPTIMISM_USDC_E]: {
                    depositAmountInUSD: 0,
                    earnedBLID: 0,
                    earnedUSD: 0,
                },
            },
            timerUpdateEarnedBLID: null,
        };
    },
    actions: {
        ...commonActions,
        ...strategiesDepositActions,
        ...strategiesBoostingActions,
    },
    getters: {
        total(state) {
            return Object.values(state.totalDeposit).reduce((prev, item) => {
                if (item) {
                    prev.depositAmountInUSD += item.depositAmountInUSD;
                    prev.earnedBLID += item.earnedBLID;
                    prev.earnedUSD += item.earnedUSD;
                }
                return prev;
            }, { depositAmountInUSD: 0, earnedBLID: 0, earnedUSD: 0 });
        },
        listOfStrategiesWithDepositAndWithoutStorageContract() {
            return this.listOfActiveStrategies.filter((strategy) => strategy.contract &&
                strategy.contract.type !== CONTRACT_TYPE.STORAGE &&
                strategy.isDeposit);
        },
        listOfStorageStrategiesWithBoosting() {
            return this.listOfActiveStrategies.filter((strategy) => strategy.contract &&
                strategy.contract.type === CONTRACT_TYPE.STORAGE &&
                strategy.isDeposit &&
                strategy.horizon.defaultNums.userBoosting &&
                strategy.horizon.defaultNums.userBoosting.apr);
        },
        noDepositListGroupedByVault() {
            const walletStore = useWalletStore();
            return this.listOfActiveStrategies
                .filter((item) => !item.isDeposit && item.id !== STRATEGY_ID.BNB_BLID_USDT)
                .reduce((accumulator, strategy) => {
                const indexVault = accumulator.findIndex((item) => item.type === strategy.vault);
                if (indexVault >= 0) {
                    accumulator[indexVault].list.push(strategy);
                }
                else {
                    let mainVault = walletStore.network
                        ? strategy.vault === linker.getStrategyVault(walletStore.network.id)
                        : false;
                    if (walletStore.network && strategy.vault === STRATEGY_VAULT.BNB_V2) {
                        if (walletStore.network.id === NETWORK_ID.BNB ||
                            linker.getStrategyVault(walletStore.network.id) === STRATEGY_VAULT.BNB) {
                            mainVault = true;
                        }
                    }
                    accumulator.push({
                        mainVault,
                        type: strategy.vault,
                        list: [strategy],
                    });
                }
                return accumulator;
            }, [])
                .sort((a, b) => {
                return Number(b.mainVault) - Number(a.mainVault);
            });
        },
        listOfStrategiesWithDeposit() {
            return this.listOfActiveStrategies.filter((item) => item.isDeposit);
        },
        listOfHorizonStrategies(state) {
            return state.list.map((item) => item.horizon);
        },
        listOfActiveStrategies(state) {
            return state.list.filter((item) => !item.horizon.isComingSoon && item.isActive);
        },
        getCurrentStrategy() {
            return (props) => {
                if ('id' in props && props.id) {
                    return this.listOfActiveStrategies.find((strategy) => strategy.id === props.id);
                }
                else if ('route' in props && props.route) {
                    return this.listOfActiveStrategies.find((strategy) => strategy.route === props.route);
                }
                else {
                    return undefined;
                }
            };
        },
        getBaseStrategyConfig() {
            return (route) => {
                const strategy = this.getCurrentStrategy({ route });
                return strategy
                    ? {
                        isActive: strategy.isActive,
                        id: strategy.id,
                        title: strategy.title,
                        subtitle: strategy.subtitle || '',
                        route: strategy.route,
                        currencies: strategy.currencies,
                        isDeposit: strategy.isDeposit,
                        earnedIncome: strategy.earnedIncome,
                        contract: strategy.contract,
                        vault: strategy.vault,
                        network: strategy.network,
                    }
                    : undefined;
            };
        },
        getContractConfig() {
            return (strategyId) => {
                const strategy = this.list.find((strategy) => strategy.id === strategyId);
                return strategy ? strategy.contract : undefined;
            };
        },
    },
});
