import { useWalletStore } from '@/web3-server/wallet';
import { NETWORK_SWITCH_STATUSED, TYPES_OF_WALLET_PROVIDERS, } from '@/web3-server/wallet/types/provider/constants';
import { useDialogStore } from '@/store/dialog';
import { myDialog } from '@/store/my-dialog';
import infra from '@/infra';
import linker from '@/web3-server/utils/linker';
import { CONNECTION_TYPE } from '@/infra/types/wallets/constants';
import { devError } from '@/utils/devError';
import { walletNetworkByWalletId } from '@/web3-server/wallet/data/network';
import { useStrategiesStore } from '@/store/perfect-strategies';
import store from '@/store';
import { unsubscribeFromWalletEvents } from '@/store/wallet/wallet-events';
import GAManager from '@/utils/ga-manager/ga-manager';
const myWallet = {
    connect: {
        toAccount(props) {
            const { connectionType, connectOnlyTo, requiredNetwork } = props;
            const walletStore = useWalletStore();
            const GAPrefix = props.GAPrefix || 'auth_';
            if (!props.GAPrefix) {
                GAManager.sendEvent(GAPrefix + 'start');
            }
            if (walletStore.isAutoAuth()) {
                walletStore.autoAuthToggle(false);
            }
            return walletStore
                .connectToAccount({
                connectionType,
                connectOnlyTo,
                networkId: requiredNetwork,
            })
                .then((response) => {
                if (response && Array.isArray(response) && response.length) {
                    setAuthEventForGA(connectionType, getChainId(requiredNetwork), GAPrefix, response);
                }
                else {
                    GAManager.sendEvent(GAPrefix + 'error');
                }
                return response;
            })
                .catch((e) => {
                GAManager.sendEvent(GAPrefix + 'error');
                throw e;
            });
        },
        walletConnect(requiredNetwork, GAPrefix) {
            return useWalletStore()
                .connectWC({ networkId: requiredNetwork })
                .then((response) => {
                if (response &&
                    response.accounts &&
                    Array.isArray(response.accounts) &&
                    response.accounts.length) {
                    setAuthEventForGA(CONNECTION_TYPE.WALLET_CONNECT, linker.getChainId(requiredNetwork).int, GAPrefix, response.accounts);
                }
                else {
                    GAManager.sendEvent(GAPrefix + 'error');
                }
                return response;
            })
                .catch((e) => {
                GAManager.sendEvent(GAPrefix + 'error');
                throw e;
            });
        },
    },
    reLogin: {
        walletConnect(requiredNetwork) {
            const walletStore = useWalletStore();
            const GAPrefix = 're_login_';
            const connect = () => {
                myWallet.connect
                    .walletConnect(requiredNetwork, GAPrefix)
                    .then((response) => {
                    if (response) {
                        const dialogStore = useDialogStore();
                        infra.wallet.connect();
                        dialogStore.goBack(1, { skipConfirmTransaction: true });
                    }
                    else if (!walletStore.isAuth) {
                        myWallet.disconnect();
                    }
                })
                    .catch((e) => {
                    if (!walletStore.isAuth) {
                        myWallet.disconnect();
                    }
                    throw e;
                });
            };
            try {
                if (walletStore.isWalletConnect()) {
                    walletStore.removeWCProvider();
                    setTimeout(async () => {
                        connect();
                    }, 500);
                }
                else {
                    connect();
                }
            }
            catch (e) {
                devError(e);
                GAManager.sendEvent(GAPrefix + 'error');
            }
        },
    },
    switchNetwork(requiredNetwork, stepBack = 1) {
        const walletStore = useWalletStore();
        const dialogStore = useDialogStore();
        const provider = walletStore.provider;
        return walletStore.switchNetwork(requiredNetwork).then((status) => {
            if (status === NETWORK_SWITCH_STATUSED.RE_LOGIN_VIA_WC) {
                myDialog.openReLoginWC(linker.getNetworkId(requiredNetwork), () => {
                    if (walletStore.isAuth) {
                        if (stepBack === 0) {
                            dialogStore.close();
                        }
                        else {
                            dialogStore.goBack(stepBack);
                        }
                    }
                    else {
                        myDialog.goHome();
                    }
                });
            }
            else if (status === NETWORK_SWITCH_STATUSED.ERROR) {
                myDialog.openNotifyAboutTransaction({ isSuccess: false, subtitle: 'when switching a network' }, { stepBack });
            }
            else if (provider &&
                provider.type === TYPES_OF_WALLET_PROVIDERS.BLOCTO &&
                provider.ethereum) {
                infra.wallet.connect();
            }
            return status;
        });
    },
    disconnect(disableAutoLogin) {
        const strategiesStore = useStrategiesStore();
        const walletStore = useWalletStore();
        store.commit('wallet/setUser', '');
        store.commit('wallet/setConnectionType', null);
        store.commit('wallet/setTransaction', []);
        if (strategiesStore.timerUpdateEarnedBLID) {
            clearInterval(strategiesStore.timerUpdateEarnedBLID);
            strategiesStore.timerUpdateEarnedBLID = null;
        }
        unsubscribeFromWalletEvents();
        strategiesStore.cleanStrategies();
        walletStore.disconnect(disableAutoLogin);
    },
};
export default myWallet;
function setAuthEventForGA(connectionType, chainId, GAPrefix, response) {
    const GAValue = {
        walletType: connectionType,
        chainId,
    };
    if (process.env.VUE_APP_USER_DEBUG &&
        process.env.VUE_APP_USER_DEBUG === 'true' &&
        response &&
        Array.isArray(response) &&
        response.length) {
        GAValue.uid = 'w' + response[0];
    }
    GAManager.sendEvent(GAPrefix + 'success', {
        valueWithParams: GAValue,
    });
}
function getChainId(networkId) {
    const walletStore = useWalletStore();
    if (networkId) {
        return linker.getChainId(networkId).int;
    }
    else if (walletStore.network) {
        return walletStore.network.chainId.int;
    }
    else {
        return walletNetworkByWalletId.STARGATE_BNB.chainId.int;
    }
}
