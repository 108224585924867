import { SWAP_SITE_ID, SWAP_SITE_TYPE } from '@/web3-server/wallet/types/swap/constants';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import quickSwap from '@/assets/images/dex/quickswap.svg';
import xyFinance from '@/assets/images/dex/xy-finance.svg';
import uniswap from '@/assets/images/svg/uniswap.svg';
import pancakeswap from '@/assets/images/svg/pancakeswap.svg';
import lbank from '@/assets/images/svg/lbank.svg';
const allChain = [
    {
        id: SWAP_SITE_ID.ALL_XY_FINANCE,
        type: SWAP_SITE_TYPE.XY_FINANCE,
        link: 'https://app.xy.finance/?amount=1000&fromTokenAddress=0x55d398326f99059ff775485246999027b3197955&sourceChainId=56&targetChainId=56&toTokenAddress=0x766afcf83fd5eaf884b3d529b432ca27a6d84617',
        network: 'all chains',
        price: '',
        icon: xyFinance,
        linkConstructor: {
            link: 'https://app.xy.finance/?amount=1000',
            tokenAddress: {
                from: 'fromTokenAddress',
                to: 'toTokenAddress',
            },
            chainId: {
                from: 'sourceChainId',
                to: 'targetChainId',
            },
        },
    },
];
const polygon = [
    {
        id: SWAP_SITE_ID.POLYGON_QUICK_SWAP,
        type: SWAP_SITE_TYPE.QUICK_SWAP,
        link: 'https://quickswap.exchange/#/swap?swapIndex=0&currency0=&currency1=0x4b27Cd6E6a5E83d236eAD376D256Fe2F9e9f0d2E',
        network: NETWORK_ID.POLYGON,
        price: '',
        icon: quickSwap,
    },
];
const eth = [
    {
        id: SWAP_SITE_ID.ETH_LBANK,
        type: SWAP_SITE_TYPE.LBANK,
        link: 'https://www.lbank.info/exchange/blid/usdt/#usd',
        price: '',
        icon: lbank,
    },
    {
        id: SWAP_SITE_ID.ETH_UNISWAP,
        type: SWAP_SITE_TYPE.UNISWAP,
        link: 'https://app.uniswap.org/#/swap?inputCurrency=0x8A7aDc1B690E81c758F1BD0F72DFe27Ae6eC56A5&chain=mainnet',
        network: NETWORK_ID.ETH,
        price: '',
        icon: uniswap,
    },
];
const bnb = [
    // {
    //   id: SWAP_SITE_ID.BNB_UNISWAP,
    //   type: SWAP_SITE_TYPE.UNISWAP,
    //   link: 'https://app.uniswap.org/#/swap?inputCurrency=0x766afcf83fd5eaf884b3d529b432ca27a6d84617&chain=mainnet',
    //   network: NETWORK_ID.BNB,
    //   price: '',
    //   icon: uniswap,
    // },
    {
        id: SWAP_SITE_ID.BNB_PANCAKESWAP,
        type: SWAP_SITE_TYPE.PANCAKESWAP,
        link: 'https://pancakeswap.finance/swap?outputCurrency=0x766afcf83fd5eaf884b3d529b432ca27a6d84617',
        network: NETWORK_ID.BNB,
        price: '',
        icon: pancakeswap,
    },
];
const listOfSwapSites = allChain.concat(bnb, polygon, eth);
export default listOfSwapSites;
