import { useWeb3Store } from '@/web3-server';
import { LAYER_ZERO_LAB_STATUS } from '@/web3-server/web3/types/cross-chain/constants';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export default function checkTransactionStatus(hash, timeLimitMs = 2400000, timeInterval = 15000) {
    const web3Store = useWeb3Store();
    return new Promise((resolve, reject) => {
        const startAt = new Date();
        const check = async () => {
            try {
                const status = await web3Store.getTransactionStatus(hash);
                if (status === LAYER_ZERO_LAB_STATUS.DELIVERED) {
                    clearInterval(intervalId);
                    resolve(LAYER_ZERO_LAB_STATUS.DELIVERED);
                }
                else if (status === LAYER_ZERO_LAB_STATUS.FAILED) {
                    clearInterval(intervalId);
                    reject(LAYER_ZERO_LAB_STATUS.FAILED);
                }
                const now = new Date();
                if (now.getTime() - startAt.getTime() > timeLimitMs) {
                    clearInterval(intervalId);
                    reject(DEV_ERROR_CODE.CONFIRM_TRANSACTION_TIME);
                }
            }
            catch (error) {
                clearInterval(intervalId);
                reject(error);
            }
        };
        const intervalId = setInterval(() => {
            check();
        }, timeInterval);
        check();
    });
}
