import CrossChainDepositor from '@/infra/abis/CrossChainDepositor.json';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import Web3 from 'web3';
import linker from '@/web3-server/utils/linker';
import getDstGasForCallFromContract from '@/web3-server/web3/actions/cross-chain/get-dst-gas-for-call-from-contract';
export default async function getDepositGasFee(rpcUrl, contractAddress, chainId, dstGasForCall) {
    try {
        const web = new Web3(rpcUrl);
        const contract = new web.eth.Contract(CrossChainDepositor, contractAddress);
        const dstGasForCallFromContract = (await getDstGasForCallFromContract(contract)) || dstGasForCall;
        const gas = contract.methods
            .getDepositFeeStargate(chainId, dstGasForCallFromContract)
            .call();
        return gas
            .then((val) => {
            return linker.decimal.getWei(val);
        })
            .catch(() => {
            devError(DEV_ERROR_CODE.WEB_CONTRACT);
            return null;
        });
    }
    catch (e) {
        devError(DEV_ERROR_CODE.WEB_CONTRACT);
        return null;
    }
}
