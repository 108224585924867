import { sendEvent } from '@/web3-server/web3/handler/sendEvent';
import { PID } from '@/infra/types/contracts/constants';
import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
import converter from '@/web3-server/utils/converter';
import { getSendOptions } from '@/web3-server/web3/handler/get-send-options';
export default async function withdraw(request, strategyContract, currency, amount, supportEIP1559) {
    const { web3 } = request;
    const { type: contractType } = strategyContract;
    const amountUnit = converter.withoutType
        .toWei(amount, {
        decimals: currency.decimals,
        round: true,
    })
        .toFixed();
    const contract = new web3.eth.Contract(strategyContract.abi, strategyContract.address);
    const getContractMethod = () => {
        if (contractType === CONTRACT_TYPE.STORAGE) {
            return contract.methods.withdraw(amountUnit, currency.burnAddress ? currency.burnAddress : currency.address);
        }
        else if (contractType === CONTRACT_TYPE.STAKING) {
            return contract.methods.leaveStaking(amountUnit);
        }
        else if (contractType === CONTRACT_TYPE.FARMING) {
            return contract.methods.withdraw(PID.FAMING_BLID_USDT, amountUnit);
        }
    };
    const sendOptions = await getSendOptions(request, {
        supportEIP1559,
    });
    sendEvent(request, getContractMethod().send(sendOptions));
}
