import getListOfCurrencyTags from '@/web3-server/web3-currencies/actions/composables/get-list-of-currency-tags';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import getAllCurrencies from '@/web3-server/web3-currencies/actions/composables/get-all-currencies';
import { useWeb3StrategiesStore } from '@/web3-server/web3-strategies';
import linker from '@/web3-server/utils/linker';
import { useWalletStore } from '@/web3-server/wallet';
import { getAvailableWeb3ByNetworkId, readyChecks } from '@/web3-server/web3/handler/inspectors';
import { getBasicInfo } from '@/web3-server/web3-currencies/actions/get-basic-info';
import { getSimpleBasicInfo } from '@/web3-server/web3-currencies/actions/get-simple-basic-info';
import { useWeb3Store } from '@/web3-server';
function updateCurrencyFromList(list, updatedList) {
    updatedList.forEach((updatedCurrency) => {
        const duplicateIndex = list.findIndex((item) => item.currencyAddress === updatedCurrency.currencyAddress &&
            item.contractAddress === updatedCurrency.contractAddress);
        if (duplicateIndex >= 0) {
            list.splice(duplicateIndex, 1);
        }
        list.push(updatedCurrency);
    });
}
export const web3CurrenciesActions = {
    getCurrency(currency) {
        let foundCurrency = undefined;
        if (currency) {
            if ('id' in currency && currency.id) {
                foundCurrency = this.getById(currency.id);
            }
            else if ('address' in currency && currency.address) {
                foundCurrency = this.list.find((item) => item.address.toLowerCase() === currency.address.toLowerCase());
            }
        }
        return foundCurrency;
    },
    getCurrencyType(currency) {
        let currencyType = undefined;
        if (currency) {
            if ('type' in currency) {
                currencyType = currency.type;
            }
            else {
                currencyType = this.getCurrency(currency)?.symbol;
            }
        }
        return currencyType;
    },
    async getApprovedAndBalance(listOfIds, listOfContractAddress, currentNetwork) {
        const walletStore = useWalletStore();
        const web3Store = useWeb3Store();
        const currencies = [];
        const listOfWeb = currentNetwork
            ? [{ networkId: currentNetwork, value: web3Store.web3ByNetwork[currentNetwork] }]
            : getAvailableWeb3ByNetworkId();
        listOfIds.forEach((currencyId) => {
            const currency = this.getById(currencyId);
            if (currency) {
                currencies.push(currency);
            }
        });
        if (walletStore.userAddress &&
            listOfWeb.length &&
            listOfIds.length &&
            listOfContractAddress.length) {
            return getSimpleBasicInfo(listOfWeb, walletStore.userAddress, currencies, listOfContractAddress, true);
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR, {
                userAddress: walletStore.userAddress,
                listOfWeb,
                listOfIds,
                listOfContractAddress,
            });
            return null;
        }
    },
    getBasicInfoById(id, strategyAddress) {
        const currency = this.getById(id);
        const deposited = this.getDepositedById(currency?.address ? { currencyAddress: currency.address } : { id }, strategyAddress);
        const approved = this.getApprovalById(currency?.address ? { currencyAddress: currency.address } : { id }, strategyAddress);
        return currency && currency.state.balance
            ? {
                deposited,
                approved,
                balance: currency.state.balance,
            }
            : undefined;
    },
    getDepositedById(byCurrency, strategyAddress) {
        const currency = byCurrency && 'id' in byCurrency && byCurrency.id ? this.getById(byCurrency.id) : undefined;
        const currencyAddress = byCurrency && 'currencyAddress' in byCurrency && byCurrency.currencyAddress
            ? byCurrency.currencyAddress
            : currency?.address;
        return this.depositedCurrenciesUnderContracts.find((item) => item.currencyAddress === currencyAddress && item.contractAddress === strategyAddress);
    },
    getApprovalById(byCurrency, strategyAddress) {
        const currency = byCurrency && 'id' in byCurrency && byCurrency.id ? this.getById(byCurrency.id) : undefined;
        const currencyAddress = byCurrency && 'currencyAddress' in byCurrency && byCurrency.currencyAddress
            ? byCurrency.currencyAddress
            : currency?.address;
        return this.approvedCurrenciesUnderContracts.find((item) => item.currencyAddress === currencyAddress && item.contractAddress === strategyAddress);
    },
    getBalanceById(id) {
        const currency = this.getById(id);
        return currency && currency.state.balance ? currency.state.balance : undefined;
    },
    async updateAll() {
        const walletStore = useWalletStore();
        const web3StrategiesStore = useWeb3StrategiesStore();
        const userWalletAddress = walletStore.userAddress || '0x266110c9d12b3608F62DC58B542cfcb9Ab872DD5';
        const listOfWeb3 = getAvailableWeb3ByNetworkId();
        const strategies = web3StrategiesStore.list.filter((strategy) => readyChecks.strategy.isContract(strategy) && strategy.listOfCurrencyId?.length);
        const currencies = this.list.filter((currency) => currency.burnAddress ? true : readyChecks.currency.isAddress(currency));
        const nativeCurrencies = this.list.filter((currency) => currency.burnAddress);
        if (nativeCurrencies.length) {
            nativeCurrencies.forEach((nativeCurrency) => {
                this.getNativeBalance(nativeCurrency.id).then((balance) => {
                    nativeCurrency.state.balance = balance;
                });
            });
        }
        if (userWalletAddress && strategies.length && listOfWeb3.length && currencies.length) {
            await getBasicInfo(listOfWeb3, userWalletAddress, currencies, strategies, true).then((response) => {
                updateCurrencyFromList(this.approvedCurrenciesUnderContracts, response.approved);
                updateCurrencyFromList(this.depositedCurrenciesUnderContracts, response.deposited);
                response.balances.forEach((currencyWithBalance) => {
                    const currency = currencies.find((item) => item.id === currencyWithBalance.currencyId);
                    if (currency) {
                        currency.state.balance = currencyWithBalance.balance;
                    }
                });
            });
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR, {
                userWalletAddress,
                strategies: strategies.length,
                listOfWeb3: listOfWeb3.length,
                currencies: currencies.length,
            });
        }
    },
    updateCurrenciesFromStrategy(strategy) {
        const walletStore = useWalletStore();
        const userWalletAddress = walletStore.userAddress;
        const listOfWeb3 = getAvailableWeb3ByNetworkId();
        const provenStrategies = [strategy].filter((item) => readyChecks.strategy.isContract(item) && item.listOfCurrencyId?.length);
        const currencies = provenStrategies
            .reduce((listOfCurrencies, item) => {
            item.listOfCurrencyId.forEach((currencyId) => {
                const currency = this.list.find((item) => item.id === currencyId);
                if (currency) {
                    const duplicateIndex = listOfCurrencies.findIndex((item) => item.id === currencyId);
                    if (duplicateIndex < 0) {
                        listOfCurrencies.push(currency);
                    }
                }
            });
            if (item.crossChain && item.crossChain.availableCurrencies?.length) {
                item.crossChain.availableCurrencies.forEach((currencyId) => {
                    const currency = this.list.find((item) => item.id === currencyId);
                    if (currency) {
                        const duplicateIndex = listOfCurrencies.findIndex((item) => item.id === currencyId);
                        if (duplicateIndex < 0) {
                            listOfCurrencies.push(currency);
                        }
                    }
                });
            }
            return listOfCurrencies;
        }, [])
            .filter((currency) => currency.burnAddress ? true : readyChecks.currency.isAddress(currency));
        const nativeCurrencies = currencies.filter((currency) => currency.burnAddress);
        if (nativeCurrencies.length) {
            nativeCurrencies.forEach((nativeCurrency) => {
                this.getNativeBalance(nativeCurrency.id).then((balance) => {
                    nativeCurrency.state.balance = balance;
                });
            });
        }
        if (userWalletAddress && provenStrategies.length && listOfWeb3.length && currencies.length) {
            getBasicInfo(listOfWeb3, userWalletAddress, currencies, provenStrategies, false).then((response) => {
                updateCurrencyFromList(this.approvedCurrenciesUnderContracts, response.approved);
                updateCurrencyFromList(this.depositedCurrenciesUnderContracts, response.deposited);
            });
        }
        else {
            devError(DEV_ERROR_CODE.WEB_STORAGE_NO_VAR, {
                userWalletAddress,
                strategies: provenStrategies.length,
                listOfWeb3: listOfWeb3.length,
                currencies: currencies.length,
            });
        }
    },
    getWholeList() {
        const web3Strategies = useWeb3StrategiesStore();
        return getAllCurrencies(web3Strategies.list);
    },
    getListOfTags(listOfCurrencies) {
        const web3Strategies = useWeb3StrategiesStore();
        const walletStore = useWalletStore();
        const listOfStrategies = web3Strategies.list;
        const networkVault = walletStore.network
            ? linker.getStrategyVault(walletStore.network.id)
            : undefined;
        if (listOfCurrencies && listOfStrategies) {
            return getListOfCurrencyTags(listOfCurrencies, listOfStrategies, networkVault);
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR);
            return [];
        }
    },
};
