export default function updateCrossCurrency(web3Store, strategyContractAddress, currency, requestVia) {
    const web3Currency = web3Store.getCrossCurrency(strategyContractAddress, {
        address: currency.address,
        decimals: currency.decimals,
        burnAddress: currency.burnAddress,
    }, requestVia);
    if (web3Currency) {
        web3Currency.availableBalancePromise.then((availableBalance) => {
            currency.balance = availableBalance.toFixed();
        });
        web3Currency.approvedPromise.then((approved) => {
            currency.approved = approved;
        });
    }
    return web3Currency;
}
