import { useToast } from 'vue-toastification';
import toastPending from '@/components/toast-pending/toast-pending.vue';
import { getMethods } from '@/store/notify/composables/get-methods';
import { NOTIFICATION_TYPES, TYPES_OF_SAVED_OPERATAIONS, } from '@/infra/types/saved-operations/constants';
import { DateTime } from 'luxon';
import defaultNetwork from '@/web3-server/wallet/data/cross-chain/default-network';
import getOperations from '@/store/operations/operations';
import getCrossChainMethodsForNotify from '@/store/notify/composables/cross-chain';
import { useWalletStore } from '@/web3-server/wallet';
import { ref } from 'vue';
const notifyStorage = {
    getLocaleStorageKey() {
        const walletStore = useWalletStore();
        const LOCALE_STORAGE_KEY = 'operation';
        return `${LOCALE_STORAGE_KEY}_${walletStore.userAddress}`;
    },
    get() {
        return JSON.parse(localStorage.getItem(notifyStorage.getLocaleStorageKey()) || '[]');
    },
    set(updatedListOfNotify) {
        localStorage.setItem(notifyStorage.getLocaleStorageKey(), JSON.stringify(updatedListOfNotify));
    },
    remove: {
        byIndex: {
            withList(listOfNotify, index) {
                listOfNotify.splice(index, 1);
                notifyStorage.set(listOfNotify);
            },
            withoutList(index) {
                const listOfNotify = notifyStorage.get();
                notifyStorage.remove.byIndex.withList(listOfNotify, index);
            },
        },
        byHash(hash) {
            const listOfNotify = notifyStorage.get();
            const index = listOfNotify.findIndex((item) => item.hash === hash);
            if (index >= 0) {
                notifyStorage.remove.byIndex.withList(listOfNotify, index);
            }
        },
        byToastId(toastId) {
            const listOfNotify = notifyStorage.get();
            const index = listOfNotify.findIndex((item) => item.toastId !== null && item.toastId !== undefined && item.toastId === toastId);
            if (index >= 0) {
                notifyStorage.remove.byIndex.withList(listOfNotify, index);
            }
        },
    },
};
const notifyStack = {
    config: {
        maxStorageHistory: 20,
        minStorageHistory: 10,
    },
    storage: ref([]),
    history: ref([]),
    idExists(id) {
        return id !== undefined && id !== null;
    },
    isInStorage: {
        byId(id) {
            return !!notifyStack.storage.value.find((item) => item.id === id);
        },
        byType(props) {
            return !!notifyStack.storage.value.find((item) => item.id === props.id && item.type === props.type);
        },
        byTypeAndHistory(props) {
            return notifyStack.isInStorage.byType(props) || notifyStack.isInHistory(props);
        },
    },
    isInHistory(props) {
        return !!notifyStack.history.value.find((item) => item.id === props.id && item.type === props.type);
    },
    add(props) {
        if (!notifyStack.isInStorage.byId(props.id)) {
            if (notifyStack.storage.value.length === notifyStack.config.maxStorageHistory) {
                notifyStack.storage.value.splice(0, notifyStack.config.minStorageHistory);
            }
            notifyStack.storage.value.push(props);
        }
        if (notifyStack.history.value.length === notifyStack.config.maxStorageHistory) {
            notifyStack.history.value.splice(0, notifyStack.config.minStorageHistory);
        }
        notifyStack.history.value.push(props);
    },
    remove(id) {
        const index = notifyStack.storage.value.findIndex((item) => item.id === id);
        if (index >= 0) {
            notifyStack.storage.value.splice(index, 1);
        }
    },
};
const notify = {
    show: {
        pending(props, id) {
            const toast = useToast();
            const notifyId = toast({ component: toastPending, props }, notifyStack.idExists(id) ? { id } : undefined);
            notifyStack.add({ id: notifyId, type: NOTIFICATION_TYPES.PENDING });
            return notifyId;
        },
        pendingWithSave(props) {
            const notifyId = Date.now();
            const toastId = notify.show.pending({ text: props.text, hash: props.hash, link: props.link }, notifyId);
            props.toastId = toastId;
            notify.actions.save(props);
            return toastId;
        },
        success: (id) => {
            if (id &&
                notifyStack.isInStorage.byTypeAndHistory({ id, type: NOTIFICATION_TYPES.SUCCESS })) {
                return id;
            }
            else {
                const toast = useToast();
                const notifyId = toast.success('Transaction complete', notifyStack.idExists(id) ? { id } : undefined);
                notifyStack.add({ id: notifyId, type: NOTIFICATION_TYPES.SUCCESS });
                return notifyId;
            }
        },
        error: (id) => {
            if (id && notifyStack.isInStorage.byTypeAndHistory({ id, type: NOTIFICATION_TYPES.ERROR })) {
                return id;
            }
            else {
                const toast = useToast();
                const notifyId = toast.error('Something went wrong', notifyStack.idExists(id) ? { id } : undefined);
                notifyStack.add({ id: notifyId, type: NOTIFICATION_TYPES.ERROR });
                return notifyId;
            }
        },
    },
    hide: {
        withMemoryErasure(toastId, hash) {
            notify.hide.withoutMemoryErasure(toastId);
            notify.actions.remove({ toastId, hash });
        },
        withoutMemoryErasure(toastId) {
            const toast = useToast();
            if (toastId !== undefined && toastId !== null) {
                toast.dismiss(toastId);
                notifyStack.remove(toastId);
            }
        },
    },
    actions: {
        remove(props) {
            const { hash, toastId } = props;
            if (hash) {
                notifyStorage.remove.byHash(hash);
            }
            else if (toastId !== undefined && toastId !== null) {
                notifyStorage.remove.byToastId(toastId);
            }
        },
        save(props) {
            const listOfNotify = notifyStorage.get();
            const notifyItem = {
                type: props.type || TYPES_OF_SAVED_OPERATAIONS.OTHER,
                createdAt: DateTime.now().toISO(),
                text: props.text || 'Pending transaction',
                rpcUrl: props.rpcUrl || defaultNetwork.BNB.rpcUrl,
                hash: props.hash,
                toastId: props.toastId || Date.now(),
                detailed: props.detailed,
            };
            if (props.link || props.hash) {
                notifyItem.link = props.link || defaultNetwork.BNB.blockExplorerUrl + 'tx/' + props.hash;
            }
            if (props.hash) {
                const duplicateIndex = listOfNotify.findIndex((item) => item.hash === notifyItem.hash);
                if (duplicateIndex >= 0) {
                    listOfNotify.splice(duplicateIndex, 1, notifyItem);
                }
                else {
                    listOfNotify.push(notifyItem);
                }
            }
            else {
                listOfNotify.push(notifyItem);
            }
            notifyStorage.set(listOfNotify);
        },
        init() {
            const listOfNotify = notifyStorage.get();
            if (listOfNotify.length) {
                const { timeCheck, wait } = getMethods();
                listOfNotify.forEach((item, index) => {
                    const clear = () => notifyStorage.remove.byIndex.withList(listOfNotify, index);
                    if (timeCheck(item.createdAt) && item.detailed) {
                        const operations = getOperations(item.detailed.default);
                        const crossChainForNotify = item.detailed.network
                            ? getCrossChainMethodsForNotify(operations.crossChain(item.detailed.network))
                            : null;
                        const toastId = notifyStack.isInStorage.byId(item.toastId) ||
                            notifyStack.isInHistory({ id: item.toastId, type: NOTIFICATION_TYPES.PENDING })
                            ? item.toastId
                            : notify.show.pending({
                                text: item.text,
                                link: item.link,
                                hash: item.hash,
                            }, item.toastId);
                        const notifyProps = {
                            toastId,
                            hash: item.hash,
                        };
                        if (item.type === TYPES_OF_SAVED_OPERATAIONS.CROSSCHAIN_BNB &&
                            crossChainForNotify &&
                            item.hash) {
                            crossChainForNotify.toBNB(item.hash, item.toastId);
                        }
                        else if (item.hash && item.rpcUrl) {
                            wait(item.rpcUrl, item.hash)
                                .then(() => {
                                if (item.type === TYPES_OF_SAVED_OPERATAIONS.CROSSCHAIN_MY_NETWORK) {
                                    if (crossChainForNotify) {
                                        crossChainForNotify.myNetwork.onSuccess(item.hash).finally(() => {
                                            notifyStorage.remove.byToastId(item.toastId);
                                        });
                                    }
                                }
                                else if (item.type === TYPES_OF_SAVED_OPERATAIONS.APPROVE &&
                                    item.detailed?.paramsForTokenApproval) {
                                    operations
                                        .approve(item.detailed.paramsForTokenApproval)
                                        .transactionCompleted.onSuccess(notifyProps);
                                }
                                else {
                                    operations.default.transactionCompleted.onSuccess(notifyProps);
                                }
                            })
                                .catch(() => {
                                if (item.type === TYPES_OF_SAVED_OPERATAIONS.CROSSCHAIN_MY_NETWORK) {
                                    if (crossChainForNotify) {
                                        crossChainForNotify.myNetwork.onError(notifyProps);
                                    }
                                }
                                else if (item.type === TYPES_OF_SAVED_OPERATAIONS.APPROVE &&
                                    item.detailed?.paramsForTokenApproval) {
                                    operations
                                        .approve(item.detailed.paramsForTokenApproval)
                                        .transactionCompleted.onError(notifyProps);
                                }
                                else {
                                    operations.default.transactionCompleted.onError(notifyProps);
                                }
                            })
                                .finally(() => {
                                notify.hide.withoutMemoryErasure(toastId);
                            });
                        }
                        else {
                            clear();
                            notify.hide.withoutMemoryErasure(toastId);
                        }
                    }
                    else {
                        clear();
                    }
                });
            }
        },
    },
};
export default notify;
