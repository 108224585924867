export var BRIDGE_TYPE;
(function (BRIDGE_TYPE) {
    BRIDGE_TYPE["STARGATE"] = "STARGATE";
})(BRIDGE_TYPE || (BRIDGE_TYPE = {}));
export var LAYER_ZERO_LAB_STATUS;
(function (LAYER_ZERO_LAB_STATUS) {
    LAYER_ZERO_LAB_STATUS["INFLIGHT"] = "INFLIGHT";
    LAYER_ZERO_LAB_STATUS["DELIVERED"] = "DELIVERED";
    LAYER_ZERO_LAB_STATUS["FAILED"] = "FAILED";
})(LAYER_ZERO_LAB_STATUS || (LAYER_ZERO_LAB_STATUS = {}));
