import ema from './moving-averages';
import { gweiToWei, weiToGweiNumber } from './utils';
import Decimal from 'decimal.js';
// samplingCurve is a helper function for the base fee percentile range calculation.
const samplingCurve = (sumWeight, sampleMin, sampleMax) => {
    if (sumWeight <= sampleMin) {
        return 0;
    }
    if (sumWeight >= sampleMax) {
        return 1;
    }
    return (1 - Math.cos(((sumWeight - sampleMin) * 2 * Math.PI) / (sampleMax - sampleMin))) / 2;
};
const suggestBaseFee = (baseFee, order, timeFactor, sampleMin, sampleMax) => {
    if (timeFactor < 1e-6) {
        return baseFee[baseFee.length - 1];
    }
    const pendingWeight = (1 - Math.exp(-1 / timeFactor)) / (1 - Math.exp(-baseFee.length / timeFactor));
    let sumWeight = 0;
    let result = 0;
    let samplingCurveLast = 0;
    for (const or of order) {
        sumWeight += pendingWeight * Math.exp((or - baseFee.length + 1) / timeFactor);
        const samplingCurveValue = samplingCurve(sumWeight, sampleMin, sampleMax);
        result += (samplingCurveValue - samplingCurveLast) * baseFee[or];
        if (samplingCurveValue >= 1) {
            return result;
        }
        samplingCurveLast = samplingCurveValue;
    }
    return result;
};
export const suggestMaxBaseFee = async (provider, fromBlock = 'latest', blockCountHistory = 100, sampleMin = 0.1, sampleMax = 0.3, maxTimeFactor = 15) => {
    // feeHistory API call without a reward percentile specified is cheap even with a light client backend because it only needs block headers.
    // Therefore we can afford to fetch a hundred blocks of base fee history in order to make meaningful estimates on variable time scales.
    const feeHistory = await provider.eth.getFeeHistory(blockCountHistory, fromBlock, []);
    const baseFees = [];
    const order = [];
    for (let i = 0; i < feeHistory.baseFeePerGas.length; i++) {
        baseFees.push(weiToGweiNumber(feeHistory.baseFeePerGas[i]));
        order.push(i);
    }
    // If a block is full then the baseFee of the next block is copied. The reason is that in full blocks the minimal tip might not be enough to get included.
    // The last (pending) block is also assumed to end up being full in order to give some upwards bias for urgent suggestions.
    baseFees[baseFees.length - 1] *= 9 / 8;
    for (let i = feeHistory.gasUsedRatio.length - 1; i >= 0; i--) {
        if (feeHistory.gasUsedRatio[i] > 0.9) {
            baseFees[i] = baseFees[i + 1];
        }
    }
    order.sort((a, b) => {
        const aa = baseFees[a];
        const bb = baseFees[b];
        if (aa < bb) {
            return -1;
        }
        if (aa > bb) {
            return 1;
        }
        return 0;
    });
    const result = [];
    let maxBaseFee = 0;
    for (let timeFactor = maxTimeFactor; timeFactor >= 0; timeFactor--) {
        let bf = suggestBaseFee(baseFees, order, timeFactor, sampleMin, sampleMax);
        if (bf > maxBaseFee) {
            maxBaseFee = bf;
        }
        else {
            bf = maxBaseFee;
        }
        result[timeFactor] = bf;
    }
    const suggestedMaxBaseFee = Math.max(...result);
    return {
        baseFeeSuggestion: gweiToWei(suggestedMaxBaseFee),
    };
};
export const suggestMaxPriorityFee = async (provider, fromBlock = 'latest') => {
    const feeHistory = await provider.eth.getFeeHistory(10, fromBlock, [10, 20, 25, 30, 40, 50]);
    const blocksRewards = feeHistory.reward;
    if (!blocksRewards.length)
        throw new Error('Error: block reward was empty');
    const blocksRewardsPerc20 = blocksRewards.map((reward) => weiToGweiNumber(reward[1]));
    const blocksRewardsPerc30 = blocksRewards.map((reward) => weiToGweiNumber(reward[3]));
    const blocksRewardsPerc40 = blocksRewards.map((reward) => weiToGweiNumber(reward[4]));
    const emaPerc20 = ema(blocksRewardsPerc20, blocksRewardsPerc20.length).at(-1);
    const emaPerc30 = ema(blocksRewardsPerc30, blocksRewardsPerc30.length).at(-1);
    const emaPerc40 = ema(blocksRewardsPerc40, blocksRewardsPerc40.length).at(-1);
    if (emaPerc20 === undefined || emaPerc30 === undefined || emaPerc40 === undefined)
        throw new Error('Error: ema was empty');
    return {
        maxPriorityFeeSuggestions: {
            fast: gweiToWei(emaPerc30),
            normal: gweiToWei(emaPerc20),
            urgent: gweiToWei(emaPerc40),
        },
    };
};
export default async function suggestFees(provider) {
    const { baseFeeSuggestion } = await suggestMaxBaseFee(provider);
    const { maxPriorityFeeSuggestions } = await suggestMaxPriorityFee(provider);
    return {
        baseFeeSuggestion,
        maxPriorityFeeSuggestions,
    };
}
export async function suggestMaxFees(provider, maxType) {
    const fees = await suggestFees(provider);
    const baseFee = new Decimal(fees.baseFeeSuggestion).mul(1.5).round();
    const maxPriorityFeePerGas = new Decimal(fees.maxPriorityFeeSuggestions[maxType])
        .mul(1.5)
        .round()
        .toFixed();
    return {
        maxFeePerGas: baseFee.plus(maxPriorityFeePerGas).toFixed(),
        maxPriorityFeePerGas,
    };
}
