import { useDialogStore } from '@/store/dialog';
import { useStrategiesStore } from '@/store/perfect-strategies';
import { useWalletStore } from '@/web3-server/wallet';
import store from '@/store';
import { myDialog } from '@/store/my-dialog';
import infra from '@/infra';
import { TYPES_OF_WALLET_PROVIDERS } from '@/web3-server/wallet/types/provider/constants';
import { devError } from '@/utils/devError';
export async function initWCProvider(WCProvider) {
    const walletStore = useWalletStore();
    const provider = WCProvider || (await walletStore.getCurrentWCProvider(false));
    walletStore.wc.provider = provider;
    if (!WCProvider && provider) {
        await provider.enable();
        return provider;
    }
    else {
        return WCProvider;
    }
}
export async function accountsChanged(accounts) {
    const dialogStore = useDialogStore();
    const strategiesStore = useStrategiesStore();
    const walletStore = useWalletStore();
    if (accounts.length > 0) {
        if (walletStore.userAddress !== accounts[0]) {
            strategiesStore.cleanStrategies();
            walletStore.userAddress = accounts[0];
            store.commit('wallet/setUser', accounts[0]);
            await infra.wallet.connect();
            if (dialogStore.current) {
                myDialog.goHome();
            }
        }
    }
    else {
        infra.wallet.disconnect();
    }
}
export async function chainChanged(chainId) {
    try {
        const id = typeof chainId === 'string' ? Number(chainId) : chainId;
        if (store.state.wallet.chainId !== id) {
            store.commit('wallet/setChainId', id);
            await infra.wallet.connect();
        }
    }
    catch (e) {
        devError(e);
        store.commit('wallet/setChainId', chainId);
        await infra.wallet.connect();
    }
}
export async function disconnect() {
    const walletStore = useWalletStore();
    const WCPluginProvider = await initWCProvider();
    const eventProvider = getEthProviderForEvents({ WCPluginProvider });
    const isDefaultBrowserExtension = walletStore.provider &&
        walletStore.provider.type === TYPES_OF_WALLET_PROVIDERS.DEFAULT_BROWSERS_EXTENSIONS;
    const isConnectProp = eventProvider && 'isConnected' in eventProvider;
    if ((isConnectProp && !eventProvider.isConnected()) || isDefaultBrowserExtension) {
        setTimeout(() => {
            if (isConnectProp) {
                if (!eventProvider.isConnected()) {
                    infra.wallet.disconnect();
                }
            }
            else {
                try {
                    if (!eventProvider.isConnected()) {
                        infra.wallet.disconnect();
                    }
                }
                catch (e) {
                    infra.wallet.disconnect();
                }
            }
        }, 3000);
    }
    else {
        infra.wallet.disconnect();
    }
}
export function getEthProviderForEvents(params) {
    const { WCPluginProvider } = params;
    const walletStore = useWalletStore();
    const walletProvider = walletStore.provider;
    const cryptoProviderFromWalletProvider = walletStore.getCryptoBrowserProvider();
    const bloctoProvider = walletStore.getBloctoProviderToRead();
    const bloctoSession = walletStore.getBloctoSession();
    if (walletProvider &&
        walletProvider.type === TYPES_OF_WALLET_PROVIDERS.WALLET_CONNECT &&
        walletProvider.session &&
        WCPluginProvider) {
        return WCPluginProvider;
    }
    else if (cryptoProviderFromWalletProvider) {
        return cryptoProviderFromWalletProvider;
    }
    else if (bloctoProvider && bloctoSession) {
        return bloctoProvider;
    }
    else {
        return walletStore.getDefaultBrowserExtension();
    }
}
export function unsubscribeFromWalletEvents(WCPluginProvider) {
    const walletStore = useWalletStore();
    const eventProvider = new Promise((resolve) => {
        if (WCPluginProvider) {
            resolve(getEthProviderForEvents({
                WCPluginProvider,
            }));
        }
        else {
            walletStore.getCurrentWCProvider(false).then((WCProvider) => {
                resolve(getEthProviderForEvents({
                    WCPluginProvider: WCProvider || undefined,
                }));
            });
        }
    });
    eventProvider.then((event) => {
        if (event) {
            event.removeListener('accountsChanged', accountsChanged);
            event.removeListener('chainChanged', chainChanged);
            event.removeListener('disconnect', disconnect);
        }
    });
}
export function subscribeToWalletEvents(WCPluginProvider) {
    const walletStore = useWalletStore();
    const eventProvider = new Promise((resolve) => {
        if (WCPluginProvider) {
            resolve(getEthProviderForEvents({
                WCPluginProvider,
            }));
        }
        else {
            walletStore.getCurrentWCProvider(false).then((WCProvider) => {
                resolve(getEthProviderForEvents({
                    WCPluginProvider: WCProvider || undefined,
                }));
            });
        }
    });
    eventProvider.then((event) => {
        if (event) {
            event.once('accountsChanged', accountsChanged);
            event.once('chainChanged', chainChanged);
            event.once('disconnect', disconnect);
        }
    });
}
export function bloctoEventControl(bloctoSDK) {
    unsubscribeFromWalletEventsFromBlocto();
    subscribeToWalletEventFromBlocto(bloctoSDK);
}
export function subscribeToWalletEventFromBlocto(bloctoSDK) {
    if (bloctoSDK && bloctoSDK.ethereum) {
        bloctoSDK.ethereum.on('connect', connectFromBlocto);
    }
}
export function unsubscribeFromWalletEventsFromBlocto() {
    const walletStore = useWalletStore();
    if (walletStore.blocto.sdk?.ethereum) {
        walletStore.blocto.sdk.ethereum.removeListener('connect', connectFromBlocto);
    }
}
function connectFromBlocto(info) {
    const walletStore = useWalletStore();
    if (walletStore.blocto.sdk?.ethereum) {
        unsubscribeFromWalletEventsFromBlocto();
    }
    infra.wallet.connect();
}
