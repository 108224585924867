import getCurrentAllowedNetwork from '@/web3-server/wallet/actions/network/composables/get-current-allowed-network';
const networkActions = {
    getAllowedNetwork(ownProvider) {
        return getCurrentAllowedNetwork(ownProvider || this.provider);
    },
    getAllowedNetworkWithoutProvider() {
        return getCurrentAllowedNetwork();
    },
};
export default networkActions;
