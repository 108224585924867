import { suggestMaxFees } from '@/web3-server/utils/suggest-fees';
export async function getSendOptions({ userWalletAddress, web3 }, params) {
    const sendOptions = {
        from: userWalletAddress,
    };
    if (params) {
        if (params.amountInNetworkToken) {
            sendOptions.value = params.amountInNetworkToken;
        }
        if (params.supportEIP1559) {
            const { maxFeePerGas, maxPriorityFeePerGas } = await suggestMaxFees(web3, 'urgent');
            sendOptions.maxFeePerGas = maxFeePerGas;
            sendOptions.maxPriorityFeePerGas = maxPriorityFeePerGas;
        }
    }
    return sendOptions;
}
