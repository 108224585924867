import ERC20 from '@/infra/abis/ERC20.json';
import converter from '@/web3-server/utils/converter';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export default async function getApproved({ web3, userWalletAddress }, strategyContractAddress, props, currencyDecimals) {
    const defaultApprove = '0';
    try {
        const currencyContract = 'currencyContract' in props
            ? props.currencyContract
            : new web3.eth.Contract(ERC20.abi, props.currencyAddress);
        return currencyContract.methods
            .allowance(userWalletAddress, strategyContractAddress)
            .call()
            .then((allowance) => {
            return converter.withoutType.fromWei(allowance, { decimals: currencyDecimals }).toFixed();
        })
            .catch(() => {
            devError(DEV_ERROR_CODE.WEB_CONTRACT_METHOD);
            return defaultApprove;
        });
    }
    catch (e) {
        devError(e);
        return defaultApprove;
    }
}
