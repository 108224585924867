const infra = {
    wallet: {
        async connect(params) {
            return await import('@/infra/wallet/connect').then((module) => module.connect(params));
        },
        setUserTransactions(user) {
            return import('@/infra/wallet/transactions').then((module) => module.setUserTransactions(user));
        },
        addTransaction(transaction) {
            return import('@/infra/wallet/transactions').then((module) => module.addTransaction(transaction));
        },
        disconnect(disableAutoLogin) {
            return import('@/infra/wallet/disconnect').then((module) => module.disconnect(disableAutoLogin));
        },
        addBlidToken() {
            return import('@/infra/wallet/add-blid-token').then((module) => module.addBlidToken());
        },
        getCurrentExtension(connectionType) {
            return import('@/infra/wallet/get-current-extension').then((module) => module.getCurrentExtension(connectionType));
        },
    },
};
export default infra;
