import { PID } from '@/infra/types/contracts/constants';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import { numWei } from '@/composables/useFormatCurrency';
export default async function getDepositFromStaking(userWalletAddress, masterContract, BLIDPrice) {
    let isDeposit = false, USDDeposit = 0;
    try {
        const depositInfo = await masterContract.methods
            .userInfo(PID.STAKING_BLID, userWalletAddress)
            .call();
        if (depositInfo && depositInfo.amount) {
            const BLIDDeposit = depositInfo.amount;
            USDDeposit = numWei(BLIDDeposit) * BLIDPrice;
            if (USDDeposit) {
                isDeposit = true;
            }
        }
    }
    catch (e) {
        devError(DEV_ERROR_CODE.SAFC_USER_INFO_STAKING);
    }
    return { isDeposit, USDDeposit };
}
