import { STRATEGY_STATUS } from '@/web3-server/web3-strategies/types/config/constants';
export default function getStrategyStatus(listOfStrategies, networkId) {
    if (networkId) {
        return listOfStrategies.map((strategy) => {
            let status = STRATEGY_STATUS.DEPOSIT;
            if (strategy.network !== networkId) {
                if (strategy.crossChain) {
                    if (strategy.crossChain.availableNetworks.includes(networkId)) {
                        status = STRATEGY_STATUS.CROSS_CHAIN;
                    }
                    else {
                        status = STRATEGY_STATUS.DISABLED;
                    }
                }
                else {
                    status = STRATEGY_STATUS.DISABLED;
                }
            }
            return {
                strategyId: strategy.id,
                status,
            };
        });
    }
    else {
        return listOfStrategies.map((strategy) => {
            return {
                strategyId: strategy.id,
                status: STRATEGY_STATUS.DEPOSIT,
            };
        });
    }
}
