import { DateTime } from 'luxon';
import { devError } from '@/utils/devError';
import { Multicall } from 'ethereum-multicall';
import multiCallUtils from '@/web3-server/web3/handler/multicall-utils';
export default async function getAPYAndAPR(web3, BLIDPrice, storageContract, strategyContractData) {
    const result = {
        apy: 0,
        apr: 0,
    };
    const earns = await getEarns(web3, storageContract, strategyContractData);
    const periodInDays = await getPeriodInDays(earns);
    if (periodInDays) {
        const DAYS_IN_YEAR = 365;
        const avgPercentPerDay = getAvgPercentPerDay(BLIDPrice, earns, periodInDays);
        result.apy = (Math.pow(1 + avgPercentPerDay, 365) - 1) * 100;
        result.apr = avgPercentPerDay * 100 * DAYS_IN_YEAR;
    }
    return result;
}
async function getEarns(web3, storageContract, strategyContractData) {
    const earns = [];
    try {
        const countEarns = Number(await storageContract.methods.getCountEarns().call());
        const limit = Math.min(countEarns, 6);
        const calls = [];
        for (let i = limit; i > 0; i--) {
            calls.push({
                reference: `earn${countEarns - i}`,
                methodName: 'getEarnsByID',
                methodParameters: [countEarns - i],
            });
        }
        const contractCallContext = [
            {
                reference: 'getEarnsByID',
                contractAddress: strategyContractData.address,
                abi: strategyContractData.abi,
                calls,
            },
        ];
        const multicall = new Multicall({
            web3Instance: web3,
            tryAggregate: true,
        });
        const results = await multicall.call(contractCallContext);
        const callsResults = results?.results?.getEarnsByID?.callsReturnContext;
        if (callsResults && callsResults.length > 0) {
            for (const item of callsResults) {
                earns.push({
                    amount: multiCallUtils.getVal('hex', item, 0),
                    timestamp: multiCallUtils.getVal('hex', item, 1),
                    usd: multiCallUtils.getVal('hex', item, 2),
                });
            }
        }
    }
    catch (e) {
        devError(e);
    }
    return earns;
}
function getPeriodInDays(earns) {
    try {
        if (earns && earns.length) {
            const firstDateTime = DateTime.fromJSDate(new Date(Number.parseInt(earns[0].timestamp, 10) * 1000));
            const lastDateTime = DateTime.fromJSDate(new Date(Number.parseInt(earns[earns.length - 1].timestamp, 10) * 1000));
            const diff = lastDateTime.diff(firstDateTime, ['days']);
            return diff.toObject().days;
        }
        else {
            return 0;
        }
    }
    catch (e) {
        return 0;
    }
}
function getAvgPercentPerDay(BLIDPrice, earns, periodInDays) {
    let totalInBLID = 0;
    let totalDepositedInUSD = 0;
    for (const earn of earns) {
        totalInBLID += Number(earn.amount);
        totalDepositedInUSD += Number(earn.usd);
    }
    const avgDeposited = totalDepositedInUSD / earns.length;
    const avgPercent = (totalInBLID * BLIDPrice) / avgDeposited;
    return avgPercent / periodInDays;
}
