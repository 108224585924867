import { numWei } from '@/composables/useFormatCurrency';
export default async function getIsBoostingV2(storageContract) {
    try {
        const BLIDPerBlockPromise = storageContract.methods.blidPerBlock().call();
        return BLIDPerBlockPromise.then((value) => {
            return !!value && !!numWei(value);
        }).catch(() => {
            return false;
        });
    }
    catch (e) {
        return false;
    }
}
