import { DateTime, Interval } from 'luxon';
import Web3 from 'web3';
import { devError } from '@/utils/devError';
import { waitForTxReceiptAndTransaction } from '@/web3-server/web3/handler/wait';
export function getMethods() {
    return {
        timeCheck(createdAt) {
            const now = DateTime.now();
            const startDate = DateTime.fromISO(createdAt);
            const countHours = Interval.fromDateTimes(startDate, now).length('hours');
            return countHours < 1;
        },
        wait(rpcUrl, hash) {
            return new Promise((resolve, reject) => {
                try {
                    const web3 = new Web3(rpcUrl);
                    waitForTxReceiptAndTransaction(web3, hash)
                        .then((result) => {
                        if (result.isSuccess && result.receipt) {
                            resolve();
                        }
                        else {
                            reject();
                        }
                    })
                        .catch(() => {
                        reject();
                    });
                }
                catch (e) {
                    devError(e);
                    reject();
                }
            });
        },
    };
}
