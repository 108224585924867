import { event } from 'vue-gtag';
export function GAEvent(name, extra) {
    const getPrefix = () => {
        let prefix = '';
        if (extra && extra.eventPrefix) {
            prefix = extra.eventPrefix + '_';
        }
        return prefix;
    };
    const getParams = () => {
        let params = undefined;
        if (extra) {
            if (extra.value) {
                params = { value: extra.value };
            }
            else if (extra.valueWithParams) {
                params = extra.valueWithParams;
            }
        }
        return params;
    };
    event(getPrefix() + name, getParams());
}
