export var DEV_ERROR_CODE;
(function (DEV_ERROR_CODE) {
    DEV_ERROR_CODE["GSC_GET_TOKEN_DEPOSIT"] = "Empty deposit of the general strategy token";
    DEV_ERROR_CODE["GSC_BALANCE_OF"] = "Empty balance result of general strategy";
    DEV_ERROR_CODE["SAFC_USER_INFO_STAKING"] = "Empty deposit of staking strategy";
    DEV_ERROR_CODE["GET_TOKEN_DEPOSIT_FARMING"] = "Empty deposit of farming strategy";
    DEV_ERROR_CODE["GET_EARNED_BLID"] = "Empty result for earned BLID";
    DEV_ERROR_CODE["CONFIRM_TRANSACTION_STATUS"] = "The receipt has no status when verifying the transaction";
    DEV_ERROR_CODE["CONFIRM_TRANSACTION_TIME"] = "Transaction verification time limits exceeded";
    DEV_ERROR_CODE["WEB_STORAGE_NO_VAR"] = "Missing required variables for web3 request";
    DEV_ERROR_CODE["MISSING_VAR"] = "Required variable missing";
    DEV_ERROR_CODE["GET_TVL"] = "Empty TVL result";
    DEV_ERROR_CODE["GET_QUANTITY_TOKENS_IN_BLOCK"] = "Failed to get quantity tokens in block";
    DEV_ERROR_CODE["GET_GENERAL_INFO_CONTRACT"] = "Failed to get general info from contract";
    DEV_ERROR_CODE["GET_APY"] = "Empty APY result";
    DEV_ERROR_CODE["AXIOS"] = "Error when trying to get data from Axios request";
    DEV_ERROR_CODE["WEB_CONTRACT"] = "Error while working with a contract";
    DEV_ERROR_CODE["SWITCH_NETWORK"] = "Network switch error";
    DEV_ERROR_CODE["WEB_CONTRACT_METHOD"] = "Error in contract method";
    DEV_ERROR_CODE["WEB_STORAGE_ITEM_SEARCH"] = "Item not found in web3 storage";
    DEV_ERROR_CODE["WC_PROVIDER_NOT_INIT"] = "WalletConnect not initialized";
})(DEV_ERROR_CODE || (DEV_ERROR_CODE = {}));
