import { goerliCurrencies, listOfCurrenciesWithLinks, web3StoreDataCurrencies, web3StoreDataNameOfCurrencies, } from '@/web3-server/web3-currencies/data';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
export default function getAllCurrencies(listOfStrategies) {
    const currenciesByNetwork = {
        [NETWORK_ID.BNB]: getCurrencyWithAllParams(web3StoreDataCurrencies.BNB),
        [NETWORK_ID.POLYGON]: getCurrencyWithAllParams(web3StoreDataCurrencies.POLYGON),
        [NETWORK_ID.ETH]: getCurrencyWithAllParams(web3StoreDataCurrencies.ETH),
        [NETWORK_ID.ARBITRUM]: getCurrencyWithAllParams(web3StoreDataCurrencies.ARBITRUM),
        [NETWORK_ID.OPTIMISM]: getCurrencyWithAllParams(web3StoreDataCurrencies.OPTIMISM),
        [NETWORK_ID.GOERLI]: getCurrencyWithAllParams(goerliCurrencies),
    };
    const listOfCurrencies = Object.values(currenciesByNetwork).reduce((prev, item) => {
        if (item) {
            prev.push(...item);
        }
        return prev;
    }, []);
    return {
        byList: listOfCurrencies,
        byNetwork: currenciesByNetwork,
        byStrategies: listOfStrategies.map((strategy) => {
            const defaultCurrencies = listOfCurrencies.filter((currency) => strategy.listOfCurrencyId.includes(currency.id));
            if (strategy.crossChain && strategy.crossChain.availableCurrencies.length) {
                const availableCurrencies = strategy.crossChain.availableCurrencies;
                defaultCurrencies.push(...listOfCurrencies.filter((currency) => availableCurrencies.includes(currency.id)));
            }
            return {
                strategyId: strategy.id,
                currencies: defaultCurrencies,
            };
        }),
    };
}
export function getAllCurrenciesForStore() {
    return listOfCurrenciesWithLinks.reduce((prev, currenciesWithLinks) => {
        const web3Currencies = currenciesWithLinks.currencies.map((currency) => {
            return {
                ...currency,
                address: currency.address.toLowerCase(),
                state: {
                    balance: null,
                },
                description: web3StoreDataNameOfCurrencies[currency.symbol],
                relations: currenciesWithLinks.relations,
            };
        });
        prev.push(...web3Currencies);
        return prev;
    }, []);
}
function getCurrencyWithAllParams(linkedCurrencies) {
    const defaultCustomStates = {
        balance: '',
        approved: '0',
        deposited: '',
    };
    return linkedCurrencies.currencies.map((item) => {
        return {
            ...item,
            ...JSON.parse(JSON.stringify(defaultCustomStates)),
            ...web3StoreDataNameOfCurrencies[item.symbol],
            ...linkedCurrencies.relations,
            address: item.address.toLowerCase(),
        };
    });
}
