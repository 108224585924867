import ERC20 from '@/infra/abis/ERC20.json';
import { devError } from '@/utils/devError';
import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
import { PID } from '@/infra/types/contracts/constants';
import getApproved from '@/web3-server/web3/actions/currencies/get-approved';
import getCurrencyBalance from '@/web3-server/web3/actions/currencies/get-currency-balance';
export default function getCurrency(requestWithUser, strategyContract, currency, allowDeposit) {
    try {
        const { web3, userWalletAddress } = requestWithUser;
        const currencyContract = new web3.eth.Contract(ERC20.abi, currency.address);
        const result = {
            availableBalancePromise: getCurrencyBalance(requestWithUser, { contract: currencyContract }, currency.decimals || 18),
            approvedPromise: getApproved(requestWithUser, strategyContract.address, { currencyContract }, currency.decimals || 18),
        };
        const contract = new web3.eth.Contract(strategyContract.abi, strategyContract.address);
        if (strategyContract.type === CONTRACT_TYPE.STORAGE && allowDeposit) {
            result.depositPromise = contract.methods
                .getTokenDeposit(userWalletAddress, currency.address)
                .call()
                .then((depositTokenAmount) => {
                return web3.utils.fromWei(depositTokenAmount);
            });
        }
        else if (strategyContract.type === CONTRACT_TYPE.STAKING) {
            result.depositPromise = contract.methods
                .userInfo(PID.STAKING_BLID, userWalletAddress)
                .call()
                .then((depositInfo) => {
                return depositInfo && depositInfo.amount ? web3.utils.fromWei(depositInfo.amount) : '0';
            });
        }
        else if (strategyContract.type === CONTRACT_TYPE.FARMING) {
            result.depositPromise = contract.methods
                .userInfo(PID.FAMING_BLID_USDT, userWalletAddress)
                .call()
                .then((depositInfo) => {
                return depositInfo && depositInfo.amount ? web3.utils.fromWei(depositInfo.amount) : '0';
            });
        }
        return result;
    }
    catch (e) {
        devError(e);
        return null;
    }
}
