import { NETWORK_SWITCH_STATUSED } from '@/web3-server/wallet/types/provider/constants';
import { useWalletStore } from '@/web3-server/wallet';
export default async function switchNetworkViaBlocto(network) {
    try {
        const walletStore = useWalletStore();
        const ethProvider = walletStore.getBloctoProviderToWrite();
        let status = NETWORK_SWITCH_STATUSED.ERROR;
        if (ethProvider) {
            await ethProvider.request({
                method: 'wallet_addEthereumChain',
                params: [network],
            });
            await ethProvider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: network.chainId }],
            });
            const accounts = await ethProvider.request({ method: 'eth_requestAccounts' });
            if (accounts && accounts.length && accounts[0]) {
                status = NETWORK_SWITCH_STATUSED.SUCCESS;
            }
            else {
                await walletStore.removeBloctoSession();
                const bloctoSDK = walletStore.instantiateBloctoSDK(network.chainId, network.rpcUrls[0]);
                if (bloctoSDK && bloctoSDK.ethereum) {
                    try {
                        const acc = await bloctoSDK.ethereum.request({
                            method: 'eth_requestAccounts',
                        });
                        if (acc && acc.length && acc[0]) {
                            status = NETWORK_SWITCH_STATUSED.SUCCESS;
                        }
                        else {
                            walletStore.clearWallet();
                        }
                    }
                    catch (e) {
                        walletStore.clearWallet();
                    }
                }
            }
        }
        return status;
    }
    catch (e) {
        return NETWORK_SWITCH_STATUSED.ERROR;
    }
}
