import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import getDepositSummary from '@/web3-server/web3/actions/cross-chain/get-cross-deposit-summary';
import checker from '@/web3-server/utils/checker';
import Decimal from 'decimal.js';
import getWalletBalance from '@/web3-server/web3/actions/cross-chain/get-wallet-balance';
import { checkVars, requestAPI } from '@/web3-server/web3/handler/inspectors';
import deposit from '@/web3-server/web3/actions/cross-chain/deposit-cross-chain';
import { networkForCrossChain } from '@/web3-server/wallet/data/network';
import { LAYER_ZERO_LAB_STATUS } from '@/web3-server/web3/types/cross-chain/constants';
import getTransactionStatus from '@/web3-server/web3/actions/cross-chain/get-transaction-status';
import { useWalletStore } from '@/web3-server/wallet';
import checkTransactionStatus from '@/web3-server/web3/actions/cross-chain/check-transaction-status';
import web3Constants from '@/web3-server/web3/data/web3-constants';
const crossChainActions = {
    depositCrossChain(networkProps, tokenAddress, decimal, events) {
        const request = requestAPI('user');
        if (request.isUser &&
            networkProps &&
            networkProps.bridgeType &&
            networkProps.networkId &&
            networkProps.contractAddress &&
            decimal &&
            checker.decimal.type.isWei(decimal.amount) &&
            checker.decimal.isNotEmpty(decimal.amount.value) &&
            checker.decimal.type.isWei(decimal.fee) &&
            checker.decimal.isNotEmpty(decimal.fee.value) &&
            checker.decimal.type.isSimple(decimal.amountWithCommission) &&
            checker.decimal.isNotEmpty(decimal.amountWithCommission.value) &&
            checkVars([tokenAddress])) {
            const bnb = networkForCrossChain[networkProps.bridgeType].BNB;
            const networkCross = networkForCrossChain[networkProps.bridgeType][networkProps.id];
            if (bnb &&
                bnb.chainId &&
                bnb.rpcUrl &&
                bnb.contractAddress &&
                networkCross &&
                networkCross.contractAddress &&
                networkCross.dstGasForCall &&
                web3Constants.crossChainSlippage &&
                checker.decimal.type.isSimple(web3Constants.crossChainSlippage.forCalc) &&
                checker.decimal.isNotEmpty(web3Constants.crossChainSlippage.forCalc.value)) {
                deposit(request.getWithUser, bnb, networkCross, tokenAddress, decimal.amount, decimal.fee, decimal.amountWithCommission, web3Constants.crossChainSlippage.forCalc, events);
            }
            else {
                throw DEV_ERROR_CODE.MISSING_VAR;
            }
        }
        else {
            throw DEV_ERROR_CODE.MISSING_VAR;
        }
    },
    async getCrossDepositSummary(props) {
        const walletStore = useWalletStore();
        if (props &&
            props.bridgeType &&
            props.networkId &&
            props.currencyType &&
            checker.decimal.type.isSimple(props.amountSimple) &&
            checker.decimal.isNotEmpty(props.amountSimple.value)) {
            return getDepositSummary(props, walletStore.userAddress);
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR);
            return null;
        }
    },
    async checkDepositCreationAccess(rpcUrl, gas) {
        const walletStore = useWalletStore();
        if (rpcUrl && checker.decimal.isNotEmpty(gas) && walletStore.userAddress) {
            const walletBalance = await getWalletBalance(rpcUrl, walletStore.userAddress);
            const balance = new Decimal(walletBalance.formatted.full);
            if (checker.decimal.isNotEmpty(balance)) {
                return balance.greaterThan(gas);
            }
            else {
                devError(DEV_ERROR_CODE.MISSING_VAR);
                return false;
            }
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR);
            return false;
        }
    },
    async getTransactionStatus(hash) {
        if (hash) {
            return getTransactionStatus(hash);
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR);
            return LAYER_ZERO_LAB_STATUS.FAILED;
        }
    },
    async checkTransactionStatus(hash) {
        if (hash) {
            return checkTransactionStatus(hash);
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR);
            return LAYER_ZERO_LAB_STATUS.FAILED;
        }
    },
};
export default crossChainActions;
