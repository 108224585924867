import ERC20 from '@/infra/abis/ERC20.json';
import { devError } from '@/utils/devError';
import converter from '@/web3-server/utils/converter';
export default async function getCurrencyBalance(requestWithUser, contractOptions, currencyDecimals) {
    try {
        const { web3, userWalletAddress } = requestWithUser;
        const currencyContract = 'contract' in contractOptions
            ? contractOptions.contract
            : new web3.eth.Contract(ERC20.abi, contractOptions.address);
        return currencyContract.methods
            .balanceOf(userWalletAddress)
            .call()
            .then((availableBalance) => {
            return converter.withType.fromWei(availableBalance, { decimals: currencyDecimals });
        });
    }
    catch (e) {
        devError(e);
        return converter.withType.fromWei('0', { decimals: 18 });
    }
}
