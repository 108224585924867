import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import getTVLFromStaking from '@/web3-server/web3/actions/strategies/master-contract/staking/get-tvl';
import getTVLFromFarming from '@/web3-server/web3/actions/strategies/master-contract/farming/get-tvl';
import { PID } from '@/web3-server/web3/types/strategies/master-contract/constants';
import getApyForStakingAndFarming from '@/web3-server/web3/actions/strategies/master-contract/get-apy';
import getLpContract from '@/web3-server/web3/actions/strategies/master-contract/farming/get-contract';
import getFarmPrice from '@/web3-server/web3/actions/strategies/master-contract/farming/get-farm-price';
export function getGeneralInfoFromStaking(request, BLIDPrice, BLIDTokenAddress, strategyContract) {
    try {
        const masterContract = new request.web3.eth.Contract(strategyContract.abi, strategyContract.address);
        const tvlPromise = getTVLFromStaking(request, BLIDPrice, BLIDTokenAddress, strategyContract.address);
        const apyPromise = getApyForStakingAndFarming(request, masterContract, strategyContract.address, PID.STAKING_BLID, BLIDTokenAddress, BLIDPrice, BLIDPrice);
        return { tvlPromise, apyPromise };
    }
    catch (e) {
        devError(DEV_ERROR_CODE.GET_GENERAL_INFO_CONTRACT);
        return undefined;
    }
}
export async function getGeneralInfoFromFarming(request, BLIDPrice, BLIDTokenAddress, lpTokenAddress, strategyContract) {
    let result = undefined;
    try {
        const masterContract = new request.web3.eth.Contract(strategyContract.abi, strategyContract.address);
        const lpContract = await getLpContract(request, lpTokenAddress);
        if (lpContract) {
            const tvlPromise = getTVLFromFarming(lpContract, strategyContract.address, BLIDTokenAddress, BLIDPrice);
            const apyPromise = getApyForStakingAndFarming(request, masterContract, strategyContract.address, PID.FAMING_BLID_USDT, lpTokenAddress, BLIDPrice, getFarmPrice(lpContract, BLIDPrice, BLIDTokenAddress));
            result = { tvlPromise, apyPromise };
        }
        return result;
    }
    catch (e) {
        devError(DEV_ERROR_CODE.GET_GENERAL_INFO_CONTRACT);
        return result;
    }
}
