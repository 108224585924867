import axios from 'axios';
export function versionCheck() {
    const storageKey = 'version';
    const version = localStorage.getItem(storageKey);
    axios.get('version.txt?timestamp=' + Date.now().toString()).then((response) => {
        if (response && response.data) {
            if (!version || response.data !== version) {
                localStorage.setItem(storageKey, response.data);
            }
            if (version && response.data !== version) {
                location.reload();
            }
        }
    });
}
export function removeRefreshBtn() {
    const btn = document.getElementById('refresh-page-btn');
    if (btn) {
        btn.remove();
    }
}
