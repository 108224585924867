import ERC20 from '@/infra/abis/ERC20.json';
import { numWei } from '@/composables/useFormatCurrency';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export default async function getApyForStakingAndFarming({ web3 }, masterContract, masterContractAddress, pid, tokenAddress, BLIDPrice, tokenPrice) {
    const result = {
        apy: 0,
        apr: 0,
    };
    try {
        const infoCall = await masterContract.methods.poolInfo(pid).call();
        const totalAllocPoint = await masterContract.methods.totalAllocPoint().call();
        const BLIDPerBlock = await masterContract.methods.blidPerBlock().call();
        const farmAllocPoint = infoCall.allocPoint;
        const contract = new web3.eth.Contract(ERC20.abi, tokenAddress);
        const balanceOfMaster = await contract.methods.balanceOf(masterContractAddress).call();
        const poolLiquidityUsd = numWei(balanceOfMaster) * tokenPrice;
        const poolWeight = Number(farmAllocPoint) / Number(totalAllocPoint);
        return getFarmApr(poolWeight, BLIDPrice, poolLiquidityUsd, numWei(BLIDPerBlock));
    }
    catch (e) {
        devError(DEV_ERROR_CODE.GET_APY);
        return result;
    }
}
function getFarmApr(poolWeight, cakePriceUsd, poolLiquidityUsd, cakePerBlockInfo) {
    const BSC_BLOCK_TIME = 3;
    const CAKE_PER_BLOCK = cakePerBlockInfo;
    const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365;
    const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR;
    const BLOCKS_PER_DAY = (60 / BSC_BLOCK_TIME) * 60 * 24;
    const CAKE_PER_DAY = CAKE_PER_BLOCK * BLOCKS_PER_DAY;
    const yearlyCakeRewardAllocation = poolWeight * CAKE_PER_YEAR;
    const dayCakeRewardAllocation = poolWeight * CAKE_PER_DAY;
    const APR = ((yearlyCakeRewardAllocation * cakePriceUsd) / poolLiquidityUsd) * 100;
    const APY = (Math.pow((dayCakeRewardAllocation * cakePriceUsd) / poolLiquidityUsd + 1, 365) - 1) * 100;
    return { apy: Number.isFinite(APY) ? APY : 0, apr: Number.isFinite(APR) ? APR : 0 };
}
