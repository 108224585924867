import axios from 'axios';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export default async function getBLIDPriceFromOurServer() {
    try {
        const requestUrl = process.env.VUE_APP_DEV_PROXY_API_URL || '';
        return axios.get(requestUrl + '/api/v1/price/blid/');
    }
    catch (e) {
        devError(DEV_ERROR_CODE.AXIOS);
        return null;
    }
}
