import web3 from 'web3';
const multiCallUtils = {
    getVal: getValueFromMultiCall,
};
function getValueFromMultiCall(type, item, index = 0) {
    const result = {
        str: type === 'hex' ? '0' : '',
        boolean: false,
    };
    if (item && item.returnValues && item.returnValues.length) {
        const current = item.returnValues[index];
        if (current) {
            if (type === 'hex') {
                if (typeof current === 'object' && 'hex' in current) {
                    result.str = web3.utils.hexToNumberString(current.hex);
                }
            }
            else if (type === 'string') {
                if (typeof current === 'string' && current) {
                    result.str = current;
                }
            }
            else if (type === 'boolean') {
                if (typeof current === 'boolean') {
                    result.boolean = current;
                }
            }
        }
    }
    if (type === 'hex' || type === 'string') {
        return result.str;
    }
    else {
        return result.boolean;
    }
}
export default multiCallUtils;
