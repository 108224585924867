import converter from '@/web3-server/utils/converter';
import { devError } from '@/utils/devError';
export default async function getTokenDepositFromStorage({ web3, userWalletAddress }, contractOptions, currency) {
    const defaultValue = converter.withType.fromWei('0');
    try {
        const strategyContract = 'contract' in contractOptions
            ? contractOptions.contract
            : new web3.eth.Contract(contractOptions.abi, contractOptions.address);
        return strategyContract.methods
            .getTokenDeposit(userWalletAddress, currency.address)
            .call()
            .then((depositTokenAmount) => {
            return converter.withType.fromWei(depositTokenAmount, { decimals: currency.decimals });
        })
            .catch((e) => {
            devError(e);
            return defaultValue;
        });
    }
    catch (e) {
        devError(e);
        return defaultValue;
    }
}
