import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import { useWeb3Store } from '@/web3-server';
import { useWalletStore } from '@/web3-server/wallet';
import { SWAP_SITE_ID } from '@/web3-server/wallet/types/swap/constants';
import { getShortDecimal } from '@/web3-server/web3/handler/calc';
import { devError } from '@/utils/devError';
const BLIDPriceActions = {
    async initBLIDPriceFromCoinGecko(proposedNetwork) {
        const web3Store = useWeb3Store();
        try {
            const walletStore = useWalletStore();
            const swapSite = walletStore.listOfSwapSites.find((swap) => swap.id === SWAP_SITE_ID.ALL_XY_FINANCE);
            const response = await web3Store.getBLIDPriceFromCoinGecko(proposedNetwork);
            const priceInNumFormat = response.price.value.toNumber();
            if (swapSite) {
                updateLinkOnSwipeSite(swapSite, response.xyFinance.chainId, response.xyFinance.tokenAddress);
            }
            return {
                [NETWORK_ID.BNB]: priceInNumFormat,
                [NETWORK_ID.POLYGON]: priceInNumFormat,
                [NETWORK_ID.ETH]: priceInNumFormat,
                [NETWORK_ID.ARBITRUM]: priceInNumFormat,
                [NETWORK_ID.OPTIMISM]: priceInNumFormat,
                [NETWORK_ID.GOERLI]: priceInNumFormat,
            };
        }
        catch (e) {
            devError(e);
            return {
                [NETWORK_ID.BNB]: 0,
                [NETWORK_ID.POLYGON]: 0,
                [NETWORK_ID.ETH]: 0,
                [NETWORK_ID.ARBITRUM]: 0,
                [NETWORK_ID.OPTIMISM]: 0,
                [NETWORK_ID.GOERLI]: 0,
            };
        }
    },
    async initBLIDPrice(proposedNetwork) {
        const web3Store = useWeb3Store();
        const setPrice = (id, price) => {
            const swapSite = this.listOfSwapSites.find((swap) => swap.id === id);
            if (swapSite) {
                swapSite.price = price;
            }
        };
        try {
            const { BNB, POLYGON, ETH, allChain } = web3Store.getBLIDPrice(proposedNetwork);
            updateSwapSiteForAllChains(SWAP_SITE_ID.ALL_XY_FINANCE, allChain.xyFinance);
            BNB.lbank.then((val) => {
                if (val) {
                    setPrice(SWAP_SITE_ID.ETH_LBANK, val);
                }
            });
            const pancakeSwapFromBNB = await BNB.pancakeswap.then((val) => {
                if (val) {
                    setPrice(SWAP_SITE_ID.BNB_PANCAKESWAP, val.formatted.toString());
                }
                return val.formatted;
            });
            const liFiFromPolygon = await POLYGON.liFi.then((val) => {
                const formatted = getShortDecimal(val.simple, 3, 'down');
                if (val) {
                    setPrice(SWAP_SITE_ID.POLYGON_QUICK_SWAP, formatted.toFixed());
                }
                return formatted.toNumber();
            });
            const liFiFromETH = await ETH.liFi.then((val) => {
                const formatted = getShortDecimal(val.simple, 3, 'down');
                if (val) {
                    setPrice(SWAP_SITE_ID.ETH_UNISWAP, formatted.toFixed());
                }
                return formatted.toNumber();
            });
            return {
                [NETWORK_ID.BNB]: pancakeSwapFromBNB,
                [NETWORK_ID.POLYGON]: liFiFromPolygon,
                [NETWORK_ID.ETH]: liFiFromETH,
                [NETWORK_ID.ARBITRUM]: liFiFromETH,
                [NETWORK_ID.OPTIMISM]: liFiFromETH,
                [NETWORK_ID.GOERLI]: liFiFromETH,
            };
        }
        catch (e) {
            devError(e);
            return {
                [NETWORK_ID.BNB]: 0,
                [NETWORK_ID.POLYGON]: 0,
                [NETWORK_ID.ETH]: 0,
                [NETWORK_ID.ARBITRUM]: 0,
                [NETWORK_ID.OPTIMISM]: 0,
                [NETWORK_ID.GOERLI]: 0,
            };
        }
    },
};
function updateSwapSiteForAllChains(id, data) {
    const walletStore = useWalletStore();
    const swapSite = walletStore.listOfSwapSites.find((swap) => swap.id === id);
    if (swapSite) {
        data.then(({ price, tokenAddress, chainId }) => {
            price.then((val) => {
                if (val) {
                    swapSite.price = val.toString();
                }
            });
            updateLinkOnSwipeSite(swapSite, chainId, tokenAddress);
        });
    }
}
function updateLinkOnSwipeSite(swapSite, chainId, tokenAddress) {
    if (swapSite.linkConstructor && tokenAddress) {
        const linkConstructor = swapSite.linkConstructor;
        let link = linkConstructor.link;
        if (linkConstructor.tokenAddress) {
            if (linkConstructor.tokenAddress.from && tokenAddress.from) {
                link += '&' + linkConstructor.tokenAddress.from + '=' + tokenAddress.from;
            }
            if (linkConstructor.tokenAddress.to && tokenAddress.to) {
                link += '&' + linkConstructor.tokenAddress.to + '=' + tokenAddress.to;
            }
        }
        if (linkConstructor.chainId && chainId) {
            if (linkConstructor.chainId.from && chainId) {
                link += '&' + linkConstructor.chainId.from + '=' + chainId;
            }
            if (linkConstructor.chainId.to && chainId) {
                link += '&' + linkConstructor.chainId.to + '=' + chainId;
            }
        }
        swapSite.link = link;
    }
}
export default BLIDPriceActions;
