import { useWalletStore } from '@/web3-server/wallet';
import { stepTransactionMessages } from '@/web3-server/web3/data/transaction-step-by-step';
import { NETWORK_SWITCH_STATUSED } from '@/web3-server/wallet/types/provider/constants';
import getOperations from '@/store/operations/operations';
import { TRANSACTION_TYPES } from '@/infra/types/transactions/constants';
import { PROCESS_ID_FOR_TRANSACTION_STEP, TRANSACTION_STEP_ID, } from '@/web3-server/web3/types/transaction-step-by-step/constants';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import converter from '@/web3-server/utils/converter';
import { myDialog } from '@/store/my-dialog';
import { useDialogStore } from '@/store/dialog';
import { useCurrenciesStore } from '@/store/currencies';
import GAManager from '@/utils/ga-manager/ga-manager';
import { GA_EVENT_NAME } from '@/infra/types/ga-manager/constants';
import checker from '@/web3-server/utils/checker';
import { decimalFormatting } from '@/composables/useFormatCurrency';
const transactionStepByStep = {
    getSteps(requiredNetwork, isApproved) {
        const listOfStepId = this.getRequiredStepIds(requiredNetwork, isApproved);
        return [];
    },
    getRequiredStepIds(requiredNetwork, isApproved) {
        const walletStore = useWalletStore();
        const currentNetwork = walletStore.network;
        const steps = [TRANSACTION_STEP_ID.APPROVE, TRANSACTION_STEP_ID.MINT];
        if (currentNetwork && currentNetwork.id && currentNetwork.id !== requiredNetwork) {
            steps.unshift(TRANSACTION_STEP_ID.SWITCH_NETWORK);
        }
        return steps;
    },
    getDefaultMessages(stepId) {
        return stepTransactionMessages.default[stepId];
    },
    getMessage(messages, processId, extra) {
        const getText = () => {
            let text = '';
            if (extra) {
                if (extra.amount) {
                    text =
                        ' ' +
                            decimalFormatting(extra.amount, 3, {
                                isRounding: 'up',
                                hideFractionsFromIntegers: true,
                                showWithManyZeros: true,
                            });
                }
            }
            return text;
        };
        const message = messages && messages.length
            ? messages.find((item) => item.processId === processId)
            : undefined;
        return message
            ? message.text.replace(' $amount', getText())
            : stepTransactionMessages.universal[processId];
    },
    approve(updateText, extra) {
        const messages = this.getDefaultMessages(TRANSACTION_STEP_ID.APPROVE);
        const msg = (processId) => updateText(processId, this.getMessage(messages, processId, extra && extra.priceForEveryOne
            ? { amount: extra.priceForEveryOne.toFixed() }
            : undefined));
        if (extra) {
            const { currency, GAPrefix, contractAddress } = extra;
            const GAEvent = GAManager.hooks.approve({
                eventName: GA_EVENT_NAME.APPROVE_NFT,
                prefix: GAPrefix,
            }, { currencyType: currency.symbol });
            const defaultProps = {
                currencyType: currency.symbol,
                availableForUse: {
                    sendEventsToGA: { readyEvents: GAEvent },
                    addToWalletHistory: {
                        transactionType: TRANSACTION_TYPES.APPROVE,
                        amount: extra.priceForEveryOne.toFixed() || '0',
                    },
                },
            };
            const openErrorNotify = (subtitle) => {
                myDialog.openNotifyAboutTransaction({ isSuccess: false, subtitle }, {
                    stepBack: 1,
                });
            };
            const currencyAddress = currency.address;
            const networkId = extra.requiredNetwork;
            // const approvedCurrency: IApprovedCurrency = { contractAddress, currencyAddress };
            // currenciesStore.currencyToBeApproved.push(approvedCurrency);
            let toastId = Date.now();
            let savedHash = undefined;
            const approveOperation = getOperations(defaultProps, openErrorNotify).approve({
                networkId,
                currencyAddress,
                contractAddress,
            });
            const defaultApproveEvents = {
                allowedByUser: (allowed, hash, error) => {
                    if (GAEvent && GAEvent.signature) {
                        GAEvent.signature(allowed);
                    }
                    if (allowed) {
                        savedHash = hash;
                        toastId = approveOperation.allowedByUser.onSuccess(hash);
                        msg(PROCESS_ID_FOR_TRANSACTION_STEP.PENDING);
                    }
                    else {
                        approveOperation.allowedByUser.onError(error);
                        msg(PROCESS_ID_FOR_TRANSACTION_STEP.CANCELLED);
                    }
                },
                transactionCompleted: async (completed) => {
                    if (completed) {
                        const approvedAmount = await approveOperation.transactionCompleted.onSuccess({
                            toastId,
                            hash: savedHash,
                        });
                        const approvedDecimal = converter.numericStr.toDecimal(approvedAmount);
                        if (checker.decimal.isNotEmpty(extra.priceForEveryOne)) {
                            if (approvedDecimal.greaterThanOrEqualTo(extra.priceForEveryOne)) {
                                msg(PROCESS_ID_FOR_TRANSACTION_STEP.SUCCESS);
                            }
                            else {
                                msg(PROCESS_ID_FOR_TRANSACTION_STEP.CONDITIONS_NOT_MET);
                            }
                        }
                        else {
                            msg(PROCESS_ID_FOR_TRANSACTION_STEP.SUCCESS);
                        }
                        extra.successCallback(approvedDecimal);
                    }
                    else {
                        approveOperation.transactionCompleted.onError({ toastId, hash: savedHash });
                        msg(PROCESS_ID_FOR_TRANSACTION_STEP.FAILED);
                    }
                },
            };
            msg(PROCESS_ID_FOR_TRANSACTION_STEP.STARTED);
            this.approveCurrencyEIP1559(currencyAddress, contractAddress, { simpleValue: extra.priceForEveryOne, decimals: currency.decimals }, defaultApproveEvents);
        }
    },
    mintNFTFromStepByStep(updateText, extra) {
        const messages = this.getDefaultMessages(TRANSACTION_STEP_ID.MINT);
        const msg = (processId) => updateText(processId, this.getMessage(messages, processId, extra && extra.selectedQuantity ? { amount: extra.selectedQuantity } : undefined));
        if (extra) {
            let toastId = Date.now();
            let savedHash = undefined;
            const defaultProps = {
                currencyType: extra.currencyType,
                availableForUse: {
                    sendEventsToGA: {
                        eventName: GA_EVENT_NAME.MINT_NFT,
                        GAPrefix: extra.GAPrefix,
                        extra: {
                            currencyType: extra.currencyType,
                            amount: extra.amountSimple.toFixed(),
                        },
                    },
                    addToWalletHistory: {
                        transactionType: TRANSACTION_TYPES.MINT_NFT,
                        amount: extra.amountSimple.toFixed(),
                    },
                },
            };
            const openErrorNotify = (subtitle) => {
                myDialog.openNotifyAboutTransaction({ isSuccess: false, subtitle }, {
                    stepBack: 1,
                });
            };
            const operations = getOperations(defaultProps, openErrorNotify);
            const defaultEvents = {
                allowedByUser(allowed, hash, error) {
                    if (allowed) {
                        savedHash = hash;
                        toastId = operations.default.allowedByUser.onSuccess(extra.requiredNetwork, 'Mint NFT transaction', hash, true);
                        msg(PROCESS_ID_FOR_TRANSACTION_STEP.PENDING);
                    }
                    else {
                        operations.default.allowedByUser.onError(error);
                        msg(PROCESS_ID_FOR_TRANSACTION_STEP.CANCELLED);
                    }
                },
                transactionCompleted(completed, response) {
                    if (completed) {
                        operations.default.transactionCompleted.onSuccess({ toastId, hash: savedHash }, false);
                        msg(PROCESS_ID_FOR_TRANSACTION_STEP.SUCCESS);
                        extra.successCallback();
                    }
                    else {
                        operations.default.transactionCompleted.onError({ toastId, hash: savedHash }, response && response.error);
                        msg(PROCESS_ID_FOR_TRANSACTION_STEP.FAILED);
                    }
                },
            };
            msg(PROCESS_ID_FOR_TRANSACTION_STEP.STARTED);
            this.mintNFT(extra.selectedQuantity, {
                allowedByUser: (allowed, hash, error) => defaultEvents.allowedByUser(allowed, hash, error),
                transactionCompleted: (completed, response) => defaultEvents.transactionCompleted(completed, response),
            });
        }
    },
    async switchNetwork(updateText, extra) {
        const walletStore = useWalletStore();
        const messages = this.getDefaultMessages(TRANSACTION_STEP_ID.SWITCH_NETWORK);
        const msg = (processId) => updateText(processId, this.getMessage(messages, processId));
        if (extra && extra.requiredNetwork) {
            msg(PROCESS_ID_FOR_TRANSACTION_STEP.STARTED);
            return walletStore.switchNetwork(extra.requiredNetwork).then((status) => {
                if (status === NETWORK_SWITCH_STATUSED.SUCCESS) {
                    msg(PROCESS_ID_FOR_TRANSACTION_STEP.SUCCESS);
                }
                else if (status === NETWORK_SWITCH_STATUSED.ERROR) {
                    msg(PROCESS_ID_FOR_TRANSACTION_STEP.FAILED);
                }
                else if (status === NETWORK_SWITCH_STATUSED.RE_LOGIN_VIA_WC) {
                    const dialogStore = useDialogStore();
                    myDialog.openReLoginWC(extra.requiredNetwork, () => {
                        if (walletStore.isAuth) {
                            dialogStore.close();
                        }
                        else {
                            location.reload();
                        }
                    });
                }
                return status;
            });
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR);
            return NETWORK_SWITCH_STATUSED.ERROR;
        }
    },
    getCurrentProcess(stepId, requiredNetwork, isApproved, extraForApproval, pendingStep) {
        const walletStore = useWalletStore();
        const currenciesStore = useCurrenciesStore();
        const currentNetwork = walletStore.network;
        let processId = PROCESS_ID_FOR_TRANSACTION_STEP.STARTED;
        if (pendingStep && pendingStep === stepId) {
            processId = PROCESS_ID_FOR_TRANSACTION_STEP.PENDING;
        }
        else if (stepId === TRANSACTION_STEP_ID.SWITCH_NETWORK &&
            currentNetwork &&
            currentNetwork.id &&
            currentNetwork.id === requiredNetwork) {
            processId = PROCESS_ID_FOR_TRANSACTION_STEP.SUCCESS;
        }
        else if (stepId === TRANSACTION_STEP_ID.APPROVE) {
            if (isApproved) {
                processId = PROCESS_ID_FOR_TRANSACTION_STEP.SUCCESS;
            }
            else if (extraForApproval &&
                extraForApproval.currencyAddress &&
                extraForApproval.contractAddress &&
                currenciesStore.getInProcessOfApproval(extraForApproval.currencyAddress, extraForApproval.contractAddress)) {
                processId = PROCESS_ID_FOR_TRANSACTION_STEP.PENDING;
            }
        }
        return {
            id: stepId,
            processId,
            message: this.getMessage(this.getDefaultMessages(stepId), processId),
            time: {
                started: Date.now(),
            },
        };
    },
};
export default transactionStepByStep;
