import Web3 from 'web3';
import { getDefaultCalcByDecimal } from '@/web3-server/web3/handler/calc';
import Decimal from 'decimal.js';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export default async function getWalletBalance(rpcUrl, userWalletAddress) {
    try {
        const web = new Web3(rpcUrl);
        const balance = await web.eth.getBalance(userWalletAddress);
        return getDefaultCalcByDecimal(new Decimal(balance || 0), { type: 'divide', times: 1 });
    }
    catch (e) {
        devError(DEV_ERROR_CODE.WEB_CONTRACT);
        return getDefaultCalcByDecimal(new Decimal(0));
    }
}
