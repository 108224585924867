import ERC20 from '@/infra/abis/ERC20.json';
import { devError } from '@/utils/devError';
import converter from '@/web3-server/utils/converter';
import Decimal from 'decimal.js';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export default function getCrossCurrency({ web3, userWalletAddress }, strategyContractAddress, currency) {
    try {
        const currencyContract = new web3.eth.Contract(ERC20.abi, currency.address);
        return {
            availableBalancePromise: currencyContract.methods
                .balanceOf(userWalletAddress)
                .call()
                .then((availableBalance) => {
                return converter.withoutType.fromWei(availableBalance, { decimals: currency.decimals });
            })
                .catch(() => {
                devError(DEV_ERROR_CODE.WEB_CONTRACT_METHOD);
                return new Decimal(0);
            }),
            approvedPromise: currency.burnAddress
                ? new Promise((resolve) => resolve('115792089237316195423570985008687907853269984665640564039457'))
                : currencyContract.methods
                    .allowance(userWalletAddress, strategyContractAddress)
                    .call()
                    .then((allowance) => {
                    return converter.withoutType
                        .fromWei(allowance, { decimals: currency.decimals })
                        .toFixed();
                })
                    .catch(() => {
                    devError(DEV_ERROR_CODE.WEB_CONTRACT_METHOD);
                    return '0';
                }),
        };
    }
    catch (e) {
        devError(e);
        return null;
    }
}
