import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
import { PID } from '@/infra/types/contracts/constants';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import Decimal from 'decimal.js';
import converter from '@/web3-server/utils/converter';
export default async function getEarnedIncome(userWalletAddress, contract, contractType, BLIDPriceSimple) {
    const defaultEarnedIncome = {
        earnedBLID: new Decimal(0),
        earnedUSD: new Decimal(0),
    };
    try {
        let promise = null;
        if (contractType === CONTRACT_TYPE.STORAGE) {
            promise = contract.methods.balanceEarnBLID(userWalletAddress).call();
        }
        else if (contractType === CONTRACT_TYPE.STAKING) {
            promise = contract.methods.pendingBlid(PID.STAKING_BLID, userWalletAddress).call();
        }
        else if (contractType === CONTRACT_TYPE.FARMING) {
            promise = contract.methods.pendingBlid(PID.FAMING_BLID_USDT, userWalletAddress).call();
        }
        if (contractType === CONTRACT_TYPE.STORAGE) {
            const boosting = contract.methods
                .getBoostingClaimableBLID(userWalletAddress)
                .call();
            if (promise) {
                return promise
                    .then((value) => {
                    return boosting
                        .then((boostValue) => {
                        const balanceEarnBLID = new Decimal(value);
                        const boostingClaimableBLID = new Decimal(boostValue);
                        const calc = balanceEarnBLID.plus(boostingClaimableBLID);
                        return calcIncome(calc.toFixed(), BLIDPriceSimple);
                    })
                        .catch(() => {
                        return calcIncome(value, BLIDPriceSimple);
                    });
                })
                    .catch(() => {
                    return defaultEarnedIncome;
                });
            }
            else {
                return defaultEarnedIncome;
            }
        }
        else {
            return promise
                ? promise.then((value) => calcIncome(value, BLIDPriceSimple))
                : defaultEarnedIncome;
        }
    }
    catch (e) {
        devError(DEV_ERROR_CODE.GET_EARNED_BLID);
        return defaultEarnedIncome;
    }
}
function calcIncome(incomeWei, BLIDPriceSimple) {
    const earnedBLIDSimple = converter.withoutType.fromWei(incomeWei);
    return {
        earnedBLID: earnedBLIDSimple,
        earnedUSD: new Decimal(earnedBLIDSimple).mul(BLIDPriceSimple),
    };
}
