import Decimal from 'decimal.js';
import { getNftCollection } from '@/web3-server/web3/actions/nft/get-nft-collection';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import linker from '@/web3-server/utils/linker';
import { mintNft } from '@/web3-server/web3/actions/nft/mint-nft';
import { requestAPI } from '@/web3-server/web3/handler/inspectors';
import { useWeb3Currencies } from '@/web3-server/web3-currencies';
import { useWalletStore } from '@/web3-server/wallet';
import { getPriceByQuantity } from '@/web3-server/web3/actions/nft/get-price-by-quantity';
import NFTDistributionWithPrice from '@/web3-server/abis/nft/distributor-with-price.json';
import converter from '@/web3-server/utils/converter';
const nftActions = {
    calcUserCapabilitiesForNft(totalPrice, currency) {
        const userPrice = totalPrice.value.minus(currency && currency.availableUserBalance ? currency.availableUserBalance.value : 0);
        const priceForUser = linker.decimal.getSimple(userPrice.isNegative() ? new Decimal(0) : userPrice);
        return {
            priceForUser,
            balanceIsEnoughForMinting: priceForUser.value.isZero(),
            approvedAmountSufficientForMinting: currency && currency.approvedAmountByUser
                ? currency.approvedAmountByUser.value.greaterThanOrEqualTo(totalPrice.value)
                : false,
        };
    },
    async getPriceByQuantity(networkId, nftDistributionContractAddress, amount, currencyDecimals) {
        const request = requestAPI('default', { web3: { networkId } });
        if (request.is &&
            nftDistributionContractAddress &&
            amount &&
            amount !== '0' &&
            currencyDecimals) {
            return getPriceByQuantity(request.get, {
                address: nftDistributionContractAddress,
                abi: NFTDistributionWithPrice,
            }, amount, currencyDecimals);
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR, {
                nftDistributionContractAddress,
                amount,
                currencyDecimals,
                request: request.is,
            });
            return converter.withType.fromWei('0');
        }
    },
    async getCollection(currencyId, requiredNetworkId) {
        const walletStore = useWalletStore();
        const web3Currencies = useWeb3Currencies();
        const nftConfig = this.nftCollection;
        const currency = web3Currencies.getById(currencyId);
        if (nftConfig.address && currency) {
            const response = await getNftCollection([{ networkId: requiredNetworkId, value: this.web3ByNetwork[requiredNetworkId] }], walletStore.userAddress, currency, {
                id: nftConfig.activeTokenId,
                contractAddress: nftConfig.address,
                abi: nftConfig.abi,
                networkId: nftConfig.networkId,
            });
            const { priceForEveryone, notAvailableQuantity, totalQuantity, isMintAvailable, imgPromise } = response.collection;
            const userCapabilities = this.calcUserCapabilitiesForNft(priceForEveryone, response.currency);
            return {
                basic: {
                    priceForEveryone,
                    priceForUser: userCapabilities.priceForUser,
                    notAvailableQuantity,
                    availableQuantity: linker.decimal.getSimple(totalQuantity.value.minus(notAvailableQuantity.value)),
                    totalQuantity,
                    state: {
                        balanceIsEnoughForMinting: userCapabilities.balanceIsEnoughForMinting,
                        approvedAmountSufficientForMinting: userCapabilities.approvedAmountSufficientForMinting,
                        isMintAvailable,
                    },
                },
                imgPromise,
                currency: response.currency,
            };
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR, nftConfig);
            return null;
        }
    },
    mintNFT(quantity = '1', events) {
        const nftConfig = this.nftCollection;
        const request = requestAPI('user', { events });
        if (request.isUser && nftConfig.address) {
            mintNft(request.getWithUser, {
                address: nftConfig.address,
                abi: nftConfig.abi,
                id: nftConfig.activeTokenId,
            }, quantity);
        }
        else {
            throw DEV_ERROR_CODE.WEB_STORAGE_NO_VAR;
        }
    },
};
export default nftActions;
