import multiCallManager from '@/web3-server/web3/handler/multicall-manager';
import { CURRENCY_ID } from '@/web3-server/web3-currencies/types/config/constants';
import ERC20 from '@/infra/abis/ERC20.json';
import converter from '@/web3-server/utils/converter';
import Decimal from 'decimal.js';
import multiCallUtils from '@/web3-server/web3/handler/multicall-utils';
import { CALL_REFERENCE_FOR_CURRENCY } from '@/web3-server/web3-currencies/types/multicall-currencies/constants';
var NAME_OF_METHODS;
(function (NAME_OF_METHODS) {
    NAME_OF_METHODS["GET_TOKEN_BALANCE"] = "balanceOf";
    NAME_OF_METHODS["GET_TOKEN_APPROVAL"] = "allowance";
})(NAME_OF_METHODS || (NAME_OF_METHODS = {}));
const nameOfMethodsByCallReference = {
    [CALL_REFERENCE_FOR_CURRENCY.GET_TOKEN_APPROVAL]: NAME_OF_METHODS.GET_TOKEN_APPROVAL,
    [CALL_REFERENCE_FOR_CURRENCY.GET_TOKEN_BALANCE]: NAME_OF_METHODS.GET_TOKEN_BALANCE,
};
export async function getSimpleBasicInfo(request, userWalletAddress, currencies, contractAddresses, balanceIsNeed) {
    const approved = [];
    const balances = [];
    const multiCallByStrategies = multiCallManager(request, getContractsForMultiCall(currencies, contractAddresses));
    const calls = getCalls(currencies, contractAddresses, userWalletAddress, balanceIsNeed);
    const multiCallResponse = await multiCallByStrategies.call({
        multiCall: calls.filter((item) => !!item.value.length),
    });
    if (multiCallResponse && multiCallResponse.length) {
        multiCallResponse.forEach((callsResults) => {
            const currentCurrency = getCurrencyApprovalAndBalance(callsResults);
            if (currentCurrency) {
                if (currentCurrency.approved.length) {
                    approved.push(...currentCurrency.approved);
                }
                if (currentCurrency.balance) {
                    balances.push(currentCurrency.balance);
                }
            }
        });
    }
    return { approved, balances };
}
export function getCurrencyContractsForMultiCall(currencies, contractAddresses) {
    return getContractsForMultiCall(currencies, contractAddresses);
}
export function getCurrencyCallsForMultiCall(currencies, contractAddresses, userWalletAddress, balanceIsNeed) {
    return getCalls(currencies, contractAddresses, userWalletAddress, balanceIsNeed);
}
export function getDataAboutCurrenciesForMultiCall(userWalletAddress, currencies, contractAddresses, balanceIsNeed) {
    return {
        contacts: getContractsForMultiCall(currencies, contractAddresses),
        calls: getCalls(currencies, contractAddresses, userWalletAddress, balanceIsNeed),
    };
}
function getContractsForMultiCall(currencies, contractAddresses) {
    const extra = getExtraForCurrencyContracts(currencies, contractAddresses);
    return getSimpleCurrencies(currencies).map((currency) => {
        return {
            id: currency.id,
            networkId: currency.relations.networkId,
            contractAddress: currency.address,
            abi: ERC20.abi,
            extra: extra.filter((item) => item.currencyId === currency.id),
        };
    });
}
function getExtraForCurrencyContracts(currencies, contractAddresses) {
    const extra = [];
    contractAddresses.forEach((contract) => {
        const currency = currencies.find((item) => item.id === contract.currencyId);
        if (currency) {
            extra.push({
                reference: `${CALL_REFERENCE_FOR_CURRENCY.GET_TOKEN_APPROVAL}${currency.id}`,
                decimals: currency.decimals,
                currencyId: currency.id,
                currencyAddress: currency.address,
                contractAddress: contract.address,
            });
        }
    });
    return extra;
}
function getCalls(currencies, contractAddresses, userWalletAddress, balanceIsNeed) {
    const calls = [];
    const callReferenceForTokenBalance = CALL_REFERENCE_FOR_CURRENCY.GET_TOKEN_BALANCE;
    contractAddresses.forEach((contract) => {
        const currency = currencies.find((item) => item.id === contract.currencyId);
        if (currency) {
            if (!currency.burnAddress) {
                calls.push(getTokenApprovalByStrategy(currency, contract.address, userWalletAddress));
            }
        }
    });
    if (balanceIsNeed) {
        getSimpleCurrencies(currencies).forEach((currency) => {
            calls.push({
                id: currency.id,
                networkId: currency.relations.networkId,
                value: [
                    {
                        reference: `${callReferenceForTokenBalance}${currency.id}`,
                        methodName: nameOfMethodsByCallReference[callReferenceForTokenBalance],
                        methodParameters: [userWalletAddress],
                    },
                ],
            });
        });
    }
    return calls;
}
function getTokenApprovalByStrategy(currency, contractAddress, userWalletAddress) {
    const callReference = CALL_REFERENCE_FOR_CURRENCY.GET_TOKEN_APPROVAL;
    return {
        id: currency.id,
        networkId: currency.relations.networkId,
        value: [
            {
                reference: `${callReference}${currency.id}`,
                methodName: nameOfMethodsByCallReference[callReference],
                methodParameters: [userWalletAddress, contractAddress],
            },
        ],
    };
}
function getCurrencyApprovalAndBalance(callsResults) {
    let balance = null;
    const approved = [];
    const listOfCurrencyId = Object.values(CURRENCY_ID);
    const currenciesForApproved = callsResults.extra;
    const currencyId = callsResults.id;
    const decimals = currenciesForApproved?.length
        ? currenciesForApproved.find((item) => item.currencyId === callsResults.id)?.decimals
        : 18;
    for (const item of callsResults.value) {
        const currentReference = currenciesForApproved?.length
            ? currenciesForApproved.find((currency) => currency.reference === item.reference && currency.currencyId === callsResults.id)
            : undefined;
        if (currentReference) {
            const wei = multiCallUtils.getVal('hex', item, 0);
            const simple = converter.withoutType.fromWei(wei, { decimals: currentReference.decimals });
            approved.push({
                currencyId: currentReference.currencyId,
                currencyAddress: currentReference.currencyAddress,
                contractAddress: currentReference.contractAddress,
                approvedAmount: {
                    wei: new Decimal(wei),
                    simple,
                    simpleInStringFormat: simple.toFixed(),
                },
            });
        }
        else if (listOfCurrencyId.includes(currencyId) &&
            item.reference === CALL_REFERENCE_FOR_CURRENCY.GET_TOKEN_BALANCE + callsResults.id) {
            const wei = multiCallUtils.getVal('hex', item, 0);
            const simple = converter.withoutType.fromWei(wei, { decimals });
            balance = {
                currencyId,
                balance: {
                    wei: new Decimal(wei),
                    simple,
                    simpleInStringFormat: simple.toFixed(),
                },
            };
        }
    }
    return {
        approved,
        balance,
    };
}
function getSimpleCurrencies(currencies) {
    return currencies.filter((currency) => isNotNative(currency));
}
function isNotNative(currency) {
    return !currency.burnAddress;
}
