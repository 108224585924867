import Decimal from 'decimal.js';
import { getDefaultCalcByDecimal, getShortDecimal } from '@/web3-server/web3/handler/calc';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import web3 from 'web3';
import getCalcCurrentAPY from '@/web3-server/web3/actions/strategies/boost/get-calc-current-apy';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import { walletNetworkById } from '@/web3-server/wallet/data/network';
const BLOCK_PER_YEAR = {
    [NETWORK_ID.BNB]: new Decimal(web3.utils.toWei(walletNetworkById.BNB.blockPerYear)),
    [NETWORK_ID.POLYGON]: new Decimal(web3.utils.toWei(walletNetworkById.POLYGON.blockPerYear)),
    [NETWORK_ID.ETH]: new Decimal(web3.utils.toWei(walletNetworkById.ETH.blockPerYear)),
    [NETWORK_ID.ARBITRUM]: new Decimal(web3.utils.toWei(walletNetworkById.ARBITRUM.blockPerYear)),
    [NETWORK_ID.OPTIMISM]: new Decimal(web3.utils.toWei(walletNetworkById.OPTIMISM.blockPerYear)),
    [NETWORK_ID.GOERLI]: new Decimal(web3.utils.toWei(walletNetworkById.GOERLI.blockPerYear)),
};
export function getConfigForBoosting({ web3, userWalletAddress, BLIDPriceWei }, strategyContract, networkId) {
    try {
        const storageContract = new web3.eth.Contract(strategyContract.abi, strategyContract.address);
        return getConfig(storageContract, userWalletAddress, BLIDPriceWei, networkId);
    }
    catch (e) {
        devError(DEV_ERROR_CODE.WEB_CONTRACT);
        return null;
    }
}
export async function getBoostingMaxAPY(storageContract, BLIDPriceWei, networkId, strategyResults) {
    const defaultResult = {
        apr: {
            original: 0,
            roundedDown: 0,
        },
        apy: {
            original: 0,
            roundedDown: 0,
        },
        pureAPR: {
            original: 0,
            roundedDown: 0,
        },
    };
    try {
        const BLIDPerBlockPromise = storageContract.methods.blidPerBlock().call();
        const maxBLIDPerUSDPromise = storageContract.methods.maxBlidPerUSD().call();
        return getMaxAPYPromise(BLIDPerBlockPromise, maxBLIDPerUSDPromise, BLIDPriceWei, networkId)
            .then((boosting) => {
            return {
                apr: getBoostingRounded(boosting.apr, strategyResults && strategyResults.apr),
                apy: getBoostingRounded(boosting.apy, strategyResults && strategyResults.apy),
                pureAPR: getBoostingRounded(boosting.apr),
            };
        })
            .catch(() => {
            return defaultResult;
        });
    }
    catch (e) {
        return defaultResult;
    }
}
export async function getUserBoosting(storageContract, userWalletAddress, BLIDPriceWei, networkId, strategyResult) {
    const defaultResult = {
        apr: {
            original: 0,
            roundedDown: 0,
        },
        apy: {
            original: 0,
            roundedDown: 0,
        },
    };
    try {
        const { maxAPYPromise, getBoostingBLIDAmountPromise, BLIDNeedPromise } = getConfig(storageContract, userWalletAddress, BLIDPriceWei, networkId);
        return Promise.all([maxAPYPromise, getBoostingBLIDAmountPromise, BLIDNeedPromise])
            .then((values) => {
            const maxAPYData = values[0];
            const depositedBLID = new Decimal(values[1]);
            const BLIDNeed = values[2];
            if (!BLIDNeed.decimal.isZero() && !depositedBLID.isZero()) {
                const currentAPYData = getCalcCurrentAPY(BLIDNeed.decimal, depositedBLID);
                if (new Decimal(currentAPYData.apr.formatted.full).greaterThan(maxAPYData.apr.formatted.full)) {
                    return getBoostingMaxAPY(storageContract, BLIDPriceWei, networkId, strategyResult).then((boostingMAX) => {
                        return {
                            apr: boostingMAX.apr,
                            apy: boostingMAX.apy,
                        };
                    });
                }
                else {
                    return {
                        apr: getBoostingRounded(currentAPYData.apr, strategyResult && strategyResult.apr),
                        apy: getBoostingRounded(currentAPYData.apy, strategyResult && strategyResult.apy),
                    };
                }
            }
            else {
                return defaultResult;
            }
        })
            .catch(() => {
            return defaultResult;
        });
    }
    catch (e) {
        return defaultResult;
    }
}
function getBoostingRounded(boosting, apy) {
    const result = {
        original: 0,
        roundedDown: 0,
    };
    if (boosting && boosting.formatted && boosting.formatted.full) {
        const calc = new Decimal(boosting.formatted.full).plus(apy || 0);
        result.original = calc.toNumber();
        result.roundedDown = getShortDecimal(calc, 2, 'down').toNumber();
    }
    return result;
}
function getConfig(storageContract, userWalletAddress, BLIDPriceWei, networkId) {
    const userBalancePromise = storageContract.methods
        .balanceOf(userWalletAddress)
        .call();
    const BLIDPerBlockPromise = storageContract.methods.blidPerBlock().call();
    const getBoostingBLIDAmountPromise = storageContract.methods
        .getBoostingBLIDAmount(userWalletAddress)
        .call();
    const maxBLIDPerUSDPromise = storageContract.methods.maxBlidPerUSD().call();
    const BLIDNeedPromise = Promise.all([
        BLIDPerBlockPromise,
        userBalancePromise,
    ])
        .then((values) => {
        const BLIDPerBlock = new Decimal(values[0]);
        const balanceWei = new Decimal(values[1]);
        const hundred = new Decimal(web3.utils.toWei('100'));
        const first = BLIDPerBlock.mul(BLOCK_PER_YEAR[networkId] || BLOCK_PER_YEAR.BNB)
            .mul(BLIDPriceWei)
            .mul(hundred);
        const calc = new Decimal(balanceWei).div(first);
        return getDefaultCalcByDecimal(calc, { type: 'multiply', times: 3 });
    })
        .catch((e) => {
        devError(DEV_ERROR_CODE.WEB_CONTRACT_METHOD);
        throw e;
    });
    return {
        maxAPYPromise: getMaxAPYPromise(BLIDPerBlockPromise, maxBLIDPerUSDPromise, BLIDPriceWei, networkId),
        getBoostingBLIDAmountPromise,
        BLIDNeedPromise,
    };
}
function getMaxAPYPromise(BLIDPerBlockPromise, maxBLIDPerUSDPromise, BLIDPriceWei, networkId) {
    return Promise.all([BLIDPerBlockPromise, maxBLIDPerUSDPromise])
        .then((values) => {
        const BLIDPerBlock = new Decimal(values[0]);
        const maxBLIDPerUSDWei = new Decimal(values[1]).div(2);
        const hundred = new Decimal(web3.utils.toWei('100'));
        const max = BLIDPerBlock.mul(BLOCK_PER_YEAR[networkId] || BLOCK_PER_YEAR.BNB)
            .mul(BLIDPriceWei)
            .mul(maxBLIDPerUSDWei)
            .mul(hundred);
        const apr = getDefaultCalcByDecimal(max, { type: 'divide', times: 5 });
        const pureBoosting = new Decimal(apr.formatted.full).div(100).div(365);
        const boostingAPY = new Decimal(Decimal.pow(new Decimal(1).plus(pureBoosting), 365))
            .minus(1)
            .mul(100);
        return {
            apr,
            apy: getDefaultCalcByDecimal(boostingAPY),
        };
    })
        .catch((e) => {
        devError(DEV_ERROR_CODE.WEB_CONTRACT_METHOD);
        throw e;
    });
}
