import { NETWORK_RECEIVE_STATUSES } from '@/web3-server/wallet/types/network/constants';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import { connectToAccount } from '@/web3-server/wallet/actions/connect/composables/connect-to-account';
import { walletNetworkById } from '@/web3-server/wallet/data/network';
const connectActions = {
    connectToAccount(params) {
        return connectToAccount(this, params);
    },
    async connectWC(by) {
        try {
            await this.removeWCProvider();
            const provider = await this.getWCProvider(by, true);
            this.wc.provider = provider;
            if (provider) {
                return provider.enable().then((accounts) => {
                    this.saveConnectionData(accounts);
                    return { provider, accounts };
                });
            }
            else {
                devError(DEV_ERROR_CODE.WC_PROVIDER_NOT_INIT);
                return null;
            }
        }
        catch (e) {
            devError(e);
            return null;
        }
    },
    async connectEth(networkId) {
        const ethProvider = this.getDefaultBrowserExtension();
        const currentNetwork = await this.getAllowedNetworkWithoutProvider();
        if (ethProvider) {
            try {
                return this.requestAccounts(ethProvider, currentNetwork, networkId);
            }
            catch (e) {
                devError(e);
                return null;
            }
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR);
            return null;
        }
    },
    async connectExodus(networkId) {
        const exodusProvider = this.getExodusProvider();
        const currentNetwork = await this.getAllowedNetworkWithoutProvider();
        if (exodusProvider) {
            try {
                return this.requestAccounts(exodusProvider, currentNetwork, networkId);
            }
            catch (e) {
                devError(e);
                return null;
            }
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR);
            return null;
        }
    },
    async connectBlocto(networkId) {
        const bloctoNetworkId = this.getBloctoNetwork();
        const network = walletNetworkById[networkId || bloctoNetworkId];
        const bloctoSDK = this.instantiateBloctoSDK(network.chainId.hex, network.rpcUrl);
        if (bloctoSDK.ethereum) {
            try {
                return this.requestAccounts(bloctoSDK.ethereum, {
                    status: NETWORK_RECEIVE_STATUSES.SUCCESS,
                    response: network,
                }, networkId);
            }
            catch (e) {
                devError(e);
                return null;
            }
        }
        else {
            devError(DEV_ERROR_CODE.MISSING_VAR);
            return null;
        }
    },
    async requestAccounts(ethProvider, currentNetwork, networkId) {
        return ethProvider.request({ method: 'eth_requestAccounts' }).then((response) => {
            if (response && Array.isArray(response) && response.length) {
                this.saveConnectionData(response);
                if (networkId &&
                    currentNetwork &&
                    currentNetwork.response &&
                    currentNetwork.response.id !== networkId) {
                    this.switchNetwork(networkId);
                }
            }
            return response;
        });
    },
    saveConnectionData(accounts) {
        this.userAddress = accounts && Array.isArray(accounts) && accounts.length ? accounts[0] : '';
        this.updateProviderWithNetwork();
    },
    disconnect(disableAutoLogin) {
        if (disableAutoLogin) {
            this.autoAuthToggle(true);
        }
        this.removeWCProvider();
        this.removeBloctoSession();
        this.disconnectToLiFi();
        this.clearWallet();
    },
    autoAuthToggle(toggle) {
        if (toggle) {
            localStorage.setItem(this.disableAutoLogin.key, 'true');
            this.disableAutoLogin.value = true;
        }
        else {
            localStorage.removeItem(this.disableAutoLogin.key);
            this.disableAutoLogin.value = false;
        }
    },
    isAutoAuth(key) {
        return localStorage.getItem(key || this.disableAutoLogin.key) === 'true';
    },
};
export default connectActions;
