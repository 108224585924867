export default function getListOfCurrencyTags(list, listOfStrategies, networkVault) {
    return list.map((item) => {
        const strategy = listOfStrategies.find((strategy) => strategy.id === item.strategyId);
        let currencies = [];
        if (item.currencies.length > 1 && strategy) {
            const isCrossChain = !!(networkVault &&
                strategy.vault !== networkVault &&
                strategy.crossChain &&
                strategy.crossChain.availableCurrencies &&
                strategy.crossChain.availableCurrencies.length);
            if (isCrossChain && strategy.crossChain) {
                const availableCurrencies = strategy.crossChain.availableCurrencies;
                const templateCurrencies = item.currencies.filter((currency) => currency.vault === strategy.vault && !currency.forbiddenToDeposit);
                const listOfCrossChainCurrencies = item.currencies.filter((currency) => currency.vault === networkVault &&
                    availableCurrencies.includes(currency.id) &&
                    !currency.forbiddenToDeposit);
                currencies = templateCurrencies.map((currency) => {
                    const crossChainCurrency = listOfCrossChainCurrencies.find((item) => item.symbol === currency.symbol);
                    const finalCurrency = crossChainCurrency ? crossChainCurrency : currency;
                    return {
                        id: finalCurrency.id,
                        symbol: finalCurrency.symbol,
                        disabled: !crossChainCurrency,
                    };
                });
            }
            else {
                currencies = item.currencies
                    .filter((currency) => currency.vault === strategy.vault && !currency.forbiddenToDeposit)
                    .map((currency) => {
                    return {
                        id: currency.id,
                        symbol: currency.symbol,
                        disabled: false,
                    };
                });
            }
        }
        return {
            strategyId: item.strategyId,
            currencies: currencies.length > 1 ? currencies : [],
        };
    });
}
