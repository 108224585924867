import { defineStore } from 'pinia';
import { STRATEGY_ID, STRATEGY_VAULT } from '@/web3-server/web3-strategies/types/config/constants';
import { currencyListAdapter } from '@/components/popup/manager/manager.adapter';
import { useStrategiesStore } from '@/store/perfect-strategies';
import { useWeb3Store } from '@/web3-server';
import updateCurrency from '@/store/currencies/composables/update-currency';
import { BRIDGE_TYPE } from '@/web3-server/web3/types/cross-chain/constants';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import updateCrossCurrency from '@/store/currencies/composables/update-cross-currency';
import { useWalletStore } from '@/web3-server/wallet';
import { CURRENCY_ID, CURRENCY_TYPE } from '@/web3-server/web3-currencies/types/config/constants';
import linker from '@/web3-server/utils/linker';
import { networkForCrossChain, walletNetworkById } from '@/web3-server/wallet/data/network';
import getAllCurrencies from '@/web3-server/web3-currencies/actions/composables/get-all-currencies';
import { listOfWeb3Strategies } from '@/web3-server/web3-strategies/data/config';
import { useWeb3Currencies } from '@/web3-server/web3-currencies';
const allCurrencies = getAllCurrencies(listOfWeb3Strategies);
export const BLIDCurrencyConfig = allCurrencies.byNetwork.BNB.find((item) => item.symbol === CURRENCY_TYPE.BLID) || {
    id: CURRENCY_ID.BNB_BLID,
    symbol: CURRENCY_TYPE.BLID,
    address: process.env.VUE_APP_STAKING_BLID_TOKEN_ADDRESS || '0x32Ee7c89D689CE87cB3419fD0F184dc6881Ab3C7',
    decimals: 18,
};
export const useCurrenciesStore = defineStore('currencies', {
    state: () => ({
        config: {
            currencyTypes: CURRENCY_TYPE,
        },
        commonList: allCurrencies.byList,
        currencyToBeApproved: [],
        approvedCurrenciesUnderContracts: [],
        depositedCurrenciesUnderContracts: [],
        selectedCurrencyForCreateDeposit: null,
        selectedCurrencyForWithdrawal: null,
    }),
    actions: {
        async updateAll(presetNetworkId) {
            const strategiesStore = useStrategiesStore();
            const web3Store = useWeb3Store();
            const listOfPromises = [];
            const BLIDCurrencyForPolygon = this.commonList.find((currency) => currency.id === CURRENCY_ID.POLYGON_BLID);
            const BLIDCurrencyForArbitrum = this.commonList.find((currency) => currency.id === CURRENCY_ID.ARB_BLID);
            const BLIDCurrencyForOptimism = this.commonList.find((currency) => currency.id === CURRENCY_ID.OP_BLID);
            // if (this.approvedCurrenciesUnderContracts.length) {
            //   this.approvedCurrenciesUnderContracts = [];
            // }
            if (BLIDCurrencyForPolygon && BLIDCurrencyForPolygon.address) {
                listOfPromises.push(web3Store
                    .getCurrencyBalance({ address: BLIDCurrencyForPolygon.address }, BLIDCurrencyForPolygon.decimals, { vault: BLIDCurrencyForPolygon.vault })
                    .then((balance) => {
                    BLIDCurrencyForPolygon.balance = balance.value.toFixed();
                    return balance;
                }));
            }
            if (BLIDCurrencyForArbitrum && BLIDCurrencyForArbitrum.address) {
                listOfPromises.push(web3Store
                    .getCurrencyBalance({ address: BLIDCurrencyForArbitrum.address }, BLIDCurrencyForArbitrum.decimals, { vault: BLIDCurrencyForArbitrum.vault })
                    .then((balance) => {
                    BLIDCurrencyForArbitrum.balance = balance.value.toFixed();
                    return balance;
                }));
            }
            if (BLIDCurrencyForOptimism && BLIDCurrencyForOptimism.address) {
                listOfPromises.push(web3Store
                    .getCurrencyBalance({ address: BLIDCurrencyForOptimism.address }, BLIDCurrencyForOptimism.decimals, { vault: BLIDCurrencyForOptimism.vault })
                    .then((balance) => {
                    BLIDCurrencyForOptimism.balance = balance.value.toFixed();
                    return balance;
                }));
            }
            strategiesStore.listOfActiveStrategies.forEach((strategyConfig) => {
                listOfPromises.push(...this.updateCurrencyStrategy(strategyConfig, presetNetworkId));
            });
            return Promise.all(listOfPromises);
        },
        updateCurrencyStrategy(strategyConfig, presetNetworkId) {
            const walletStore = useWalletStore();
            const listOfPromises = [];
            const currentNetwork = presetNetworkId
                ? walletNetworkById[presetNetworkId]
                : walletStore.network;
            if (strategyConfig.crossChain) {
                let networkList = networkForCrossChain[strategyConfig.crossChain]
                    ? Object.values(networkForCrossChain[strategyConfig.crossChain])
                    : [];
                if (currentNetwork && currentNetwork.id === NETWORK_ID.BNB) {
                    networkList = networkList.filter((item) => item.networkId !== NETWORK_ID.BNB);
                }
                networkList.forEach((network) => {
                    listOfPromises.push(this.update({
                        contract: strategyConfig.contract,
                        currencies: strategyConfig.currencies,
                        vault: strategyConfig.vault,
                    }, true, {
                        network: {
                            bridgeType: network.bridgeType,
                            id: network.id,
                            networkId: network.networkId,
                            contractAddress: network.contractAddress,
                        },
                        rpcUrl: network.rpcUrl,
                    }));
                });
            }
            listOfPromises.push(this.update({
                contract: strategyConfig.contract,
                currencies: strategyConfig.currencies,
                vault: strategyConfig.vault,
            }, !!strategyConfig.crossChain, !!strategyConfig.crossChain && currentNetwork && currentNetwork.crossChain
                ? {
                    network: {
                        bridgeType: currentNetwork.crossChain.bridgeType,
                        id: currentNetwork.crossChain.id,
                        networkId: currentNetwork.crossChain.networkId,
                        contractAddress: currentNetwork.crossChain.contractAddress,
                    },
                    rpcUrl: currentNetwork.rpcUrl,
                }
                : undefined));
            return listOfPromises;
        },
        async update(strategy, isCrossChain, extra) {
            const web3Store = useWeb3Store();
            const web3Currencies = useWeb3Currencies();
            const network = extra && 'network' in extra && extra.network;
            let currencies = strategy.currencies;
            const listOfPromises = [];
            if (isCrossChain) {
                if (network) {
                    currencies = strategy.currencies.filter((item) => item.bridgeType === network.bridgeType && item.networkId === network.networkId);
                }
                else {
                    currencies = strategy.currencies.filter((item) => item.networkId === NETWORK_ID.BNB && item.bridgeType === BRIDGE_TYPE.STARGATE);
                }
            }
            currencies.forEach((currency) => {
                if (isCrossChain && network && network.networkId !== NETWORK_ID.BNB) {
                    const web3Currency = updateCrossCurrency(web3Store, network.contractAddress, currency, {
                        vault: linker.getStrategyVault(extra.network.networkId),
                    });
                    if (web3Currency) {
                        web3Currency.approvedPromise.then((approvedAmount) => {
                            const duplicateIndex = this.approvedCurrenciesUnderContracts.findIndex((item) => item.currencyAddress === currency.address &&
                                item.contractAddress === network.contractAddress);
                            if (duplicateIndex >= 0) {
                                this.approvedCurrenciesUnderContracts.splice(duplicateIndex, 1);
                            }
                            this.approvedCurrenciesUnderContracts.push({
                                currencyType: currency.symbol,
                                currencyAddress: currency.address,
                                contractAddress: network.contractAddress,
                                approvedAmount,
                            });
                        });
                        listOfPromises.push(Promise.all([web3Currency.availableBalancePromise, web3Currency.approvedPromise]));
                    }
                }
                else {
                    const web3Currency = updateCurrency(web3Store, web3Currencies, strategy.contract, currency, strategy.vault);
                    if (web3Currency) {
                        web3Currency.approvedPromise.then((approvedAmount) => {
                            const duplicateIndex = this.approvedCurrenciesUnderContracts.findIndex((item) => item.currencyAddress === currency.address &&
                                item.contractAddress === strategy.contract.address);
                            if (duplicateIndex >= 0) {
                                this.approvedCurrenciesUnderContracts.splice(duplicateIndex, 1);
                            }
                            this.approvedCurrenciesUnderContracts.push({
                                currencyType: currency.symbol,
                                currencyAddress: currency.address,
                                contractAddress: strategy.contract.address,
                                approvedAmount,
                            });
                        });
                        listOfPromises.push(Promise.all([
                            web3Currency.availableBalancePromise,
                            web3Currency.approvedPromise,
                            web3Currency.depositPromise,
                        ]));
                    }
                }
            });
            return Promise.all(listOfPromises);
        },
        getApprovedFromWeb3(currencyAddress, contractAddress, currencyDecimals, web3Network) {
            const web3Store = useWeb3Store();
            return web3Store
                .getApprovedCurrency(contractAddress, { currencyAddress }, currencyDecimals, web3Network)
                .then((approvedAmount) => {
                if (approvedAmount) {
                    const currency = this.commonList.find((item) => item.address === currencyAddress);
                    if (currency) {
                        const duplicateIndex = this.approvedCurrenciesUnderContracts.findIndex((item) => item.currencyAddress === currency.address &&
                            item.contractAddress === contractAddress);
                        if (duplicateIndex >= 0) {
                            this.approvedCurrenciesUnderContracts.splice(duplicateIndex, 1);
                        }
                        this.approvedCurrenciesUnderContracts.push({
                            currencyType: currency.symbol,
                            currencyAddress: currency.address,
                            contractAddress,
                            approvedAmount,
                        });
                    }
                }
                return approvedAmount;
            });
        },
        clearCurrencyInProgress(currencyAddress, contractAddress) {
            const index = this.currencyToBeApproved.findIndex((item) => item.currencyAddress === currencyAddress && item.contractAddress === contractAddress);
            if (index > -1) {
                this.currencyToBeApproved.splice(index, 1);
            }
        },
        addDepositToCurrency(currencyId, contractAddress, deposit) {
            const currency = this.commonList.find((item) => item.id === currencyId);
            if (currency && contractAddress && deposit) {
                const index = this.depositedCurrenciesUnderContracts.findIndex((item) => item.contractAddress === contractAddress && item.currencyAddress === currency.address);
                if (index > -1) {
                    if (deposit !== '0') {
                        this.depositedCurrenciesUnderContracts.splice(index, 1, {
                            currencyAddress: currency.address,
                            contractAddress,
                            depositedAmount: deposit,
                            currencyType: currency.symbol,
                        });
                    }
                    else if (this.depositedCurrenciesUnderContracts[index].depositedAmount !== '0') {
                        this.depositedCurrenciesUnderContracts.splice(index, 1);
                    }
                }
                else if (deposit !== '0') {
                    this.depositedCurrenciesUnderContracts.push({
                        currencyAddress: currency.address,
                        contractAddress,
                        depositedAmount: deposit,
                        currencyType: currency.symbol,
                    });
                }
            }
        },
    },
    getters: {
        listForStrategies() {
            return {
                [STRATEGY_ID.BNB_STABLECOINS]: allCurrencies.byNetwork.BNB.filter((item) => [
                    CURRENCY_TYPE.BUSD,
                    CURRENCY_TYPE.USDT,
                    CURRENCY_TYPE.USDC,
                    CURRENCY_TYPE.DAI,
                ].includes(item.symbol)).concat(allCurrencies.byNetwork.POLYGON.filter((item) => item.symbol === CURRENCY_TYPE.USDT), allCurrencies.byNetwork.ETH.filter((item) => item.symbol === CURRENCY_TYPE.USDT)),
                [STRATEGY_ID.BNB_ETH]: allCurrencies.byNetwork.BNB.filter((item) => item.symbol === CURRENCY_TYPE.ETH),
                [STRATEGY_ID.BNB_BTC]: allCurrencies.byNetwork.BNB.filter((item) => item.symbol === CURRENCY_TYPE.BTC),
                [STRATEGY_ID.BNB_BLID]: allCurrencies.byNetwork.BNB.filter((item) => item.symbol === CURRENCY_TYPE.BLID),
                [STRATEGY_ID.BNB_BLID_USDT]: allCurrencies.byNetwork.BNB.filter((item) => item.symbol === CURRENCY_TYPE.BLID_USDT),
                [STRATEGY_ID.BNB_ALTCOINS]: allCurrencies.byNetwork.BNB.filter((item) => [
                    CURRENCY_TYPE.XRP,
                    CURRENCY_TYPE.XVS,
                    CURRENCY_TYPE.LTC,
                    CURRENCY_TYPE.ADA,
                    CURRENCY_TYPE.LINK,
                    CURRENCY_TYPE.DOT,
                    CURRENCY_TYPE.MATIC,
                ].includes(item.symbol)),
                [STRATEGY_ID.POLYGON_USDT]: allCurrencies.byNetwork.POLYGON.filter((item) => [CURRENCY_TYPE.USDT].includes(item.symbol)),
                [STRATEGY_ID.POLYGON_USDC]: allCurrencies.byNetwork.POLYGON.filter((item) => [CURRENCY_TYPE.USDC].includes(item.symbol)),
                [STRATEGY_ID.POLYGON_MATIC]: allCurrencies.byNetwork.POLYGON.filter((item) => [CURRENCY_TYPE.MATIC].includes(item.symbol)),
                [STRATEGY_ID.POLYGON_DAI]: allCurrencies.byNetwork.POLYGON.filter((item) => [CURRENCY_TYPE.DAI].includes(item.symbol)),
                [STRATEGY_ID.POLYGON_ETH]: allCurrencies.byNetwork.POLYGON.filter((item) => [CURRENCY_TYPE.ETH].includes(item.symbol)),
                [STRATEGY_ID.BNB_BNB]: allCurrencies.byNetwork.BNB.filter((item) => [CURRENCY_TYPE.BNB].includes(item.symbol)),
                [STRATEGY_ID.BNB_USDT_V2]: allCurrencies.byNetwork.BNB.filter((item) => [CURRENCY_TYPE.USDT].includes(item.symbol)),
                [STRATEGY_ID.ARBITRUM_USDC_E]: allCurrencies.byNetwork.ARBITRUM.filter((item) => [CURRENCY_TYPE.USDC_E].includes(item.symbol)),
                [STRATEGY_ID.DEV_BNB_USDT_DFORCE]: allCurrencies.byNetwork.BNB.filter((item) => [CURRENCY_TYPE.USDT].includes(item.symbol)),
                [STRATEGY_ID.DEV_POLYGON_STABLECOINS]: allCurrencies.byNetwork.POLYGON.filter((item) => [
                    CURRENCY_TYPE.USDC,
                    CURRENCY_TYPE.ETH,
                    CURRENCY_TYPE.MATIC,
                    CURRENCY_TYPE.DAI,
                ].includes(item.symbol)),
                [STRATEGY_ID.OPTIMISM_USDC_E]: allCurrencies.byNetwork.OPTIMISM.filter((item) => [CURRENCY_TYPE.USDC_E].includes(item.symbol)),
            };
        },
        tagsForStrategies() {
            return Object.keys(this.listForStrategies).reduce((prev, name) => {
                const key = name;
                const currencies = this.listForStrategies[key];
                const vault = linker.getStrategyVault(key);
                if (currencies.length > 1) {
                    prev[key] = currencies
                        .filter((item) => item.vault === vault && !item.forbiddenToDeposit)
                        .map((item) => {
                        return {
                            id: item.id,
                            symbol: item.symbol,
                        };
                    });
                }
                return prev;
            }, {
                [STRATEGY_ID.BNB_STABLECOINS]: [],
                [STRATEGY_ID.BNB_ETH]: [],
                [STRATEGY_ID.BNB_BTC]: [],
                [STRATEGY_ID.BNB_BLID]: [],
                [STRATEGY_ID.BNB_BLID_USDT]: [],
                [STRATEGY_ID.BNB_ALTCOINS]: [],
                [STRATEGY_ID.POLYGON_USDT]: [],
                [STRATEGY_ID.POLYGON_USDC]: [],
                [STRATEGY_ID.POLYGON_MATIC]: [],
                [STRATEGY_ID.POLYGON_DAI]: [],
                [STRATEGY_ID.POLYGON_ETH]: [],
                [STRATEGY_ID.BNB_BNB]: [],
                [STRATEGY_ID.BNB_USDT_V2]: [],
                [STRATEGY_ID.ARBITRUM_USDC_E]: [],
                [STRATEGY_ID.DEV_BNB_USDT_DFORCE]: [],
                [STRATEGY_ID.DEV_POLYGON_STABLECOINS]: [],
                [STRATEGY_ID.OPTIMISM_USDC_E]: [],
            });
        },
        getAddress() {
            return (currencyType, networkId) => {
                const currency = allCurrencies.byNetwork[networkId || NETWORK_ID.BNB].find((item) => item.symbol === currencyType);
                return currency ? currency.address : '';
            };
        },
        getCurrency(state) {
            return (currencyType, network) => {
                let commonList = state.commonList;
                if (network) {
                    if ('vault' in network && network.vault) {
                        commonList = state.commonList.filter((item) => item.vault === network.vault);
                    }
                    else if ('networkId' in network &&
                        network.networkId &&
                        'bridgeType' in network &&
                        network.bridgeType) {
                        commonList = state.commonList.filter((item) => item.networkId === network.networkId && item.bridgeType === network.bridgeType);
                    }
                    else if ('networkId' in network && network.networkId) {
                        commonList = state.commonList.filter((item) => item.networkId === network.networkId);
                    }
                }
                return commonList.find((currency) => currency.symbol === currencyType);
            };
        },
        getInProcessOfApproval(state) {
            return (currencyAddress, contractAddress) => {
                return !!state.currencyToBeApproved.find((item) => item.currencyAddress === currencyAddress && item.contractAddress === contractAddress);
            };
        },
        getApprovedFromList(state) {
            return (currencyAddress, contractAddress) => {
                return state.approvedCurrenciesUnderContracts.find((item) => item.currencyAddress === currencyAddress && item.contractAddress === contractAddress);
            };
        },
        getBLIDFromBNBChain() {
            return this.getCurrency(this.config.currencyTypes.BLID, { vault: STRATEGY_VAULT.BNB });
        },
        strategies(state) {
            const listOfStrategyIds = Object.values(STRATEGY_ID).filter((item) => typeof item === 'number');
            const currencies = {};
            listOfStrategyIds.forEach((strategyId) => {
                const currenciesConfig = this.listForStrategies[strategyId];
                if (currenciesConfig) {
                    const toCreateNewDeposit = currencyListAdapter(currenciesConfig.sort((a, b) => Number(b.balance.replace(/\s/, '')) - Number(a.balance.replace(/\s/, ''))), true);
                    const toWithdrawDeposit = currencyListAdapter(currenciesConfig
                        .filter((item) => item.deposited && item.deposited !== '0')
                        .sort((a, b) => Number(b.deposited.replace(/\s/, '')) - Number(a.deposited.replace(/\s/, ''))), false);
                    currencies[strategyId] = {
                        common: currenciesConfig,
                        createDeposit: {
                            list: toCreateNewDeposit,
                            selected: state.selectedCurrencyForCreateDeposit
                                ? {
                                    ...state.selectedCurrencyForCreateDeposit,
                                    fullBalance: state.selectedCurrencyForCreateDeposit.balance,
                                }
                                : toCreateNewDeposit[0],
                        },
                        depositWithdrawal: {
                            list: toWithdrawDeposit,
                            selected: state.selectedCurrencyForWithdrawal
                                ? {
                                    ...state.selectedCurrencyForWithdrawal,
                                    fullBalance: state.selectedCurrencyForWithdrawal.balance,
                                }
                                : toWithdrawDeposit[0],
                        },
                    };
                }
            });
            return currencies;
        },
    },
});
