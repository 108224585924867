import getStrategyStatus from '@/web3-server/web3-strategies/actions/composables/get-strategy-status';
export const web3StrategiesActions = {
    getDataById(strategyId, selectedNetworkByCrossChain) {
        let result = null;
        if (strategyId) {
            const strategy = this.getById(strategyId);
            if (strategy) {
                const currentNetworkAndContractAddress = strategy.crossChain &&
                    selectedNetworkByCrossChain &&
                    selectedNetworkByCrossChain.networkId &&
                    selectedNetworkByCrossChain.contractAddress &&
                    selectedNetworkByCrossChain.networkId !== strategy.network
                    ? {
                        networkId: selectedNetworkByCrossChain.networkId,
                        contractAddress: selectedNetworkByCrossChain.contractAddress,
                    }
                    : { networkId: strategy.network, contractAddress: strategy.contract.address };
                result = {
                    data: strategy,
                    currentData: currentNetworkAndContractAddress,
                };
            }
        }
        return result;
    },
    getStatusList(networkId) {
        return getStrategyStatus(this.list, networkId);
    },
};
