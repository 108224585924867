import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "space-y-7"
};
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { class: "mb-2.5 ml-5 text-[2.625rem] tracking-tight leading-12/10 text-white title-italic" };
const _hoisted_4 = { class: "space-y-1.5" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_StrategyHorizon = _resolveComponent("StrategyHorizon");
    return (_ctx.listOfStrategiesByVaults && _ctx.listOfStrategiesByVaults.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listOfStrategiesByVaults, (vault, i) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                    key: vault.type + i
                }, [
                    (vault.list && vault.list.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title[i]), 1),
                            _createElementVNode("div", _hoisted_4, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vault.list, (strategy) => {
                                    return (_openBlock(), _createBlock(_component_StrategyHorizon, {
                                        key: strategy.id,
                                        strategy: strategy.horizon,
                                        onOpen: ($event) => (_ctx.$emit('open', strategy)),
                                        onOpenInfo: ($event) => (_ctx.$emit('openInfo', strategy.route)),
                                        onSelectedCurrency: ($event) => (_ctx.openByCurrency(strategy, $event)),
                                        onSwitchNetwork: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('switchNetwork', $event)))
                                    }, null, 8, ["strategy", "onOpen", "onOpenInfo", "onSelectedCurrency"]));
                                }), 128))
                            ])
                        ]))
                        : _createCommentVNode("", true)
                ], 64));
            }), 128))
        ]))
        : _createCommentVNode("", true);
}
