import { createStore } from 'vuex';
import wallet from '@/store/wallet';
import popup from '@/store/popup';
export default createStore({
    modules: {
        wallet,
        popup,
    },
    getters: {},
    mutations: {},
    actions: {},
});
