import { PROCESS_ID_FOR_TRANSACTION_STEP, TRANSACTION_STEP_ID, } from '@/web3-server/web3/types/transaction-step-by-step/constants';
const defaultMessageOfProcesses = {
    [TRANSACTION_STEP_ID.SWITCH_NETWORK]: [
        { processId: PROCESS_ID_FOR_TRANSACTION_STEP.STARTED, text: 'Switch network' },
        { processId: PROCESS_ID_FOR_TRANSACTION_STEP.PENDING, text: 'Switch network' },
        { processId: PROCESS_ID_FOR_TRANSACTION_STEP.SUCCESS, text: 'Switch network' },
        { processId: PROCESS_ID_FOR_TRANSACTION_STEP.FAILED, text: 'Failed to switch network' },
        { processId: PROCESS_ID_FOR_TRANSACTION_STEP.CANCELLED, text: 'Need to switch network' },
        {
            processId: PROCESS_ID_FOR_TRANSACTION_STEP.CONDITIONS_NOT_MET,
            text: 'Failed to switch network',
        },
    ],
    [TRANSACTION_STEP_ID.APPROVE]: [
        {
            processId: PROCESS_ID_FOR_TRANSACTION_STEP.STARTED,
            text: 'Sign transaction "Approve $amount BLID"',
        },
        { processId: PROCESS_ID_FOR_TRANSACTION_STEP.PENDING, text: 'Approve $amount BLID' },
        { processId: PROCESS_ID_FOR_TRANSACTION_STEP.SUCCESS, text: 'Approved $amount BLID' },
        { processId: PROCESS_ID_FOR_TRANSACTION_STEP.FAILED, text: 'Failed to Approve $amount BLID' },
        {
            processId: PROCESS_ID_FOR_TRANSACTION_STEP.CANCELLED,
            text: 'Failed to Approve $amount BLID',
        },
        {
            processId: PROCESS_ID_FOR_TRANSACTION_STEP.CONDITIONS_NOT_MET,
            text: 'Required amount was not approved',
        },
    ],
    [TRANSACTION_STEP_ID.MINT]: [
        {
            processId: PROCESS_ID_FOR_TRANSACTION_STEP.STARTED,
            text: 'Sign transaction "Mint $amount NFT"',
        },
        { processId: PROCESS_ID_FOR_TRANSACTION_STEP.PENDING, text: 'Mint $amount NFT' },
        { processId: PROCESS_ID_FOR_TRANSACTION_STEP.SUCCESS, text: 'Mint $amount NFT' },
        { processId: PROCESS_ID_FOR_TRANSACTION_STEP.FAILED, text: 'Failed to Mint $amount NFT' },
        { processId: PROCESS_ID_FOR_TRANSACTION_STEP.CANCELLED, text: 'Failed to Mint $amount NFT' },
        {
            processId: PROCESS_ID_FOR_TRANSACTION_STEP.CONDITIONS_NOT_MET,
            text: 'Failed to Mint $amount NFT',
        },
    ],
};
const universalProcessMessage = {
    [PROCESS_ID_FOR_TRANSACTION_STEP.NOT_STARTED]: 'Preparing for the transaction',
    [PROCESS_ID_FOR_TRANSACTION_STEP.STARTED]: 'Please sign the transaction',
    [PROCESS_ID_FOR_TRANSACTION_STEP.PENDING]: 'Waiting for the transaction',
    [PROCESS_ID_FOR_TRANSACTION_STEP.FAILED]: 'Transaction failed',
    [PROCESS_ID_FOR_TRANSACTION_STEP.SUCCESS]: 'Transaction completed',
    [PROCESS_ID_FOR_TRANSACTION_STEP.CANCELLED]: 'Signature required',
    [PROCESS_ID_FOR_TRANSACTION_STEP.CONDITIONS_NOT_MET]: 'Transaction failed',
};
export const stepByStepTransactionHeader = {
    [TRANSACTION_STEP_ID.SWITCH_NETWORK]: 'Switch network',
    [TRANSACTION_STEP_ID.APPROVE]: 'Approve BLID',
    [TRANSACTION_STEP_ID.MINT]: 'Mint NFT',
};
export const stepTransactionMessages = {
    default: defaultMessageOfProcesses,
    universal: universalProcessMessage,
};
