export var NETWORK_ID;
(function (NETWORK_ID) {
    NETWORK_ID["BNB"] = "BNB";
    NETWORK_ID["ETH"] = "ETH";
    NETWORK_ID["POLYGON"] = "POLYGON";
    NETWORK_ID["ARBITRUM"] = "ARBITRUM";
    NETWORK_ID["OPTIMISM"] = "OPTIMISM";
    NETWORK_ID["GOERLI"] = "GOERLI";
})(NETWORK_ID || (NETWORK_ID = {}));
export var STARGATE_NETWORK_ID;
(function (STARGATE_NETWORK_ID) {
    STARGATE_NETWORK_ID["POLYGON"] = "POLYGON";
    STARGATE_NETWORK_ID["BNB"] = "BNB";
    STARGATE_NETWORK_ID["ETH"] = "ETH";
})(STARGATE_NETWORK_ID || (STARGATE_NETWORK_ID = {}));
export var WALLET_NETWORK_ID;
(function (WALLET_NETWORK_ID) {
    WALLET_NETWORK_ID["BNB_STARGATE"] = "STARGATE_BNB";
    WALLET_NETWORK_ID["POLYGON_STARGATE"] = "STARGATE_POLYGON";
    WALLET_NETWORK_ID["ETH_STARGATE"] = "STARGATE_ETH";
    WALLET_NETWORK_ID["ARBITRUM"] = "ARBITRUM";
    WALLET_NETWORK_ID["OPTIMISM"] = "OPTIMISM";
    WALLET_NETWORK_ID["GOERLI"] = "GOERLI";
})(WALLET_NETWORK_ID || (WALLET_NETWORK_ID = {}));
export var NETWORK_RECEIVE_STATUSES;
(function (NETWORK_RECEIVE_STATUSES) {
    NETWORK_RECEIVE_STATUSES["SUCCESS"] = "SUCCESS";
    NETWORK_RECEIVE_STATUSES["CHAIN_ID_MISSING"] = "CHAIN_ID_MISSING";
    NETWORK_RECEIVE_STATUSES["ALLOWED_NETWORK_NOT_FOUND"] = "ALLOWED_NETWORK_NOT_FOUND";
})(NETWORK_RECEIVE_STATUSES || (NETWORK_RECEIVE_STATUSES = {}));
