import { sendEvent } from '@/web3-server/web3/handler/sendEvent';
import { PID } from '@/infra/types/contracts/constants';
import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
import { getSendOptions } from '@/web3-server/web3/handler/get-send-options';
export default async function claim(request, strategyContract, supportEIP1559) {
    const { web3 } = request;
    const { type: contractType } = strategyContract;
    const contract = new web3.eth.Contract(strategyContract.abi, strategyContract.address);
    const getContractMethod = async () => {
        if (contractType === CONTRACT_TYPE.STORAGE) {
            return contract.methods.claimAllRewardBLID();
        }
        else if (contractType === CONTRACT_TYPE.STAKING) {
            return contract.methods.leaveStaking(0);
        }
        else if (contractType === CONTRACT_TYPE.FARMING) {
            return contract.methods.withdraw(PID.FAMING_BLID_USDT, 0);
        }
    };
    const sendOptions = await getSendOptions(request, { supportEIP1559 });
    getContractMethod().then((method) => {
        sendEvent(request, method.send(sendOptions));
    });
}
