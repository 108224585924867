import { decimalFormatting, useFormatCurrency, } from '@/composables/useFormatCurrency';
const strategiesAdapter = {
    getTVL: getTVL,
    getAPYOrAPR: getAPYOrAPR,
    getBoosting: getBoosting,
};
export default strategiesAdapter;
function getTVL(tvl) {
    if (tvl && isFinite(tvl)) {
        let digits = 0;
        if (tvl >= 1000000) {
            digits = 2;
        }
        else if (tvl >= 100000000) {
            digits = 1;
        }
        return useFormatCurrency(tvl, digits, true);
    }
    else {
        return '0';
    }
}
function getAPYOrAPR(val) {
    if (val && isFinite(val)) {
        return decimalFormatting(val, 0, { transformLargeNums: true, isRounding: 'default' });
    }
    else {
        return '0';
    }
}
function getBoosting(val) {
    if (val && isFinite(val)) {
        return decimalFormatting(val, 2, { transformLargeNums: true, isRounding: 'default' });
    }
    else {
        return '0';
    }
}
