import StargateFeeLibrary from '@/infra/abis/StargateFeeLibrary.json';
import Web3 from 'web3';
import Decimal from 'decimal.js';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import converter from '@/web3-server/utils/converter';
export default async function getConversionFee(userAddress, rpcUrl, feeLibrary, chainId, fromPoolId, toPoolId, amountSimple) {
    const defaultDecimal = new Decimal(0);
    try {
        const web = new Web3(rpcUrl);
        const contract = new web.eth.Contract(StargateFeeLibrary, feeLibrary);
        const amountWei = converter.withoutType.toWei(amountSimple.value, { decimals: 6 });
        const swapAmount = contract.methods
            .getFees(fromPoolId, toPoolId, chainId, userAddress, amountWei.toFixed())
            .call();
        return swapAmount
            .then((response) => {
            return amountWei
                .minus(response[1])
                .minus(response[4])
                .minus(response[3])
                .minus(response[2]);
        })
            .catch(() => defaultDecimal);
    }
    catch (e) {
        devError(DEV_ERROR_CODE.WEB_CONTRACT);
        return defaultDecimal;
    }
}
