import Decimal from 'decimal.js';
const commonDecimal = {
    wei: new Decimal(0),
    simple: new Decimal(0),
    simpleInStringFormat: '0',
};
const strategyTemplate = {
    commonData: {
        apy: commonDecimal,
        apr: commonDecimal,
        tvl: commonDecimal,
        boosting: {
            apy: commonDecimal,
            apr: commonDecimal,
            pureAPR: commonDecimal,
        },
    },
    deposit: {
        depositAmountInUSD: commonDecimal,
        earnedBLID: commonDecimal,
        percentageYield: commonDecimal,
        depositedBoostInBLID: commonDecimal,
        userBoosting: {
            apy: commonDecimal,
            apr: commonDecimal,
        },
    },
};
export function getStrategyTemplate() {
    return getTemplate({ ...strategyTemplate });
}
function getTemplate(readyTemplate) {
    const template = {};
    Object.keys(readyTemplate).forEach((key) => {
        const value = readyTemplate[key];
        if (value && 'wei' in value) {
            template[key] = { ...value };
        }
        else if (value) {
            template[key] = getTemplate(value);
        }
    });
    return template;
}
