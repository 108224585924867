import BloctoSDK from '@blocto/sdk';
import { walletNetworkById } from '@/web3-server/wallet/data/network';
import { devError } from '@/utils/devError';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import linker from '@/web3-server/utils/linker';
const bloctoActions = {
    getBloctoNetwork() {
        const currentNetwork = localStorage.getItem(this.blocto.currentNetwork.key) || NETWORK_ID.BNB;
        return linker.getNetworkId(currentNetwork);
    },
    setBloctoNetwork(networkId) {
        localStorage.setItem(this.blocto.currentNetwork.key, networkId);
        this.blocto.currentNetwork.value = networkId;
    },
    removeBloctoNetwork() {
        localStorage.removeItem(this.blocto.currentNetwork.key);
        this.blocto.currentNetwork.value = NETWORK_ID.BNB;
    },
    instantiateBloctoSDK(chainIdInHexFormat, rpcUrl) {
        return new BloctoSDK({
            ethereum: {
                chainId: chainIdInHexFormat,
                rpc: rpcUrl,
            },
            appId: '5a708c84-3d89-4fdf-828a-503bbfe661dd',
        });
    },
    initBloctoSDK() {
        const networkId = this.getBloctoNetwork();
        const network = walletNetworkById[networkId];
        const sdk = this.instantiateBloctoSDK(network.chainId.hex, network.rpcUrl);
        this.blocto.sdk = sdk;
        return sdk;
    },
    getBloctoProviderToRead() {
        return this.blocto.sdk ? this.blocto.sdk.ethereum : undefined;
    },
    getBloctoProviderToWrite() {
        const networkId = this.getBloctoNetwork();
        const network = walletNetworkById[networkId];
        return this.instantiateBloctoSDK(network.chainId.hex, network.rpcUrl).ethereum;
    },
    getBloctoSession() {
        const defaultBloctoSDK = this.getBloctoProviderToRead();
        let currentSession = null;
        if (defaultBloctoSDK) {
            if (defaultBloctoSDK.sessionKey) {
                const { sessionKey } = defaultBloctoSDK;
                currentSession = JSON.parse(localStorage.getItem(sessionKey) || '{}');
            }
        }
        return currentSession && currentSession.expiry && currentSession.value ? currentSession : null;
    },
    isBloctoConnected() {
        const provider = this.getBloctoProviderToRead();
        const session = this.getBloctoSession();
        return !!(provider && session && provider.session.connected);
    },
    async removeBloctoSession() {
        try {
            const defaultBloctoSDK = this.getBloctoProviderToRead();
            if (defaultBloctoSDK && defaultBloctoSDK.session && defaultBloctoSDK.session.connected) {
                await defaultBloctoSDK.request({ method: 'wallet_disconnect' });
                localStorage.removeItem(defaultBloctoSDK.sessionKey);
            }
        }
        catch (e) {
            devError(e);
        }
    },
};
export default bloctoActions;
