import { useWeb3Store } from '@/web3-server';
import { devError } from '@/utils/devError';
function getCrossChainMethodsForNotify(crossChainOperation) {
    const web3Store = useWeb3Store();
    const crossChainMethodsForNotify = {
        myNetwork: {
            async onSuccess(hash) {
                const toastId = crossChainOperation.sentToBridge.onSuccess({ hash });
                return crossChainMethodsForNotify.toBNB(hash, toastId);
            },
            onError(props) {
                crossChainOperation.sentToBridge.onError(props);
            },
        },
        async toBNB(hash, toastId) {
            if (hash) {
                const props = {
                    toastId,
                    hash,
                };
                return web3Store
                    .checkTransactionStatus(hash)
                    .then(() => {
                    crossChainOperation.transactionCompleted.onSuccess(props);
                    return true;
                })
                    .catch((e) => {
                    devError(e);
                    crossChainOperation.transactionCompleted.onError(props);
                    return false;
                });
            }
            else {
                return false;
            }
        },
    };
    return crossChainMethodsForNotify;
}
export default getCrossChainMethodsForNotify;
