import { sendEvent } from '@/web3-server/web3/handler/sendEvent';
import { getSendOptions } from '@/web3-server/web3/handler/get-send-options';
import converter from '@/web3-server/utils/converter';
import checker from '@/web3-server/utils/checker';
export default function depositBoosting(request, strategyContract, amount, currencyDecimals, depositedWei, supportEIP1559) {
    const { web3 } = request;
    const storageContract = new web3.eth.Contract(strategyContract.abi, strategyContract.address);
    const amountWei = converter.withoutType.toWei(amount, {
        decimals: currencyDecimals,
        round: true,
    });
    const send = (amount) => {
        getSendOptions(request, { supportEIP1559 }).then((sendOptions) => {
            sendEvent(request, storageContract.methods.depositBLID(amount.toFixed()).send(sendOptions));
        });
    };
    if (depositedWei && checker.decimal.isNotEmpty(depositedWei)) {
        const requiredAmount = amountWei.minus(depositedWei);
        if (checker.decimal.isNotEmpty(requiredAmount)) {
            send(requiredAmount);
        }
        else {
            send(amountWei);
        }
    }
    else {
        send(amountWei);
    }
}
