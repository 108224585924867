import axios from 'axios';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
export default async function getBLIDPriceFromLiFi(params = {
    chain: 'POL',
    token: {
        from: '0x4b27Cd6E6a5E83d236eAD376D256Fe2F9e9f0d2E',
        to: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    },
}) {
    try {
        const requestUrl = 'https://li.quest/v1/quote';
        const options = {
            params: {
                fromChain: params.chain,
                toChain: params.chain,
                fromToken: params.token.from,
                toToken: params.token.to,
                fromAddress: '0x552008c0f6870c2f77e5cC1d2eb9bdff03e30Ea0',
                toAddress: '0x552008c0f6870c2f77e5cC1d2eb9bdff03e30Ea0',
                fromAmount: '1000000000000000000',
                order: 'RECOMMENDED',
                slippage: '0.005',
                integrator: 'tst',
                allowExchanges: '',
            },
        };
        return axios.get(requestUrl, options);
    }
    catch (e) {
        devError(DEV_ERROR_CODE.AXIOS);
        return null;
    }
}
