export var STRATEGY_CARD_TYPE;
(function (STRATEGY_CARD_TYPE) {
    STRATEGY_CARD_TYPE["GENERAL"] = "LOW RISK STRATEGIES";
    STRATEGY_CARD_TYPE["STAKING"] = "STAKING";
    STRATEGY_CARD_TYPE["FARMING"] = "FARMING";
})(STRATEGY_CARD_TYPE || (STRATEGY_CARD_TYPE = {}));
export var STRATEGY_TABS;
(function (STRATEGY_TABS) {
    STRATEGY_TABS["MANAGE"] = "manage";
    STRATEGY_TABS["DEPOSIT"] = "deposit";
    STRATEGY_TABS["BOOST"] = "boost";
    STRATEGY_TABS["INFO"] = "info";
})(STRATEGY_TABS || (STRATEGY_TABS = {}));
