import { decimalFormatting } from '@/composables/useFormatCurrency';
export function currencyListAdapter(currencyList, isOpenDeposit) {
    return currencyList.map((currency) => currencyAdapter(currency, isOpenDeposit));
}
export function currencyAdapter(currency, isOpenDeposit) {
    const fullBalance = isOpenDeposit ? currency.balance : currency.deposited;
    return {
        id: currency.id,
        address: currency.address,
        symbol: currency.symbol,
        name: currency.name,
        subtitle: currency.subtitle,
        balance: decimalFormatting(fullBalance, 2, { showWithManyZeros: true }),
        fullBalance,
        approved: currency.approved,
    };
}
export function earnedIncomeAdapter(earnedIncome) {
    return {
        id: earnedIncome.id,
        symbol: earnedIncome.symbol,
        balance: earnedIncome.balance,
    };
}
