import { validErrorsWhileWaitingForTransaction, waitForTxReceiptAndTransaction, waitTransaction, } from '@/web3-server/web3/handler/wait';
export function sendEvent(request, promise, transactionPendingSettings) {
    const { events, web3 } = request;
    const transaction = (completed, response) => {
        if (events && events.transactionCompleted) {
            events.transactionCompleted(completed, response);
        }
    };
    const user = (allowed, hash, error) => {
        if (events && events.allowedByUser) {
            events.allowedByUser(allowed, hash, error);
        }
    };
    let transactionHash = '';
    let transactionReceipt = null;
    promise
        .on('transactionHash', (hash) => {
        transactionHash = hash;
        user(true, hash);
    })
        .then((receipt) => {
        transactionReceipt = receipt;
        waitTransaction(receipt, web3, transactionPendingSettings)
            .then(() => {
            transaction(true, { receipt, hash: transactionHash });
        })
            .catch((e) => {
            transaction(false, { receipt, hash: transactionHash, error: e });
        });
    })
        .catch((e) => {
        if (!transactionHash) {
            user(false, undefined, e);
        }
        else {
            if (validErrorsWhileWaitingForTransaction(e) && transactionHash) {
                waitForTxReceiptAndTransaction(request.web3, transactionHash).then((result) => {
                    transaction(result.isSuccess, { receipt: result.receipt, hash: transactionHash });
                });
            }
            else {
                transaction(false, { receipt: transactionReceipt, hash: transactionHash, error: e });
            }
        }
    });
}
