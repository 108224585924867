import { NETWORK_SWITCH_STATUSED } from '@/web3-server/wallet/types/provider/constants';
export default async function switchNetwork(provider, network) {
    try {
        await provider.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: network.chainId }],
        });
        return NETWORK_SWITCH_STATUSED.SUCCESS;
    }
    catch (e) {
        const err = e;
        if (err && err.code === 4902) {
            try {
                await provider.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [network],
                });
                return NETWORK_SWITCH_STATUSED.SUCCESS;
            }
            catch (e) {
                return NETWORK_SWITCH_STATUSED.ERROR;
            }
        }
        else {
            return NETWORK_SWITCH_STATUSED.ERROR;
        }
    }
}
