import { defineStore } from 'pinia';
import Web3 from 'web3';
import depositActions from '@/web3-server/web3/actions/deposit';
import strategiesActions from '@/web3-server/web3/actions/strategies/strategies-actions';
import currenciesAction from '@/web3-server/web3/actions/currencies';
import BLIDPriceAction from '@/web3-server/web3/actions/blid-price';
import userInfoActions from '@/web3-server/web3/actions/user-info';
import crossChainActions from '@/web3-server/web3/actions/cross-chain/cross-chain-actions';
import Decimal from 'decimal.js';
import web3Constants from '@/web3-server/web3/data/web3-constants';
import { STRATEGY_VAULT } from '@/web3-server/web3-strategies/types/config/constants';
import defaultNetwork from '@/web3-server/wallet/data/cross-chain/default-network';
import { NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import nftActions from '@/web3-server/web3/actions/nft';
import NFTCollection from '@/web3-server/abis/nft/collection.json';
import { NFT_ID } from '@/web3-server/web3/types/nft/constants';
import transactionStepByStep from '@/web3-server/web3/actions/transaction-step-by-step';
const web3BNB = new Web3(defaultNetwork.BNB.rpcUrl);
const web3Goerli = new Web3(defaultNetwork.GOERLI.rpcUrl);
const web3ByVault = {
    [STRATEGY_VAULT.BNB]: web3BNB,
    [STRATEGY_VAULT.BNB_V2]: web3BNB,
    [STRATEGY_VAULT.POLYGON]: new Web3(defaultNetwork.POLYGON.rpcUrl),
    [STRATEGY_VAULT.ETH]: new Web3(defaultNetwork.ETH.rpcUrl),
    [STRATEGY_VAULT.ARBITRUM]: new Web3(defaultNetwork.ARBITRUM.rpcUrl),
    [STRATEGY_VAULT.OPTIMISM]: new Web3(defaultNetwork.OPTIMISM.rpcUrl),
};
export const useWeb3Store = defineStore('web3', {
    state: () => ({
        web3: null,
        web3ByVault,
        web3ByNetwork: {
            [NETWORK_ID.BNB]: web3ByVault.BNB,
            [NETWORK_ID.POLYGON]: web3ByVault.POLYGON,
            [NETWORK_ID.ETH]: web3ByVault.ETH,
            [NETWORK_ID.ARBITRUM]: web3ByVault.ARBITRUM,
            [NETWORK_ID.OPTIMISM]: web3ByVault.OPTIMISM,
            [NETWORK_ID.GOERLI]: web3Goerli,
        },
        BLIDPrice: {
            original: '',
            wei: new Decimal(0),
            formatted: 0,
        },
        BLIDPriceByNetwork: {
            [NETWORK_ID.BNB]: {
                wei: new Decimal(0),
                simple: new Decimal(0),
                simpleInStringFormat: '0',
            },
            [NETWORK_ID.POLYGON]: {
                wei: new Decimal(0),
                simple: new Decimal(0),
                simpleInStringFormat: '0',
            },
            [NETWORK_ID.ETH]: {
                wei: new Decimal(0),
                simple: new Decimal(0),
                simpleInStringFormat: '0',
            },
            [NETWORK_ID.ARBITRUM]: {
                wei: new Decimal(0),
                simple: new Decimal(0),
                simpleInStringFormat: '0',
            },
            [NETWORK_ID.OPTIMISM]: {
                wei: new Decimal(0),
                simple: new Decimal(0),
                simpleInStringFormat: '0',
            },
            [NETWORK_ID.GOERLI]: {
                wei: new Decimal(0),
                simple: new Decimal(0),
                simpleInStringFormat: '0',
            },
        },
        constants: web3Constants,
        nftCollection: {
            address: process.env.VUE_APP_NFT_COLLECTION_ADDRESS,
            abi: NFTCollection,
            activeTokenId: NFT_ID.AUGUST,
            networkId: NETWORK_ID.POLYGON,
        },
        openBuyBLID: () => {
            //
        },
    }),
    actions: {
        ...depositActions,
        ...strategiesActions,
        ...currenciesAction,
        ...BLIDPriceAction,
        ...userInfoActions,
        ...crossChainActions,
        ...nftActions,
        ...transactionStepByStep,
    },
});
