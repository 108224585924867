import { useWeb3Store } from '@/web3-server';
import { TRANSACTION_TYPES } from '@/infra/types/transactions/constants';
import { depositEventHandler, depositEventHandlerV2, } from '@/store/strategies/actions/deposit/composables/deposit-event-handler';
import { useCurrenciesStore } from '@/store/currencies';
import { useDialogStore } from '@/store/dialog';
import { CONTRACT_TYPE } from '@/web3-server/web3/types/strategies/constants';
import { NETWORK_ID, STARGATE_NETWORK_ID } from '@/web3-server/wallet/types/network/constants';
import Decimal from 'decimal.js';
import getDepositGasFee from '@/web3-server/web3/actions/cross-chain/get-deposit-gas-fee';
import { networkForCrossChain } from '@/web3-server/wallet/data/network';
import converter from '@/web3-server/utils/converter';
import { myDialog } from '@/store/my-dialog';
import { devError } from '@/utils/devError';
import { DEV_ERROR_CODE } from '@/infra/types/dev-error/constants';
import getOperations from '@/store/operations/operations';
import { useWalletStore } from '@/web3-server/wallet';
import linker from '@/web3-server/utils/linker';
import myWallet from '@/store/wallet/my-wallet';
import { NETWORK_SWITCH_STATUSED } from '@/web3-server/wallet/types/provider/constants';
import { getStrategyAndCurrencyFromWeb3 } from '@/web3-server/web3/handler/inspectors';
import { GA_EVENT_NAME } from '@/infra/types/ga-manager/constants';
import GAManager from '@/utils/ga-manager/ga-manager';
const strategiesDepositActions = {
    async crossChainDeposit(params) {
        const { strategyId, currencyId, selectedNetwork, amount, crossChainSummary, selectedCurrencyInMultipleList, } = params;
        const { strategy, currency } = getStrategyAndCurrencyFromWeb3({
            strategyId,
            currencyId,
        });
        const depositAmount = converter.withType.toWei(new Decimal(amount.replace(/\s/, '') || 0), {
            decimals: 6,
            round: true,
        });
        const crossChainDepositAmount = linker.decimal.getSimple(crossChainSummary);
        if (selectedNetwork && strategy && currency) {
            const currencyType = currency.symbol;
            const GAEvent = GAManager.hooks.default({ eventName: GA_EVENT_NAME.CROSS_CHAIN_DEPOSIT, prefix: strategy.GAPrefix }, { currencyType: currency.symbol, amount });
            const walletStore = useWalletStore();
            const dialogStore = useDialogStore();
            const currenciesStore = useCurrenciesStore();
            const checkNetwork = () => {
                let networksMatch = true;
                const currentNetwork = walletStore.network;
                if (!currentNetwork) {
                    networksMatch = false;
                }
                else if (selectedNetwork) {
                    if (currentNetwork &&
                        (currentNetwork.id !== selectedNetwork.networkId ||
                            (currentNetwork.crossChain &&
                                currentNetwork.crossChain.bridgeType !== selectedNetwork.bridgeType))) {
                        networksMatch = false;
                    }
                }
                else if (currentNetwork.id !== NETWORK_ID.BNB) {
                    networksMatch = false;
                }
                return networksMatch;
            };
            const bnb = networkForCrossChain[selectedNetwork.bridgeType].BNB;
            const fromCurrency = currenciesStore.getCurrency(currencyType, {
                bridgeType: selectedNetwork.bridgeType,
                id: selectedNetwork.id,
                networkId: selectedNetwork.networkId,
            });
            const toCurrency = currenciesStore.getCurrency(currencyType, {
                bridgeType: selectedNetwork.bridgeType,
                id: STARGATE_NETWORK_ID.BNB,
                networkId: NETWORK_ID.BNB,
            });
            const gas = await getDepositGasFee(selectedNetwork.rpcUrl, selectedNetwork.contractAddress, bnb.chainId, selectedNetwork.dstGasForCall || 0);
            if (fromCurrency && toCurrency && gas) {
                const setConfirmTransaction = (val) => {
                    if (val) {
                        myDialog.openConfirmTransaction();
                    }
                    else {
                        dialogStore.close();
                    }
                };
                const reopenFunc = dialogStore.current && 'reopen' in dialogStore.current
                    ? dialogStore.current.reopen
                    : undefined;
                const reopenCb = () => {
                    if (reopenFunc) {
                        reopenFunc(selectedCurrencyInMultipleList
                            ? { selectedCurrency: selectedCurrencyInMultipleList, selectedNetwork }
                            : { selectedCurrencyType: currencyType, selectedNetwork });
                    }
                    else {
                        myDialog.goHome();
                    }
                };
                const openErrorNotify = () => {
                    myDialog.openNotifyAboutTransaction({ isSuccess: false }, { closingCb: reopenCb });
                };
                const error = () => {
                    setConfirmTransaction(false);
                    openErrorNotify();
                    if (GAEvent && GAEvent.after) {
                        GAEvent.after(false);
                    }
                };
                const depositCrossChain = () => {
                    // const crossChainEvent = web3Store.depositCrossChain(
                    //   {
                    //     bridgeType: selectedNetwork.bridgeType,
                    //     id: selectedNetwork.id,
                    //     networkId: selectedNetwork.networkId,
                    //     contractAddress: selectedNetwork.contractAddress,
                    //   },
                    //   { from: fromCurrency.address, to: toCurrency.address },
                    //   { amount: depositAmount, fee: gas, amountWithCommission: crossChainDepositAmount },
                    // );
                    //
                    // if (crossChainEvent) {
                    //   let toastId: number | string = Date.now();
                    //   let savedHash: string | undefined = undefined;
                    //   const crossChainOperation = getOperations(
                    //     {
                    //       currencyType,
                    //       availableForUse: {
                    //         sendEventsToGA: { readyEvents: GAEvent },
                    //         addToWalletHistory: {
                    //           amount,
                    //           transactionType: TRANSACTION_TYPES.DEPOSIT,
                    //         },
                    //       },
                    //     },
                    //     openErrorNotify,
                    //   ).crossChain({
                    //     walletNetworkId: walletNetworkById[selectedNetwork.id].walletId,
                    //     selectedCurrencyInMultipleList:
                    //       selectedCurrencyInMultipleList && selectedCurrencyInMultipleList.symbol,
                    //   });
                    //   const events = {
                    //     allowedByUser: (event: CrossChainEventABU) => {
                    //       if (event && event.detail) {
                    //         setConfirmTransaction(false);
                    //
                    //         if (event.detail.allowed) {
                    //           const params = crossChainOperation.allowedByUser.onSuccess(event.detail.hash);
                    //
                    //           toastId = params.toastId;
                    //           savedHash = event.detail.hash;
                    //         } else {
                    //           crossChainOperation.allowedByUser.onError();
                    //         }
                    //       }
                    //
                    //       crossChainEvent.removeEventListener(
                    //         crossChainEvent.defaultEvents.allowedByUser,
                    //         events.allowedByUser,
                    //       );
                    //     },
                    //     sentToBridge: (event: CrossChainEventTransaction) => {
                    //       if (event && event.detail) {
                    //         if (event.detail.completed) {
                    //           toastId = crossChainOperation.sentToBridge.onSuccess({
                    //             toastId,
                    //             hash: savedHash,
                    //           });
                    //         } else {
                    //           crossChainOperation.sentToBridge.onError({ toastId, hash: savedHash });
                    //         }
                    //       }
                    //
                    //       crossChainEvent.removeEventListener(
                    //         crossChainEvent.defaultEvents.sentToBridge,
                    //         events.sentToBridge,
                    //       );
                    //     },
                    //     transactionCompleted: (event: CrossChainEventTransaction) => {
                    //       if (event && event.detail) {
                    //         if (event.detail.completed) {
                    //           crossChainOperation.transactionCompleted.onSuccess({
                    //             toastId,
                    //             hash: savedHash,
                    //           });
                    //         } else {
                    //           crossChainOperation.transactionCompleted.onError({
                    //             toastId,
                    //             hash: savedHash,
                    //           });
                    //         }
                    //       }
                    //
                    //       crossChainEvent.removeEventListener(
                    //         crossChainEvent.defaultEvents.transactionCompleted,
                    //         events.transactionCompleted,
                    //       );
                    //     },
                    //   };
                    //
                    //   if (GAEvent && GAEvent.before) {
                    //     GAEvent.before();
                    //   }
                    //
                    //   setConfirmTransaction(true);
                    //   crossChainEvent.addEventListener(
                    //     crossChainEvent.defaultEvents.allowedByUser,
                    //     events.allowedByUser,
                    //   );
                    //   crossChainEvent.addEventListener(
                    //     crossChainEvent.defaultEvents.sentToBridge,
                    //     events.sentToBridge,
                    //   );
                    //   crossChainEvent.addEventListener(
                    //     crossChainEvent.defaultEvents.transactionCompleted,
                    //     events.transactionCompleted,
                    //   );
                    // }
                };
                if (checkNetwork()) {
                    depositCrossChain();
                }
                else {
                    myWallet.switchNetwork(selectedNetwork.networkId).then((result) => {
                        if (result === NETWORK_SWITCH_STATUSED.SUCCESS) {
                            depositCrossChain();
                        }
                        else if (result === NETWORK_SWITCH_STATUSED.ERROR) {
                            devError(DEV_ERROR_CODE.SWITCH_NETWORK);
                            error();
                        }
                    });
                }
            }
        }
        else {
            myDialog.openNotifyAboutTransaction({ isSuccess: false }, { stepBack: 1 });
        }
    },
    approve(params) {
        const dialogStore = useDialogStore();
        const currenciesStore = useCurrenciesStore();
        const { currencyType, currencyAddress, contractAddress, networkId, successCallback } = params;
        const reopenFunc = dialogStore.current && 'reopen' in dialogStore.current
            ? dialogStore.current.reopen
            : undefined;
        const clearCurrency = () => {
            const index = currenciesStore.currencyToBeApproved.findIndex((item) => item.currencyAddress === currencyAddress && item.contractAddress === contractAddress);
            if (index > -1) {
                currenciesStore.currencyToBeApproved.splice(index, 1);
            }
        };
        const GAEvent = GAManager.hooks.approve({ eventName: params.GAEventName || GA_EVENT_NAME.APPROVE_DEPOSIT, prefix: params.GAPrefix }, { currencyType: params.currencyType });
        // const approvedCurrency: IApprovedCurrency = { contractAddress, currencyAddress };
        const defaultOperationProps = {
            currencyType,
            availableForUse: {
                sendEventsToGA: { readyEvents: GAEvent },
                addToWalletHistory: {
                    amount: '0',
                    transactionType: TRANSACTION_TYPES.APPROVE,
                },
            },
        };
        // currenciesStore.currencyToBeApproved.push(approvedCurrency);
        const approveFunction = (currency) => {
            const web3Store = useWeb3Store();
            let toastId = Date.now();
            let savedHash = undefined;
            const approveOperation = getOperations(defaultOperationProps).approve({
                networkId,
                currencyAddress,
                contractAddress,
            });
            const defaultApproveEvents = {
                allowedByUser: (allowed, hash) => {
                    if (reopenFunc) {
                        reopenFunc({
                            selectedCurrencyType: currencyType,
                            selectedStargateNetworkId: linker.bridgeNetwork(networkId)?.id,
                        });
                    }
                    else {
                        dialogStore.goBack();
                    }
                    if (GAEvent && GAEvent.signature) {
                        GAEvent.signature(allowed);
                    }
                    if (allowed) {
                        savedHash = hash;
                        toastId = approveOperation.allowedByUser.onSuccess(hash);
                    }
                    else {
                        approveOperation.allowedByUser.onError();
                    }
                },
                transactionCompleted: async (completed) => {
                    if (completed) {
                        const approvedAmount = await approveOperation.transactionCompleted.onSuccess({
                            toastId,
                            hash: savedHash,
                        });
                        currency.approved = approvedAmount;
                        if (successCallback) {
                            successCallback(approvedAmount);
                        }
                    }
                    else {
                        approveOperation.transactionCompleted.onError({ toastId, hash: savedHash });
                    }
                },
            };
            // if (supportsEIP1559) {
            //   web3Store.approveCurrencyEIP1559(currency.address, contractAddress, defaultApproveEvents);
            // } else {
            web3Store.approveCurrency(currency.address, contractAddress, defaultApproveEvents);
            // }
        };
        depositEventHandler({
            networkId,
            currencyType,
            callback: approveFunction,
            propsDefaultOperation: defaultOperationProps,
            savePosition: {
                selectedNetworkId: networkId,
            },
            selectedNetworkForTransaction: linker.bridgeNetwork(networkId),
            GAEvent,
            errorCallback() {
                clearCurrency();
            },
        });
    },
    createDeposit(params, strategyRouteForPopupReopening) {
        const { strategyId, currencyId, amount, selectedNetwork } = params;
        const depositCreationFunction = ({ strategy, defaultEvents, }) => {
            const web3Store = useWeb3Store();
            web3Store.openDeposit(strategyId, currencyId, amount, {
                allowedByUser: (allowed, hash, error) => defaultEvents.allowedByUser(allowed, hash, error),
                transactionCompleted: (completed, response) => {
                    if (completed && strategyRouteForPopupReopening) {
                        myDialog.openSuccessDeposit(strategyRouteForPopupReopening, strategy.contract.type === CONTRACT_TYPE.STORAGE);
                    }
                    defaultEvents.transactionCompleted(completed, response);
                },
            });
        };
        depositEventHandlerV2({
            callback: depositCreationFunction,
            mainParams: {
                strategyId,
                currencyId,
                amount,
                transactionType: TRANSACTION_TYPES.DEPOSIT,
                GAEventName: GA_EVENT_NAME.DEPOSIT,
            },
            savePosition: {
                selectedNetwork,
                selectedCurrencyInMultipleList: params.selectedCurrencyInMultipleList,
            },
            selectedCrossChainNetwork: selectedNetwork,
        });
    },
    withdraw(params) {
        const { strategyId, currencyId, amount } = params;
        const withdrawFunction = ({ defaultEvents }) => {
            const web3Store = useWeb3Store();
            web3Store.withdraw(strategyId, currencyId, amount, {
                allowedByUser: (allowed, hash, error) => defaultEvents.allowedByUser(allowed, hash, error),
                transactionCompleted: (completed, response) => defaultEvents.transactionCompleted(completed, response),
            });
        };
        depositEventHandlerV2({
            callback: withdrawFunction,
            mainParams: {
                strategyId,
                currencyId,
                amount,
                transactionType: TRANSACTION_TYPES.WITHDRAW,
                GAEventName: GA_EVENT_NAME.WITHDRAW,
            },
        });
    },
    claim(params, strategyRouteForPopupReopening, isBoostingV2) {
        const { strategyId, currencyId, amount } = params;
        const claimFunction = ({ strategy, defaultEvents }) => {
            const web3Store = useWeb3Store();
            web3Store.claim(strategyId, {
                allowedByUser: (allowed, hash) => defaultEvents.allowedByUser(allowed, hash),
                transactionCompleted: (completed) => {
                    if (completed &&
                        strategy.contract.type === CONTRACT_TYPE.STORAGE &&
                        strategyRouteForPopupReopening) {
                        myDialog.openSuccessClaim(strategyRouteForPopupReopening, isBoostingV2 === true);
                    }
                    defaultEvents.transactionCompleted(completed);
                },
            });
        };
        depositEventHandlerV2({
            callback: claimFunction,
            mainParams: {
                strategyId,
                currencyId,
                amount,
                transactionType: TRANSACTION_TYPES.CLAIM,
                GAEventName: GA_EVENT_NAME.CLAIM,
            },
            savePosition: {
                selectedNetwork: params.selectedNetwork,
                selectedCurrencyInMultipleList: params.selectedCurrencyInMultipleList,
            },
        });
    },
};
export default strategiesDepositActions;
